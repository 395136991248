import React, { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import { Button, Fade, Icon } from '@column/column-ui-kit';

import { Headline, Inner } from '~/styles';

export const MODAL_ROOT_CLASSNAME = 'modal-portal-root';

interface ModalButtonProps {
  isLoading?: boolean;
  onClick: () => void;
  text: string;
}

export enum ModalSize {
  FitContent = 'fit-content',
  Small = 'small',
  Medium = 'medium',
}

export enum ModalVariant {
  Compact = 'compact',
  Normal = 'normal',
}

export interface ModalProps {
  headline?: string;
  icon?: React.ReactNode;
  onClose?: () => void;
  open: boolean;
  primaryButton?: ModalButtonProps;
  secondaryButton?: ModalButtonProps;
  size?: ModalSize;
  variant?: ModalVariant;
}

const Backdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0 0 0 / 40%);
`;

const BaseModal = styled.div<{ variant: ModalVariant }>`
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 150)},
    ${({ theme }) => theme.style.floatingShadowXL};
  border-radius: 12px;
  width: var(--modal-width);
  height: var(--modal-height);
  position: relative;
  z-index: 2;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      box-shadow:
        0 0 0 1px ${theme.secondary.blendToBackground(150)},
        ${({}) => theme.style.floatingShadowXL};
    `}
`;

const BodyWrapper = styled(Inner)`
  text-align: center;
`;

const Buttons = styled.div<{ variant: ModalVariant }>`
  display: flex;
  gap: 20px;
  padding: 24px;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}

  ${({ variant }) =>
    variant === ModalVariant.Compact &&
    css`
      justify-content: end;
      padding-top: 0;
    `}
    
    ${({ variant }) =>
    variant === ModalVariant.Normal &&
    css`
      justify-content: space-between;
      border-radius: 0 0 12px 12px;
      background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
      border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
    `}
`;

const CircleInner = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin: 0 auto 24px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-size: 30px;
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

const Circle = styled(CircleInner)`
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

const Close = styled.button<{ variant: ModalVariant }>`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin: -6px -4px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  z-index: 1;
  transition: background-color 0.2s;

  ${({ variant }) =>
    variant === ModalVariant.Normal &&
    css`
      position: absolute;
      top: 24px;
      right: 24px;
    `}

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(750)};

    path {
      transition: stroke 0.2s;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(1000, 100)};
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
    }
  }
`;

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: grid;
  place-items: center;
`;

const StyledButton = styled(Button)<{ modalVariant: ModalVariant }>`
  ${({ modalVariant }) =>
    modalVariant === ModalVariant.Normal &&
    css`
      flex: 1;
    `}
`;

const Header = styled.div<{ variant: ModalVariant }>`
  display: flex;
  align-items: center;

  ${({ variant }) =>
    variant === ModalVariant.Compact &&
    css`
      padding: 24px;
      justify-content: space-between;
      border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
    `}

  ${({ variant }) =>
    variant === ModalVariant.Normal &&
    css`
      flex-direction: column;
      padding-top: 24px;
    `}
`;

const Text = styled.div<{ variant: ModalVariant }>`
  display: flex;

  ${({ variant }) =>
    variant === ModalVariant.Compact &&
    css`
      align-self: center;
      line-height: 32px;
      text-align: left;
    `}

  ${({ variant }) =>
    variant === ModalVariant.Normal &&
    css`
      margin-bottom: 8px;
      text-align: center;
    `}
`;

const Wrapper = styled(Fade)<{ size?: ModalSize }>`
  --animation-enter-y: 0;
  --animation-exit-y: 0;

  --modal-width: ${({ size }) => {
    switch (size) {
      case ModalSize.Small:
        return '380px';
      case ModalSize.Medium:
        return '580px';
      case ModalSize.FitContent:
      default:
        return 'fit-content';
    }
  }};
  --modal-height: fit-content;
  z-index: 14;
  position: fixed;
  inset: 0;
  overflow: hidden;
`;

export const Modal: React.FC<PropsWithChildren<ModalProps>> & { Size: typeof ModalSize } = ({
  children,
  headline,
  icon,
  onClose,
  open,
  primaryButton,
  secondaryButton,
  size,
  variant: variantProp,
}) => {
  const variant = variantProp || ModalVariant.Normal;

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };
    window.addEventListener('keydown', keyDownListener);
    return () => window.removeEventListener('keydown', keyDownListener);
  }, []);

  const [portalRoot, setPortalRoot] = useState<HTMLElement>();
  useLayoutEffect(() => {
    const rootElements = document.getElementsByClassName(MODAL_ROOT_CLASSNAME);
    if (rootElements.length > 0) {
      setPortalRoot(rootElements[0] as HTMLElement);

      if (rootElements.length > 1) {
        console.warn('Multiple modal portal roots found. Using the first one.');
      }
    } else {
      console.error('No modal portal root found.');
    }
  }, []);

  if (!portalRoot) {
    return null;
  }

  return createPortal(
    <Wrapper size={size} show={open} timeoutEnter={0}>
      <Backdrop onClick={onClose} />
      <Scroll>
        <BaseModal onClick={(e: React.MouseEvent) => e.stopPropagation()} variant={variant}>
          <Header variant={variant}>
            {variant === ModalVariant.Normal && <Circle>{icon ? icon : <Icon.CircleInfo />}</Circle>}
            {headline && (
              <Text variant={variant}>
                <Headline fullWidth size={variant === ModalVariant.Normal ? 'small' : 'middle'}>
                  {headline}
                </Headline>
              </Text>
            )}
            <Close
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                if (onClose) {
                  onClose();
                }
              }}
              variant={variant}
            >
              <Icon.Close />
            </Close>
          </Header>
          <BodyWrapper pt={variant === ModalVariant.Normal ? 0 : 24}>{children}</BodyWrapper>
          {(primaryButton || secondaryButton) && (
            <Buttons variant={variant}>
              {secondaryButton && (
                <StyledButton
                  modalVariant={variant}
                  variant={'secondary'}
                  onClick={secondaryButton.onClick}
                  isLoading={secondaryButton.isLoading}
                >
                  {secondaryButton.text}
                </StyledButton>
              )}
              {primaryButton && (
                <StyledButton
                  modalVariant={variant}
                  variant={'primary'}
                  onClick={primaryButton.onClick}
                  isLoading={primaryButton.isLoading}
                >
                  {primaryButton.text}
                </StyledButton>
              )}
            </Buttons>
          )}
        </BaseModal>
      </Scroll>
    </Wrapper>,
    portalRoot
  );
};
Modal.Size = ModalSize;
