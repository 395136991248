import { API_URL } from '~/constants';
import { useSessionStore } from '~/stores/Session';
import { keysToCamelCase, toSnake } from '~/util/convertKeys';

interface UploadFileOptions {
  platformId: string;
  isSandbox: boolean;
  returnFile: boolean;
}

export const uploadFile = <Request extends Record<string, any>, Response>(
  url: string,
  request: Request & { file?: File | undefined },
  options?: Partial<UploadFileOptions>
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    for (const key in request) {
      if (Object.prototype.hasOwnProperty.call(request, key) && key !== 'file') {
        formData.append(toSnake(key), request[key] as any);
      }
    }

    if (request?.file) {
      formData.append('file', request.file, request.file.name.toLowerCase().replace(/(?:\.(?![^.]+$)|[^\w.])+/g, '-'));
    }

    try {
      const response = await fetch(API_URL + url, {
        method: 'POST',
        body: formData,
        headers: {
          'Live-Mode': (
            typeof options?.isSandbox !== 'undefined' ? options.isSandbox : useSessionStore.getState().isSandbox
          )
            ? 'No'
            : 'Yes',
          'Platform-Id': options?.platformId ?? useSessionStore.getState().currentUser?.defaultPlatformId ?? '',
        },
        credentials: 'include',
      });

      const json = !options?.returnFile || !response.ok ? await response.json() : await response.blob();

      if (!response.ok) {
        reject(json);
        return;
      }

      if (!options?.returnFile) {
        resolve(keysToCamelCase(json));
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data as any);
        };
        reader.onerror = reject;
        reader.readAsDataURL(json);
      }
    } catch (error) {
      reject({
        message: (error as any)?.message || 'Error reading file, please try again',
      });
    }
  });
};
