import {
  PlatformRepository,
  PlatformMeta,
  financialProductType,
  DepgraphNodeValues,
  PlatformConfig,
} from '~/repositories/PlatformRepository';

import { BaseModel, isDefined } from './BaseModel';

export type PlatformLevelType =
  | 'basic'
  | 'self_id'
  | 'sandbox_only'
  | 'kyb_pending'
  | 'industry_verification_pending'
  | 'commercial_customer'
  | 'enhanced_diligence_required';

export type PlatformType = {
  id: string;
  name: string;
  reportingTimeZone: string;
  platformLevel: PlatformLevelType;
  isLiveEnabled: boolean;
  createdAt?: Date;
  metadata?: any;
};

export type UpdatePlatform = {
  name?: string;
  reportingTimeZone?: string;
};

export class PlatformModel extends BaseModel {
  id: string;
  name: string;
  reportingTimeZone: string;
  platformLevel: PlatformLevelType;
  isLiveEnabled: boolean;
  createdAt: Date | undefined;
  metadata?: any;

  // Meta
  companyName?: string;
  signUpPurpose?: string;
  goLiveDate?: string;
  financialProductsSelected?: financialProductType[];
  financialProductsText?: string;
  financialProductsSubmitted?: boolean;
  companySize?: string;
  customersSize?: string;
  otherComment?: string;
  contactNumber?: string;
  readyToLaunch?: boolean;
  complianceCapabilities?: string;
  selfPaymentOrigination?: string;
  businessInformationSubmitted?: boolean;
  esignPolicyAccepted?: boolean;
  depositAccountAgreementAccepted?: boolean;
  commercialPurposesAcked?: boolean;
  paymentOriginationAccepted?: boolean;
  customerTypes?: string[];
  customerTypeOther?: string;
  contactToRemoveLimits?: boolean;
  businessTypes?: string[];
  moveMoneyFor?: string[];
  docSubmittedPreRootEntityCreation?: string[];

  // Config
  checkIssuingCustomCheckNumbersEnabled?: boolean;

  // dependency graph node values for onboarding
  depgraphNodeValues?: DepgraphNodeValues;

  constructor(attrs: PlatformType & PlatformConfig & PlatformMeta) {
    super();
    this.id = attrs.id;
    this.name = attrs.name;
    this.reportingTimeZone = attrs.reportingTimeZone;
    this.platformLevel = attrs.platformLevel;
    this.isLiveEnabled = attrs.isLiveEnabled;
    this.createdAt = attrs.createdAt;
    this.metadata = attrs.metadata;

    this.companyName = attrs.companyName;
    this.signUpPurpose = attrs.signUpPurpose;
    this.goLiveDate = attrs.goLiveDate;
    this.financialProductsSelected = attrs.financialProductsSelected;
    this.financialProductsText = attrs.financialProductsText;
    this.financialProductsSubmitted = attrs.financialProductsSubmitted;
    this.companySize = attrs.companySize;
    this.customersSize = attrs.customersSize;
    this.otherComment = attrs.otherComment;
    this.contactNumber = attrs.contactNumber;
    this.readyToLaunch = attrs.readyToLaunch;
    this.complianceCapabilities = attrs.complianceCapabilities;
    this.selfPaymentOrigination = attrs.selfPaymentOrigination;
    this.businessInformationSubmitted = attrs.businessInformationSubmitted;
    this.esignPolicyAccepted = attrs.esignPolicyAccepted;
    this.depositAccountAgreementAccepted = attrs.depositAccountAgreementAccepted;
    this.commercialPurposesAcked = attrs.commercialPurposesAcked;
    this.paymentOriginationAccepted = attrs.paymentOriginationAccepted;
    this.customerTypes = attrs.customerTypes;
    this.customerTypeOther = attrs.customerTypeOther;
    this.contactToRemoveLimits = attrs.contactToRemoveLimits;
    this.businessTypes = attrs.businessTypes;
    this.moveMoneyFor = attrs.moveMoneyFor;
    this.docSubmittedPreRootEntityCreation = attrs.docSubmittedPreRootEntityCreation;
    this.checkIssuingCustomCheckNumbersEnabled = attrs.checkIssuingCustomCheckNumbersEnabled;
    this.depgraphNodeValues = attrs.depgraphNodeValues;
  }

  update(attrs: UpdatePlatform) {
    if (attrs.name) {
      this.name = attrs.name;
    }
    if (attrs.reportingTimeZone) {
      this.reportingTimeZone = attrs.reportingTimeZone;
    }

    return PlatformRepository.update({ platformId: this.id, ...attrs });
  }

  async updateDepgraphNodeValues(attrs: DepgraphNodeValues) {
    this.depgraphNodeValues = { ...this.depgraphNodeValues, ...attrs };
    return PlatformRepository.updateDependencyGraph(attrs);
  }

  async updateMeta(attrs: PlatformMeta) {
    if (isDefined(attrs.companyName)) {
      this.companyName = attrs.companyName;
    }
    if (isDefined(attrs.signUpPurpose)) {
      this.signUpPurpose = attrs.signUpPurpose;
    }
    if (isDefined(attrs.goLiveDate)) {
      this.goLiveDate = attrs.goLiveDate;
    }
    if (isDefined(attrs.financialProductsSelected)) {
      this.financialProductsSelected = attrs.financialProductsSelected;
    }
    if (isDefined(attrs.financialProductsText)) {
      this.financialProductsText = attrs.financialProductsText;
    }
    if (isDefined(attrs.financialProductsSubmitted)) {
      this.financialProductsSubmitted = attrs.financialProductsSubmitted;
    }
    if (isDefined(attrs.companySize)) {
      this.companySize = attrs.companySize;
    }
    if (isDefined(attrs.customersSize)) {
      this.customersSize = attrs.customersSize;
    }
    if (isDefined(attrs.otherComment)) {
      this.otherComment = attrs.otherComment;
    }
    if (isDefined(attrs.contactNumber)) {
      this.contactNumber = attrs.contactNumber;
    }
    if (isDefined(attrs.readyToLaunch)) {
      this.readyToLaunch = attrs.readyToLaunch;
    }
    if (isDefined(attrs.complianceCapabilities)) {
      this.complianceCapabilities = attrs.complianceCapabilities;
    }
    if (isDefined(attrs.selfPaymentOrigination)) {
      this.selfPaymentOrigination = attrs.selfPaymentOrigination;
    }
    if (isDefined(attrs.businessInformationSubmitted)) {
      this.businessInformationSubmitted = attrs.businessInformationSubmitted;
    }
    if (isDefined(attrs.esignPolicyAccepted)) {
      this.esignPolicyAccepted = attrs.esignPolicyAccepted;
    }
    if (isDefined(attrs.depositAccountAgreementAccepted)) {
      this.depositAccountAgreementAccepted = attrs.depositAccountAgreementAccepted;
    }
    if (isDefined(attrs.commercialPurposesAcked)) {
      this.commercialPurposesAcked = attrs.commercialPurposesAcked;
    }
    if (isDefined(attrs.paymentOriginationAccepted)) {
      this.paymentOriginationAccepted = attrs.paymentOriginationAccepted;
    }
    if (isDefined(attrs.customerTypes)) {
      this.customerTypes = attrs.customerTypes;
    }
    if (isDefined(attrs.customerTypeOther)) {
      this.customerTypeOther = attrs.customerTypeOther;
    }
    if (isDefined(attrs.contactToRemoveLimits)) {
      this.contactToRemoveLimits = attrs.contactToRemoveLimits;
    }
    if (isDefined(attrs.businessTypes)) {
      this.businessTypes = attrs.businessTypes;
    }
    if (isDefined(attrs.moveMoneyFor)) {
      this.moveMoneyFor = attrs.moveMoneyFor;
    }
    if (isDefined(attrs.docSubmittedPreRootEntityCreation)) {
      this.docSubmittedPreRootEntityCreation = attrs.docSubmittedPreRootEntityCreation;
    }

    return PlatformRepository.updateMeta(this.id, attrs);
  }
}
