import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Icon,
  Button,
  Input,
  DropdownOption,
  Dropdown,
  AmountInput,
  formatNumber,
  CurrenciesUSD,
} from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { RestrictedBanner, PageHeader, SectionHeader } from '~/components';
import { Loan, LoanPayment, LoanRepository } from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditToolbar, FormElement, FormLabel, Inner, Grid } from '~/styles';

interface Params {
  id?: string;
}

export const PageLoansPaymentCreate: React.FC = () => {
  const currentPermission = useSessionStore((state) => state.currentPermission);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [loan, setLoan] = useState<Partial<Loan>>({});
  const [payment, setPayment] = useState<Partial<LoanPayment>>({});
  const [loanList, setLoanList] = useState<DropdownOption[]>([]);
  const [bankAccountList, setBankAccountList] = useState<DropdownOption[]>([]);

  const breadcrumb: { label: ReactNode; path: string }[] = [
    {
      label: (
        <>
          <Icon.HandCoins />
          Loans
        </>
      ),
      path: ROUTE.LOANS,
    },
  ];

  if (loan?.description) {
    breadcrumb.push({
      label: <>{loan.description}</>,
      path: `${ROUTE.LOANS}/edit/${id}`,
    });
  }

  const handleCreate = () => {
    if (!payment.currency) {
      payment.currency = 'USD';
    }

    LoanRepository.createPayment(payment)
      .then((response) => {
        navigate(`${ROUTE.LOANS}/edit/${payment.loanId}`);
        addSuccessNotification({
          content: `${response.description} added`,
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchBankAccounts = async (search?: string) => {
    let result: any = [];

    try {
      const response = await BankAccountRepository.getAll({ description: search });
      result = response.bankAccounts;
    } catch (error) {
      addDangerNotification({
        content: error as string,
      });
    }

    const entries: DropdownOption[] = [];
    result.map((entry: any) => {
      entries.push({
        label: `${
          entry?.displayName && entry?.description
            ? `${entry.displayName} – ${entry.description}`
            : entry?.description || 'Unnamed'
        } (${formatNumber(entry?.balances?.availableAmount)})`,
        small: entry.id,
        value: entry.id,
      });
    });

    setBankAccountList(entries);
  };

  const fetchLoans = (startingAfter?: string) => {
    LoanRepository.getAll({ startingAfter })
      .then((response) => {
        if (response.loans.length) {
          const entries: DropdownOption[] = startingAfter ? loanList.filter((e) => !e.isLoading) : [];
          response.loans.map((entry) => {
            entries.push({
              label: entry.description,
              value: entry.id,
            });
          });
          if (response.hasMore) {
            entries.push({
              label: 'Loading...',
              isLoading: true,
              value: false,
            });
          }
          setLoanList(entries);
        }
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const handleScrollEnd = () => {
    const gotLoading = loanList.find((e) => e.isLoading);

    if (gotLoading) {
      const filteredList = loanList.filter((e) => !e.isLoading);
      fetchLoans(filteredList[filteredList.length - 1]?.value);
    }
  };

  useEffect(() => {
    fetchBankAccounts();
    fetchLoans();

    if (!id) {
      return;
    }

    setPayment({ ...payment, loanId: id });

    LoanRepository.get(id)
      .then((response) => {
        setLoan(response);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, []);

  return (
    <>
      <PageHeader text="Create Payment">
        <Button
          onClick={() => navigate(id ? `${ROUTE.LOANS}/edit/${id}` : `${ROUTE.LOANS}/payments`)}
          variant="secondary"
          size="small"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreate}
          isDisabled={currentPermission?.loans !== 'write'}
          size="small"
          icon={<Icon.Plus />}
        >
          Create Payment
        </Button>
      </PageHeader>

      {currentPermission?.loans !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <SectionHeader text="Information" />
      <Inner data-disabled={currentPermission?.loans !== 'write'} pt={16}>
        <Grid>
          <FormElement>
            <FormLabel>Description</FormLabel>
            <Input
              onChange={(value: string) => setPayment({ ...payment, description: value })}
              value={payment?.description ?? ''}
              placeholder="Description"
            />
          </FormElement>

          <FormElement>
            <FormLabel>Loan</FormLabel>
            <Dropdown
              fullWidth
              maxWidth="640px"
              active={payment.loanId}
              onChange={(value: string) => setPayment({ ...payment, loanId: value })}
              onScrollEnd={handleScrollEnd}
              options={loanList}
              isDisabled={!!id}
            />
          </FormElement>
          <FormElement>
            <FormLabel>Bank Account</FormLabel>
            <Dropdown
              options={bankAccountList}
              active={payment.bankAccountId}
              fullWidth
              maxWidth="640px"
              search
              searchLabel="Search for description"
              onSearchChange={(value: string) => fetchBankAccounts(value)}
              onChange={(value: string) => setPayment({ ...payment, bankAccountId: value })}
            />
          </FormElement>

          <FormElement>
            <FormLabel>Principal Balance</FormLabel>
            <AmountInput
              onChange={(value?: number) => setPayment({ ...payment, principalAmount: value?.toString() })}
              onCurrencyCodeChange={(value: string) => setPayment({ ...payment, currency: value })}
              currencyCode={payment.currency}
              currencyList={CurrenciesUSD}
              value={payment.principalAmount ? parseInt(payment.principalAmount, 10) : undefined}
            />
          </FormElement>
        </Grid>
      </Inner>

      <Inner>
        <EditToolbar>
          <Button
            onClick={() => navigate(id ? `${ROUTE.LOANS}/edit/${id}` : `${ROUTE.LOANS}/payments`)}
            variant="secondary"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            isDisabled={currentPermission?.loans !== 'write'}
            size="small"
            icon={<Icon.Plus />}
          >
            Create Payment
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
