const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const smoothScrollTo = (element: HTMLElement, to: number, duration: number) => {
  const start = element.scrollTop;
  const change = to - start;
  const increment = 10;

  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;

    element.scrollTop = easeInOutQuad(currentTime, start, change, duration);

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  animateScroll();
};

export const scrollTo = (element: HTMLElement, offset?: number) => {
  const scroll = document.getElementById('scroll-container');

  if (!scroll) {
    return;
  }
  const scrollOffset = scroll.getBoundingClientRect().top;

  const elementTop =
    element.getBoundingClientRect().top < 0
      ? element.getBoundingClientRect().top * -1
      : element.getBoundingClientRect().top;

  scroll.scrollTo({
    top: elementTop - scrollOffset + (offset ?? 0),
    behavior: 'smooth',
  });
};

export const scrollToOffset = (top: number, behavior?: ScrollBehavior) => {
  const scroll = document.getElementById('scroll-container');

  if (!scroll) {
    return;
  }

  scroll.scrollTo({
    top,
    behavior,
  });
};
