import styled from 'styled-components';

import { Icon } from '@column/column-ui-kit';

export const ModalIconCircle = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin: 0 auto 24px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-size: 30px;
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

export const ModalText = styled.div`
  display: grid;
  grid-gap: 8px;
  margin: 0 auto 24px auto;
  text-align: center;
`;

export const InputSuccessIcon = styled(Icon.CircleCheck)<{ $isSuccess: boolean }>`
  --icon-color: ${({ theme }) => theme.primary.background};

  path {
    transition: stroke-dasharray 0.2s;

    &:first-child {
      stroke-dashoffset: ${({ $isSuccess }) => ($isSuccess ? '0px' : '48px')};
      stroke-dasharray: 48px;
    }
    &:last-child {
      stroke-dashoffset: ${({ $isSuccess }) => ($isSuccess ? '0px' : '9px')};
      stroke-dasharray: 9px;
      transition-delay: ${({ $isSuccess }) => ($isSuccess ? '0.15s' : '0s')};
    }
  }
`;
