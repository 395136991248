import {
  AccountNumberAccountNumber,
  AccountNumberListAccountNumbersResponse,
  AccountNumberServiceCreateAccountNumberPayload,
  AccountNumberServiceListAccountNumbersParams,
  BankAccountCreateRequest,
  BankAccountListResponse,
  BankAccountServiceListBankAccountsParams,
  BankAccountServiceUpdateBankAccountPayload,
  BankAccountWithDetails,
} from '~/typings/API';
import { convertValues } from '~/util/convertValues';

import { client } from './client';

type BankAccount = BankAccountWithDetails;
type AccountNumber = AccountNumberAccountNumber;
type ActivityType = 'Realtime' | 'Intl Wire' | 'Check' | 'ACH' | 'Wire';

export interface ActivityLineItem {
  activityLineItemId: string;
  settlementDate: string;
  type: ActivityType;
  bankAccountId: string;
  transactionId: string;
  description: string;
  amount: number;
  runningBalance: number;
}

export interface ListBankAccountActivitiesRequest extends PaginationQueryParams {
  effectiveAtGt?: string;
  effectiveAtLt?: string;
  effectiveAtGte?: string;
  effectiveAtLte?: string;
}

export interface ListBankAccountActivitiesResponse {
  activityLineItems: ActivityLineItem[];
  hasMore: boolean;
}

export type { ActivityType, BankAccount, AccountNumber, BankAccountListResponse };
export type CreateBankAccount = BankAccountCreateRequest;
export type UpdateBankAccount = BankAccountServiceUpdateBankAccountPayload;
export type CreateAccountNumber = AccountNumberServiceCreateAccountNumberPayload;
export type BankAccountFilterParams = BankAccountServiceListBankAccountsParams & { platformId?: string };

const bankAccountCast = ({ defaultAccountNumber, routingNumber }: BankAccount) => ({
  defaultAccountNumber,
  routingNumber,
});

const accountNumberCast = ({ accountNumber, routingNumber }: AccountNumber) => ({
  accountNumber,
  routingNumber,
});

export class BankAccountRepository {
  static async create(request: CreateBankAccount) {
    return client
      .post<CreateBankAccount, BankAccount>('/bank-accounts', request)
      .then((response) => convertValues<BankAccount>(response, undefined, bankAccountCast(response)));
  }

  static async update(id: string, request: UpdateBankAccount) {
    return client
      .patch<UpdateBankAccount, BankAccount>(`/bank-accounts/${id}`, request)
      .then((response) => convertValues<BankAccount>(response, undefined, bankAccountCast(response)));
  }

  static async get(request: GetRequestType) {
    const { platformId, ...requestParams } = request;
    return client
      .get<unknown, BankAccount>(`/bank-accounts/${requestParams.id}`, { platformId })
      .then((response) => convertValues<BankAccount>(response, undefined, bankAccountCast(response)));
  }

  static async getAll(request?: Partial<BankAccountFilterParams>) {
    const { platformId, ...query } = request ?? {};
    return client
      .get<BankAccountFilterParams, BankAccountListResponse>('/bank-accounts', { query, platformId })
      .then((response) => ({
        bankAccounts: Array.isArray(response?.bankAccounts)
          ? response.bankAccounts.map((e) => convertValues<BankAccount>(e, undefined, bankAccountCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async delete(id: string) {
    return client.delete<object, object>(`/bank-accounts/${id}`);
  }

  static async createAccountNumber(bankAccountId: string, accountNumber: CreateAccountNumber) {
    return client
      .post<CreateAccountNumber, AccountNumber>(`/bank-accounts/${bankAccountId}/account-numbers`, accountNumber)
      .then((response) => convertValues<AccountNumber>(response, undefined, accountNumberCast(response)));
  }

  static async getAccountNumber(request: GetRequestType) {
    const { platformId, ...requestParams } = request;
    return client
      .get<unknown, AccountNumber>(`/account-numbers/${requestParams.id}`, { platformId })
      .then((response) => convertValues<AccountNumber>(response, undefined, accountNumberCast(response)));
  }

  static async getAllAccountNumber(
    bankAccountId: string,
    query?: Partial<AccountNumberServiceListAccountNumbersParams>
  ) {
    return client
      .get<AccountNumberServiceListAccountNumbersParams, AccountNumberListAccountNumbersResponse>(
        `/bank-accounts/${bankAccountId}/account-numbers`,
        {
          query,
        }
      )
      .then((response) => ({
        accountNumbers: Array.isArray(response?.accountNumbers)
          ? response.accountNumbers.map((e) => convertValues<AccountNumber>(e, undefined, accountNumberCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async getActivity(bankAccountId: string, query: ListBankAccountActivitiesRequest) {
    return client
      .get<Omit<ListBankAccountActivitiesRequest, 'bankAccountId'>, ListBankAccountActivitiesResponse>(
        `/bank-accounts/${bankAccountId}/activities`,
        {
          query,
        }
      )
      .then((response) => ({
        activityLineItems: Array.isArray(response?.activityLineItems)
          ? response.activityLineItems.map((e) => convertValues<ActivityLineItem>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }
}
