import {
  ExternalCounterparty,
  ExternalCreateCounterpartyRequest,
  CounterpartiesList,
  CounterpartyServiceListCounterpartiesParams,
  ExternalFinancialInstitution,
} from '~/typings/API';
import { convertValues } from '~/util';

import { client } from './client';

type Counterparty = ExternalCounterparty;
type CounterpartyType = Counterparty;
export type CounterpartyListParams = CounterpartyServiceListCounterpartiesParams;
export type { CounterpartyType, Counterparty, CounterpartiesList };
export type CreateCounterparty = ExternalCreateCounterpartyRequest;
export type FinancialInstitution = ExternalFinancialInstitution & { routingNumberType?: 'aba' | 'bic' };

export interface ListExternalFinancialInstitutionParams extends PaginationQueryParams {
  countryCode?: string;
  name?: string;
  routingNumberType?: 'aba' | 'bic';
}

export interface ListExternalFinancialInstitutionResponse {
  institutions?: FinancialInstitution[];
  hasMore?: boolean;
}

export interface ValidateIBANObject {
  accountNumber: string;
  bankId: string;
  bic: string;
  branchId: string;
  checkDigits: string;
  countryCode: string;
  iban: string;
  institutionName: string;
  nationalId: string;
}

export interface GetInstitutionRequest {
  routingNumber: string;
}

const counterpartyCast = ({ accountNumber, routingNumber }: Counterparty) => ({
  accountNumber,
  routingNumber,
});

const institutionCast = ({ routingNumber }: FinancialInstitution) => ({
  routingNumber,
});

const validateIBANCast = ({ accountNumber, checkDigits, branchId }: ValidateIBANObject) => ({
  accountNumber,
  checkDigits,
  branchId,
});

export class CounterpartyRepository {
  static async create(request: CreateCounterparty) {
    return client
      .post<CreateCounterparty, CounterpartyType>('/counterparties', request)
      .then((response) => convertValues<CounterpartyType>(response, undefined, counterpartyCast(response)));
  }

  static async get(request: GetRequestType) {
    return client
      .get<unknown, CounterpartyType>(`/counterparties/${request.id}`)
      .then((response) => convertValues<CounterpartyType>(response, undefined, counterpartyCast(response)));
  }

  static async getAll(query?: Partial<CounterpartyListParams>) {
    return client
      .get<CounterpartyListParams, CounterpartiesList>('/counterparties', {
        query,
      })
      .then((response) => ({
        counterparties: Array.isArray(response?.counterparties)
          ? response.counterparties.map((e) => convertValues<CounterpartyType>(e, undefined, counterpartyCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async delete(request: GetRequestType) {
    return client.delete<object, object>(`/counterparties/${request.id}`);
  }

  static async getAllInstitutions(query: ListExternalFinancialInstitutionParams) {
    return client
      .get<ListExternalFinancialInstitutionParams, ListExternalFinancialInstitutionResponse>('/institutions', { query })
      .then((response) => ({
        institutions: Array.isArray(response?.institutions)
          ? response.institutions.map((e) => convertValues<FinancialInstitution>(e, undefined, institutionCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async getInstitution(request: GetInstitutionRequest) {
    return client
      .get<any, FinancialInstitution>(`/institutions/${request.routingNumber}`)
      .then((response) => convertValues<FinancialInstitution>(response, undefined, institutionCast(response)));
  }

  static async validateIBAN(request: GetRequestType) {
    return client
      .get<any, ValidateIBANObject>(`/iban/${request.id}`)
      .then((response) => convertValues<ValidateIBANObject>(response, undefined, validateIBANCast(response)));
  }
}
