import React, { FC, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { PopoverUser } from '~/components';
import { useSessionStore } from '~/stores/Session';

const Panel = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Avatar = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 32px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ theme }) => theme.blue.blendToBackground(900)};
  background-color: ${({ theme }) => theme.blue.blendToBackground(1000, 200)};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.blue.blendToBackground(400)} inset;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.blue.blendToBackground(400)} inset,
      0 0 0 3px ${({ theme }) => theme.blue.blendToBackground(150)};
  }

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      color: ${theme.blue.blendToBackground(3000)};
    `}
`;

export const User: FC = () => {
  const { currentUser } = useSessionStore();
  const panelRef = useRef<HTMLDivElement>(null);
  const [showPopoverUser, setShowPopoverUser] = useState<boolean>(false);

  const handleTriggerClick = useCallback(() => {
    setShowPopoverUser((s) => !s);
  }, []);

  const handlePopoverClose = useCallback(
    (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }
      if (panelRef.current && (event.target === panelRef.current || panelRef.current.contains(event.target))) {
        return;
      }
      setShowPopoverUser(false);
    },
    [panelRef]
  );

  return (
    <>
      <Panel onClick={handleTriggerClick} ref={panelRef}>
        <Avatar>
          {currentUser?.firstName && currentUser?.lastName ? (
            <>
              {currentUser.firstName?.toString().trim()[0]}
              {currentUser.lastName?.toString().trim()[0]}
            </>
          ) : (
            '-'
          )}
        </Avatar>
      </Panel>
      <PopoverUser show={showPopoverUser} onClose={handlePopoverClose} />
    </>
  );
};
