import {
  CounterpartyRepository,
  CounterpartiesList,
  CounterpartyListParams,
  Counterparty,
  CreateCounterparty,
  ValidateIBANObject,
} from '~/repositories/CounterpartyRepository';

import { createApiHook } from './utils/createApiHook';

export const useCounterparties = createApiHook<CounterpartiesList, CounterpartyListParams>(
  CounterpartyRepository.getAll,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
  }
);

export const useCounterparty = createApiHook<Counterparty, GetRequestType>(CounterpartyRepository.get);

export const useCreateCounterparty = createApiHook<Counterparty, CreateCounterparty>(CounterpartyRepository.create);

export const useDeleteCounterparty = createApiHook<object, GetRequestType>(CounterpartyRepository.delete);

export const useValidateIBAN = createApiHook<ValidateIBANObject, GetRequestType>(CounterpartyRepository.validateIBAN);
