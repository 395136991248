import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Dropdown, DropdownProps, Icon } from '@column/column-ui-kit';

import { Access } from '../types';

interface Props {
  onChange: (value: Access) => void;
  showDefault?: boolean;
  value: Access;
  variant?: DropdownProps['variant'];
}

const StyledIconDefault = styled(Icon.Settings)`
  --icon-color: ${({ theme }) => theme.gray.blendToBackground(600)} !important;
`;

const StyledIconNoAccess = styled(Icon.Close)`
  --icon-color: ${({ theme }) => theme.red.background} !important;
`;

const StyledIconViewOnly = styled(Icon.Eye)`
  --icon-color: ${({ theme }) => theme.primary.background} !important;
`;

const StyledIconFullAccess = styled(Icon.CircleCheck)`
  --icon-color: ${({ theme }) => theme.seafoam.background} !important;
`;

const StyledIconCustom = styled(Icon.Filters)`
  --icon-color: ${({ theme }) => theme.yellow.background} !important;
`;

export const AccessDropdown: React.FC<Props> = ({ onChange, showDefault, value, variant }) => {
  const options = useMemo(() => {
    const defaultOption = showDefault
      ? [
          {
            label: 'Default',
            icon: <StyledIconDefault />,
            value: Access.Default,
          },
        ]
      : [];
    return [
      ...defaultOption,
      {
        label: 'No Access',
        icon: <StyledIconNoAccess />,
        value: Access.NoAccess,
      },
      {
        label: 'View Only',
        icon: <StyledIconViewOnly />,
        value: Access.ViewOnly,
      },
      {
        label: 'Full Access',
        icon: <StyledIconFullAccess />,
        value: Access.FullAccess,
      },
      {
        label: 'Custom',
        icon: <StyledIconCustom />,
        value: Access.Custom,
      },
    ];
  }, [showDefault]);

  return (
    <Dropdown
      active={value}
      variant={variant}
      size={'small'}
      options={options}
      onChange={(newValue) => onChange(newValue as Access)}
      fullWidth
      positionRight
    />
  );
};
