import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface JSONProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const JSON = forwardRef<SVGSVGElement, JSONProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66529 13.3362C3.74443 13.3362 2.99793 12.5896 2.99793 11.6688V9.66796C2.99793 9.22574 2.82226 8.80164 2.50957 8.48895C2.19688 8.17626 1.77278 8.00059 1.33057 8.00059C1.77278 8.00059 2.19688 7.82493 2.50957 7.51224C2.82226 7.19955 2.99793 6.77545 2.99793 6.33323V4.3324C2.99793 3.41154 3.74443 2.66504 4.66529 2.66504"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3347 2.66504C12.2556 2.66504 13.0021 3.41154 13.0021 4.3324V6.33323C13.0021 7.25409 13.7486 8.00059 14.6694 8.00059C13.7486 8.00059 13.0021 8.7471 13.0021 9.66796V11.6688C13.0021 12.5896 12.2556 13.3362 11.3347 13.3362"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.78247 8.05515C5.78247 8.03673 5.76754 8.0218 5.74912 8.0218C5.7307 8.0218 5.71577 8.03673 5.71577 8.05515C5.71577 8.07357 5.7307 8.0885 5.74912 8.0885C5.76754 8.0885 5.78247 8.07357 5.78247 8.05515"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.03332 8.04929C8.03332 8.03088 8.01839 8.01595 7.99998 8.01595C7.98156 8.01595 7.96663 8.03088 7.96663 8.04929C7.96663 8.06771 7.98156 8.08264 7.99998 8.08264C8.01839 8.08264 8.03332 8.06771 8.03332 8.04929"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0342 8.04929C10.0342 8.03088 10.0192 8.01595 10.0008 8.01595C9.98241 8.01595 9.96748 8.03088 9.96748 8.04929C9.96748 8.06771 9.98241 8.08264 10.0008 8.08264C10.0192 8.08264 10.0342 8.06771 10.0342 8.04929"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
