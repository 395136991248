import React, { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { Input, Button } from '@column/column-ui-kit';

import { Form, Rows, StyledLogo, Panel, HintLink } from '../Login';
import { PasswordStrength, NotificationList, PasswordInfo } from '~/components';
import { UnderlineLink } from '~/elements';
import { ROUTE } from '~/public/routes';
import { DashboardLoginResponse, UserRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline } from '~/styles';
import { log, triggerEvent, validateEmail } from '~/util';
import { HandleReCaptcha, reCaptchaCheck } from '~/util/reCaptcha';

interface Params {
  platformId: string;
}

export const Legal = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const PageRegisterForm: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const { signIn, setSession, loadPlatform } = useSessionStore();
  const { control, getValues, handleSubmit, setValue, watch } = useForm();
  const [searchParams] = useSearchParams();
  const { platformId: platformIdPathParam } = useParams<keyof Params>() as Params;
  const [isEmailPreconfigured, setIsEmailPreconfigured] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordInfo, setPasswordInfo] = useState<PasswordInfo>({
    score: 0,
    suggestions: [],
  });
  const navigate = useNavigate();
  const location = useLocation();
  const inviteCode = searchParams.get('code');
  const platformId = searchParams.get('invite') ?? platformIdPathParam;
  useEffect(() => {
    const encodedEmail = searchParams.get('email');
    if (encodedEmail) {
      const decodedEmail = decodeURI(encodedEmail);
      setValue('email', decodedEmail);
      setIsEmailPreconfigured(true);
    }
  }, [searchParams, setValue]);
  const { handleReCaptcha } = reCaptchaCheck();

  const onSuccess = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (passwordInfo.score < 2) {
      addDangerNotification({
        display: 'page',
        content: 'Please choose a stronger password.',
      });

      setIsLoading(false);
      return;
    }

    const handleErr = (err: any) => {
      setIsLoading(false);

      addDangerNotification({
        content: err.message,
        display: 'page',
      });
    };

    try {
      await createUser(handleReCaptcha, {
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        email: getValues('email'),
        password: getValues('password'),
        platformId,
        inviteCode,
      });
    } catch (err) {
      handleErr(err);
      return;
    }

    let loginResponse: DashboardLoginResponse;
    try {
      loginResponse = await login(handleReCaptcha, {
        email: getValues('email'),
        password: getValues('password'),
      });
    } catch (err) {
      handleErr(err);
      return;
    }
    setSession(loginResponse.sessionId, loginResponse.dashboardUserId);
    const signInResp = await signIn();
    if (!signInResp) {
      setIsLoading(false);
      navigate(ROUTE.LOGIN);
      addDangerNotification({
        content: 'Error signing in, please try again',
        display: 'page',
      });
      return;
    }

    const companyName = getValues('companyName');
    if (companyName) {
      try {
        const loadPlatformResponse = await loadPlatform({ fetchMeta: true });
        await loadPlatformResponse?.updateMeta({ companyName });
      } catch (err) {
        handleErr(err);
        return;
      }
    }

    if (inviteCode) {
      // Invited users are joining an existing platform, which does not require further configuration
      navigate(ROUTE.ROOT);
    } else {
      // Users joining outside of an invite flow need to configure their platformq
      navigate(ROUTE.REGISTER_FINANCIAL_PRODUCTS);
    }
  };

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const disallowedStrings = useMemo(() => {
    return [firstName, lastName, email].filter((s) => s && s.length > 0);
  }, [firstName, lastName, email]);

  return (
    <Panel>
      <StyledLogo size="24px" />
      <Headline size="small">Create an account</Headline>
      <Form onSubmit={handleSubmit(onSuccess)}>
        <NotificationList display="page" />
        <Rows>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="First name" hasError={isTouched && !!error} isDisabled={isLoading} {...field} />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="Last name" hasError={isTouched && !!error} isDisabled={isLoading} {...field} />
            )}
          />
          {!inviteCode && (
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error, isTouched } }) => (
                <Input
                  placeholder="Company (Optional)"
                  isDisabled={isLoading}
                  hasError={isTouched && !!error}
                  {...field}
                />
              )}
            />
          )}
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: validateEmail,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input
                autoComplete="username"
                placeholder="Email address"
                hasError={isTouched && !!error}
                isDisabled={isEmailPreconfigured || isLoading}
                {...field}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <PasswordStrength
                hasError={
                  (isTouched || (watch('password') && watch('password').length > 0)) &&
                  (!!error || passwordInfo.score < 2)
                }
                {...field}
                isDisabled={isLoading}
                disallowedStrings={disallowedStrings}
                onStrengthChange={setPasswordInfo}
              />
            )}
          />
        </Rows>
        <Rows>
          <Button isLoading={isLoading}>{'Sign up'}</Button>
          <Legal>
            By signing up you agree to our{' '}
            <UnderlineLink href="https://column.com/legal/api-terms-of-service" newTab withoutArrow>
              API Terms of Service
            </UnderlineLink>{' '}
            and{' '}
            <UnderlineLink href="https://column.com/legal/privacy-policy" newTab withoutArrow>
              Privacy Policy
            </UnderlineLink>
            .
          </Legal>
          <HintLink as={Link} to={ROUTE.LOGIN}>
            Back to Login
          </HintLink>
        </Rows>
      </Form>
    </Panel>
  );
};

const createUser = async (
  handleReCaptcha: HandleReCaptcha,
  {
    firstName,
    lastName,
    email,
    password,
    platformId,
    inviteCode,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    platformId: string;
    inviteCode: string | null;
  }
) => {
  return new Promise((resolve, reject) => {
    handleReCaptcha(async (reCaptchaToken: string) => {
      try {
        const dashboardUser = await UserRepository.create(
          firstName,
          lastName,
          email,
          password,
          platformId,
          inviteCode,
          reCaptchaToken
        );

        log({
          name: 'User registered',
          context: {
            firstName,
            lastName,
            email,
          },
        });

        triggerEvent({
          category: 'User',
          action: 'Register',
        });

        resolve(dashboardUser);
      } catch (err: any) {
        reject(err);
      }
    }, reject);
  });
};

const login = async (
  handleReCaptcha: HandleReCaptcha,
  {
    email,
    password,
  }: {
    email: string;
    password: string;
  }
): Promise<DashboardLoginResponse> => {
  return new Promise((resolve, reject) => {
    handleReCaptcha(async (reCaptchaToken: string) => {
      try {
        const response = await UserRepository.login(email, password, reCaptchaToken);

        resolve(response);
      } catch (err: any) {
        reject(err);
      }
    }, reject);
  });
};
