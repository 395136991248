import React, { useCallback } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

import { useNotificationStore, NotificationGroupType, NotificationPropsType } from '~/stores/Notification';

interface UseFormErrorHandlerOptions {
  defaultDisplayType?: NotificationGroupType;
}

interface HandleFormErrorsParams {
  errors: FieldValues;
  display?: NotificationGroupType;
  headline?: string;
}

interface HandleErrorsParams
  extends Partial<Pick<NotificationPropsType, 'display' | 'timeout' | 'actionButton' | 'variant'>> {
  headline?: string;
}

export const getFormErrors = (errors: FieldErrors): string[] => {
  const errorMessages: string[] = [];

  const extractMessages = (errorObject: any) => {
    if (!errorObject) return;

    if (errorObject.hasOwnProperty('message') && errorObject.message.length > 0) {
      errorMessages.push(errorObject.message);
    } else {
      Object.values(errorObject).forEach((value) => {
        if (typeof value === 'object' && value !== null) {
          extractMessages(value);
        }
      });
    }
  };

  if (errors && typeof errors === 'object') {
    Object.values(errors).forEach(extractMessages);
  }

  return errorMessages;
};

export const useFormErrorHandler = ({ defaultDisplayType = 'global' }: UseFormErrorHandlerOptions = {}) => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);

  const handleFormErrors = useCallback(
    ({ errors, display, headline }: HandleFormErrorsParams) => {
      const displayType = display || defaultDisplayType;

      if (!errors || typeof errors !== 'object') {
        return;
      }

      const errorMessages = getFormErrors(errors);

      if (errorMessages.length === 1) {
        addDangerNotification({
          display: displayType,
          content: errorMessages[0],
        });
      }

      if (errorMessages.length > 1) {
        addDangerNotification({
          display: displayType,
          content: (
            <>
              <h4>{headline || 'Please fix the following errors:'}</h4>
              <ul>
                {errorMessages.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ul>
            </>
          ),
        });
      }
    },
    [addDangerNotification, defaultDisplayType]
  );

  const handleSubmitErrors = useCallback(
    <T extends FieldValues>(errors: FieldErrors<T>) => {
      handleFormErrors({ errors, display: defaultDisplayType });
    },
    [handleFormErrors, defaultDisplayType]
  );

  const handleErrors = useCallback(
    (errors: string | string[], { display, headline, ...notificationProps }: HandleErrorsParams = {}) => {
      const displayType = display || defaultDisplayType;

      if (typeof errors === 'string') {
        addDangerNotification({
          display: displayType,
          content: errors,
          ...notificationProps,
        });

        return;
      }

      addDangerNotification({
        display: displayType,
        content: (
          <>
            <h4>{headline || 'Please fix the following errors:'}</h4>
            <ul>
              {errors.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          </>
        ),
        ...notificationProps,
      });
    },
    [addDangerNotification, defaultDisplayType]
  );

  return {
    handleFormErrors,
    handleErrors,
    handleSubmitErrors,
  };
};
