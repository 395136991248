import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventAccountProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventAccount = forwardRef<SVGSVGElement, EventAccountProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 11.2V6.80005" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M6.33594 11.2V6.80005" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M9.66406 11.2V6.80005" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M13 11.2V6.80005" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path d="M1.6001 13H14.4001" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" />
      <path
        d="M7.99867 2.3999L2.15067 5.4719C1.59867 5.7599 1.80667 6.5999 2.43067 6.5999H13.5667C14.1907 6.5999 14.3987 5.7599 13.8467 5.4719L7.99867 2.3999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </SVG>
  );
});
