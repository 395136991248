import React from 'react';

export const helpSidebarContent = {
  sourceOfFunds: (
    <>
      <p>Please select from where the funds you plan to keep in your Column account originate.</p>
      <p />
      <p>
        Most companies will select multiple. As an example if you have raised venture dollars, are migrating from an
        existing bank, and have business revenue you would select "Investor Funded", "Existing Deposits", and "Business
        Operations".
      </p>
    </>
  ),
  physicalAddress: (
    <>
      <p>Asking for multiple addresses is a bit confusing, we get it. Physical address is your Office HQ.</p>
      <p />
      <p>
        This needs to be the physical location of your office or a location where company leadership is performing
        day-to-day work. It’s okay if this is a home address of the CEO if everyone is remote. Co-working spaces like
        WeWork are acceptable.
      </p>
      <p>
        This cannot be a virtual address, PO Box, or an address for a mail forwarding company like EarthClass Mail. This
        is also not your registered agent's address. You'll list those in the next section.
      </p>
    </>
  ),
  sourceOfFundsDetail: (
    <>
      <p>Please add a few sentences providing more detail on the sources of funds you have selected.</p>
      <p />
      <p>
        As an example, if you listed "Investor Funded", list your investors and investment amounts. For "Business
        Operations", discuss your annual revenue and main revenue drivers. A few sentences here is enough.
      </p>
    </>
  ),
  controlPerson: (
    <>
      Usually the person who controls the company from a management perspective - this would usually be a CEO or other
      executive. Required one per platform.
    </>
  ),
  beneficialOwner: (
    <>
      <p>
        Individuals who directly or indirectly own 25% or more of the company. If no individual or company directly owns
        more than 25% of your company than there are no beneficial owners other than the control person.
      </p>
      <p>
        If there is a legal entity that owns more than 25% of your company, you must confirm whether there is an
        individual who owns that entity which results in an indirect ownership of 25% or more. ie: you have a venture
        investor who owns 50% of the company and there is an individual who owns more than 50% of the fund, they would
        be a beneficial owner. If there is a venture fund that owns 25% of the company but if there is no partner who
        owns 100% of the fund, there would be no beneficial owners.
      </p>
    </>
  ),
} as const;
