import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button, Input } from '@column/column-ui-kit';

import { ROUTE } from '../routes';
import { NotificationList } from '~/components';
import { UserRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { Headline } from '~/styles';
import { log, validateEmail } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

import { Form, Panel, Rows, StyledLogo, HintLink } from './Login';

export const PageForgotPassword: React.FC = () => {
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, getValues, handleSubmit } = useForm();

  const { handleReCaptcha } = reCaptchaCheck();

  const onSuccess = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    handleReCaptcha(
      (reCaptchaToken: string) => {
        UserRepository.createPasswordReset(getValues('email'), reCaptchaToken)
          .then(() => {
            setIsLoading(false);

            log({
              name: 'User password requested',
              context: {
                email: getValues('email'),
              },
            });

            addSuccessNotification({
              content: "We've sent your an email.",
              display: 'page',
            });
          })
          .catch((error) => {
            setIsLoading(false);

            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
      () => setIsLoading(false)
    );
  };

  return (
    <Panel>
      <StyledLogo size="24px" />
      <Headline size="small">Forgot password</Headline>
      <Form onSubmit={handleSubmit(onSuccess)}>
        <NotificationList display="page" />
        <Rows>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              validate: validateEmail,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="Email address" hasError={isTouched && !!error} {...field} />
            )}
          />
        </Rows>
        <Rows>
          <Button fullWidth isLoading={isLoading}>
            Request
          </Button>
          <HintLink as={Link} to={ROUTE.LOGIN}>
            Back to Login
          </HintLink>
        </Rows>
      </Form>
    </Panel>
  );
};
