import { PageTransfersAdd } from './Add';
import { PageTransfersCheck } from './Check';
import { PageTransfers } from './Overview';
import { PageTransfersView } from './View';

export const TRANSFERS = {
  Transfers: PageTransfers,
  TransfersAdd: PageTransfersAdd,
  TransfersCheck: PageTransfersCheck,
  TransfersView: PageTransfersView,
} as const;
