import React, { useRef, FC } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface BookIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const BookIcon: FC<BookIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path d="M12 11.68L2 15.02V5.33L12 2V11.68Z" stroke="currentColor" strokeWidth="1.5" />
        <path
          d="M16.6283 15.14L9.30828 17.58C8.65828 17.8 7.98828 17.31 7.98828 16.63V9.78C7.98828 8.92 8.53828 8.15 9.35828 7.88L16.6783 5.44C17.3283 5.22 17.9983 5.71 17.9983 6.39V13.24C17.9983 14.1 17.4483 14.87 16.6283 15.14Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </IconBase>
    </Wrapper>
  );
};
