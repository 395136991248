import React, { FocusEvent, forwardRef, useCallback, useEffect, useState, PropsWithChildren } from 'react';

import { Input } from '@column/column-ui-kit';

import { useDebounce, useInstitution } from '~/hooks';
import { FinancialInstitution } from '~/repositories';
import { validateBIC, validateRoutingNumber } from '~/util';

interface RoutingNumberFieldProps {
  value: string;
  placeholder?: string;
  hasError?: boolean;
  onChange: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onInstitutionChange?: (institution: FinancialInstitution | null) => void;
}

export const RoutingNumberInput = forwardRef<HTMLInputElement, PropsWithChildren<RoutingNumberFieldProps>>(
  ({ value, onChange, onBlur, onInstitutionChange, placeholder, children, hasError }, ref) => {
    const [institution, setInstitution] = useState<FinancialInstitution | null>(null);
    const { createRequest: fetchInstitution } = useInstitution({
      onSuccess: setInstitution,
      onError: () => setInstitution(null),
    });

    const checkRoutingNumber = useDebounce((rtnNum: string) => {
      if (validateRoutingNumber(rtnNum) || validateBIC(rtnNum)) {
        fetchInstitution({ routingNumber: rtnNum });
        return;
      }
      setInstitution(null);
    }, 200);

    const handleChange = useCallback(
      (rtnNum: string) => {
        onChange(rtnNum);

        if (onInstitutionChange) {
          checkRoutingNumber(rtnNum);
        }
      },
      [onChange, checkRoutingNumber, onInstitutionChange]
    );

    useEffect(() => {
      if (onInstitutionChange) {
        onInstitutionChange(institution);
      }
    }, [onInstitutionChange, institution]);

    return (
      <Input
        onChange={handleChange}
        value={value}
        placeholder={placeholder ?? 'Routing number'}
        ref={ref}
        onBlur={onBlur}
        hasError={hasError}
      >
        {children}
      </Input>
    );
  }
);
