import { gsap } from 'gsap';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import styled from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useSessionStore } from '~/stores/Session';
import { inViewport } from '~/util';

import { NotificationContent, NotificationInner, NotificationStyleProps } from './Notification';

export interface RestrictedBannerProps {
  onActionClick?: () => void;
  customLabel?: string;
  customDescription?: string;
  className?: string;
}

const Wrapper = styled(NotificationContent)`
  transform: translateZ(0);
  background: ${({ theme }) => theme.background};
  box-shadow: inset 0 0 0 1px var(--notification-outline);
  width: auto;
  padding: 12px 20px 12px 12px;
`;

const StyledNotificationInner = styled(NotificationInner)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledIcon = styled(Icon.AnimationLock)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: var(--notification-light-bg);
  flex-shrink: 0;

  svg {
    --icon-color: var(--notification-dark);
    --icon-size: 18px;
  }
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.foreground};
  display: block;
`;

const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  display: block;
  max-width: 80ch;

  strong {
    font-weight: 500;
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const Right = styled.div`
  margin-left: auto;
  align-self: center;
  margin-right: -4px;
`;

export const RestrictedBanner: React.FC<RestrictedBannerProps> = ({
  className,
  customLabel,
  customDescription,
  onActionClick,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);
  const currentPermissionName = useSessionStore((state) => state.currentPermissionName);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleActionClick = useCallback(() => {
    if (onActionClick) {
      onActionClick();
    } else {
      openHelpSidebar('Roles');
    }
  }, [onActionClick]);

  const styleProps: NotificationStyleProps = {
    color: 'danger',
    variant: 'light',
    size: 'default',
    withClose: false,
    isClose: false,
  };

  const handleDisabledClick = useCallback(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper || isClicked) {
      return;
    }

    setIsClicked(true);

    if (!inViewport(wrapper)) {
      elementScrollIntoView(wrapper, { behavior: 'smooth' });
    }

    gsap.to(wrapper, {
      keyframes: [
        {
          scaleX: 1.01,
          scaleY: 1.03,
          duration: 0.15,
          delay: inViewport(wrapper) ? 0 : 0.4,
        },
        {
          scaleX: 1,
          scaleY: 1,
          duration: 0.25,
          onComplete: () => {
            setIsClicked(false);
          },
        },
      ],
    });
  }, [wrapperRef.current]);

  useEffect(() => {
    document.querySelectorAll("[data-disabled='true']").forEach((box) => {
      box.addEventListener('click', handleDisabledClick);
    });

    return () => {
      document.querySelectorAll("[data-disabled='true']").forEach((box) => {
        box.removeEventListener('click', handleDisabledClick);
      });
    };
  }, []);

  return (
    <Wrapper {...styleProps} className={className} ref={wrapperRef}>
      <StyledIcon />
      <StyledNotificationInner {...styleProps}>
        <Title>{customLabel ?? 'Restricted access'}</Title>
        <Description>
          {customDescription ?? (
            <>
              With your <strong>{currentPermissionName}</strong> role, access here is limited.
            </>
          )}
        </Description>
      </StyledNotificationInner>
      <Right>
        <Button size="small" variant="muted" type="button" onClick={handleActionClick}>
          Learn more
        </Button>
      </Right>
    </Wrapper>
  );
};
