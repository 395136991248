import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { DropdownElement, Icon, Input, Button, Toggle, Dropdown } from '@column/column-ui-kit';

import { ModalBase } from '../Base';
import { NotificationList, AddressField } from '~/components';
import { RoutingNumberField } from '~/components/RoutingNumberField';
import { useAddress } from '~/hooks';
import { CounterpartyRepository, CreateCounterparty } from '~/repositories';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { Divider, FormElement, FormLabel, Headline, FormText, ModalIconCircle, ModalText } from '~/styles';

export const Buttons = styled.div<{ grow?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0 -24px -24px -24px;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}

  ${({ grow }) =>
    grow &&
    css`
      * {
        flex-grow: 1;
      }
    `}
`;

const CounterpartyWrapper = styled(ModalBase)<{ type: string }>`
  --modal-width: 330px;
  padding: 24px;

  ${({ type }) =>
    type === 'wire' &&
    css`
      --modal-width: 480px;
    `}
`;

export const Form = styled.form`
  display: grid;
  grid-gap: 24px;
`;

const Fields = styled.div<{ type: string }>`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: auto-fill;

  ${({ type }) =>
    type === 'wire' &&
    css`
      grid-gap: 16px 20px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    `}
`;

export const ModalCounterparty: React.FC = () => {
  const { closeModal, getModalData } = useModalStore();
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const modalTypes: ModalType[] = ['Counterparty'];
  const [type, setType] = useState<string>('');
  const [counterparty, setCounterparty] = useState<DeepPartial<CreateCounterparty>>({});
  const { states, countries } = useAddress({ types: ['state', 'country'] });
  const stateRef = useRef<DropdownElement>(null);

  const handleAccountNumberChange = useCallback(
    (value: string) => {
      setCounterparty({ ...counterparty, accountNumber: value });
    },
    [counterparty]
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    CounterpartyRepository.create(counterparty as CreateCounterparty)
      .then((response) => {
        if (getModalData()?.callback) {
          getModalData()?.callback(response);
        }
        closeModal();
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleClose = (e: React.FormEvent) => {
    e.preventDefault();

    closeModal();
  };

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          setCounterparty({});
          setType(modalData?.type ?? '');
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <CounterpartyWrapper type={type} modalTypes={modalTypes}>
      <ModalIconCircle>
        <Icon.AnimationUserArrow />
      </ModalIconCircle>
      <ModalText>
        <Headline size="small" fullWidth>
          New counterparty
        </Headline>
      </ModalText>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <Fields type={type}>
          <FormElement>
            <FormLabel>Description</FormLabel>
            <Input
              onChange={(value: string) => setCounterparty({ ...counterparty, description: value })}
              value={counterparty.description ?? ''}
              placeholder="Description"
            />
          </FormElement>
          <FormElement>
            <FormLabel>Routing Number</FormLabel>
            <RoutingNumberField
              routingNumber={counterparty.routingNumber ?? ''}
              onRoutingNumberChange={(routingNumber: string) => setCounterparty({ ...counterparty, routingNumber })}
            />
          </FormElement>
          <FormElement>
            <FormLabel>
              Routing Number Type <small>optional</small>
            </FormLabel>
            <Dropdown
              variant="muted"
              fullWidth
              active={counterparty?.routingNumberType ?? ''}
              onChange={(value: string) =>
                setCounterparty({
                  ...counterparty,
                  routingNumberType: value,
                })
              }
              options={[
                {
                  label: 'ABA',
                  value: 'aba',
                },
                {
                  label: 'BIC',
                  value: 'bic',
                },
              ]}
            />
          </FormElement>
          <FormElement>
            <FormLabel>Account Number</FormLabel>
            <Input
              onChange={handleAccountNumberChange}
              value={counterparty.accountNumber ?? ''}
              placeholder="Account number"
            />
          </FormElement>
          {type === 'wire' && (
            <>
              <FormElement newRow>
                <FormLabel>Beneficiary Name</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryName: value } })
                  }
                  value={counterparty.wire?.beneficiaryName ?? ''}
                  placeholder="Beneficiary name"
                />
              </FormElement>
              <FormElement>
                <FormLabel>Wire Drawdown Allowed</FormLabel>
                <FormText>
                  <Toggle
                    onCheckedChange={(value: boolean) =>
                      setCounterparty({ ...counterparty, wireDrawdownAllowed: value })
                    }
                    isChecked={counterparty.wireDrawdownAllowed ?? false}
                  />
                </FormText>
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Email</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryEmail: value } })
                  }
                  value={counterparty.wire?.beneficiaryEmail ?? ''}
                  placeholder="Beneficiary email"
                />
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Phone</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryPhone: value } })
                  }
                  value={counterparty.wire?.beneficiaryPhone ?? ''}
                  placeholder="Beneficiary phone"
                />
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Legal ID</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: { ...counterparty.wire, beneficiaryLegalId: value },
                    })
                  }
                  value={counterparty.wire?.beneficiaryLegalId ?? ''}
                  placeholder="Beneficiary legal ID"
                />
              </FormElement>
              <FormElement>
                <FormLabel>Beneficiary Type</FormLabel>
                <Dropdown
                  variant="muted"
                  fullWidth
                  active={counterparty.wire?.beneficiaryType ?? ''}
                  onChange={(value: string) =>
                    setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryType: value } })
                  }
                  options={[
                    {
                      label: 'Business',
                      value: 'business',
                    },
                    {
                      label: 'Nonprofit',
                      value: 'non_profit',
                    },
                    {
                      label: 'Individual',
                      value: 'individual',
                    },
                    {
                      label: 'Sole proprietor',
                      value: 'sole_proprietor',
                    },
                  ]}
                />
              </FormElement>
              <FormElement>
                <FormLabel>Local Bank Code</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({ ...counterparty, wire: { ...counterparty.wire, localBankCode: value } })
                  }
                  value={counterparty.wire?.localBankCode ?? ''}
                  placeholder="Local bank code"
                />
              </FormElement>
              <FormElement>
                <FormLabel>Local Account Number</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: { ...counterparty.wire, localAccountNumber: value },
                    })
                  }
                  value={counterparty.wire?.localAccountNumber ?? ''}
                  placeholder="Local account number"
                />
              </FormElement>
              <FormElement as={Divider} fullWidth>
                Beneficiary Address
              </FormElement>
              <FormElement fullWidth>
                <AddressField
                  onChange={(a) => {
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: a,
                      },
                    });
                    if (!a.state) {
                      stateRef.current?.open();
                    }
                  }}
                />
              </FormElement>
              <Divider fullWidth>or</Divider>
              <FormElement>
                <FormLabel>Line 1</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, line1: value },
                      },
                    })
                  }
                  value={counterparty.wire?.beneficiaryAddress?.line1 ?? ''}
                  placeholder="Line 1"
                />
              </FormElement>
              <FormElement>
                <FormLabel>Line 2</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, line2: value },
                      },
                    })
                  }
                  value={counterparty.wire?.beneficiaryAddress?.line2 ?? ''}
                  placeholder="Line 2"
                />
              </FormElement>
              <FormElement>
                <FormLabel>City</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, city: value },
                      },
                    })
                  }
                  value={counterparty.wire?.beneficiaryAddress?.city ?? ''}
                  placeholder="City"
                />
              </FormElement>

              <FormElement>
                <FormLabel>Country</FormLabel>
                <Dropdown
                  variant="muted"
                  fullWidth
                  active={counterparty.wire?.beneficiaryAddress?.countryCode ?? ''}
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, countryCode: value },
                      },
                    })
                  }
                  options={countries.map((c) => ({
                    label: c.name,
                    value: c.code,
                  }))}
                  search
                />
              </FormElement>
              <FormElement>
                <FormLabel>Postal Code</FormLabel>
                <Input
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, postalCode: value },
                      },
                    })
                  }
                  value={counterparty.wire?.beneficiaryAddress?.postalCode ?? ''}
                  placeholder="Postal code"
                />
              </FormElement>
              <FormElement>
                <FormLabel>State</FormLabel>
                <Dropdown
                  ref={stateRef}
                  variant="muted"
                  fullWidth
                  active={counterparty.wire?.beneficiaryAddress?.state ?? ''}
                  onChange={(value: string) =>
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, state: value },
                      },
                    })
                  }
                  options={
                    counterparty.wire?.beneficiaryAddress?.countryCode
                      ? states
                          .filter((s) => s.country_code === counterparty.wire?.beneficiaryAddress?.countryCode)
                          .map((s) => ({
                            label: `${s.name} (${s.state_code})`,
                            value: s.state_code,
                          }))
                      : []
                  }
                  search
                />
              </FormElement>
            </>
          )}
        </Fields>
        <Buttons>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button>Create &amp; Select</Button>
        </Buttons>
      </Form>
    </CounterpartyWrapper>
  );
};
