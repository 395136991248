import React from 'react';

import { Datepicker, DatepickerProps } from '../../Datepicker';
import { PopoverBase, PopoverBaseProps } from '../Base';

interface PopoverDatepickerProps extends PopoverBaseProps, DatepickerProps {}

export const PopoverDatepicker: React.FC<PopoverDatepickerProps> = (props) => {
  return (
    <PopoverBase {...props}>
      <Datepicker
        date={props.date}
        onDateSubmit={props.onDateSubmit}
        disableBefore={props.disableBefore}
        disableAfter={props.disableAfter}
      />
    </PopoverBase>
  );
};
