import React from 'react';
import styled, { css } from 'styled-components';

import { IMAGE_URL } from '~/assets';
import { HelpSidebarType } from '~/stores/HelpSidebar';

import { BottomLinks, LinkList, HelpSidebarBase, Line } from './Base';

export const Image = styled.img`
  border-radius: 8px;
  display: block;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

export const HelpSidebarText = styled.div<{ noMargin?: boolean }>`
  color: ${({ theme }) => theme.secondary.background};

  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin: 20px 0 0 0;
    `}

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const HelpSidebarList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 32px;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const HelpSidebarListItem = styled.li`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 4px;
  }

  p {
    color: ${({ theme }) => theme.secondary.background};
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const HelpSidebarOnboarding: React.FC = () => {
  const helpSidebar: HelpSidebarType[] = ['Onboarding'];

  return (
    <HelpSidebarBase helpSidebars={helpSidebar} headline="Welcome to Column">
      <Image src={IMAGE_URL.WELCOME} srcSet={`${IMAGE_URL.WELCOME2X} 2x`} />
      <HelpSidebarText>
        Column N.A. is the first nationally chartered bank designed to serve developers and builders, offering financial
        capabilities through robust APIs, in as lightweight a format as possible. We have built our own ledger, core,
        and direct integration into the Federal Reserve. Our goal is to expose these capabilities to you in a simple,
        powerful and developer-friendly way.
      </HelpSidebarText>
      <Line />
      <LinkList
        title="Helpful guides"
        entries={[
          {
            label: 'Getting started guide',
            url: 'https://column.com/docs/guides/getting-started-with-the-column-api',
          },
          {
            label: 'Sandbox guide',
            url: 'https://column.com/docs/guides/sandbox-and-testing',
          },
          {
            label: 'Use case guides',
            url: 'https://column.com/docs/guides',
          },
        ]}
      />
      <BottomLinks />
    </HelpSidebarBase>
  );
};
