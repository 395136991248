import { useSessionStore } from '~/stores/Session';
import {
  WebhookCreateWebhookEndpointRequest,
  WebhookListWebhookEndpointsResponse,
  WebhookServiceListWebhookEndpointsParams,
  WebhookServiceUpdateWebhookEndpointPayload,
  WebhookServiceVerifyWebhookEndpointPayload,
  WebhookVerifyWebhookEndpointResponse,
  WebhookWebhookEndpoint,
} from '~/typings/API';
import { convertValues } from '~/util';

import { client } from './client';

export type Webhook = WebhookWebhookEndpoint;
export type CreateWebhook = WebhookCreateWebhookEndpointRequest;
export type UpdateWebhook = WebhookServiceUpdateWebhookEndpointPayload;
export type WebhookVerify = WebhookVerifyWebhookEndpointResponse;

export class WebhookRepository {
  static async create(request: CreateWebhook, sandbox?: boolean) {
    return client
      .post<CreateWebhook, Webhook>('/webhook_endpoints', request, { sandbox })
      .then((response) => convertValues<Webhook>(response));
  }

  static async verify(id: string, request: WebhookServiceVerifyWebhookEndpointPayload, sandbox?: boolean) {
    return client
      .post<WebhookServiceVerifyWebhookEndpointPayload, WebhookVerifyWebhookEndpointResponse>(
        `/webhook_endpoints/${id}/verify`,
        request,
        {
          sandbox,
        }
      )
      .then((response) => convertValues<WebhookVerifyWebhookEndpointResponse>(response));
  }

  static async get(id: string, sandbox?: boolean) {
    return client
      .get<unknown, Webhook>(`/webhook_endpoints/${id}`, { sandbox })
      .then((response) => convertValues<Webhook>(response));
  }

  static async getAll(sandbox?: boolean, query?: Partial<WebhookServiceListWebhookEndpointsParams>) {
    return client
      .get<WebhookServiceListWebhookEndpointsParams, WebhookListWebhookEndpointsResponse>('/webhook_endpoints', {
        sandbox,
        query,
      })
      .then((response) => ({
        webhookEndpoints: Array.isArray(response?.webhookEndpoints)
          ? response.webhookEndpoints.map((e) => convertValues<Webhook>(e))
          : [],
      }));
  }

  static async getAllList(query?: Partial<WebhookServiceListWebhookEndpointsParams>) {
    if (useSessionStore.getState().currentPlatform?.isLiveEnabled) {
      const sandbox = await WebhookRepository.getAll(true, query);
      const production = await WebhookRepository.getAll(false, query);

      return {
        webhookEndpoints: [
          ...sandbox.webhookEndpoints.map((e) => ({ ...e, type: 'Sandbox' })),
          ...production.webhookEndpoints.map((e) => ({ ...e, type: 'Production' })),
        ],
      };
    }

    const sandboxSec = await WebhookRepository.getAll(true, query);

    return {
      webhookEndpoints: [...sandboxSec.webhookEndpoints.map((e) => ({ ...e, type: 'Sandbox' }))],
    };
  }

  static async update(id: string, request: UpdateWebhook, sandbox?: boolean) {
    return client
      .patch<UpdateWebhook, Webhook>(`/webhook_endpoints/${id}`, request, { sandbox })
      .then((response) => convertValues<Webhook>(response));
  }

  static async delete(id: string, sandbox?: boolean) {
    return client.delete<object, any>(`/webhook_endpoints/${id}`, { sandbox });
  }
}
