import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldPath, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Chip, Icon, Input, List, ListRow, ListRowVariant } from '@column/column-ui-kit';

import { Access, Permissions, TransfersAndAccountsFields } from '../types';
import { EntitiesAndAccounts } from '../useEntitiesAndAccounts';
import {
  BankAccountLevelPermissionsToLabelsMap,
  buildBankAccountOverridesFromPermissionLevel,
  buildBankAccountOverridesFromPermissions,
  buildEntityOverridesFromPermissionLevel,
  buildEntityOverridesFromPermissions,
  EntityLevelPermissionsToLabelsMap,
  getAccessLevel,
  getBankAccountPermissions,
  getEntityPermissions,
  PlatformLevelPermissionsToLabelsMap,
  setBankAccountPermissionsFromPermissionLevel,
  setBankAccountPermissionsFromPermissions,
  setEntityPermissionsFromPermissionLevel,
  setEntityPermissionsFromPermissions,
} from '../utils';
import { AccessDropdown } from '~/app/pages/Platform/Roles/components/AccessDropdown';
import { CustomOverridesModal } from '~/app/pages/Platform/Roles/components/CustomOverridesModal';
import { PermissionToggles } from '~/app/pages/Platform/Roles/components/PermissionToggles';
import { Box, Hint, Inner, Line, SmallHeadline } from '~/styles';
import {
  BankAccountWithDetails,
  CustomRoleBankAccountLevelOverrides,
  CustomRoleEntityLevelOverrides,
  CustomRolePermissionLevel,
  CustomRolePlatformLevelPermissions,
  EntityEntity,
} from '~/typings/API';

interface Props {
  entitiesAndAccounts: EntitiesAndAccounts;
}

const DefaultPermissionsWrapper = styled.div<{ height?: number }>`
  overflow: hidden;
  transition: height 0.25s ease-in-out;
  height: ${({ height }) => height}px;
`;

const RowAction = styled.div`
  max-width: 200px;
`;

const RowTitle = styled.div`
  flex-grow: 1;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const TransfersAndAccounts: React.FC<Props> = ({ entitiesAndAccounts }) => {
  const { control, setValue } = useFormContext();
  const [bankAccountSearch, setBankAccountSearch] = useState<string>('');

  const platformLevelPermissions = useWatch<TransfersAndAccountsFields>({
    name: 'platformLevelPermissions',
  }) as CustomRolePlatformLevelPermissions;
  const [customAccessSelected, setCustomAccessSelected] = useState<boolean>(false);
  const defaultAccess = useMemo(() => {
    if (customAccessSelected) {
      return Access.Custom;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'none'
      )
    ) {
      return Access.NoAccess;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'read'
      )
    ) {
      return Access.ViewOnly;
    }
    if (
      Object.keys(PlatformLevelPermissionsToLabelsMap).every(
        (value) => platformLevelPermissions[value as keyof CustomRolePlatformLevelPermissions] === 'write'
      )
    ) {
      return Access.FullAccess;
    }
    return Access.Custom;
  }, [customAccessSelected, platformLevelPermissions]);
  const showCustomPermissions = defaultAccess === Access.Custom;

  const setDefaultAccess = useCallback(
    (access: Access) => {
      let value: CustomRolePermissionLevel;
      switch (access) {
        case Access.Custom:
          setCustomAccessSelected(true);
          if (defaultAccess === Access.NoAccess) {
            value = 'read';
            break;
          }
          return;
        case Access.FullAccess:
          setCustomAccessSelected(false);
          value = 'write';
          break;
        case Access.ViewOnly:
          setCustomAccessSelected(false);
          value = 'read';
          break;
        case Access.NoAccess:
          setCustomAccessSelected(false);
          value = 'none';
          break;
        default:
          value = 'default';
          return;
      }
      Object.keys(PlatformLevelPermissionsToLabelsMap).forEach((permission) => {
        setValue(`platformLevelPermissions.${permission}` as FieldPath<TransfersAndAccountsFields>, value);
      });
    },
    [defaultAccess, setValue]
  );

  const [defaultAccessBoxHeight, setDefaultAccessBoxHeight] = useState<number>();
  const defaultAccountPermissionsWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultAccountPermissionsWrapperRef.current) {
      setDefaultAccessBoxHeight(defaultAccountPermissionsWrapperRef.current.scrollHeight);
    }
  }, [showCustomPermissions]);

  return (
    <>
      <Inner>
        <Box variant={'secondary'}>
          <DefaultPermissionsWrapper height={defaultAccessBoxHeight}>
            <Inner ref={defaultAccountPermissionsWrapperRef}>
              <Inner p={0} pb={showCustomPermissions ? 24 : 0}>
                <RowWrapper>
                  <RowTitle>
                    <SmallHeadline>Default Account Permissions</SmallHeadline>
                    <Hint>Set the default permissions for new and existing accounts</Hint>
                  </RowTitle>
                  <RowAction>
                    <AccessDropdown onChange={setDefaultAccess} value={defaultAccess} />
                  </RowAction>
                </RowWrapper>
              </Inner>
              {showCustomPermissions && (
                <>
                  <Line />
                  <Inner p={0} pb={4} pt={28}>
                    <PermissionToggles
                      control={control}
                      permissionsToLabelsMap={PlatformLevelPermissionsToLabelsMap}
                      fieldPathPrefix="platformLevelPermissions"
                    />
                  </Inner>
                </>
              )}
            </Inner>
          </DefaultPermissionsWrapper>
        </Box>
      </Inner>
      <Inner pt={0}>
        <RowWrapper>
          <RowTitle>
            <SmallHeadline>Account Overrides</SmallHeadline>
            <Hint>Override permissions on individual Accounts and Entities.</Hint>
          </RowTitle>
          <RowAction>
            <Input
              icon={<Icon.Search />}
              onChange={setBankAccountSearch}
              placeholder="Search bank accounts"
              size={'small'}
              value={bankAccountSearch}
            />
          </RowAction>
        </RowWrapper>
      </Inner>
      <AccountOverridesList bankAccountSearch={bankAccountSearch} entitiesAndAccounts={entitiesAndAccounts} />
    </>
  );
};

const AccountActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 200px;
`;

const StyledFolderIcon = styled(Icon.Folder)`
  --icon-color: ${({ theme }) => theme.gray.blendToBackground(600)};
`;

const AccountOverridesList: React.FC<{ bankAccountSearch: string; entitiesAndAccounts: EntitiesAndAccounts }> = ({
  bankAccountSearch,
  entitiesAndAccounts,
}) => {
  const bankAccounts = entitiesAndAccounts.bankAccounts.data.filter((bankAccount) => {
    if (!bankAccountSearch) return true;
    const visibleName = bankAccount.displayName || bankAccount.description || '';
    return visibleName.toLowerCase().includes(bankAccountSearch.toLowerCase());
  });
  const entities = entitiesAndAccounts.entities.data.filter((entity) => {
    if (!bankAccountSearch) return true;
    return bankAccounts.some((bankAccount) => bankAccount.owners.includes(entity.id as string));
  });
  const rootEntities = entities.filter((entity) => entity.isRoot);
  const nonRootEntities = entities.filter((entity) => !entity.isRoot);
  const entityIdToBankAccountsMap = useMemo(() => {
    const map = new Map<string, BankAccountWithDetails[]>();
    bankAccounts.forEach((bankAccount) => {
      bankAccount.owners.forEach((entityId) => {
        if (!map.has(entityId)) {
          map.set(entityId, []);
        }
        map.get(entityId)?.push(bankAccount);
      });
    });
    return map;
  }, [bankAccounts, entities]);

  return (
    <List>
      <ListRow text="Entities and accounts" variant={ListRowVariant.Header} />
      {rootEntities.map((entity) => {
        return (
          <EntityListSection
            key={entity.id}
            entity={entity}
            bankAccounts={entityIdToBankAccountsMap.get(entity.id as string)}
            hasMoreBankAccounts={entitiesAndAccounts.bankAccounts.paginationState[entity.id as string]?.hasMore}
            loadMoreBankAccounts={() => entitiesAndAccounts.bankAccounts.loadMore(entity.id as string)}
            rootEntity
          />
        );
      })}
      {nonRootEntities.map((entity) => {
        return (
          <EntityListSection
            key={entity.id}
            entity={entity}
            bankAccounts={entityIdToBankAccountsMap.get(entity.id as string)}
            hasMoreBankAccounts={entitiesAndAccounts.bankAccounts.paginationState[entity.id as string]?.hasMore}
            loadMoreBankAccounts={() => entitiesAndAccounts.bankAccounts.loadMore(entity.id as string)}
          />
        );
      })}
    </List>
  );
};

interface EntityListSectionProps {
  bankAccounts?: BankAccountWithDetails[];
  entity: EntityEntity;
  hasMoreBankAccounts?: boolean;
  loadMoreBankAccounts: () => void;
  rootEntity?: boolean;
}
const EntityListSection: React.FC<EntityListSectionProps> = ({
  bankAccounts,
  entity,
  hasMoreBankAccounts,
  loadMoreBankAccounts,
  rootEntity,
}) => {
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState(false);
  const openOverridesModal = useCallback(() => setTimeout(() => setIsOverridesModalOpen(true), 150), []);
  const closeOverridesModal = useCallback(() => setIsOverridesModalOpen(false), []);
  const { setValue } = useFormContext<TransfersAndAccountsFields>();

  const platformLevelPermissions = useWatch<TransfersAndAccountsFields>({
    name: 'platformLevelPermissions',
  }) as CustomRolePlatformLevelPermissions;
  const { replace: replaceBankAccounts } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });
  const bankAccountLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'bankAccountLevelOverrides',
  }) as CustomRoleBankAccountLevelOverrides[];
  const { append: appendEntity, remove: removeEntity } = useFieldArray<TransfersAndAccountsFields>({
    name: 'entityLevelOverrides',
  });
  const entityLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'entityLevelOverrides',
  }) as CustomRoleEntityLevelOverrides[];

  const {
    entityIndex,
    effectivePermissions: entityPermissions,
    numOverrides,
  } = useMemo(
    () =>
      getEntityPermissions({
        entityId: entity.id as string,
        entityLevelOverrides,
        platformPermissions: platformLevelPermissions,
      }),
    [entity.id, entityLevelOverrides, platformLevelPermissions]
  );

  const setOverridesFromPermissionLevel = useCallback(
    (permissionLevel: CustomRolePermissionLevel) => {
      if (entityIndex === -1) {
        appendEntity(buildEntityOverridesFromPermissionLevel(entity.id as string, permissionLevel));
      } else {
        setEntityPermissionsFromPermissionLevel({
          permissionLevel,
          keyPrefix: `entityLevelOverrides.${entityIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [entity.id, entityIndex, appendEntity, setValue]
  );

  const setOverridesFromPermissions = useCallback(
    (permissions: Permissions) => {
      if (entityIndex === -1) {
        appendEntity(buildEntityOverridesFromPermissions(entity.id as string, permissions));
      } else {
        setEntityPermissionsFromPermissions({
          permissions,
          keyPrefix: `entityLevelOverrides.${entityIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [entity.id, entityIndex, appendEntity, setValue]
  );

  // The current (persisted in the form) access level for this entity
  const entityAccessLevel = useMemo(() => {
    if (entityIndex === -1) {
      return Access.Default;
    }
    return getAccessLevel(entityPermissions);
  }, [entityIndex, entityPermissions]);
  // The access level to show in the UI
  const visibleAccessLevel = isOverridesModalOpen ? Access.Custom : entityAccessLevel;

  const setEntityAccessLevel = useCallback(
    (access: Access) => {
      switch (access) {
        case Access.Custom:
          openOverridesModal();
          return;
        case Access.Default:
          if (entityIndex !== -1) {
            removeEntity(entityIndex);
          }
          return;
        case Access.FullAccess:
          setOverridesFromPermissionLevel('write');
          return;
        case Access.ViewOnly:
          setOverridesFromPermissionLevel('read');
          return;
        case Access.NoAccess:
          setOverridesFromPermissionLevel('none');
          // Remove all bank account overrides for this entity
          replaceBankAccounts(
            bankAccountLevelOverrides.filter(
              (override) => !bankAccounts?.some((bankAccount) => bankAccount.id === override.bankAccountId)
            )
          );
          return;
      }
    },
    [bankAccountLevelOverrides, replaceBankAccounts, setOverridesFromPermissionLevel]
  );

  return (
    <>
      <CustomOverridesModal
        breadcrumbs={[entity.name as string]}
        currentPermissions={entityPermissions}
        onClose={closeOverridesModal}
        onSave={(permissions) => {
          setOverridesFromPermissions(permissions);
          closeOverridesModal();
        }}
        open={isOverridesModalOpen}
        parentPermissions={platformLevelPermissions as Permissions}
        permissionsToLabelsMap={EntityLevelPermissionsToLabelsMap}
      />
      <ListRow
        icon={<StyledFolderIcon filled={!!rootEntity} flat />}
        rightDecoration={
          <>
            {hasMoreBankAccounts && (
              <Button onClick={() => loadMoreBankAccounts()} size={'tiny'} variant={'subtle'}>
                Load More Accounts
              </Button>
            )}
            {entityAccessLevel === Access.Custom && numOverrides > 0 && <Chip>{numOverrides} Overrides</Chip>}
            <AccountActionsWrapper>
              <AccessDropdown
                onChange={setEntityAccessLevel}
                value={visibleAccessLevel}
                variant={'muted'}
                showDefault
              />
              {visibleAccessLevel === Access.Custom && (
                <Button onClick={openOverridesModal} variant={'secondary'} size={'small'}>
                  Edit
                </Button>
              )}
            </AccountActionsWrapper>
          </>
        }
        text={entity.name as string}
        variant={ListRowVariant.SubHeader}
      />
      {bankAccounts?.map((bankAccount) => (
        <BankAccountListRow
          key={bankAccount.id}
          bankAccount={bankAccount}
          parentName={entity.name as string}
          parentOverrides={entityLevelOverrides}
          parentPermissions={entityPermissions}
        />
      ))}
    </>
  );
};

interface BankAccountListRowProps {
  bankAccount: BankAccountWithDetails;
  parentName: string;
  parentOverrides: CustomRoleEntityLevelOverrides[];
  parentPermissions: Permissions;
}
const BankAccountListRow: React.FC<BankAccountListRowProps> = ({
  bankAccount,
  parentName,
  parentOverrides,
  parentPermissions,
}) => {
  const [isOverridesModalOpen, setIsOverridesModalOpen] = useState<boolean>(false);
  const openOverridesModal = useCallback(() => setTimeout(() => setIsOverridesModalOpen(true), 150), []);
  const closeOverridesModal = useCallback(() => setIsOverridesModalOpen(false), []);
  const { setValue } = useFormContext<TransfersAndAccountsFields>();

  const bankAccountLevelOverrides = useWatch<TransfersAndAccountsFields>({
    defaultValue: [],
    name: 'bankAccountLevelOverrides',
  }) as CustomRoleBankAccountLevelOverrides[];
  const { append: appendBankAccount, remove: removeBankAccount } = useFieldArray<TransfersAndAccountsFields>({
    name: 'bankAccountLevelOverrides',
  });

  const {
    bankAccountIndex,
    effectivePermissions: bankAccountPermissions,
    numOverrides,
  } = useMemo(
    () =>
      getBankAccountPermissions({
        bankAccountId: bankAccount.id,
        bankAccountLevelOverrides,
        entityPermissions: parentPermissions,
      }),
    [bankAccount.id, bankAccountLevelOverrides, parentPermissions]
  );

  const setOverridesFromPermissionLevel = useCallback(
    (permissionLevel: CustomRolePermissionLevel) => {
      if (bankAccountIndex === -1) {
        appendBankAccount(buildBankAccountOverridesFromPermissionLevel(bankAccount.id, permissionLevel));
      } else {
        setBankAccountPermissionsFromPermissionLevel({
          permissionLevel,
          keyPrefix: `bankAccountLevelOverrides.${bankAccountIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [bankAccount.id, bankAccountIndex, appendBankAccount, setValue]
  );

  const setOverridesFromPermissions = useCallback(
    (permissions: Permissions) => {
      if (bankAccountIndex === -1) {
        appendBankAccount(buildBankAccountOverridesFromPermissions(bankAccount.id, permissions));
      } else {
        setBankAccountPermissionsFromPermissions({
          permissions,
          keyPrefix: `bankAccountLevelOverrides.${bankAccountIndex}.`,
          setter: setValue as (key: string, value: string) => void,
        });
      }
    },
    [bankAccount.id, bankAccountIndex, appendBankAccount, setValue]
  );

  // The current (persisted in the form) access level for this bank account
  const bankAccountAccessLevel = useMemo(() => {
    if (bankAccountIndex === -1) {
      return Access.Default;
    }
    return getAccessLevel(bankAccountPermissions);
  }, [bankAccountIndex, bankAccountPermissions]);
  // The access level to show in the UI
  const visibleAccessLevel = isOverridesModalOpen ? Access.Custom : bankAccountAccessLevel;
  const parentAccessLevel = useMemo(() => getAccessLevel(parentPermissions), [parentPermissions]);

  const setBankAccountAccessLevel = useCallback(
    (access: Access) => {
      switch (access) {
        case Access.Custom:
          openOverridesModal();
          return;
        case Access.Default:
          if (bankAccountIndex !== -1) {
            removeBankAccount(bankAccountIndex);
          }
          return;
        case Access.FullAccess:
          setOverridesFromPermissionLevel('write');
          return;
        case Access.ViewOnly:
          setOverridesFromPermissionLevel('read');
          return;
        case Access.NoAccess:
          setOverridesFromPermissionLevel('none');
          return;
      }
    },
    [setOverridesFromPermissionLevel, removeBankAccount]
  );

  const showAccessDropdown = parentOverrides.length === 0 || parentAccessLevel !== Access.NoAccess;

  return (
    <>
      <CustomOverridesModal
        breadcrumbs={[parentName, bankAccount.displayName ?? bankAccount.description ?? 'Unnamed']}
        currentPermissions={bankAccountPermissions}
        onClose={closeOverridesModal}
        onSave={(permissions) => {
          setOverridesFromPermissions(permissions);
          closeOverridesModal();
        }}
        open={isOverridesModalOpen}
        parentPermissions={parentPermissions}
        permissionsToLabelsMap={BankAccountLevelPermissionsToLabelsMap}
      />
      <ListRow
        key={bankAccount.id}
        depth={1}
        rightDecoration={
          showAccessDropdown && (
            <>
              {bankAccountAccessLevel === Access.Custom && numOverrides > 0 && <Chip>{numOverrides} Overrides</Chip>}
              <AccountActionsWrapper>
                <AccessDropdown
                  onChange={setBankAccountAccessLevel}
                  value={visibleAccessLevel}
                  variant={'muted'}
                  showDefault
                />
                {visibleAccessLevel === Access.Custom && (
                  <Button onClick={openOverridesModal} variant={'muted'} size={'small'}>
                    Edit
                  </Button>
                )}
              </AccountActionsWrapper>
            </>
          )
        }
        text={bankAccount.displayName ?? bankAccount.description ?? 'Unnamed'}
        variant={ListRowVariant.Default}
      />
    </>
  );
};
