import { API_URL } from '~/constants';
import { getQueryString } from '~/hooks/useQueryParams';
import { keysToSnakeCase } from '~/util/convertKeys';
import { convertValuesToString } from '~/util/convertValues';

export type RequestMethodType = 'GET' | 'POST' | 'PATCH' | 'DELETE';

export class APIRequest<Data, Query> {
  #data: string | undefined;
  #query: string | undefined;
  #headers: Record<string, string> = {};
  #init?: RequestInit;

  constructor(
    private path: string,
    private method: RequestMethodType,
    data?: Data,
    query?: Query,
    headers?: Record<string, string>,
    init?: RequestInit
  ) {
    this.setData(data);
    this.setQuery(query);

    this.#headers = headers ?? {};
    this.#init = init;
  }

  addHeader(key: string, value: string) {
    this.#headers[key] = value;

    return this;
  }

  setData(data?: Data) {
    if (typeof data === 'undefined' || data === null) {
      this.#data = undefined;

      return this;
    }

    if (typeof data === 'string') {
      this.#data = data;

      return this;
    }

    this.#data =
      typeof data === 'object' && Object.keys(data).length > 0
        ? JSON.stringify(keysToSnakeCase(convertValuesToString(data)))
        : undefined;

    return this;
  }

  setQuery(query: Query | string | undefined) {
    this.#query =
      typeof query === 'object'
        ? getQueryString<Query>(keysToSnakeCase(query))
        : typeof query === 'string'
          ? query
          : '';

    return this;
  }

  getRequest(): Request {
    return new Request(API_URL + this.path + this.#query, {
      method: this.method,
      headers: this.#headers,
      body: this.#data,
      credentials: 'include',
      ...this.#init,
    });
  }
}
