import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventLoanProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventLoan = forwardRef<SVGSVGElement, EventLoanProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99815 14.0023H2.66426C2.29597 14.0022 1.99745 13.7036 1.99731 13.3354V9.33369C1.99745 8.9654 2.29597 8.66688 2.66426 8.66675H3.99815C4.36644 8.66688 4.66496 8.9654 4.66509 9.33369V13.3354C4.66496 13.7036 4.36644 14.0022 3.99815 14.0023V14.0023Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66695 11.3346H9.97416C10.2628 11.3348 10.5437 11.2412 10.7745 11.0679L12.3858 9.85936C12.7881 9.55788 13.3509 9.59795 13.7064 9.9534V9.9534C13.896 10.1428 14.0025 10.3997 14.0025 10.6677C14.0025 10.9357 13.896 11.1926 13.7064 11.382L12.3238 12.7646C11.9513 13.1369 11.477 13.3908 10.9606 13.4942L9.00642 13.885C8.619 13.9625 8.21921 13.953 7.83594 13.857L6.06853 13.4155C5.85724 13.3623 5.64017 13.3354 5.42227 13.3355H4.66528"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66695 11.3345H9.65736C9.92536 11.3346 10.1824 11.2282 10.3719 11.0387C10.5614 10.8491 10.6678 10.5921 10.6678 10.3241V10.122C10.6679 9.65857 10.3524 9.25456 9.9028 9.14228L8.37416 8.76012C8.1257 8.69812 7.87059 8.66675 7.61451 8.66675V8.66675C6.9961 8.66656 6.3915 8.84968 5.87712 9.19297L4.66528 10.0006"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8471 4.48665C13.4982 5.1378 13.4982 6.19351 12.8471 6.84466C12.196 7.4958 11.1402 7.4958 10.4891 6.84466C9.83795 6.19351 9.83795 5.1378 10.4891 4.48665C10.8018 4.17396 11.2259 3.99829 11.6681 3.99829C12.1103 3.99829 12.5344 4.17396 12.8471 4.48665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.96987 4.3788C4.39135 3.80028 4.39135 2.86232 4.96986 2.2838L5.28494 1.96872C5.86346 1.3902 6.80142 1.3902 7.37994 1.96872L7.69502 2.2838C8.27354 2.86232 8.27354 3.80028 7.69502 4.3788L7.37994 4.69388C6.80143 5.27239 5.86346 5.2724 5.28494 4.69388L4.96987 4.3788Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
