import { gsap } from 'gsap';
import Physics2DPlugin from 'gsap/Physics2DPlugin';

import { MousePositionType } from '~/hooks/useMousePosition';

gsap.registerPlugin(Physics2DPlugin);

const QUANTITY = 160;
const DOT_SIZE_MAX = 3;
const DOT_SIZE_MIN = 1;
const MIN_ANGLE = 0.4;
const MAX_ANGLE = 1.6;

interface Position {
  x: number;
  y: number;
}

let dotIndex = 0;

export const calculateDistance = (
  element: HTMLElement,
  parentElement: HTMLElement,
  mousePosition: MousePositionType
) => {
  const { mouseX, mouseY } = mousePosition;
  const initial = mousePosition;
  const elementBounding = element.getBoundingClientRect();
  const parentElementBounding = parentElement.getBoundingClientRect();

  const nx1 = elementBounding.left;
  const ny1 = elementBounding.top;
  const nx2 = nx1 + elementBounding.width;
  const ny2 = ny1 + elementBounding.height;

  const elementOffset = {
    top: elementBounding.top - parentElementBounding.top,
    left: elementBounding.left - parentElementBounding.left,
  };

  const maxX1 = Math.max(mouseX, nx1);
  const minX2 = Math.min(mouseX, nx2);
  const maxY1 = Math.max(mouseY, ny1);
  const minY2 = Math.min(mouseY, ny2);
  const intersectX = minX2 >= maxX1;
  const intersectY = minY2 >= maxY1;
  const to = {
    x: intersectX ? mouseX : nx2 < mouseX ? nx2 : nx1,
    y: intersectY ? mouseY : ny2 < mouseY ? ny2 : ny1,
  };
  const distX = to.x - initial.mouseX;
  const distY = to.y - initial.mouseY;

  return Math.sqrt(distX * distX + distY * distY) / elementOffset.left;
};

export const calculateRotate = (element: HTMLElement, mousePosition: MousePositionType) => {
  const { mouseX, mouseY } = mousePosition;
  const elementBounding = element.getBoundingClientRect();

  const center = {
    x: elementBounding.left + elementBounding.width / 2,
    y: elementBounding.top + elementBounding.height / 2,
  };

  const radians = Math.atan2(mouseX - center.x, mouseY - center.y);

  return radians * (180 / Math.PI) * -1 + 180;
};

export const getCirclePath = (value: number) => {
  return `M46,80 C55.3966448,80 63.9029705,76.1880913 70.0569683,70.0262831 C76.2007441,63.8747097 80,55.3810367 80,46 C80,36.6003571 76.1856584,28.0916013 70.0203842,21.9371418 C63.8692805,15.7968278 55.3780386, ${value} 46, ${value} C36.596754, ${value} 28.0850784,15.8172663 21.9300655,21.9867066 C15.7939108,28.1372443 12,36.6255645 12,46 C12,55.4035343 15.8175004,63.9154436 21.9872741,70.0705007 C28.1377665,76.2063225 36.6258528,80 46,80 Z`;
};

export const clampNumber = (value: number, minValue?: number, maxValue?: number) => {
  return Math.min(Math.max(value, minValue ?? 0), maxValue ?? 1);
};

export const generateDots = (parentElement: HTMLElement) => {
  const dots: HTMLDivElement[] = [];

  for (let i = QUANTITY - 1; i >= 0; i--) {
    const dot = document.createElement('div');

    gsap.set(dot, {
      xPercent: -50,
      yPercent: -50,
      force3D: true,
      backgroundColor: 'var(--upload-dot-color)',
      position: 'absolute',
      borderRadius: '50%',
      pointerEvents: 'none',
      zIndex: '1',
      top: 0,
      left: 0,
    });

    parentElement.appendChild(dot);
    dots[i] = dot;
  }

  return dots;
};

const shootDot = (
  particles: gsap.core.Timeline,
  parentElement: HTMLElement,
  emitterElement: HTMLElement,
  move: Position[],
  dots: HTMLDivElement[]
) => {
  const parentBounding = parentElement.getBoundingClientRect();
  const emitterBounding = emitterElement.getBoundingClientRect();

  const angle =
    Math.atan2(move[1].y - move[0].y, move[1].x - move[0].x) * (MIN_ANGLE + Math.random() * (MAX_ANGLE - MIN_ANGLE));
  const size = DOT_SIZE_MIN + Math.random() * (DOT_SIZE_MAX - DOT_SIZE_MIN);
  const length = Math.random() * (size / 2);

  const dot = dots[dotIndex === QUANTITY ? (dotIndex = 0) : dotIndex++];

  gsap.set(dot, {
    opacity: 1,
    x: Math.cos(angle) * length + emitterBounding.left - parentBounding.left,
    y: Math.sin(angle) * length + emitterBounding.top - parentBounding.top,
    width: size,
    height: size,
  });

  if (particles.progress() !== 1) {
    gsap.to(dot, {
      opacity: 0,
      duration: (1 + Math.random()) * 0.32,
      physics2D: {
        angle: (angle * 160) / Math.PI,
        velocity: (10 + Math.random() * 16) * -1,
        gravity: 200,
      },
    });
  } else {
    dots.forEach((elem) => elem.remove());
    emitterElement.remove();
    dotIndex = 0;
  }
};

export const createParticles = (
  parentElement: HTMLElement,
  circleElement: HTMLElement,
  fromPoint: MousePositionType,
  onDotReached?: () => void
) => {
  const parentBounding = parentElement.getBoundingClientRect();
  const circleBounding = circleElement.getBoundingClientRect();
  const emitterElement = document.createElement('div');

  gsap.set(emitterElement, {
    position: 'absolute',
    top: fromPoint.mouseY - parentBounding.top,
    left: fromPoint.mouseX - parentBounding.left,
    pointerEvents: 'none',
  });

  parentElement.appendChild(emitterElement);

  const move: Position[] = [
    {
      x: fromPoint.mouseX - circleBounding.left,
      y: fromPoint.mouseY - circleBounding.top,
    },
    {
      x: circleBounding.left + circleBounding.width / 2 - fromPoint.mouseX,
      y: circleBounding.top + circleBounding.height / 2 - fromPoint.mouseY,
    },
  ];

  const particleTimeline = gsap.timeline().to(emitterElement, {
    x: move[1].x,
    y: move[1].y,
    duration: 1,
    onStart: () => {
      setTimeout(() => {
        if (onDotReached) {
          onDotReached();
        }
      }, 450);
    },
  });

  const dots = generateDots(parentElement);

  gsap
    .timeline({
      repeat: QUANTITY,
      onStart: () => dots.forEach((elem) => (elem.style.display = 'block')),
    })
    .call(shootDot, [particleTimeline, parentElement, emitterElement, move, dots], 2 / QUANTITY);
};

export const moveCircle = (
  circleElement: HTMLElement,
  parentElement: HTMLElement,
  largePreview: boolean,
  onDone: () => void
) => {
  const circleBounding = circleElement.getBoundingClientRect();
  const parentBounding = parentElement.getBoundingClientRect();

  gsap.fromTo(
    circleElement,
    {
      top: '0px',
      left: '0px',
      margin: '0',
      x: (parentBounding.width - circleBounding.width) / 2,
      y: (parentBounding.height - circleBounding.height) / 2,
    },
    {
      '--circle-svg-scale': '0.525',
      x: -4,
      y: -4,
      duration: 0.35,
      delay: 0.2,
      onComplete: () => {
        onDone();
      },
      onStart: () => {
        gsap.fromTo(
          parentElement,
          {
            height: '120px',
          },
          {
            height: largePreview ? 'auto' : '44px',
            duration: 0.35,
          }
        );
      },
    }
  );
};

export const resetCircle = (circleElement: HTMLElement) => {
  gsap.set(circleElement, {
    top: '50%',
    left: '50%',
    margin: '-26px 0 0 -26px',
    '--circle-svg-scale': '1',
    x: 0,
    y: 0,
  });
};
