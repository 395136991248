import { createApiHook } from '~/hooks/utils/createApiHook';
import { RoleRepository } from '~/repositories/RoleRepository';
import {
  CreateCustomRoleRequestObject,
  CustomRoleResponseObject,
  CustomRolesList,
  UpdateCustomRoleRequestObject,
} from '~/typings/API';

export const useCreateCustomRole = createApiHook<CustomRoleResponseObject, CreateCustomRoleRequestObject>(
  RoleRepository.create
);

export const useGetCustomRole = createApiHook<CustomRoleResponseObject, { roleId: string }>(RoleRepository.get, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
});

export const useListRoles = createApiHook<CustomRolesList, undefined>(RoleRepository.list, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
});

export const useUpdateCustomRole = createApiHook<
  CustomRoleResponseObject,
  { roleId: string } & UpdateCustomRoleRequestObject
>(RoleRepository.update);
