import { client } from './client';

export class WebsiteRepository {
  static async contact(fields: Record<string, string>, reCaptchaToken: string) {
    return client.post<{ fields: Record<string, string> }, any>(
      '/website/contact-us',
      { fields },
      {
        sandbox: false,
        reCaptchaToken,
      }
    );
  }
}
