import React, { PropsWithChildren, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { ROUTE } from '../routes';
import { SVG_URL } from '~/assets';
import { helpSidebars } from '~/components';
import { NotificationList, StyledNotification } from '~/components/NotificationList';
import { BusinessPageIdType, PlatformMeta } from '~/repositories/PlatformRepository';
import { useSessionStore } from '~/stores/Session';

import { GoogleDisclaimer, Header, HeaderWebsite } from './Partial';

const StyledNotificationList = styled(NotificationList)`
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 0;
  width: 400px;
  margin: 0;

  ${StyledNotification} {
    margin: 8px 0 0 0;
  }
`;

interface LayoutProps {}

interface QuestionsPageType {
  label: string;
  route: string;
  platformKey: keyof PlatformMeta;
}

interface BusinessPageType {
  label: string;
  route: string;
  id: BusinessPageIdType;
}

const questionPages: QuestionsPageType[] = [
  {
    label: 'Financial products',
    route: ROUTE.REGISTER_FINANCIAL_PRODUCTS,
    platformKey: 'financialProductsSelected',
  },
  {
    label: 'Company employees',
    route: ROUTE.REGISTER_COMPANY_EMPLOYEES,
    platformKey: 'companySize',
  },
  {
    label: 'Customers',
    route: ROUTE.REGISTER_CUSTOMERS,
    platformKey: 'customerTypes',
  },
  {
    label: 'Compliance function',
    route: ROUTE.REGISTER_COMPLIANCE,
    platformKey: 'complianceCapabilities',
  },
];

const businessPages: BusinessPageType[] = [
  {
    label: 'Business information',
    route: ROUTE.REGISTER_BUSINESS_INFORMATION,
    id: 'information',
  },
  {
    label: 'Business location',
    route: ROUTE.REGISTER_BUSINESS_LOCATION,
    id: 'location',
  },
  {
    label: 'Your customers',
    route: ROUTE.REGISTER_BUSINESS_CUSTOMER_TYPES,
    id: 'your-customers',
  },
  {
    label: 'Your information',
    route: ROUTE.REGISTER_BUSINESS_YOUR_INFORMATION,
    id: 'your-information',
  },
];

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.foreground};
  height: 100vh;
  overflow-y: auto;
`;

const Inner = styled.div`
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: clamp(92px, 14vh, 164px) 0;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: clamp(92px, 10vh, 112px) 0;
  }
`;

const Content = styled.div`
  position: relative;
  flex-grow: 1;
  display: grid;
`;

const StyledHeader = styled(Header)<{ show?: boolean }>`
  ${({ show }) =>
    !show &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`;

const StyledHeaderWebsite = styled(HeaderWebsite)<{ show?: boolean }>`
  ${({ show }) =>
    !show &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`;

const StyledGoogleDisclaimer = styled(GoogleDisclaimer)<{ show?: boolean }>`
  transition: opacity 0.15s;

  ${({ show }) =>
    !show &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`;

const ProgressSidebar = styled.ul<{ $isShow?: boolean }>`
  margin: 14px 0 0 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 28px;
  position: fixed;
  z-index: 2;
  left: 24px;
  top: clamp(92px, 14vh, 164px);
  pointer-events: ${({ $isShow }) => !$isShow && 'none'};
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transform: translateX(${({ $isShow }) => ($isShow ? '0px' : '-8px')});
  transition:
    transform 0.25s,
    opacity 0.15s;
`;

const Glow = keyframes`
  0%{
   transform: scale(0);
  }
  25%,
  70% {
    opacity: .75;
  }
  95%,
  100%{
    opacity: 0;
  }
  100% {
    transform: scale(5);
    box-shadow: 0 0 0 1.5px currentColor;
    filter: blur(3px);
  }
`;

const ProgressSidebarEntry = styled.li<{ $isActive?: boolean; $isDone?: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  cursor: pointer;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ $isActive, $isDone, theme }) =>
    $isActive
      ? $isDone
        ? theme.success.blendToBackground(1150)
        : theme.primary.background
      : theme.secondary.blendToBackground(700)};
  transition: color 0.15s;

  &:hover {
    color: ${({ $isActive, theme }) => !$isActive && theme.secondary.background};
  }

  &:not(:last-child) {
    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      width: 2px;
      height: 22px;
      left: 9px;
      border-radius: 1px;
      top: 22px;
      background-color: ${({ theme }) => theme.secondary.blendToBackground(200)};
    }

    &:after {
      left: 6px;
      top: 6px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      color: ${({ theme }) => theme.success.background};
      animation: ${({ $isDone }) => ($isDone ? Glow : 'none')} 0.6s linear forwards;
    }
  }
`;

const Bounce = keyframes`
  50% {
    transform: scale(1.1) translateZ(0);
  }
`;

const ProgressSidebarIcon = styled.div<{ $isActive?: boolean; $isDone?: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    transform: translateZ(0);
    box-shadow: inset 0 0 0
      ${({ $isDone, $isActive, theme }) =>
        $isDone
          ? `10px ${theme.success.background}`
          : `2px ${$isActive ? theme.primary.blendToBackground(800) : theme.secondary.blendToBackground(400)}`};
    transition: box-shadow 0.15s;
    animation: ${({ $isDone }) => ($isDone ? Bounce : 'none')} 0.2s ease 0.3s;
  }

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.success.foreground};
    transform: translateY(1%);
    position: relative;
    z-index: 1;

    stroke-dasharray: 12px;
    stroke-dashoffset: ${({ $isDone }) => ($isDone ? '0px' : '12px')};
    transition: stroke-dashoffset 0.15s ease ${({ $isDone }) => ($isDone ? '.15s' : '0s')};

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        --icon-color: ${theme.background};
      `}
  }
`;

const SignIcon = styled(Icon.AnimationSign)`
  svg {
    --icon-color: currentColor;
  }
`;

const PatternShow = keyframes`
  to {
    opacity: .35;
  }
`;

const Pattern = styled.div`
  position: absolute;
  top: 40%;
  left: -100%;
  right: -100%;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  mask-image: radial-gradient(ellipse at bottom, rgba(0, 0, 0, 0.3), transparent 30%);
  background-color: ${({ theme }) => theme.primary.background};
  animation: ${PatternShow} 0.5s linear forwards;
`;

const PatternInner = styled.div`
  position: absolute;
  inset: 0;
  background-image: url(${SVG_URL.pattern});
  background-repeat: repeat;
  background-position: right top;
  background-size: 20px, 20px;
  mix-blend-mode: overlay;
`;

const SidebarPlaceholder = styled.div`
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  background-color: ${({ theme }) => theme.body};
  width: 248px;
  border-right: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};
`;

export const Layout: React.FC<PropsWithChildren<LayoutProps>> = (props) => {
  const { isSignedIn, currentPermission } = useSessionStore();
  const [scrollDistance, setScrollDistance] = useState<number>(0);
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [completedPages, setCompletedPages] = useState<BusinessPageIdType[]>([]);
  const [submitSign, setSubmitSign] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [platformMeta, setPlatformMeta] = useState<Partial<Record<keyof PlatformMeta, boolean>>>({
    financialProductsSelected: false,
    companySize: false,
    customerTypes: false,
    complianceCapabilities: false,
  });

  const showGoogleDisclaimer = useMemo(
    () =>
      location.pathname.startsWith(ROUTE.LOGIN) ||
      location.pathname === ROUTE.REGISTER ||
      location.pathname.startsWith(ROUTE.FORGOT_PASSWORD),
    [location.pathname]
  );

  const showHeaderWebsite = useMemo(
    () => location.pathname.startsWith('/contact') || location.pathname.startsWith('/plaid'),
    [location.pathname]
  );

  const showPattern = useMemo(() => location.pathname.startsWith('/plaid'), [location.pathname]);

  const handleScroll = () => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    const value = wrapper.scrollTop;

    setScrollDistance(value <= 0 ? 0 : value);
  };

  useLayoutEffect(() => {
    setShowHeader(isSignedIn() && location.pathname.startsWith('/register/questions'));

    if (!wrapperRef.current) {
      return;
    }
    wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === ROUTE.REGISTER_BUSINESS_SUBMIT) {
      setSubmitSign(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    handleScroll();

    const wrapper = wrapperRef.current;

    if (!wrapper) {
      return;
    }

    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({ isLoading: state.isLoading, currentPlatform: state.currentPlatform }),
        ({ isLoading, currentPlatform }) => {
          if (isLoading) {
            return;
          }

          setCompletedPages(currentPlatform?.depgraphNodeValues?.completedPages ?? []);

          setPlatformMeta({
            financialProductsSelected:
              currentPlatform?.financialProductsSelected && currentPlatform?.financialProductsSelected.length > 0,
            companySize: !!(currentPlatform?.companySize && currentPlatform.companySize.toString().length > 0),
            customerTypes: currentPlatform?.customerTypes && currentPlatform?.customerTypes.length > 0,
            complianceCapabilities: !!(
              currentPlatform?.complianceCapabilities && currentPlatform.complianceCapabilities.toString().length > 0
            ),
          });

          setShowHeader(
            isSignedIn() &&
              (location.pathname.startsWith(ROUTE.REGISTER_QUESTIONS) ||
                location.pathname === ROUTE.REGISTER_OPEN_BANK_ACCOUNT ||
                location.pathname.startsWith(ROUTE.REGISTER_BUSINESS))
          );
        },
        { fireImmediately: true }
      ),
    [location.pathname]
  );

  return (
    <Wrapper className="wrapper" ref={wrapperRef}>
      <ProgressSidebar
        $isShow={
          location.pathname.startsWith(ROUTE.REGISTER_BUSINESS) &&
          !!currentPermission &&
          currentPermission?.platformInfo !== 'none'
        }
      >
        {businessPages.map((page: BusinessPageType) => (
          <ProgressSidebarEntry
            key={page.id}
            $isDone={completedPages.includes(page.id)}
            $isActive={location.pathname === page.route}
            onClick={() => navigate(page.route)}
          >
            <ProgressSidebarIcon
              $isDone={completedPages.includes(page.id)}
              $isActive={location.pathname === page.route}
            >
              <Icon.Tick />
            </ProgressSidebarIcon>
            {page.label}
          </ProgressSidebarEntry>
        ))}
        {completedPages.length === 4 && (
          <ProgressSidebarEntry
            $isActive={location.pathname === ROUTE.REGISTER_BUSINESS_SUBMIT}
            onClick={() => navigate(ROUTE.REGISTER_BUSINESS_SUBMIT)}
          >
            <SignIcon running={submitSign} onDone={() => setSubmitSign(false)} />
            Submit
          </ProgressSidebarEntry>
        )}
      </ProgressSidebar>

      <ProgressSidebar
        $isShow={
          location.pathname.startsWith(ROUTE.REGISTER_QUESTIONS) &&
          !!currentPermission &&
          currentPermission?.platformInfo !== 'none'
        }
      >
        {questionPages.map((page: QuestionsPageType, index: number) => (
          <ProgressSidebarEntry
            key={index}
            $isDone={platformMeta[page.platformKey]}
            $isActive={location.pathname === page.route}
            onClick={() => navigate(page.route)}
          >
            <ProgressSidebarIcon $isDone={platformMeta[page.platformKey]} $isActive={location.pathname === page.route}>
              <Icon.Tick />
            </ProgressSidebarIcon>
            {page.label}
          </ProgressSidebarEntry>
        ))}
      </ProgressSidebar>
      <Inner>
        <StyledHeader show={showHeader} isScrolled={scrollDistance >= 40} />
        <StyledHeaderWebsite show={showHeaderWebsite} isScrolled={scrollDistance >= 40} />
        <Content>{props.children}</Content>
        <StyledGoogleDisclaimer show={showGoogleDisclaimer} />
      </Inner>
      {showPattern && (
        <Pattern>
          <PatternInner />
        </Pattern>
      )}
      {helpSidebars}
      {location.pathname.startsWith(`${ROUTE.REGISTER}/`) && <StyledNotificationList display="global" size="large" />}
      <SidebarPlaceholder className={'sidebar-placeholder'} />
    </Wrapper>
  );
};
