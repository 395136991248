import React from 'react';

import { Input } from '@column/column-ui-kit';

import { RenderFields } from '~/components';
import { CreateCustomRoleRequestObject } from '~/typings/API';

type RoleInfoFields = Pick<CreateCustomRoleRequestObject, 'name' | 'description'>;

export const RoleInfo: React.FC = () => {
  return RenderFields<RoleInfoFields>({
    sections: [
      {
        fields: [
          {
            id: 'name',
            defaultValue: '',
            label: 'Role Name',
            rules: { required: true },
            children: ({ value, onChange, onBlur }, { isTouched, error }) => (
              <Input
                placeholder="Role name"
                hasError={isTouched && !!error}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
          {
            id: 'description',
            defaultValue: '',
            label: 'Role Description',
            description: 'Describe the use of the role and summarize its permissions',
            children: ({ value, onChange, onBlur }, { isTouched, error }) => (
              <Input
                placeholder="Role description"
                hasError={isTouched && !!error}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            ),
          },
        ],
      },
    ],
  });
};
