import {
  GetIntlWireConfigRequest,
  InternationalWireBillingConfig,
  PlatformRepository,
} from '~/repositories/PlatformRepository';

import { createApiHook } from './utils/createApiHook';

export const useGetPlatformIntlWireConfig = createApiHook<
  InternationalWireBillingConfig,
  GetIntlWireConfigRequest | undefined
>(PlatformRepository.getInternationalWireConfig);
