import React, { PropsWithChildren, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface UnderlineLinkProps {
  className?: string;
  href?: string;
  to?: string;
  newTab?: boolean;
  withoutArrow?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const styles = css`
  --line-background-size: 100%;
  --line-background-delay: 0.15s;
  --line-stroke-dashoffset: 46;
  --line-stroke-duration: 0.15s;
  --line-stroke-easing: linear;
  --line-stroke-delay: 0s;

  background: none;
  border: none;
  font: inherit;
  font-weight: 500;
  display: inline;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;
  line-height: 20px;
  text-decoration: none;
  color: currentColor;

  &:hover {
    --line-background-size: 0%;
    --line-background-delay: 0s;
    --line-stroke-dashoffset: 26;
    --line-stroke-duration: 0.3s;
    --line-stroke-easing: cubic-bezier(0.3, 1.5, 0.5, 1);
    --line-stroke-delay: 0.195s;
  }

  span {
    background-image: linear-gradient(0deg, currentColor 0%, currentColor 100%);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: var(--line-background-size) 1px;
    transition: background-size 0.2s linear var(--line-background-delay);
    transform: translateZ(0);
    line-height: 20px;
  }

  svg {
    position: absolute;
    vertical-align: top;
    display: inline;
    line-height: 1;
    height: 18px;
    left: calc(100% - 2px);
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1px;
    stroke: currentColor;
    stroke-dasharray: 7.95 30;
    stroke-dashoffset: var(--line-stroke-dashoffset);
    transition: stroke-dashoffset var(--line-stroke-duration) var(--line-stroke-easing) var(--line-stroke-delay);
  }
`;

const InternalLink = styled(Link)`
  ${styles}
`;

const ExternalLink = styled.a`
  ${styles}
`;

export const UnderlineLink: React.FC<PropsWithChildren<UnderlineLinkProps>> = (props) => {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  if (props.to) {
    return (
      <InternalLink to={props.to} className={props.className} onClick={handleClick}>
        <span> {props.children}</span>
        {!props.withoutArrow && (
          <svg viewBox="0 0 13 20">
            <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
          </svg>
        )}
      </InternalLink>
    );
  }

  return (
    <ExternalLink
      href={props.href}
      target={props.newTab ? '_blank' : undefined}
      rel={props.newTab ? 'noreferrer' : undefined}
      className={props.className}
      onClick={handleClick}
    >
      <span>{props.children}</span>
      {!props.withoutArrow && (
        <svg viewBox="0 0 13 20">
          <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" />
        </svg>
      )}
    </ExternalLink>
  );
};
