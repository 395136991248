import { gsap } from 'gsap';
import React, { FC, PropsWithChildren, ReactNode, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export interface PlaneButtonStyleProps {
  isHovered: boolean;
  isFocussed: boolean;
  isPressed: boolean;
  isDisabled: boolean;
  isAnimated: boolean;
  icon?: ReactNode;
  iconRight: boolean;
}

export interface PlaneButtonProps extends Partial<PlaneButtonStyleProps> {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDone?: () => void;
  onHoverChange?: (value: boolean) => void;
  onFocusChange?: (value: boolean) => void;
  onPressChange?: (value: boolean) => void;
  className?: string;
  icon?: ReactNode;
  success: ReactNode;
}

const ButtonWrapper = styled.div<PlaneButtonStyleProps>`
  --button-background: ${({ theme }) => theme.background};
  --button-background-dark: ${({ theme }) => theme.secondary.blendToBackground(200)};
  --button-background-darkest: ${({ theme }) => theme.secondary.blendToBackground(400)};

  --button-background: ${({ isHovered, isAnimated, theme }) => (isHovered || isAnimated) && theme.background};
  border-radius: 8px;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css<PlaneButtonStyleProps>`
      --button-background: ${theme.secondary.blendToBackground(25)};
      --button-background-dark: ${theme.secondary.blendToBackground(150)};
      --button-background-darkest: ${theme.secondary.blendToBackground(200)};

      --button-background: ${({ isHovered, isAnimated }) =>
        (isHovered || isAnimated) && theme.secondary.blendToBackground(50)};
    `}

  --text-opacity: 1;
  --success-x: -12px;
  --success-opacity: 0;
  --border-radius: 5px;
  --overflow: hidden;
  --x: 0px;
  --y: 0px;
  --rotate: 0deg;
  --plane-x: 0px;
  --plane-y: 0px;
  --plane-opacity: 1;
  --trails: ${({ theme }) => theme.secondary.blendToBackground(125)};
  --trails-stroke: 57px;
  --left-wing-background: var(--button-background);
  --left-wing-first-x: 0;
  --left-wing-first-y: 0;
  --left-wing-second-x: 50;
  --left-wing-second-y: 0;
  --left-wing-third-x: 0;
  --left-wing-third-y: 100;
  --left-body-background: var(--button-background);
  --left-body-first-x: 51;
  --left-body-first-y: 0;
  --left-body-second-x: 51;
  --left-body-second-y: 100;
  --left-body-third-x: 0;
  --left-body-third-y: 100;
  --right-wing-background: var(--button-background);
  --right-wing-first-x: 49;
  --right-wing-first-y: 0;
  --right-wing-second-x: 100;
  --right-wing-second-y: 0;
  --right-wing-third-x: 100;
  --right-wing-third-y: 100;
  --right-body-background: var(--button-background);
  --right-body-first-x: 49;
  --right-body-first-y: 0;
  --right-body-second-x: 49;
  --right-body-second-y: 100;
  --right-body-third-x: 100;
  --right-body-third-y: 100;

  color: ${({ theme }) => theme.secondary.background};
  display: table;
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  min-width: 60px;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  font-size: 14px;
  transition: transform 0.2s;
  transform: scale(var(--button-scale, 1)) translateZ(0);

  svg {
    --icon-size: 16px;
    --icon-color: currentColor;
  }

  &:before {
    content: '';
    z-index: 5;
    position: absolute;
    pointer-events: none;
    inset: 1px;
    box-shadow:
      ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
        ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
        ${({ theme }) => theme.style.buttonSecondaryBorderColor},
      0 0 0 var(--button-outline, 0px)
        var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
      ${({ theme }) => theme.style.buttonSecondaryShadow};
    border-radius: 8px;
    transition:
      opacity 0.2s,
      box-shadow 0.2s;

    ${({ isAnimated }) =>
      isAnimated &&
      css`
        opacity: 0;
      `}

    ${({ isHovered }) =>
      isHovered &&
      css`
        box-shadow:
          ${({ theme }) => theme.style.buttonSecondaryHoveredBorderProperties}
            ${({ theme }) => theme.style.buttonSecondaryHoveredBorderWidth}
            ${({ theme }) => theme.style.buttonSecondaryHoveredBorderColor},
          0 0 0 var(--button-outline, 0px)
            var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
          ${({ theme }) => theme.style.buttonSecondaryHoveredShadow};
      `};

    ${({ theme }) =>
      theme.id !== 'default' &&
      css<PlaneButtonStyleProps>`
        box-shadow: inset 0 1px 0 0 ${theme.secondary.blendToBackground(75)};
      `}
  }
`;

const Default = styled.span<PlaneButtonStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 4;
  opacity: var(--text-opacity);
`;

const Success = styled.div<PlaneButtonStyleProps>`
  display: block;
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transform: translateX(var(--success-x)) translateZ(0);
  opacity: var(--success-opacity);
`;

const Trails = styled.svg<PlaneButtonStyleProps>`
  pointer-events: none;
  position: absolute;
  display: block;
  width: 33px;
  height: 64px;
  top: -4px;
  left: 16px;
  fill: none;
  stroke: var(--trails);
  stroke-linecap: round;
  stroke-width: 2;
  stroke-dasharray: 57px;
  stroke-dashoffset: var(--trails-stroke);
  transform: rotate(68deg) translateZ(0);
`;

const Plane = styled.div<PlaneButtonStyleProps>`
  pointer-events: none;
  position: absolute;
  inset: 0;
  transform: translate(var(--x), var(--y)) rotate(var(--rotate)) translateZ(0);
`;

const PlaneBase = styled.div<PlaneButtonStyleProps>`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: var(--plane-opacity);
  transform: translate(var(--plane-x), var(--plane-y)) translateZ(0);
  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius);
    transform: translate(var(--part-x, 0.4%), var(--part-y, 0)) translateZ(0);
    z-index: var(--z-index, 2);
    background-color: var(--background, var(--left-wing-background));
    clip-path: polygon(
      calc(var(--first-x, var(--left-wing-first-x)) * 1%) calc(var(--first-y, var(--left-wing-first-y)) * 1%),
      calc(var(--second-x, var(--left-wing-second-x)) * 1%) calc(var(--second-y, var(--left-wing-second-y)) * 1%),
      calc(var(--third-x, var(--left-wing-third-x)) * 1%) calc(var(--third-y, var(--left-wing-third-y)) * 1%)
    );

    ${({ isAnimated }) =>
      !isAnimated &&
      css`
        transition: background-color 0.2s;
      `}
  }
`;

const PlaneLeft = styled(PlaneBase)<PlaneButtonStyleProps>`
  &:after {
    --part-x: -1%;
    --z-index: 1;
    --background: var(--left-body-background);
    --first-x: var(--left-body-first-x);
    --first-y: var(--left-body-first-y);
    --second-x: var(--left-body-second-x);
    --second-y: var(--left-body-second-y);
    --third-x: var(--left-body-third-x);
    --third-y: var(--left-body-third-y);
  }
`;

const PlaneRight = styled(PlaneBase)<PlaneButtonStyleProps>`
  &:before {
    --part-x: -1%;
    --z-index: 2;
    --background: var(--right-wing-background);
    --first-x: var(--right-wing-first-x);
    --first-y: var(--right-wing-first-y);
    --second-x: var(--right-wing-second-x);
    --second-y: var(--right-wing-second-y);
    --third-x: var(--right-wing-third-x);
    --third-y: var(--right-wing-third-y);
  }
  &:after {
    --part-x: 0;
    --z-index: 1;
    --background: var(--right-body-background);
    --first-x: var(--right-body-first-x);
    --first-y: var(--right-body-first-y);
    --second-x: var(--right-body-second-x);
    --second-y: var(--right-body-second-y);
    --third-x: var(--right-body-third-x);
    --third-y: var(--right-body-third-y);
  }
`;

export const PlaneButton: FC<PropsWithChildren<PlaneButtonProps>> = (props) => {
  const [isHoveredState, setIsHoveredState] = useState<boolean>(false);
  const [isFocussedState, setIsFocussedState] = useState<boolean>(false);
  const [isPressedState, setIsPressedState] = useState<boolean>(false);
  const [isAnimatedState, setIsAnimatedState] = useState<boolean>(false);

  const buttonRef = useRef<HTMLDivElement>(null);

  const styleProps: PlaneButtonStyleProps = {
    isHovered: props.isHovered ?? isHoveredState,
    isFocussed: props.isFocussed ?? isFocussedState,
    isAnimated: props.isAnimated ?? isAnimatedState,
    isPressed: props.isPressed ?? isPressedState,
    isDisabled: props.isDisabled ?? false,
    icon: props.icon ?? false,
    iconRight: props.iconRight ?? false,
  };

  const handleHoverChange = (value: boolean) => {
    if (props.onHoverChange && value !== isHoveredState) {
      props.onHoverChange(value);
    }
    if (!value) {
      handlePressChange(false);
    }
    setIsHoveredState(value);
  };

  const handleFocusChange = (value: boolean) => {
    if (props.onFocusChange && value !== isFocussedState) {
      props.onFocusChange(value);
    }
    setIsFocussedState(value);
  };

  const handlePressChange = (value: boolean) => {
    if (props.onPressChange && value !== isPressedState) {
      props.onPressChange(value);
    }
    if (value) {
      handleFocusChange(true);
    }
    setIsPressedState(value);
  };

  const getVar = (variable: string) => {
    if (!buttonRef.current) {
      return;
    }
    return getComputedStyle(buttonRef.current).getPropertyValue(variable);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event);
    }

    handleFocusChange(false);
    handlePressChange(false);

    if (!buttonRef?.current || isAnimatedState) {
      return;
    }

    setIsAnimatedState(true);

    gsap.to(buttonRef.current, {
      keyframes: [
        {
          '--left-wing-first-x': 50,
          '--left-wing-first-y': 100,
          '--right-wing-second-x': 50,
          '--right-wing-second-y': 100,
          duration: 0.2,
          onComplete: (target: HTMLDivElement) => {
            gsap.set(target, {
              '--left-wing-first-y': 0,
              '--left-wing-second-x': 40,
              '--left-wing-second-y': 100,
              '--left-wing-third-x': 0,
              '--left-wing-third-y': 100,
              '--left-body-third-x': 40,
              '--right-wing-first-x': 50,
              '--right-wing-first-y': 0,
              '--right-wing-second-x': 60,
              '--right-wing-second-y': 100,
              '--right-wing-third-x': 100,
              '--right-wing-third-y': 100,
              '--right-body-third-x': 60,
            });
          },
          onCompleteParams: [buttonRef.current],
        },
        {
          '--left-wing-third-x': 20,
          '--left-wing-third-y': 90,
          '--left-wing-second-y': 90,
          '--left-body-third-y': 90,
          '--right-wing-third-x': 80,
          '--right-wing-third-y': 90,
          '--right-body-third-y': 90,
          '--right-wing-second-y': 90,
          duration: 0.2,
        },
        {
          '--rotate': '50deg',
          '--left-wing-third-y': 95,
          '--left-wing-third-x': 27,
          '--right-body-third-x': 45,
          '--right-wing-second-x': 45,
          '--right-wing-third-x': 60,
          '--right-wing-third-y': 83,
          duration: 0.25,
        },
        {
          '--rotate': '60deg',
          '--plane-x': '-8px',
          '--plane-y': '40px',
          duration: 0.2,
        },
        {
          '--rotate': '40deg',
          '--plane-x': '45px',
          '--plane-y': '-300px',
          '--plane-opacity': 0,
          duration: 0.375,
          onComplete: () => {
            if (props.onDone) {
              props.onDone();
            }
          },
        },
      ],
    });

    gsap.to(buttonRef.current, {
      keyframes: [
        {
          '--text-opacity': 0,
          '--border-radius': '0px',
          '--left-wing-background': getVar('--button-background-dark'),
          '--right-wing-background': getVar('--button-background-dark'),
          duration: 0.11,
        },
        {
          '--left-wing-background': getVar('--button-background'),
          '--right-wing-background': getVar('--button-background'),
          duration: 0.14,
        },
        {
          '--left-body-background': getVar('--button-background-dark'),
          '--right-body-background': getVar('--button-background-darkest'),
          duration: 0.25,
          delay: 0.1,
        },
        {
          '--trails-stroke': 171,
          duration: 0.22,
          delay: 0.22,
        },
        {
          '--success-opacity': 1,
          '--success-x': 0,
          duration: 0.2,
          delay: 0.15,
        },
        {
          '--success-stroke': 0,
          duration: 0.15,
        },
      ],
    });
  };

  return (
    <ButtonWrapper
      onClick={handleClick}
      onPointerEnter={() => handleHoverChange(true)}
      onPointerMove={() => handleHoverChange(true)}
      onPointerLeave={() => handleHoverChange(false)}
      onFocus={() => handleFocusChange(true)}
      onBlur={() => handleFocusChange(false)}
      onPointerDown={() => handlePressChange(true)}
      onPointerUp={() => handlePressChange(false)}
      className={props.className}
      ref={buttonRef}
      {...styleProps}
    >
      <Default {...styleProps}>
        {!props.iconRight && props.icon}
        {props.children}
        {props.iconRight && props.icon}
      </Default>
      <Success {...styleProps}>{props.success}</Success>
      <Trails viewBox="0 0 33 64" {...styleProps}>
        <path d="M26,4 C28,13.3333333 29,22.6666667 29,32 C29,41.3333333 28,50.6666667 26,60" />
        <path d="M6,4 C8,13.3333333 9,22.6666667 9,32 C9,41.3333333 8,50.6666667 6,60" />
      </Trails>
      <Plane {...styleProps}>
        <PlaneLeft {...styleProps} />
        <PlaneRight {...styleProps} />
      </Plane>
    </ButtonWrapper>
  );
};
