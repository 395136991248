import styled, { css } from 'styled-components';

interface Props {
  withMargin?: boolean;
  fullWidth?: boolean;
  size?: 'default' | 'middle' | 'small';
}

interface HeadlineProps {
  withMargin?: boolean;
  withSmallMargin?: boolean;
}

export const Headline = styled.h1<Props>`
  font-family: inherit;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  line-height: 24px;
  color: ${({ theme }) => theme.foreground};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '36ch')};

  small {
    vertical-align: baseline;
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }

  ${({ size }) =>
    size === 'middle' &&
    css`
      font-size: 20px;
      line-height: 24px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 16px;
      line-height: 20px;
    `}

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: 12px;
    `}
`;

export const SubHeadline = styled.h2`
  font-family: inherit;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  line-height: 1.6;
  letter-spacing: -0.125px;
  color: ${({ theme }) => theme.foreground};
`;

export const SmallHeadline = styled.h3<HeadlineProps>`
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  letter-spacing: -0.125px;
  color: ${({ theme }) => theme.foreground};

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: 8px;
    `}

  ${({ withSmallMargin }) =>
    withSmallMargin &&
    css`
      margin-bottom: 4px;
    `}

  small {
    font-size: inherit;
    font-weight: 400;
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  }
`;

export const TinyHeadline = styled.h4<HeadlineProps>`
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  line-height: 1.6;
  letter-spacing: -0.125px;
  color: ${({ theme }) => theme.foreground};

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-bottom: 8px;
    `}

  ${({ withSmallMargin }) =>
    withSmallMargin &&
    css`
      margin-bottom: 4px;
    `}
`;

export const Paragraph = styled.p<Props>`
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.background};

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 12px;
      font-weight: 400;
    `}
`;

export const ParagraphLight = styled(Paragraph)<Props>`
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const Hint = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const Caption = styled.span`
  font-size: 10px;
  display: block;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const Truncate = styled.span<{ isSmall?: boolean }>`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: ${({ theme, isSmall }) => isSmall && theme.secondary.blendToBackground(800)};
  font-size: ${({ isSmall }) => isSmall && '12px'};

  small {
    display: inline-block;
    margin-left: 4px;
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    font-size: 12px;
  }
`;

export const DefinitionList = styled.dl`
  dt {
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.foreground};
    margin: 0 0 4px;
  }

  dd {
    font-size: 14px;
    margin: 0 0 24px;
  }
`;

export const Heading = styled.h1`
  color: ${({ theme }) => theme.foreground};
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const PageHeader = styled.div`
  padding: 16px 24px;
  background: ${({ theme }) => theme.background};
  position: sticky;
  top: 0;
  z-index: 500;
  margin-bottom: 1px;

  ${Heading} {
    font-size: 20px;
    line-height: 24px;
  }
`;
