import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon, ToggleHeight } from '@column/column-ui-kit';

export interface NotificationStyleProps {
  color: 'success' | 'warning' | 'danger' | 'info';
  withClose?: boolean;
  isClose?: boolean;
  shouldClose?: boolean;
  smoothShow?: boolean;
  fullWidth?: boolean;
  variant: 'default' | 'light';
  size: 'default' | 'large';
}

export type NotificationActionButton = {
  label: string;
  onClick: () => void;
};

export interface NotificationProps extends Partial<NotificationStyleProps> {
  onClose?: () => void;
  actionButton?: NotificationActionButton;
  className?: string;
}

const StyledToggleHeight = styled(ToggleHeight)<{ $fullWidth?: boolean }>`
  transition-duration: 0.1s;

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

export const NotificationContent = styled.div<NotificationStyleProps>`
  --notification-color: ${({ theme }) => theme.success.background};
  --notification-dark: ${({ theme }) => theme.success.blendToBackground(1200)};
  --notification-light: ${({ theme }) => theme.success.blendToBackground(900)};
  --notification-light-bg: ${({ theme }) => theme.success.blendToBackground(800, 150)};
  --notification-outline: ${({ theme }) => theme.success.blendToBackground(800, 350)};
  --notification-pale: ${({ theme }) => theme.success.blendToBackground(1000, 75)};
  --notification-transparent: ${({ theme }) => theme.success.blendToBackground(1000, 0)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      --notification-light: ${theme.success.blendToBackground(900)};
      --notification-light-bg: ${theme.success.blendToBackground(1000, 200)};
      --notification-outline: ${theme.success.blendToBackground(800, 500)};
      --notification-pale: ${theme.success.blendToBackground(1000, 75)};
    `}

  padding: 8px 20px 8px 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.secondary.blendToForeground(1, 950)};
  backdrop-filter: blur(4px);
  position: relative;
  display: flex;
  text-align: left;
  box-sizing: border-box;
  gap: 12px;
  color: ${({ theme }) => theme.background};
  transition: opacity 0.2s;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  h4 {
    display: block;
    font: inherit;
    margin: 0;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 4px 0 0 14px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  ${({ isClose }) =>
    isClose &&
    css`
      opacity: 0;
    `}

  ${({ color }) =>
    color === 'warning' &&
    css`
      --notification-color: ${({ theme }) => theme.warning.background};
      --notification-dark: ${({ theme }) => theme.warning.blendToBackground(1200)};
      --notification-light: ${({ theme }) => theme.warning.blendToBackground(900)};
      --notification-light-bg: ${({ theme }) => theme.warning.blendToBackground(800, 150)};
      --notification-outline: ${({ theme }) => theme.warning.blendToBackground(800, 350)};
      --notification-pale: ${({ theme }) => theme.warning.blendToBackground(1000, 75)};
      --notification-transparent: ${({ theme }) => theme.warning.blendToBackground(1000, 0)};

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          --notification-light: ${theme.warning.blendToBackground(900)};
          --notification-light-bg: ${theme.warning.blendToBackground(1000, 150)};
          --notification-outline: ${theme.warning.blendToBackground(800, 350)};
          --notification-pale: ${theme.warning.blendToBackground(1000, 75)};
        `}
    `}
  ${({ color }) =>
    color === 'danger' &&
    css`
      --notification-color: ${({ theme }) => theme.danger.background};
      --notification-dark: ${({ theme }) => theme.danger.blendToBackground(1100)};
      --notification-light: ${({ theme }) => theme.danger.blendToBackground(900)};
      --notification-light-bg: ${({ theme }) => theme.danger.blendToBackground(800, 150)};
      --notification-outline: ${({ theme }) => theme.danger.blendToBackground(800, 350)};
      --notification-pale: ${({ theme }) => theme.danger.blendToBackground(1000, 75)};
      --notification-transparent: ${({ theme }) => theme.danger.blendToBackground(1000, 0)};

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          --notification-light: ${theme.danger.blendToBackground(900)};
          --notification-light-bg: ${theme.danger.blendToBackground(1000, 150)};
          --notification-outline: ${theme.danger.blendToBackground(800, 350)};
          --notification-pale: ${theme.danger.blendToBackground(1000, 75)};
        `}
    `}
  ${({ color }) =>
    color === 'info' &&
    css`
      --notification-color: ${({ theme }) => theme.primary.background};
      --notification-dark: ${({ theme }) => theme.primary.blendToBackground(1100)};
      --notification-light: ${({ theme }) => theme.primary.blendToBackground(900)};
      --notification-light-bg: ${({ theme }) => theme.primary.blendToBackground(800, 150)};
      --notification-outline: ${({ theme }) => theme.primary.blendToBackground(800, 350)};
      --notification-pale: ${({ theme }) => theme.primary.blendToBackground(1000, 75)};
      --notification-transparent: ${({ theme }) => theme.primary.blendToBackground(1000, 0)};

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          --notification-light: ${theme.primary.blendToBackground(900)};
          --notification-light-bg: ${theme.primary.blendToBackground(1000, 150)};
          --notification-outline: ${theme.primary.blendToBackground(800, 350)};
          --notification-pale: ${theme.primary.blendToBackground(1000, 75)};
        `}
    `} 
    ${({ variant }) =>
    variant === 'light' &&
    css`
      background-color: var(--notification-pale);
      color: ${({ theme }) => theme.foreground};
      box-shadow: inset 0 0 0 1px var(--notification-outline);
    `};
  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 12px 24px 12px 12px;
    `};
`;

const StyledIcon = styled.div<NotificationStyleProps>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--notification-light-bg);
  box-shadow: inset 0 0 1px var(--notification-outline);

  flex-shrink: 0;

  ${({ isClose }) =>
    isClose &&
    css`
      opacity: 0;
      filter: blur(4px);
      transform: translateY(-8px) translateZ(0);
      transition:
        filter 0.3s,
        transform 0.3s,
        opacity 0.3s;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      width: 32px;
      height: 32px;
    `};

  svg {
    --icon-color: var(--notification-light);
    --icon-size: 18px;
  }
`;

export const NotificationInner = styled.div<NotificationStyleProps>`
  line-height: 20px;
  margin: 2px 0;
  font-size: 14px;
  font-weight: 500;

  ${({ size }) =>
    size === 'large' &&
    css`
      margin: 5px 0;
    `};

  small {
    margin-top: -2px;
    display: block;
    font-weight: normal;
    font-size: 14px;
    color: ${({ theme, variant }) => variant === 'light' && theme.secondary.blendToBackground(800)};
    opacity: ${({ variant }) => variant === 'default' && css`0.8`};
  }

  button {
    margin-top: 8px;
    margin-bottom: 4px;
  }
`;

const Right = styled.div`
  margin-left: auto;
  display: flex;
  gap: 5px;
  align-items: flex-start;
`;

const Action = styled.button<NotificationStyleProps>`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  border-radius: 3px;
  padding: 4px 8px;
  line-height: 16px;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  font-family: inherit;
  transition:
    background-color 0.2s,
    transform 0.2s;
  color: ${({ theme }) => theme.foreground};
  background-color: ${({ theme }) => theme.background};

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(1, 850)};
  }

  &:active {
    transform: scale(0.975);
  }

  &:last-child {
    margin-right: -12px;
  }

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.foreground};
      background-color: ${({ theme }) => theme.secondary.blendToBackground(1, 1000)};

      &:hover {
        background-color: ${({ theme }) => theme.secondary.blendToBackground(1, 750)};
      }
    `};
`;

const Close = styled.div<NotificationStyleProps>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 6px;

  box-sizing: border-box;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -12px;
  opacity: 0.6;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  svg {
    transition: color 0.2s;
    --icon-size: 22px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(200)};
  }

  ${({ variant }) =>
    variant === 'light' &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.secondary.blendToBackground(75)};
      }

      svg {
        --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
      }
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      width: 32px;
      height: 32px;
      padding: 4px;
    `}
`;

export const Notification: React.FC<PropsWithChildren<NotificationProps>> = (props) => {
  const [isClose, setIsClose] = useState<boolean>(props.isClose ?? props.smoothShow ?? false);
  const [didShow, setDidShow] = useState<boolean>(false);

  const styleProps: NotificationStyleProps = {
    color: props.color ?? 'success',
    variant: props.variant ?? 'default',
    size: props.size ?? 'default',
    fullWidth: props.fullWidth ?? false,
    withClose: props.withClose ?? true,
    isClose: isClose,
  };

  const handleClose = () => {
    setIsClose(true);
  };

  const handleActionClick = () => {
    if (props.actionButton?.onClick) {
      props.actionButton.onClick();
    }
  };

  const handleChangeComplete = (state: boolean) => {
    if (!state || (props.smoothShow && !didShow)) {
      return;
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    setIsClose(props.isClose ?? props.shouldClose ?? false);
  }, [props.isClose, props.shouldClose]);

  useEffect(() => {
    if (!props.smoothShow) {
      return;
    }
    setTimeout(() => {
      setIsClose(false);
      setDidShow(true);
    }, 0);
  }, []);

  return (
    <StyledToggleHeight
      isClose={isClose}
      hasOverflow={false}
      onChangeComplete={handleChangeComplete}
      $fullWidth={styleProps.fullWidth}
    >
      <NotificationContent className={props.className} {...styleProps}>
        <StyledIcon {...styleProps}>
          {styleProps.color === 'success' && <Icon.CircleCheck />}
          {styleProps.color === 'warning' && <Icon.Warning />}
          {styleProps.color === 'danger' && <Icon.CircleCross />}
          {styleProps.color === 'info' && <Icon.CircleInfo />}
        </StyledIcon>
        <NotificationInner {...styleProps}>{props.children}</NotificationInner>
        {(props.actionButton?.label || styleProps.withClose) && (
          <Right>
            {props.actionButton?.label && (
              <Action onClick={handleActionClick} {...styleProps} type="button">
                {props.actionButton.label}
              </Action>
            )}
            {styleProps.withClose && (
              <Close onClick={handleClose} {...styleProps}>
                <Icon.Cross />
              </Close>
            )}
          </Right>
        )}
      </NotificationContent>
    </StyledToggleHeight>
  );
};
