import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import React, { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Fade, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { DocsIcon, HelpIcon } from '~/elements/icons';
import { Logo } from '~/elements/Logo';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';
import { useNavigate } from '~/lib/navigation';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';

import { MenuItem } from './MenuItem';
import { Platform } from './Toolbar';

gsap.registerPlugin(MorphSVGPlugin);

interface SidebarProps {
  className?: string;
}

export const PROFILE_ENTRIES = [
  {
    label: 'Profile',
    path: ROUTE.PROFILE,
  },
  {
    label: 'Your Platforms',
    path: ROUTE.PLATFORMS,
    activePath: `${ROUTE.PLATFORMS}/edit`,
  },
];

export const PLATFORM_ENTRIES = [
  {
    label: 'Info',
    path: ROUTE.PLATFORM,
    activePath: ROUTE.PLATFORM,
  },
  {
    label: 'Settings',
    path: ROUTE.PLATFORM_SETTINGS,
    activePath: ROUTE.PLATFORM_SETTINGS,
  },
  {
    label: 'Team',
    path: ROUTE.PLATFORM_TEAM,
    activePath: ROUTE.PLATFORM_TEAM,
  },
  {
    label: 'Roles',
    path: ROUTE.PLATFORM_ROLES,
    activePath: ROUTE.PLATFORM_ROLES,
  },
  {
    label: 'API Keys',
    path: ROUTE.PLATFORM_API_KEYS,
    activePath: `${ROUTE.PLATFORM_API_KEYS}/edit`,
  },
  {
    label: 'Webhooks',
    path: ROUTE.PLATFORM_WEBHOOKS,
    activePath: `${ROUTE.PLATFORM_WEBHOOKS}/edit`,
  },
  {
    label: 'Root Entity (Sandbox)',
    path: ROUTE.PLATFORM_ROOT_ENTITY,
    isSandboxOnly: true,
  },
];

export const LOANS_ENTRIES = [
  {
    label: 'Overview',
    path: ROUTE.LOANS,
    activePath: `${ROUTE.LOANS}/edit`,
  },
  {
    label: 'Disbursements',
    path: `${ROUTE.LOANS}/disbursements`,
    activePath: `${ROUTE.LOANS}/disbursements`,
  },
  {
    label: 'Payments',
    path: `${ROUTE.LOANS}/payments`,
    activePath: `${ROUTE.LOANS}/payments`,
  },
];

export const DOCUMENT_ENTRIES = [
  {
    label: 'Statements',
    path: ROUTE.STATEMENTS,
    activePath: ROUTE.STATEMENTS,
  },
  {
    label: 'Reporting',
    path: ROUTE.REPORTING,
    activePath: ROUTE.REPORTING,
  },
];

export const EVENTS = [
  {
    label: 'Overview',
    path: ROUTE.EVENTS,
  },
  {
    label: 'Webhook',
    path: ROUTE.EVENTS_WEBHOOK,
  },
];

const Wrapper = styled.div`
  --sidebar-o: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
`;

const StyledFade = styled(Fade)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Nav = styled.div<{ $showSandboxBanner?: boolean }>`
  --sidebar-scrollbar-background: transparent;
  --sidebar-thumb-background: transparent;

  display: flex;
  flex-direction: column;
  opacity: var(--sidebar-o);
  margin: 0;
  padding: 12px;
  height: 100%;
  justify-content: space-between;

  scrollbar-width: thin;
  scrollbar-color: var(--sidebar-thumb-background) var(--sidebar-scrollbar-background);
  overflow-y: scroll;

  &:hover {
    --sidebar-thumb-background: ${({ theme }) => theme.secondary.blendToBackground(150)};
  }

  @media (min-height: 820px) {
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--sidebar-scrollbar-background);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--sidebar-thumb-background);
    border-radius: 6px;
    border: 3px solid var(--sidebar-scrollbar-background);
  }
`;

const Section = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  padding-bottom: 8px;
  margin-bottom: 8px;
  gap: 2px;
  display: flex;
  flex-direction: column;

  &:last-child {
    border: 0;
  }

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      border-bottom: 1px solid ${theme.secondary.blendToBackground(150)};
    `}
`;

const LogoLink = styled.button`
  background: none;
  padding: 12px 16px 16px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 48px;
`;

const LinkItem = styled.a`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        color: ${theme.secondary.blendToBackground(1300)};
        background-color: ${theme.secondary.blendToBackground(50)};

        svg {
          --icon-color: ${theme.secondary.blendToBackground(1300)};
          --icon-background: ${theme.secondary.blendToBackground(50)};
        }
      `};
  }

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    --icon-background: ${({ theme }) => theme.secondary.blendToBackground(50)};

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        --icon-background: ${theme.background};
      `};
  }

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      svg {
        --icon-background: ${theme.background};
      }

      &:hover {
        color: ${theme.secondary.blendToBackground(1300)};
        background-color: ${theme.secondary.blendToBackground(50)};

        svg {
          --icon-color: ${theme.secondary.blendToBackground(1300)};
          --icon-background: ${theme.secondary.blendToBackground(50)};
        }
      }
    `};
`;

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const { currentPlatform, currentUser } = useSessionStore();
  const { openHelpSidebar } = useHelpSidebarStore();
  const navigate = useNavigate();

  const handleDocsClick = () => {
    log({
      name: 'Documentation clicked',
      context: currentUser,
    });

    window.open('https://column.com/docs');
  };

  const customRolesEnabled = useFeatureFlag(FeatureFlag.DashboardCustomRoles);
  const platformEntries = useMemo(() => {
    if (customRolesEnabled) {
      return PLATFORM_ENTRIES;
    }
    return PLATFORM_ENTRIES.filter((entry) => entry.label !== 'Roles');
  }, [customRolesEnabled]);

  return (
    <Wrapper className={props.className} ref={ref}>
      <div>
        <LogoLink onClick={() => navigate(ROUTE.ROOT)}>
          <Logo variant="word" size="16" />
        </LogoLink>
      </div>

      <StyledFade show={!!currentUser}>
        <Platform />

        <Nav>
          <div>
            <Section>
              {currentPlatform && (
                <>
                  <MenuItem label="Home" icon={<Icon.AnimationHome />} path={ROUTE.ROOT} exact />
                  <MenuItem
                    label="Platform"
                    icon={<Icon.AnimationLayers />}
                    path={ROUTE.PLATFORM}
                    entries={platformEntries}
                    activePath={ROUTE.PLATFORM_ROOT}
                  />
                </>
              )}
              <MenuItem label="User" icon={<Icon.AnimationUser />} path={ROUTE.PROFILE} entries={PROFILE_ENTRIES} />
            </Section>
            {currentPlatform && (
              <>
                <Section>
                  <MenuItem label="Transfers" icon={<Icon.AnimationArrows />} path={ROUTE.TRANSFERS} />
                  <MenuItem
                    label="Loans"
                    icon={<Icon.AnimationHandCoins />}
                    path={ROUTE.LOANS}
                    entries={LOANS_ENTRIES}
                  />
                  <MenuItem label="Accounts" icon={<Icon.AnimationWallet />} path={ROUTE.BANK_ACCOUNTS} />
                  <MenuItem label="Entities" icon={<Icon.AnimationDocument />} path={ROUTE.ENTITIES} />
                </Section>
                <Section>
                  <MenuItem
                    label="Documents"
                    icon={<Icon.AnimationDocumentEdit />}
                    path={ROUTE.STATEMENTS}
                    activePath={ROUTE.DOCUMENTS}
                    entries={DOCUMENT_ENTRIES}
                  />
                  <MenuItem
                    label="Events"
                    icon={<Icon.AnimationCalendar />}
                    path={ROUTE.EVENTS}
                    entries={EVENTS}
                    activePath={ROUTE.EVENTS}
                  />
                </Section>
              </>
            )}
          </div>

          <div>
            <LinkItem onClick={() => openHelpSidebar('Support')}>
              <HelpIcon /> Help
            </LinkItem>
            <LinkItem onClick={handleDocsClick}>
              <DocsIcon /> Docs
            </LinkItem>
          </div>
        </Nav>
      </StyledFade>
    </Wrapper>
  );
});
