import { BANK_ACCOUNTS } from './BankAccounts';
import { PageContactForm } from './ContactForm';
import { COUNTERPARTIES } from './Counterparties';
import { DOCUMENTS } from './Documents';
import { ENTITIES } from './Entities';
import { PageEvents } from './Events';
import { PageHome } from './Home';
import { PageCommercialDepositAccountAgreement } from './Legal/CommercialDepositAccountAgreement';
import { PageSweepProgramDepositPlacementCustodialAgreements } from './Legal/SweepProgramDepositPlacementCustodialAgreements';
import { LOANS } from './Loans';
import { PLATFORM } from './Platform';
import { RETURNS } from './Returns';
import { PageSVBResources } from './SVBResources';
import { PageTasks } from './Tasks';
import { TRANSFERS } from './Transfers';
import { USER } from './User';
import { PageWebhookEvents } from './WebhookEvents';

export const PAGE = {
  Home: PageHome,
  Events: PageEvents,
  WebhookEvents: PageWebhookEvents,
  Tasks: PageTasks,
  ContactForm: PageContactForm,
  SVBResources: PageSVBResources,
  CommercialDepositAccountAgreement: PageCommercialDepositAccountAgreement,
  SweepProgramDepositPlacementCustodialAgreements: PageSweepProgramDepositPlacementCustodialAgreements,
  ...BANK_ACCOUNTS,
  ...ENTITIES,
  ...TRANSFERS,
  ...COUNTERPARTIES,
  ...RETURNS,
  ...PLATFORM,
  ...USER,
  ...DOCUMENTS,
  ...LOANS,
} as const;

export type PageType = keyof typeof PAGE;
