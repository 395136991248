import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon, ToggleHeight } from '@column/column-ui-kit';

import { ROUTE as APP_ROUTE } from '~/app/routes';
import { useNavigate } from '~/lib/navigation';
import { ROUTE } from '~/public/routes';
import { useSessionStore } from '~/stores/Session';
import { Box } from '~/styles';

import { getPlatformStatus } from './PlatformBanner';

const StyledToggleHeight = styled(ToggleHeight)`
  --toggle-height-padding: 0 0 16px 0;
`;

const Wrapper = styled(Box)<{ $status: boolean }>`
  display: flex;
  gap: 16px;
  padding: 24px 24px 24px 16px;

  ${({ $status }) =>
    $status &&
    css`
      background-image: linear-gradient(40deg, rgb(42, 105, 202), rgb(37, 60, 180) 60%);
      box-shadow:
        0 0 0 1px ${({ theme }) => theme.primary.background},
        0 1px 2px ${({ theme }) => theme.secondary.blendToBackground(1200, 50)};

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          box-shadow:
            0 0 0 1px ${theme.black.blendToBackground(1000, 500)},
            0 1px 2px ${({}) => theme.secondary.blendToBackground(1200, 50)};
        `}

      ${StyledIcon} {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.white.blendToBackground(1000, 200)};

        svg {
          --icon-color: ${({ theme }) => theme.white.background};
        }
      }

      ${Title} {
        color: ${({ theme }) => theme.white.background};
      }

      ${Message} {
        p {
          color: ${({ theme }) => theme.white.blendToBackground(1000, 700)};
        }
      }
    `}
`;

const Message = styled.div`
  display: grid;
  gap: 4px;

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: ${({ theme }) => theme.secondary.background};
  }
`;

const Close = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 12px;
  top: 12px;
  padding: 0;
  margin: 0;
  z-index: 1;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  transition: background-color 0.2s;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(100)};
  }
`;

const Title = styled.h3`
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.foreground};
`;

const StyledIcon = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: -8px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
  transition: box-shadow 0.2s;

  svg {
    top: -1px;
    position: relative;
    --icon-color: ${({ theme }) => theme.secondary.background};
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.primary.background};

  &:after {
    background: ${({ theme }) => theme.white.background};
  }
`;

const CTA = styled.div`
  margin-top: 12px;
`;

export const SandboxBanner: React.FC = () => {
  const navigate = useNavigate();
  const { currentPermission, currentPlatform, updateSettings, settings } = useSessionStore();
  const platformInfoPermissions = useMemo(
    () => currentPermission?.platformInfo === 'read' || currentPermission?.platformInfo === 'write',
    [currentPlatform]
  );

  const platformStatus = getPlatformStatus(currentPlatform?.platformLevel);
  const onboardingEnabled = currentPlatform?.metadata?.defaultDependencyGraphInstanceId;

  const handleClose = useCallback(() => {
    updateSettings({ platformBannerDismissed: true });
  }, [updateSettings]);

  return (
    <StyledToggleHeight
      isClose={!platformInfoPermissions || (settings?.platformBannerDismissed && platformStatus === 'approved')}
    >
      <Wrapper $status={platformStatus !== 'approved'}>
        <StyledIcon>{platformStatus === 'approved' ? <Icon.Checkmark /> : <Icon.ArrowRight />}</StyledIcon>

        {platformStatus === 'approved' && (
          <Close onClick={handleClose}>
            <Icon.Cross />
          </Close>
        )}

        <div>
          <Message>
            <Title>
              {platformStatus === 'approved'
                ? "You're approved"
                : platformStatus === 'pending'
                  ? 'Pending'
                  : onboardingEnabled
                    ? 'Continue onboarding'
                    : 'Start moving real money'}
            </Title>
            <p>
              {platformStatus === 'approved'
                ? 'You’re in sandbox mode which is used for testing. To move real money, switch to Live mode from the top right corner.'
                : platformStatus === 'pending'
                  ? 'Your onboarding approval is pending. We will contact you with updates on your account approval status.'
                  : onboardingEnabled
                    ? 'Please finish our onboarding process for us to review. '
                    : 'Please contact us to start your onboarding process. '}
            </p>
          </Message>

          {platformStatus !== 'approved' && platformStatus !== 'pending' && onboardingEnabled && (
            <CTA>
              <StyledButton size="small" onClick={() => navigate(ROUTE.REGISTER_BUSINESS_INFORMATION)}>
                Continue
              </StyledButton>
            </CTA>
          )}

          {platformStatus !== 'approved' && platformStatus !== 'pending' && !onboardingEnabled && (
            <CTA>
              <StyledButton size="small" onClick={() => navigate(APP_ROUTE.CONTACT_FORM)}>
                Contact Us
              </StyledButton>
            </CTA>
          )}
        </div>
      </Wrapper>
    </StyledToggleHeight>
  );
};
