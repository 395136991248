import chroma from 'chroma-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Button } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { Logo } from '~/elements';
import { PlatformModel } from '~/models';
import { ROUTE as PUBLIC_ROUTE } from '~/public/routes';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useSessionStore } from '~/stores/Session';

interface HeaderProps {
  className?: string;
  isScrolled?: boolean;
}

export const HeaderWrapper = styled.div<{ isScrolled?: boolean; isOpenSidebar: boolean }>`
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 140px auto 140px;
  align-items: center;
  transition:
    padding 0.2s,
    box-shadow 0.2s,
    opacity 0.2s;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      padding-top: 12px;
      padding-bottom: 12px;
    `}

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    inset: 0;
    transform: translateZ(0);
    background-color: ${({ theme }) => chroma(theme.body).alpha(0.975).css()};

    @supports ((-webkit-backdrop-filter: blur(8px)) or (backdrop-filter: blur(8px))) {
      backdrop-filter: blur(8px);
      background-color: ${({ theme }) => chroma(theme.body).alpha(0.9).css()};
    }

    ${({ isScrolled, isOpenSidebar }) =>
      (isScrolled || isOpenSidebar) &&
      css`
        box-shadow: 0 1px 0 0 ${({ theme }) => theme.secondary.blendToBackground(150)};

        ${({ theme }) =>
          theme.id !== 'default' &&
          css`
            box-shadow: 0 1px 0 0 ${theme.secondary.blendToBackground(25)};
          `}
      `}
  }
`;

const StyledLogo = styled(Logo)`
  position: relative;
  z-index: 1;
`;

const StyledButton = styled(Button)`
  width: 140px;
`;

const List = styled.div`
  position: relative;
  z-index: 1;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
`;

const Progress = styled.div<{ percent: number }>`
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(300)};
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    background-color: ${({ theme }) => theme.primary.background};
    border-radius: inherit;
    display: block;
    height: 2px;
    width: 100%;
    transform: translateX(${({ percent }) => `-${100 - percent}%`});
    transition: transform 0.2s;
  }
`;

export const Header: React.FC<HeaderProps> = (props) => {
  const [percent, setPercent] = useState<number>(0);
  const openHelpSidebars = useHelpSidebarStore((state) => state.openHelpSidebars);
  const navigate = useNavigate();
  const location = useLocation();

  const getPercent = useCallback(
    (platform?: PlatformModel) => {
      if (location.pathname.startsWith(PUBLIC_ROUTE.REGISTER_QUESTIONS)) {
        if (location.pathname === PUBLIC_ROUTE.REGISTER_FINANCIAL_PRODUCTS) {
          setPercent(25);
        }

        if (location.pathname === PUBLIC_ROUTE.REGISTER_COMPANY_EMPLOYEES) {
          setPercent(50);
        }

        if (location.pathname === PUBLIC_ROUTE.REGISTER_CUSTOMERS) {
          setPercent(75);
        }

        if (location.pathname === PUBLIC_ROUTE.REGISTER_COMPLIANCE) {
          setPercent(platform?.complianceCapabilities ? 100 : 90);
        }
      }
    },
    [location.pathname]
  );

  useEffect(
    () => useSessionStore.subscribe((state) => state.currentPlatform, getPercent, { fireImmediately: true }),
    [location.pathname]
  );

  return (
    <HeaderWrapper
      className={props.className}
      isScrolled={props.isScrolled && openHelpSidebars.length < 1}
      isOpenSidebar={openHelpSidebars.length > 0}
    >
      <StyledLogo variant="word" size="16px" />
      <List>{location.pathname.startsWith(PUBLIC_ROUTE.REGISTER_QUESTIONS) && <Progress percent={percent} />}</List>
      {location.pathname.startsWith(PUBLIC_ROUTE.REGISTER_QUESTIONS) ? (
        <StyledButton
          variant="secondary"
          size="small"
          onClick={() =>
            navigate(ROUTE.ROOT, {
              state: {
                from: 'register',
              },
            })
          }
        >
          Skip to sandbox
        </StyledButton>
      ) : (
        <StyledButton variant="secondary" size="small" onClick={() => navigate(ROUTE.ROOT)}>
          Go to Dashboard
        </StyledButton>
      )}
    </HeaderWrapper>
  );
};
