import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { Fade } from '@column/column-ui-kit';

export interface PopoverBaseProps {
  show?: boolean;
  className?: string;
  withoutBackdrop?: boolean;
  onClose?: (event: MouseEvent) => void;
}

const Wrapper = styled(Fade)`
  --animation-exit-y: -8px;

  position: absolute;
  justify-content: center;
  z-index: 5;
  top: var(--popover-top, 16px);
  left: var(--popover-left, 16px);
  right: var(--popover-right, auto);
`;

export const PopoverAction = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-radius: 0 0 12px 12px;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  padding: 12px 12px 11px 12px;
  margin: 12px 0 0 0;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;

export const StyledPopover = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.background};
  border-radius: 12px;
  box-shadow:
    ${({ theme }) => theme.style.dropdownBorderProperties} ${({ theme }) => theme.style.dropdownBorderWidth}
      ${({ theme }) => theme.style.dropdownBorderColor},
    ${({ theme }) => theme.style.floatingShadow1PropertiesM} ${({ theme }) => theme.style.floatingShadow1ColorM},
    ${({ theme }) => theme.style.floatingShadow2PropertiesM} ${({ theme }) => theme.style.floatingShadow2ColorM};
  padding: var(--popover-padding, 4px);
  width: var(--popover-width, 320px);
  min-width: var(--popover-min-width, 0);
  position: relative;
  z-index: 1;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      box-shadow: 0 0 0 1px ${() => theme.secondary.blendToBackground(150)};
    `}
`;

export const PopoverBase: React.FC<PropsWithChildren<PopoverBaseProps>> = (props) => {
  const baseRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) {
      return;
    }
    if (
      props.withoutBackdrop ||
      (baseRef.current && (event.target === baseRef.current || baseRef.current.contains(event.target)))
    ) {
      return;
    }
    if (props.onClose) {
      props.onClose(event);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);

    return () => document.removeEventListener('click', handleDocumentClick, true);
  }, [handleDocumentClick]);

  return (
    <Wrapper show={props.show ?? false} timeoutEnter={0} className={props.className}>
      <StyledPopover ref={baseRef}>{props.children}</StyledPopover>
    </Wrapper>
  );
};
