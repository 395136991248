import { gsap } from 'gsap';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Chip, Dropdown, Fade, Icon } from '@column/column-ui-kit';

import { LogoLoading } from '~/elements';
import { useQueryParams } from '~/hooks';
import { useSessionStore } from '~/stores/Session';

import { Pagination } from './Pagination';
import { Table, TableProps, TableRow } from './Table';

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  will-change: height;
  transform: translateZ(0);
`;

const StyledTable = styled(Table)`
  tr {
    align-items: center;
  }
`;

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const Entries = styled(Chip)`
  --chip-pale: ${({ theme }) => theme.secondary.blendToBackground(150)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 -4px;
  }
`;

const Bottom = styled.div`
  margin-top: 16px;
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface DataTableDefaultProps {
  [key: string]: unknown;
  id?: string;
}

export interface DataTableProps<T extends DataTableDefaultProps & TableRow<Record<string, any>>>
  extends Omit<TableProps, 'data'> {
  tableId: string;
  response: T[] | undefined;
  hasMore?: boolean;
  hasFilter?: boolean;
  isInitialLoad?: boolean;
  setQueryParams?: (params: any, replace?: boolean) => void;
  empty?: ReactNode;
}

export interface DataTableRefProps {
  setQueryParams: (params: any) => void;
}

export const DataTable = <T extends DataTableDefaultProps & TableRow<Record<string, any>>>(
  props: DataTableProps<T>
) => {
  const { updateSettings } = useSessionStore();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const fadeRef = useRef<HTMLDivElement>(null);
  const [entriesOpen, setEntriesOpen] = useState<boolean>(false);

  const { queryParams } = useQueryParams<Record<string, unknown>>();

  useEffect(() => {
    if (!props.setQueryParams || !queryParams || !props.isInitialLoad) return;

    props.setQueryParams(
      {
        limit: queryParams?.limit ?? 10,
        page: 1,
      },
      true
    );
  }, [queryParams]);

  const handlePrev = useCallback(() => {
    if (!props.setQueryParams || !queryParams || !props.response) return;

    props.setQueryParams({
      startingAfter: undefined,
      endingBefore: Number(queryParams?.page) - 1 === 1 ? undefined : props.response?.[0]?.id,
      page: Number(queryParams?.page ?? 1) - 1,
    });
  }, [queryParams, props.response]);

  const handleNext = useCallback(() => {
    if (!props.setQueryParams || !queryParams || !props.response) return;

    props.setQueryParams({
      startingAfter: props.response?.at(-1)?.id,
      endingBefore: undefined,
      page: Number(queryParams?.page ?? 1) + 1,
    });
  }, [queryParams, props.response]);

  const handleOnExit = () => {
    gsap.set(wrapperRef.current, {
      height: fadeRef.current?.offsetHeight,
    });
  };

  const handleOnEnter = () => {
    gsap.to(wrapperRef.current, {
      height: fadeRef.current?.offsetHeight,
      duration: 0.4,
      clearProps: 'height',
    });
  };

  return (
    <Container>
      <Fade show={!!props.isInitialLoad} base={StyledLoading} />
      <Wrapper ref={wrapperRef}>
        <Fade show={!props.isInitialLoad} ref={fadeRef} onExit={handleOnExit} onEnter={handleOnEnter}>
          {props.empty && !props.isInitialLoad && typeof props.response === 'undefined' && !props.hasFilter ? (
            props.empty
          ) : (
            <StyledTable {...props} data={props.response ?? []} isLoading={props.isLoading && !props.isInitialLoad} />
          )}
        </Fade>
      </Wrapper>
      <Bottom>
        <Fade show={!props.isInitialLoad && typeof props.response !== 'undefined'} timeoutEnter={400}>
          <Dropdown
            options={[5, 10, 15, 20, 25, 50].map((entries: number) => ({
              label: entries.toString(),
              value: entries,
            }))}
            size="small"
            variant="muted"
            active={Number(queryParams?.limit ?? 10)}
            customLabel={
              <Entries counter={Number(queryParams?.limit ?? 10)}>
                {entriesOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
              </Entries>
            }
            onOpenChange={setEntriesOpen}
            onChange={(value: number) => {
              updateSettings({
                tableSettings: {
                  [props.tableId]: {
                    limit: value,
                  },
                },
              }).then(() => {
                props.setQueryParams?.({
                  startingAfter: undefined,
                  endingBefore: undefined,
                  limit: value,
                  page: 1,
                });
              });
            }}
          />
        </Fade>
        <Fade show={!props.isInitialLoad && typeof props.response !== 'undefined'} timeoutEnter={400}>
          <Pagination
            disablePrev={Number(queryParams?.page ?? 1) === 1}
            disableNext={!props?.hasMore}
            current={Number(queryParams?.page ?? 1)}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </Fade>
      </Bottom>
    </Container>
  );
};
