import { useCallback } from 'react';

import {
  AchRepository,
  AchTransfer,
  BookRepository,
  BookTransfer,
  FxQuote,
  IntlWireRepository,
  IntlWireTransfer,
  TransferFilterParams,
  TransferListResponse,
  TransferRepository,
  transfers,
  WireRepository,
  WireTransfer,
  FxRateSheet,
  CreateFXQuoteRequest,
  CreateIntlWireTransfer,
} from '~/repositories';
import { CheckRepository, CheckTransfer } from '~/repositories/Transfer/CheckRepository';
import { ApiError } from '~/typings/API';

import { createApiHook, RepositoryMethod } from './utils/createApiHook';

export type TransferID = 'ach' | 'wire' | 'book' | 'intl-wire' | 'check';
export type TransferType = AchTransfer | WireTransfer | BookTransfer | IntlWireTransfer | CheckTransfer;
type TransferResponse = (params: GetRequestType) => Promise<TransferType>;

export type TransferFilterType = TransferFilterParams & {
  page?: number;
};

const TransferMethods = new Map<TransferID, TransferResponse>([
  ['ach', AchRepository.get],
  ['wire', WireRepository.get],
  ['book', BookRepository.get],
  ['intl-wire', IntlWireRepository.get],
  ['check', CheckRepository.get],
  // ['realtime', RealtimeRepository.get],
]);

export const useTransfer = <T>(type: TransferID, triggerAutomatically: boolean = true) =>
  createApiHook<T, GetRequestType>(TransferMethods.get(type) as RepositoryMethod<T, GetRequestType>, {
    triggerAutomatically,
    triggerOnSessionStoreSubscribe: triggerAutomatically,
  });

export const useTransfers = createApiHook<TransferListResponse, TransferFilterType>(TransferRepository.getAll, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
  includeQueryParams: true,
  addQueryParamsToUrl: true,
  excludeQueryParams: ['page'],
});

export const useTransfersFetch = createApiHook<TransferListResponse, TransferFilterType>(TransferRepository.getAll);

export const useCreateIntlWireTransfer = createApiHook<IntlWireTransfer, CreateIntlWireTransfer>(
  IntlWireRepository.create
);

interface CheckTransferIdProps {
  onSuccess: (id: string) => void;
  onError: (message: string) => void;
}

export const useCheckTransferId = ({ onSuccess, onError }: CheckTransferIdProps) => {
  const handleSuccess = useCallback(
    (data: TransferType) => {
      if (onSuccess && typeof data?.id !== 'undefined') {
        onSuccess(data.id);
      }
    },
    [onSuccess]
  );

  const handleError = useCallback(
    (error: ApiError) => {
      console.info('Filter by ID', { error });

      if (onError) {
        onError('Please enter a valid Transfer ID');
      }
    },
    [onError]
  );

  const { createRequest: requestAchTransfer } = useTransfer<AchTransfer>(
    'ach',
    false
  )({ onSuccess: handleSuccess, onError: handleError });
  const { createRequest: requestWireTransfer } = useTransfer<WireTransfer>(
    'wire',
    false
  )({ onSuccess: handleSuccess, onError: handleError });
  const { createRequest: requestBookTransfer } = useTransfer<BookTransfer>(
    'book',
    false
  )({ onSuccess: handleSuccess, onError: handleError });
  const { createRequest: requestIntlWireTransfer } = useTransfer<IntlWireTransfer>(
    'intl-wire',
    false
  )({ onSuccess: handleSuccess, onError: handleError });
  const { createRequest: requestCheckTransfer } = useTransfer<CheckTransfer>(
    'check',
    false
  )({ onSuccess: handleSuccess, onError: handleError });

  const checkTransferId = useCallback((id: string) => {
    if (!transfers.some((transfer) => transfer.idString && id.startsWith(transfer.idString))) {
      onError('Enter a valid Transfer ID');

      return;
    }

    if (id.startsWith('acht_')) {
      requestAchTransfer({ id });
    } else if (id.startsWith('wire_')) {
      requestWireTransfer({ id });
    } else if (id.startsWith('book_')) {
      requestBookTransfer({ id });
    } else if (id.startsWith('chkt_')) {
      requestCheckTransfer({ id });
    } else if (id.startsWith('swft_')) {
      requestIntlWireTransfer({ id });
    }
  }, []);

  return checkTransferId;
};

export const useFxRateSheet = createApiHook<FxRateSheet, unknown>(IntlWireRepository.getFxRateSheet);

export const useCreateFxQuote = createApiHook<FxQuote, CreateFXQuoteRequest>(IntlWireRepository.createFxQuote);
