import React from 'react';
import styled from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { useNavigate } from '~/lib/navigation';
import { SubHeadline, Paragraph, TinyHeadline } from '~/styles';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 32px;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 24px;
`;

const Box = styled.div`
  border-radius: 8px;
  padding: 24px;
  display: grid;
  grid-gap: 8px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(75)};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(125)};
`;

const Requirement = styled.div`
  display: flex;
  gap: 4px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
  margin-bottom: 8px;

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const StyledButton = styled(Button)`
  justify-self: start;
  align-self: flex-end;
  margin-top: 12px;
`;

const StyledParagraph = styled(Paragraph)`
  font-weight: 400;
`;

const Additional = styled.div`
  border-radius: 8px;
  padding: 40px;
  display: grid;
  grid-gap: 32px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

const AdditionalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AdditionalText = styled.div`
  display: grid;
  grid-gap: 4px;
  max-width: 340px;
`;

const AdditionalLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

const AdditionalGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const AdditionalEntry = styled.div`
  display: grid;
  grid-gap: 4px;
`;

export const PageFeatures: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Columns>
        <Box>
          <Requirement>
            <Icon.CircleInfo />
            Requires bank account
          </Requirement>
          <SubHeadline>Increase limits</SubHeadline>
          <Paragraph>
            Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibuls justo odio.
          </Paragraph>
          <StyledButton onClick={() => navigate(ROUTE.CONTACT_FORM)}>Contact us</StyledButton>
        </Box>
        <Box>
          <Requirement>
            <Icon.CircleInfo />
            Requires bank account
          </Requirement>
          <SubHeadline>Increase limits &amp; enable FBO Accounts</SubHeadline>
          <Paragraph>
            Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibuls justo odio.
          </Paragraph>
          <StyledButton onClick={() => navigate(ROUTE.CONTACT_FORM)}>Contact us</StyledButton>
        </Box>
      </Columns>
      <Additional>
        <AdditionalHeader>
          <AdditionalText>
            <SubHeadline>Additional products</SubHeadline>
            <Paragraph>Lorem ipsum dolor. Viva mus sagittis lacus vel augue laoreet.</Paragraph>
          </AdditionalText>

          <Button onClick={() => navigate(ROUTE.CONTACT_FORM)}>Contact Us</Button>
        </AdditionalHeader>
        <AdditionalLine />
        <AdditionalGrid>
          <AdditionalEntry>
            <TinyHeadline>Originate loans</TinyHeadline>
            <StyledParagraph size="small">Lorem ipsum dolor. Viva mus sagittis lacus vel augue.</StyledParagraph>
          </AdditionalEntry>
          <AdditionalEntry>
            <TinyHeadline>Originate loans</TinyHeadline>
            <StyledParagraph size="small">Lorem ipsum dolor. Viva mus sagittis lacus vel augue.</StyledParagraph>
          </AdditionalEntry>
          <AdditionalEntry>
            <TinyHeadline>Originate loans</TinyHeadline>
            <StyledParagraph size="small">Lorem ipsum dolor. Viva mus sagittis lacus vel augue.</StyledParagraph>
          </AdditionalEntry>
        </AdditionalGrid>
      </Additional>
    </Wrapper>
  );
};
