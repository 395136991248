import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventIdentityProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventIdentity = forwardRef<SVGSVGElement, EventIdentityProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0002 8.40005C9.54659 8.40005 10.8002 7.14645 10.8002 5.60005C10.8002 4.05365 9.54659 2.80005 8.0002 2.80005C6.4538 2.80005 5.2002 4.05365 5.2002 5.60005C5.2002 7.14645 6.4538 8.40005 8.0002 8.40005Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14 14.3999C14 11.0879 11.312 8.3999 8 8.3999C4.688 8.3999 2 11.0879 2 14.3999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </SVG>
  );
});
