import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Icon, Fade, Dropdown, Chip } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { ApiKeyPreview, EmptyState, PageHeader, Table, TableColumn } from '~/components';
import { LogoLoading, RelativeTime } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import { ApiKeyRepository } from '~/repositories';
import { useAlertStore } from '~/stores/Alert';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

interface ApiKeyList {
  id?: string;
  name?: string;
  key?: ReactNode;
  type?: ReactNode;
  status?: ReactNode;
  created?: Date | undefined;
  action?: ReactNode;
}

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const StyledDropdown = styled(Dropdown)`
  margin: -10px 0;
`;

export const PageApiKeys: React.FC = () => {
  const { currentUser } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openAlert = useAlertStore((state) => state.openAlert);
  const navigate = useNavigate();
  const [apiKeyList, setApiKeyList] = useState<ApiKeyList[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleDelete = (entry: any) => {
    openAlert({
      headline: 'Delete API key',
      text: 'This action cannot be undone.',
      submitText: 'Delete',
      callback: () => {
        if (!currentUser) {
          return;
        }
        ApiKeyRepository.delete(currentUser.defaultPlatformId, entry.id, entry.type === 'Sandbox')
          .then(() => {
            addSuccessNotification({
              content: `${entry.name} deleted`,
            });
            fetchData();
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          });
      },
    });
  };

  const fetchData = () => {
    if (!currentUser) {
      return;
    }
    ApiKeyRepository.getAllList(currentUser.defaultPlatformId)
      .then((response) => {
        const entries: ApiKeyList[] = [];
        if (response.apiKeys.length) {
          response.apiKeys.map((entry: any) => {
            entries.push({
              id: entry.id,
              name: entry.name,
              key: entry.apiKeyToken,
              type: entry.type,
              created: entry.createdAt,
              action: (
                <StyledDropdown
                  isInside
                  positionRight
                  options={[
                    {
                      label: 'Edit',
                      onClick: () => navigate(`${ROUTE.PLATFORM_API_KEYS}/edit/${entry.type}/${entry.id}`),
                    },
                    {
                      label: 'Delete',
                      isDanger: true,
                      onClick: () => handleDelete(entry),
                    },
                  ]}
                  variant="dots"
                />
              ),
            });
          });
        }
        setApiKeyList(entries);
        setLoading(false);
      })
      .catch((e) => console.error('ApiKeyRepository.getAllList', e));
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => fetchData(),
        { fireImmediately: true }
      ),
    []
  );

  const columns: TableColumn[] = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 'minmax(300px, auto)',
      Cell: (current) => <>{current.value}</>,
    },
    {
      Header: 'Key',
      width: 'min-content',
      Cell: (current) => <ApiKeyPreview value={current.value} />,
      accessor: 'key',
    },
    {
      Header: 'Type',
      width: 'min-content',
      Cell: (current) => (
        <Chip icon={current.value === 'Production' ? <Icon.CircleCheck /> : <Icon.Sandbox />}>{current.value}</Chip>
      ),
      accessor: 'type',
    },
    {
      Header: 'Created',
      accessor: 'created',
      sortType: 'datetime',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader text="API Keys">
        <Button onClick={() => navigate(`${ROUTE.PLATFORM_API_KEYS}/edit`)} icon={<Icon.Plus />} size="small">
          Create API Key
        </Button>
      </PageHeader>

      <Fade show={loading} base={StyledLoading} />
      <Fade show={!loading}>
        {apiKeyList.length < 1 ? (
          <EmptyState headline="No API keys" text="Add your first API key to get started.">
            <Button onClick={() => navigate(`${ROUTE.PLATFORM_API_KEYS}/edit`)} size="small" icon={<Icon.Plus />}>
              Create API Key
            </Button>
          </EmptyState>
        ) : (
          <Table
            columns={columns}
            data={apiKeyList}
            onRowClick={(row) => {
              const originalRow: ApiKeyList = row.original;
              navigate(`${ROUTE.PLATFORM_API_KEYS}/edit/${originalRow.type}/${originalRow.id}`);
            }}
          />
        )}
      </Fade>
    </>
  );
};
