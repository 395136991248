import React, { useRef, FC } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface DownloadIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const DownloadIcon: FC<DownloadIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={32}>
        <path d="M27 20V24C27 25.66 25.66 27 24 27H8C6.34 27 5 25.66 5 24V20" stroke="currentColor" strokeWidth="2" />
        <path d="M16 20V5" stroke="currentColor" strokeWidth="2" />
        <path
          d="M21.9983 15L17.4083 19.59C16.6283 20.37 15.3583 20.37 14.5783 19.59L9.98828 15"
          stroke="currentColor"
          strokeWidth="2"
        />
      </IconBase>
    </Wrapper>
  );
};
