import React from 'react';

import { Dropdown } from '@column/column-ui-kit';

import {
  OptionsFull,
  OptionsFullGranular,
  OptionsNotCreatable,
  OptionsNotEditable,
  OptionsOnOff,
} from '~/app/pages/Platform/Roles/types';
import { RenderFields } from '~/components';
import { CreateCustomRoleRequestObject } from '~/typings/API';

type AdditionalPermissionsFields = Pick<CreateCustomRoleRequestObject, 'platformLevelPermissions'>;

export const AdditionalPermissions: React.FC = () => {
  return RenderFields<AdditionalPermissionsFields>({
    sections: [
      {
        fields: [
          {
            id: 'platformLevelPermissions.platformInfo',
            label: 'Platform Settings',
            description: 'View and edit platform information and due diligence.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsNotCreatable} onChange={onChange} fullWidth />
            ),
          },
        ],
      },
      {
        fields: [
          {
            id: 'platformLevelPermissions.entities',
            label: 'Entities',
            description: 'Set the default permissions for new and existing entities.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.bankAccounts',
            label: 'Bank Accounts',
            description: 'Set the default permissions for new and existing bank accounts.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFullGranular} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.counterparties',
            label: 'Counterparties',
            description: 'Set the default permissions for new and existing counterparties.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsNotEditable} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.apiKeys',
            label: 'API Keys',
            description:
              'API keys allow developers on your team to connect to your platform. Access should be granted sparingly.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
          {
            id: 'platformLevelPermissions.loans',
            label: 'Loans',
            description: 'Set the default loan permissions for new and existing bank accounts.',
            children: ({ value, onChange }) => (
              <Dropdown active={value} options={OptionsFull} onChange={onChange} fullWidth />
            ),
          },
        ],
      },
      {
        fields: [
          {
            id: 'platformLevelPermissions.reporting',
            label: 'Reports and Statements',
            description: 'Set the default reporting permissions for new and existing bank accounts.',
            children: ({ value, onChange }) => {
              const onOffValue = value === 'write' ? 'on' : 'off';
              const onOffOnChange = (newValue: 'on' | 'off') =>
                newValue === 'on' ? onChange('write') : onChange('none');
              return <Dropdown active={onOffValue} options={OptionsOnOff} onChange={onOffOnChange} fullWidth />;
            },
          },
        ],
      },
    ],
  });
};
