import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { useNavigate } from '~/lib/navigation';
import { PlatformLevelType } from '~/models';
import { Box } from '~/styles';

import { IconCircle } from './GetStarted';

interface PlatformBannerProps {
  state?: PlatformLevelType;
}

const StyledBox = styled(Box)`
  position: relative;
`;

const Title = styled.div<PlatformBannerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: inherit;

  ${({ state }) =>
    (state === 'basic' || state === 'sandbox_only') &&
    css`
      background-image: linear-gradient(200deg, trasnaprent, ${({ theme }) => theme.background} 150px);
    `}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
`;

const LabelInfo = styled.span`
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.background};
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
`;

const Content = styled.div`
  padding: 20px;
`;

const Headline = styled.h3`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
`;

const Grid = styled.ul<{ columnAmount?: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columnAmount }) => columnAmount ?? '4'}, minmax(0, 1fr));
  grid-gap: 40px;
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  counter-reset: section;
`;

const GridEntry = styled.li<{ isNumeric?: boolean }>`
  display: grid;
  grid-gap: 4px;

  ${({ isNumeric }) =>
    isNumeric &&
    css`
      &:before {
        content: counter(section);
        counter-increment: section;
        width: 22px;
        height: 22px;
        line-height: 22px;
        margin-bottom: 8px;
        display: block;
        text-align: center;
        font-size: 11px;
        font-weight: bold;
        border-radius: 50%;
        color: ${({ theme }) => theme.primary.background};
        background-color: ${({ theme }) => theme.primary.blendToBackground(125)};
        box-shadow: inset 0 -1px 1px ${({ theme }) => theme.primary.blendToBackground(250)};
      }
    `}
`;

export const getPlatformStatus = (state?: PlatformLevelType): 'approved' | 'pending' | 'not_started' => {
  if (state === 'commercial_customer' || state === 'self_id') {
    return 'approved';
  }
  if (state === 'enhanced_diligence_required' || state === 'industry_verification_pending' || state === 'kyb_pending') {
    return 'pending';
  }
  return 'not_started';
};

export const PlatformBanner: React.FC<PlatformBannerProps> = ({ state }) => {
  const navigate = useNavigate();
  const [isClose, setIsClose] = useState<boolean>(true);

  const handleToggleContent = useCallback(() => {
    setIsClose((s) => !s);
  }, []);

  const content = useMemo(() => {
    const platformStatus = getPlatformStatus(state);
    if (platformStatus === 'approved') {
      return {
        icon: <Icon.CircleCheck />,
        title: 'Approved',
        description: 'You now have a commercial bank account at Column!',
      };
    }
    if (platformStatus === 'pending') {
      return {
        icon: <Icon.Clock />,
        title: 'Pending Approval',
        description: 'Information received, updates will be provided through email.',
      };
    }
    return;
  }, [state]);

  return (
    <StyledBox>
      {content && (
        <Title state={state}>
          <LabelWrapper>
            <IconCircle>{content.icon}</IconCircle>
            <Labels>
              <Label>{content.title}</Label>
              <LabelInfo>{content.description}</LabelInfo>
            </Labels>
          </LabelWrapper>
        </Title>
      )}
    </StyledBox>
  );
};
