import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Checkbox, SegmentedControl, SegmentedControlSize } from '@column/column-ui-kit';

import { PopoverAction, PopoverBase, PopoverBaseProps } from '../Base';
import { Line } from '~/styles';
import { formatString } from '~/util';

const PopoverFilterTransferData = [
  'ach_credit',
  'ach_debit',
  'wire',
  'book',
  'check_credit',
  'check_debit',
  'swift',
  'realtime',
] as const;

export interface PopoverFilterTransferTypeData {
  type?: (typeof PopoverFilterTransferData)[];
  isIncoming?: boolean;
}

interface PopoverFilterTransferTypeProps extends PopoverBaseProps {
  data: PopoverFilterTransferTypeData;
  onSubmit?: (data: PopoverFilterTransferTypeData) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-padding: 0px;
  --popover-width: 248px;
  --popover-left: 0px;
`;

const List = styled.div`
  padding: 8px;
  margin-bottom: -12px;
`;

export const ListLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  text-transform: uppercase;
  padding: 8px;
`;

const Entry = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.foreground};
  transition: background-color 0.15s;
  border-radius: 6px;
  text-transform: capitalize;

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(75)};
  }
`;

const Divider = styled(Line)`
  margin: 8px 0;
`;

export const PopoverFilterTransferType: React.FC<PopoverFilterTransferTypeProps> = ({
  data,
  onSubmit,
  ...popoverProps
}) => {
  const [currentData, setCurrentData] = useState<PopoverFilterTransferTypeData>({});

  const isActive = useCallback(
    (key: string) => {
      const optionKey = key as unknown as typeof PopoverFilterTransferData;

      return !!(currentData.type && currentData.type.includes(optionKey));
    },
    [currentData]
  );

  const handleOptionChange = useCallback(
    (key: string) => {
      const optionKey = key as unknown as typeof PopoverFilterTransferData;

      if (isActive(key)) {
        setCurrentData((s) => ({ ...s, type: currentData.type?.filter((k) => k !== optionKey) }));
        return;
      }
      setCurrentData((s) => ({ ...s, type: [...(s.type ?? []), optionKey] }));
    },
    [currentData]
  );

  const handleResetClick = () => {
    setCurrentData({});

    if (onSubmit) {
      onSubmit({});
    }
  };

  const handleApplyClick = () => {
    if (onSubmit) {
      onSubmit(currentData);
    }
  };

  useEffect(() => {
    setCurrentData(data);
  }, [data, popoverProps.show]);

  return (
    <Wrapper {...popoverProps}>
      <List>
        <ListLabel>Filter type</ListLabel>
        <SegmentedControl
          size={SegmentedControlSize.Small}
          options={[
            {
              label: 'All',
              value: undefined,
            },
            {
              label: 'Incoming',
              value: true,
            },
            {
              label: 'Outgoing',
              value: false,
            },
          ]}
          fullWidth
          activeUndefined
          active={currentData.isIncoming}
          onOptionChange={(value) => setCurrentData((s) => ({ ...s, isIncoming: value }))}
        />
        <Divider />
        {PopoverFilterTransferData.map((entry) => (
          <Entry key={entry} $isActive={isActive(entry)} onClick={() => handleOptionChange(entry)}>
            {formatString(entry)
              .replaceAll('Ach', 'ACH')
              .replaceAll('Swift', 'International Wire')
              .replaceAll('Check credit', 'Deposited Check')
              .replaceAll('Check debit', 'Issued Check')}
            <Checkbox isChecked={isActive(entry)} onCheckedChange={() => handleOptionChange(entry)} />
          </Entry>
        ))}
      </List>
      <PopoverAction>
        <Button size="small" variant="secondary" onClick={handleResetClick}>
          Reset
        </Button>
        <Button size="small" onClick={handleApplyClick}>
          Apply
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
