import React from 'react';
import styled from 'styled-components';

import { Logo } from '~/elements';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';

import { HeaderWrapper } from './Header';

interface HeaderProps {
  className?: string;
  isScrolled?: boolean;
}

const LogoWrapper = styled.a`
  position: relative;
  display: block;
  z-index: 1;
  padding: 4px 0;
  transform: translateY(2px);
`;

export const HeaderWebsite: React.FC<HeaderProps> = (props) => {
  const openHelpSidebars = useHelpSidebarStore((state) => state.openHelpSidebars);

  return (
    <HeaderWrapper
      className={props.className}
      isScrolled={props.isScrolled && openHelpSidebars.length < 1}
      isOpenSidebar={openHelpSidebars.length > 0}
    >
      <LogoWrapper href="https://column.com">
        <Logo variant="word" size="17px" />
      </LogoWrapper>
    </HeaderWrapper>
  );
};
