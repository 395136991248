import React, { useRef } from 'react';

import { Button, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import {
  EmptyState,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
  PageHeader,
} from '~/components';
import { RelativeTime } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import {
  EventRepository,
  getEventRoute,
  getEventStatus,
  EventTypes,
  getEventIcon,
  getRawEventDirection,
  getRawEventType,
  getEventSummary,
  parseEvent,
  ParsedEvent,
  getEventACHType,
} from '~/repositories';
import { useModalStore } from '~/stores/Modal';

import { EventMeta, EventType, EventDetail, EventIcon, Action, JSONButton } from './Events';

type EventsTableRow = ParsedEvent & { action: React.ReactElement };

export const PageWebhookEvents: React.FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleFetch = async (params: any) => {
    return EventRepository.getAllWebhooks(params).then((response) => {
      const entries = response.events.map(
        (entry): EventsTableRow => ({
          ...parseEvent(entry),
          action: (
            <Action>
              <JSONButton
                onlyIcon
                size="tiny"
                variant="muted"
                onClick={(event) => {
                  event.stopPropagation();
                  openModal('EventDetail', {
                    data: entry.data,
                  });
                }}
              >
                JSON
              </JSONButton>
            </Action>
          ),
        })
      );
      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const eventTypes = Object.values(EventTypes).filter((name: string) => !['UNRECOGNIZED'].includes(name));

  const filter: PopoverFilterEntry[] = [
    {
      id: 'types',
      label: 'Type',
      type: 'select',
      list: eventTypes.map((eventType) => ({
        label: eventType,
        value: eventType,
      })),
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  const columns: TableColumn[] = [
    {
      Header: 'Type',
      accessor: 'data',
      width: 'minmax(300px, auto)',
      Cell: (current) => (
        <EventMeta>
          <EventType>
            {getRawEventDirection(current.value.eventType)} {getRawEventType(current.value.eventType)}{' '}
            {getEventACHType(current.value)}
          </EventType>

          <EventDetail>
            <EventIcon>{getEventIcon(current.value.eventType)}</EventIcon>

            <span>{getEventSummary(current.value)}</span>
          </EventDetail>
        </EventMeta>
      ),
    },
    {
      Header: 'Status',
      accessor: 'eventType',
      width: 'minmax(min-content, 100px)',
      Cell: (current) => getEventStatus(current.value),
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      sortType: 'datetime',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader text="Webhook Events" border={false} />

      <PaginationWrapper
        tableId="webhookEvents"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        rowClick={({ original }: { original: EventsTableRow }) =>
          navigate(
            getRawEventType(original.eventType) === 'Identity'
              ? `${getEventRoute(original.eventType)}/${(original.data.subType ?? 'undefined').toLowerCase()}/${original.data.id}`
              : getRawEventType(original.eventType) === undefined
                ? ''
                : `${getEventRoute(original.eventType)}/${original.data.id}`
          )
        }
        empty={
          <EmptyState headline="No webhook events found" text="Create your first person or a business entity.">
            <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/person`)} size="small" icon={<Icon.User />}>
              Create Entity
            </Button>
          </EmptyState>
        }
      />
    </>
  );
};
