import React from 'react';
import styled, { css } from 'styled-components';

import { Input, Button, Toggle, Dropdown, ToggleHeight } from '@column/column-ui-kit';

import { DateInput } from '../../DateInput';
import { PopoverAction, PopoverBase, PopoverBaseProps } from '../Base';
import { PopoverListTitle, PopoverListLabel } from '../List';

export interface PopoverFilterListEntry {
  value: string;
  label: string;
}

export interface PopoverFilterEntryActiveTypeValue {
  filterType: string;
  value: string;
  label: string;
}

export interface PopoverFilterEntry {
  id: string;
  label: string;
  show?: boolean;
  type: 'date' | 'exactDate' | 'select' | 'input' | 'boolean';
  typeActive?: string;
  list?: PopoverFilterListEntry[];
  active?: any;
}

interface PopoverFilterProps extends PopoverBaseProps {
  options: PopoverFilterEntry[];
  onDone?: () => void;
  onReset?: () => void;
  onOptionChange?: (active: PopoverFilterEntry[]) => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-padding: 12px 0;
  --popover-width: 216px;
`;

const List = styled.div`
  display: grid;
  grid-gap: 12px;
`;

const PopoverListEntry = styled.div``;

const StyledToggleHeight = styled(ToggleHeight)`
  --toggle-height-padding: 8px 0 0 0;
`;

const PopoverListEntryContent = styled.div`
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  padding: 12px 11px;
  margin: 0 1px;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  display: grid;
  grid-gap: 8px;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
      border-bottom: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;

const StyledPopoverAction = styled(PopoverAction)`
  margin: 12px 0 -11px 0;
`;

export const PopoverFilter: React.FC<PopoverFilterProps> = (props) => {
  const updateOptions = (index: number, key: string, value: any) => {
    const options = [
      ...props.options.slice(0, index),
      { ...props.options[index], [key]: value },
      ...props.options.slice(index + 1),
    ];

    if (props.onOptionChange) {
      props.onOptionChange(options);
    }
  };

  const handleToggleChange = (value: boolean, index: number) => {
    updateOptions(index, 'show', value);
  };

  const handleResetClick = () => {
    if (props.onReset) {
      props.onReset();
    }
  };

  const handleDoneClick = () => {
    if (props.onDone) {
      props.onDone();
    }
  };

  return (
    <Wrapper {...props}>
      <List>
        {props.options.map((entry: PopoverFilterEntry, index: number) => (
          <PopoverListEntry key={index}>
            <PopoverListTitle>
              <PopoverListLabel>{entry.label}</PopoverListLabel>
              <Toggle isChecked={entry.show} onCheckedChange={(value: boolean) => handleToggleChange(value, index)} />
            </PopoverListTitle>
            <StyledToggleHeight isClose={!entry.show}>
              <PopoverListEntryContent>
                {entry.type === 'date' && (
                  <>
                    <Dropdown
                      active={entry.typeActive ?? 'gt'}
                      options={[
                        { label: 'Greater than ', value: 'gt' },
                        { label: 'Greater than / equal', value: 'gte' },
                        { label: 'Less than ', value: 'lt' },
                        { label: 'Less than / equal', value: 'lte' },
                      ]}
                      onChange={(value: string) => updateOptions(index, 'typeActive', value)}
                      size="small"
                      fullWidth
                      maxWidth="600px"
                    />
                    <DateInput
                      value={entry.active}
                      onChange={(value: string | undefined) => updateOptions(index, 'active', value)}
                      size="small"
                    />
                  </>
                )}
                {entry.type === 'exactDate' && (
                  <DateInput
                    value={entry.active}
                    onChange={(value: string | undefined) => updateOptions(index, 'active', value)}
                    size="small"
                  />
                )}
                {entry.type === 'select' && entry.list && (
                  <Dropdown
                    active={entry.active}
                    options={entry.list}
                    onChange={(value: string) => updateOptions(index, 'active', value)}
                    size="small"
                    fullWidth
                    maxWidth="600px"
                    search={entry.list.length >= 10}
                  />
                )}
                {entry.type === 'input' && (
                  <Input
                    placeholder="Search..."
                    size="small"
                    variant="light"
                    value={entry.active ?? ''}
                    onChange={(value: string) => updateOptions(index, 'active', value)}
                  />
                )}
                {entry.type === 'boolean' && (
                  <Dropdown
                    active={entry.active}
                    options={[
                      { label: 'True', value: true },
                      { label: 'False', value: false },
                    ]}
                    onChange={(value: string) => updateOptions(index, 'active', value)}
                    size="small"
                    fullWidth
                    maxWidth="600px"
                  />
                )}
              </PopoverListEntryContent>
            </StyledToggleHeight>
          </PopoverListEntry>
        ))}
      </List>
      <PopoverAction>
        <Button onClick={handleResetClick} size="small" variant="secondary">
          Reset
        </Button>
        <Button onClick={handleDoneClick} size="small">
          Done
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
