import { APIKEYS } from './ApiKeys';
import { PageFeatures } from './Features';
import { INFO } from './Info';
import { PageRoles } from './Roles';
import { PageCreateRole } from './Roles/Create';
import { PageEditRole } from './Roles/Edit';
import { PageRootEntity } from './RootEntity';
import { PagePlatformSettings } from './Settings';
import { PageTeam } from './Team';
import { WEBHOOKS } from './Webhooks';

export const PLATFORM = {
  Features: PageFeatures,
  PlatformSettings: PagePlatformSettings,
  RootEntity: PageRootEntity,
  CreateRole: PageCreateRole,
  EditRole: PageEditRole,
  Roles: PageRoles,
  Team: PageTeam,
  ...INFO,
  ...APIKEYS,
  ...WEBHOOKS,
} as const;
