export const checkReturnCodesMap = new Map<string, string>([
  ['A', 'Not Sufficient Funds'],
  ['B', 'Uncollected Funds Hold'],
  ['C', 'Stop Payment'],
  ['D', 'Closed Account'],
  ['E', 'Unable to Locate Account'],
  ['F', 'Frozen/Blocked Account'],
  ['G', 'Stale Dated'],
  ['H', 'Post Dated'],
  ['I', 'Endorsement Missing'],
  ['J', 'Endorsement Irregular'],
  ['K', 'Signature(s) Missing'],
  ['L', 'Signature(s) Irregular, Suspected Forgery'],
  ['M', 'Non-Cash Item (Non-Negotiable)'],
  ['N', 'Altered/Fictitious Item/Suspected Counterfeit/Counterfeit'],
  ['O', 'Unable to Process'],
  ['P', 'Item outside of stated dollar amount limit'],
  ['Q', 'Not Authorized'],
  ['R', 'Branch/Account Sold'],
  ['S', 'Refer to Maker'],
  ['T', 'Item cannot be re-presented'],
  ['U', 'Unusable Image'],
  ['W', 'Cannot Determine Amount–Amount cannot be verified'],
  ['X', 'Refer to Image–Return Reason information is contained within the image of the item'],
  ['Y', 'Duplicate Presentment'],
  ['Z', 'Forgery–An affidavit shall be available upon request'],
  ['3', 'Warranty Breach (Includes Rule 8 & 9 claims)'],
  ['4', 'RCC Warranty Breach (Rule 8)'],
  ['5', 'Forged and Counterfeit Warranty Breach (Rule 9)'],
  ['6', 'Retired/Ineligible/Failed Institution Routing Number'],
  ['7', 'Reserved for Future Use by X9'],
  ['8', 'Reserved for Future Use by X9'],
  ['9', 'Reserved for Future Use by X9'],
  ['0', 'Reserved for Future Use by X9'],
]);
