import React, { useRef, FC } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface QRCodeIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const QRCodeIcon: FC<QRCodeIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={24}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6H10V10H6V6Z"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 14H10V18H6V14Z"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 10H14V6L18 6V10Z"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0354 15.9646C16.0549 15.9842 16.0549 16.0158 16.0354 16.0354C16.0158 16.0549 15.9842 16.0549 15.9646 16.0354C15.9451 16.0158 15.9451 15.9842 15.9646 15.9646C15.9842 15.9451 16.0158 15.9451 16.0354 15.9646"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0354 13.9646C14.0549 13.9842 14.0549 14.0158 14.0354 14.0354C14.0158 14.0549 13.9842 14.0549 13.9646 14.0354C13.9451 14.0158 13.9451 13.9842 13.9646 13.9646C13.9842 13.9451 14.0158 13.9451 14.0354 13.9646"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0354 17.9646C14.0549 17.9842 14.0549 18.0158 14.0354 18.0354C14.0158 18.0549 13.9842 18.0549 13.9646 18.0354C13.9451 18.0158 13.9451 17.9842 13.9646 17.9646C13.9842 17.9451 14.0158 17.9451 14.0354 17.9646"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0354 17.9646C18.0549 17.9842 18.0549 18.0158 18.0354 18.0354C18.0158 18.0549 17.9842 18.0549 17.9646 18.0354C17.9451 18.0158 17.9451 17.9842 17.9646 17.9646C17.9842 17.9451 18.0158 17.9451 18.0354 17.9646"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.4995 7.978V4.5C21.4995 3.395 20.6045 2.5 19.4995 2.5H16.0215"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.978 2.5H4.5C3.395 2.5 2.5 3.395 2.5 4.5V7.978"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 16.0215V19.4995C2.5 20.6045 3.395 21.4995 4.5 21.4995H7.978"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0215 21.4995H19.4995C20.6045 21.4995 21.4995 20.6045 21.4995 19.4995V16.0215"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0354 13.9646C18.0549 13.9842 18.0549 14.0158 18.0354 14.0354C18.0158 14.0549 17.9842 14.0549 17.9646 14.0354C17.9451 14.0158 17.9451 13.9842 17.9646 13.9646C17.9842 13.9451 18.0158 13.9451 18.0354 13.9646"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconBase>
    </Wrapper>
  );
};
