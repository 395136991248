import React, { useCallback, useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, DropdownOption, Icon } from '@column/column-ui-kit';

import { PopoverAction, PopoverBase, PopoverBaseProps } from '../Base';
import { FormElement, FormLabel } from '~/styles';
import { formatString } from '~/util';

const TransferStatusObject = {
  ach: [
    'initiated',
    'pending_submission',
    'submitted',
    'settled',
    'returned',
    'completed',
    'canceled',
    'scheduled',
    'pending_return',
    'return_dishonored',
    'return_contested',
    'manual_review',
    'manual_review_approved',
  ],
  book: ['rejected', 'completed', 'hold', 'canceled'],
  wire: ['initiated', 'pending_submission', 'submitted', 'completed', 'rejected', 'manual_review'],
  swift: [
    'initiated',
    'pending_submission',
    'submitted',
    'completed',
    'pending_return',
    'returned',
    'manual_review',
    'failed',
  ],
  checks: [
    'issued',
    'deposited',
    'pending_deposit',
    'stopped',
    'rejected',
    'pending_first_return',
    'pending_second_return',
    'first_return',
    'recleared',
    'pending_reclear',
    'second_return',
    'settled',
    'pending_user_initiated_return',
    'user_initiated_returned',
    'manual_review',
  ],
} as const;

export type PopoverFilterTransferStatusData = string;

interface PopoverFilterTransferStatusProps extends PopoverBaseProps {
  data: PopoverFilterTransferStatusData[];
  onSubmit?: (data: PopoverFilterTransferStatusData[]) => void;
}

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 12px 12px 0 12px;
`;

const Wrapper = styled(PopoverBase)`
  --popover-padding: 0px;
  --popover-width: 248px;
  --popover-left: 0px;
`;

const List = styled.div`
  display: grid;
  grid-gap: 8px;
`;

const Entry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.background};
`;

export const PopoverFilterTransferStatus: React.FC<PopoverFilterTransferStatusProps> = ({
  data,
  onSubmit,
  ...popoverProps
}) => {
  const [currentData, setCurrentData] = useState<PopoverFilterTransferStatusData[]>(data ?? []);

  const handleClick = useCallback(
    (entry: string) => {
      if (currentData.includes(entry)) {
        setCurrentData((s) => s.filter((k) => k !== entry));
        return;
      }
      setCurrentData((s) => [...s, entry]);
    },
    [currentData]
  );

  const handleRemove = useCallback(
    (entry: string) => {
      if (!currentData.includes(entry)) {
        return;
      }
      setCurrentData((s) => s.filter((k) => k !== entry));
    },
    [currentData]
  );

  const entries = useMemo(() => {
    const array: DropdownOption[] = [];

    for (const key in TransferStatusObject) {
      if (TransferStatusObject.hasOwnProperty(key)) {
        array.push({
          label: formatString(key).toUpperCase(),
          isDivider: true,
        });

        const values = TransferStatusObject[key as keyof typeof TransferStatusObject];

        values.forEach((entry) => {
          array.push({
            label: formatString(entry),
            value: entry,
            onClick: () => handleClick(entry),
          });
        });
      }
    }

    return array;
  }, []);

  const handleResetClick = () => {
    setCurrentData([]);

    if (onSubmit) {
      onSubmit([]);
    }
  };

  const handleApplyClick = () => {
    if (onSubmit) {
      onSubmit(currentData);
    }
  };

  useEffect(() => {
    setCurrentData(data);
  }, [data, popoverProps.show]);

  return (
    <Wrapper {...popoverProps}>
      <Grid>
        <FormElement>
          <FormLabel>Status</FormLabel>
          <List>
            {[...new Set(currentData)].map((entry) => (
              <Entry key={entry}>
                <Chip>{formatString(entry)}</Chip>
                <Button
                  size="tiny"
                  variant="subtle"
                  onlyIcon
                  icon={<Icon.Cross />}
                  onClick={() => handleRemove(entry)}
                />
              </Entry>
            ))}
            <Dropdown
              search
              active={currentData}
              options={entries}
              size="small"
              label="Add status..."
              renderInside
              fullWidth
            />
          </List>
        </FormElement>
      </Grid>
      <PopoverAction>
        <Button size="small" variant="secondary" onClick={handleResetClick}>
          Reset
        </Button>
        <Button size="small" onClick={handleApplyClick}>
          Apply
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
