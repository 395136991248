import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Dropdown } from '@column/column-ui-kit';

import { PopoverAction, PopoverBase, PopoverBaseProps } from '../Base';
import { FormElement, FormLabel } from '~/styles';
import {
  getMonthBoundaries,
  getYearOptions,
  MonthBoundariesType,
  MonthsMap,
  parseMounthBoundaries,
} from '~/util/dateFilterHelpers';

interface PopoverFilterPeriodProps extends PopoverBaseProps {
  data: Partial<MonthBoundariesType>;
  onSubmit?: (data: Partial<MonthBoundariesType>) => void;
}

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 12px 12px 0 12px;
`;

const Wrapper = styled(PopoverBase)`
  --popover-padding: 0px;
  --popover-width: 248px;
  --popover-left: 0px;
`;

export const PopoverFilterPeriod: FC<PopoverFilterPeriodProps> = ({ data, onSubmit, ...popoverProps }) => {
  const [currentYear, setCurrentYear] = useState<number | undefined>(undefined);
  const [currentMonth, setCurrentMonth] = useState<number | undefined>(undefined);

  const handleResetClick = useCallback(() => {
    setCurrentYear(undefined);
    setCurrentMonth(undefined);

    if (onSubmit) {
      onSubmit({
        fromDate: undefined,
        toDate: undefined,
      });
    }
  }, [onSubmit]);

  const handleApplyClick = useCallback(() => {
    if (!currentYear || !currentMonth) {
      return;
    }

    if (onSubmit) {
      onSubmit(getMonthBoundaries(currentYear, currentMonth));
    }
  }, [currentYear, currentMonth, onSubmit]);

  useEffect(() => {
    const { year, month } = parseMounthBoundaries(data.fromDate);

    setCurrentYear(year);
    setCurrentMonth(month);
  }, [data, popoverProps.show]);

  return (
    <Wrapper {...popoverProps}>
      <Grid>
        <FormElement>
          <FormLabel>Year</FormLabel>
          <Dropdown
            active={currentYear}
            onChange={setCurrentYear}
            options={getYearOptions(2022)}
            size="small"
            placeholder="Select year..."
            renderInside
            fullWidth
          />
        </FormElement>
        <FormElement>
          <FormLabel>Month</FormLabel>
          <Dropdown
            active={currentMonth}
            onChange={setCurrentMonth}
            options={Array.from(MonthsMap, ([value, label]) => ({ label, value }))}
            size="small"
            placeholder="Select month..."
            renderInside
            fullWidth
          />
        </FormElement>
      </Grid>
      <PopoverAction>
        <Button size="small" variant="secondary" onClick={handleResetClick}>
          Reset
        </Button>
        <Button
          size="small"
          onClick={handleApplyClick}
          isDisabled={typeof currentYear === 'undefined' || typeof currentMonth === 'undefined' || currentMonth < 0}
        >
          Apply
        </Button>
      </PopoverAction>
    </Wrapper>
  );
};
