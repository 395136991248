import React, { createRef, forwardRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface LoadingProps {
  text?: string;
  size?: string;
  smallSpace?: boolean;
  className?: string;
}

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 25%;
`;

const Animate = styled.div<{ $start: boolean; $size: string }>`
  --duration: 1600ms;

  white-space: nowrap;
  user-select: none;
  font-weight: 500;
  transform: translate(-50%, var(--loading-top, -50%));
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  ${({ $size }) =>
    $size &&
    css`
      font-size: ${$size};
    `}

  span {
    --x: 0;
    --y: 0;
    --move-y: 0;
    --move-y-s: 0;
    --delay: 0ms;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    text-indent: calc(var(--x) * -1);
    overflow: hidden;
    transform: translate(var(--x), var(--y)) translateZ(0);
  }

  ${({ $start }) =>
    $start &&
    css`
      div {
        opacity: 0;
      }
      span {
        animation: move var(--duration) ease-in-out var(--delay);
      }
    `}

  @keyframes move {
    30% {
      transform: translate(var(--x), var(--move-y));
    }
    82% {
      transform: translate(var(--x), var(--move-y-s));
    }
  }
`;

const Inner = styled.div``;

export const Loading = forwardRef<HTMLDivElement, LoadingProps>((props, ref) => {
  const [start, setStart] = useState<boolean>(false);

  const size = props.size ?? '14px';

  const animateRef = createRef<HTMLDivElement>();

  const startAnimation = (elem: HTMLDivElement) => {
    setStart(false);
    setCSSVars(
      elem,
      props.smallSpace ? 8 : undefined,
      props.smallSpace ? 32 : undefined,
      props.smallSpace ? 4 : undefined,
      props.smallSpace ? 8 : undefined
    );
    void elem.offsetWidth;
    setStart(true);
  };

  const setCSSVars = (
    elem: HTMLDivElement,
    min: number = 12,
    max: number = 44,
    minMove: number = 8,
    maxMove: number = 16
  ) => {
    const width = Math.ceil(elem.offsetWidth);
    const text = elem.textContent;

    for (let i = 1; i < width; i++) {
      const num = Math.floor(Math.random() * (max - min + 1)) + min,
        numMove = Math.floor(Math.random() * (maxMove - minMove + 1)) + minMove,
        dir = i % 2 === 0 ? 1 : -1,
        spanCurrent = elem.querySelectorAll('span')[i],
        span = (spanCurrent ? spanCurrent : document.createElement('span')) as HTMLSpanElement;

      span.style.setProperty('--x', `${i - 1}px`);
      span.style.setProperty('--move-y', `${num * dir}px`);
      span.style.setProperty('--move-y-s', `${i % 2 === 0 ? num * dir - numMove : num * dir + numMove}px`);
      span.style.setProperty('--delay', `${i * 10}ms`);
      if (!spanCurrent) {
        span.textContent = text;
        elem.appendChild(span);
      }
    }
  };

  useEffect(() => {
    if (!animateRef.current) {
      return;
    }

    const animateDiv = animateRef.current;

    startAnimation(animateDiv);

    ['animationend', 'webkitAnimationEnd', 'oAnimationEnd'].forEach((evt) =>
      animateDiv.querySelector('span:last-child')?.addEventListener(evt, () => startAnimation(animateDiv), false)
    );

    return () => {
      ['animationend', 'webkitAnimationEnd', 'oAnimationEnd'].forEach((evt) =>
        animateDiv.querySelector('span:last-child')?.removeEventListener(evt, () => startAnimation(animateDiv), false)
      );
    };
  }, []);

  return (
    <Wrapper className={props.className} ref={ref}>
      <Animate ref={animateRef} $start={start} $size={size}>
        <Inner>{props.text ?? 'Loading'}</Inner>
      </Animate>
    </Wrapper>
  );
});
