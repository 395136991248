import React from 'react';
import styled from 'styled-components';

interface GoogleDisclaimerProps {
  className?: string;
}

const Text = styled.p`
  margin: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  bottom: 24px;
  font-size: 10px;
  width: 100%;
  max-width: 260px;
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};

  a {
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.secondary.background};
    }
  }
`;

export const GoogleDisclaimer: React.FC<GoogleDisclaimerProps> = (props) => (
  <Text className={props.className}>
    This site is protected by reCAPTCHA and the Google{' '}
    <a target="_blank" href="https://policies.google.com/privacy" rel="noreferrer">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a target="_blank" href="https://policies.google.com/terms" rel="noreferrer">
      Terms of Service
    </a>{' '}
    apply.
  </Text>
);
