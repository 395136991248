import { client } from './client';

export const PlaidParams = {
  response_type: undefined,
  redirect_uri: undefined,
  scope: undefined,
  client_id: undefined,
  state: undefined,
} as const;

export type PlaidParamsType = typeof PlaidParams;

interface GetTempCodeResponse {
  platformId: string;
  dashboardUserId: string;
  tempCode: string;
}

export type PlaidErrorType =
  | 'invalid_request'
  | 'unauthorized_client'
  | 'access_denied'
  | 'unsupported_response_type'
  | 'invalid_scope'
  | 'server_error'
  | 'temporarily_unavailable';

export interface PlaidRedirectUrlType {
  code?: string;
  error?: PlaidErrorType;
}

export const handlePlaidRedirectUrl = (params: PlaidParamsType, object: PlaidRedirectUrlType) => {
  if (!params.redirect_uri || !params.state) {
    return false;
  }

  const url = new URL(params.redirect_uri);

  url.searchParams.set('state', params.state);

  if (object.error) {
    url.searchParams.set('error', object.error);
  }

  if (object.code) {
    url.searchParams.set('code', object.code);
  }

  window.location.replace(url);
};

export class PlaidRepository {
  static async getTempCode(platformId?: string) {
    return client.post<object, GetTempCodeResponse>(
      '/plaid/auth/oauth2/temp-code',
      {},
      {
        sandbox: false,
        platformId,
      }
    );
  }
}
