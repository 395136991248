import {
  ClearLoanDisbursementRequest,
  CreateLoanDisbursementRequest,
  CreateLoanPaymentRequest,
  CreateLoanRequest,
  LendingServiceGetDisbursementsParams,
  LendingServiceGetPaymentsParams,
  LendingServiceListLoansParams,
  Loan,
  LoanDisbursement,
  LoanPayment,
  UpdateLoanDisbursementRequest,
  UpdateLoanRequest,
} from '~/typings/API';
import { convertValues } from '~/util';

import { client } from './client';

interface ListLoansResponse {
  loans: Loan[];
  hasMore: boolean;
}

interface ListLoansPaymentsResponse {
  payments: LoanPayment[];
  hasMore: boolean;
}

interface ListLoansDisbursementResponse {
  disbursements: LoanDisbursement[];
  hasMore: boolean;
}

export type { Loan, LoanPayment, LoanDisbursement };

export class LoanRepository {
  static async create(request: CreateLoanRequest) {
    return client.post<CreateLoanRequest, Loan>('/loans', request).then((response) => convertValues<Loan>(response));
  }

  static async getAll(query?: LendingServiceListLoansParams) {
    return client.get<LendingServiceListLoansParams, ListLoansResponse>('/loans', { query }).then((response) => ({
      loans: Array.isArray(response?.loans) ? response.loans.map((e) => convertValues<Loan>(e)) : [],
      hasMore: Boolean(response?.hasMore),
    }));
  }

  static async get(id: string) {
    return client.get<any, Loan>(`/loans/${id}`).then((response) => convertValues<Loan>(response));
  }

  static async update(id: string, request: UpdateLoanRequest) {
    return client
      .patch<UpdateLoanRequest, Loan>(`/loans/${id}`, request)
      .then((response) => convertValues<Loan>(response));
  }

  static async createDisbursement(request: CreateLoanDisbursementRequest) {
    return client
      .post<CreateLoanDisbursementRequest, LoanDisbursement>('/loans/disbursements', request)
      .then((response) => convertValues<LoanDisbursement>(response));
  }

  static async updateDisbursement(id: string, request: UpdateLoanDisbursementRequest) {
    return client
      .patch<UpdateLoanDisbursementRequest, LoanDisbursement>(`/loans/disbursements/${id}`, request)
      .then((response) => convertValues<LoanDisbursement>(response));
  }

  static async clearDisbursement(id: string, request: ClearLoanDisbursementRequest) {
    return client
      .post<ClearLoanDisbursementRequest, LoanDisbursement>(`/loans/disbursements/${id}/clear`, request)
      .then((response) => convertValues<LoanDisbursement>(response));
  }

  static async cancelDisbursement(id: string) {
    return client
      .post<any, LoanDisbursement>(`/loans/disbursements/${id}/cancel`, {})
      .then((response) => convertValues<LoanDisbursement>(response));
  }

  static async getDisbursement(id: string) {
    return client
      .get<any, LoanDisbursement>(`/loans/disbursements/${id}`)
      .then((response) => convertValues<LoanDisbursement>(response));
  }

  static async getAllDisbursements(query?: LendingServiceGetDisbursementsParams) {
    return client
      .get<LendingServiceGetDisbursementsParams, ListLoansDisbursementResponse>('/loans/disbursements', { query })
      .then((response) => ({
        disbursements: Array.isArray(response?.disbursements)
          ? response.disbursements.map((e) => convertValues<LoanDisbursement>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async createPayment(request: CreateLoanPaymentRequest) {
    return client
      .post<CreateLoanPaymentRequest, LoanPayment>('/loans/payments', request)
      .then((response) => convertValues<LoanPayment>(response));
  }

  static async getPayment(id: string) {
    return client
      .get<unknown, LoanPayment>(`/loans/payments/${id}`)
      .then((response) => convertValues<LoanPayment>(response));
  }

  static async getAllPayments(query?: LendingServiceGetPaymentsParams) {
    return client
      .get<LendingServiceGetPaymentsParams, ListLoansPaymentsResponse>('/loans/payments', { query })
      .then((response) => ({
        payments: Array.isArray(response?.payments) ? response.payments.map((e) => convertValues<LoanPayment>(e)) : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }
}
