import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import React, { ReactNode, createRef, useEffect, useState, cloneElement, ReactElement, MouseEvent } from 'react';
import { NavLink, matchPath, useLocation, useMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Icon, ToggleHeight, Tooltip, useDidMount } from '@column/column-ui-kit';

import { useSessionStore } from '~/stores/Session';

gsap.registerPlugin(MorphSVGPlugin);

interface SubmenuEntryProps {
  label: string;
  path: string;
  activePath?: string;
  isSandboxOnly?: boolean;
}

interface MenuItemProps {
  label: string;
  icon: ReactNode;
  path: string;
  activePath?: string;
  moveDot?: boolean;
  exact?: boolean;
  entries?: SubmenuEntryProps[];
  isSandbox?: boolean;
}

const MenuItemWrapper = styled.div`
  position: relative;
`;

const Entry = styled(NavLink)<{ $isActive: boolean; $isParent: boolean }>`
  color: ${({ theme }) => theme.secondary.background};
  text-decoration: none;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  transition:
    background-color 0.1s,
    box-shadow 0.1s,
    color 0.1s;

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    --icon-background: ${({ theme }) => theme.secondary.blendToBackground(50)};

    path {
      transition:
        fill 0.1s,
        stroke 0.1s;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
    background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};

    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
      --icon-background: ${({ theme }) => theme.secondary.blendToBackground(150)};
    }
  }

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      svg {
        --icon-background: ${theme.background};
      }

      &:hover {
        color: ${theme.secondary.blendToBackground(1300)};
        background-color: ${theme.secondary.blendToBackground(50)};

        svg {
          --icon-color: ${theme.secondary.blendToBackground(1300)};
          --icon-background: ${theme.secondary.blendToBackground(50)};
        }
      }
    `};

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.blue.background};
      background-color: ${({ theme }) => theme.primary.blendToBackground(300)};

      svg {
        --icon-color: ${({ theme }) => theme.blue.background};
        --icon-background: ${({ theme }) => theme.primary.blendToBackground(300)};
      }

      &:hover {
        color: ${({ theme }) => theme.blue.background};
        background-color: ${({ theme }) => theme.primary.blendToBackground(300)};

        svg {
          --icon-color: ${({ theme }) => theme.blue.background};
          --icon-background: ${({ theme }) => theme.primary.blendToBackground(300)};
        }
      }

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          background-color: ${theme.primary.blendToBackground(1000, 200)};
          color: ${theme.primary.blendToBackground(1300)};

          svg {
            --icon-background: ${theme.primary.blendToBackground(100)};
            --icon-color: ${theme.primary.blendToBackground(1300)};
          }

          &:hover {
            background-color: ${theme.primary.blendToBackground(1000, 200)};
            color: ${theme.primary.blendToBackground(1300)};

            svg {
              --icon-background: ${theme.primary.blendToBackground(100)};
              --icon-color: ${theme.primary.blendToBackground(1300)};
            }
          }
        `}
    `}

  ${({ $isActive, $isParent }) =>
    $isActive &&
    $isParent &&
    css`
      background: transparent;

      svg {
        --icon-background: ${({ theme }) => theme.secondary.blendToBackground(100)};
      }

      &:hover {
        background: transparent;

        svg {
          --icon-background: ${({ theme }) => theme.secondary.blendToBackground(100)};
        }
      }

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          svg {
            --icon-background: ${theme.background};
          }

          &:hover {
            svg {
              --icon-background: ${theme.background};
            }
          }
        `}
    `};
`;

const Arrow = styled.button<{ isOpen?: boolean }>`
  appearance: none;
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
  pointer-events: none;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    path {
      transition: stroke 0.1s;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        --icon-color: ${({ theme }) => theme.primary.background};
      }

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          svg {
            --icon-color: ${theme.primary.blendToBackground(1300)};
          }
        `}
    `}
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SubmenuWrapper = styled.div`
  position: relative;
  display: grid;
  grid-gap: 2px;
  padding: 2px 0 0 24px;
`;

const SubmenuEntry = styled(NavLink)<{ $isActive: boolean; $isDisabled?: boolean }>`
  display: block;
  padding: 8px 12px;
  border-radius: 8px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.1s;
  color: ${({ theme }) => theme.secondary.blendToBackground(900)};

  &:hover {
    color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
    background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
  }

  ${({ $isActive, $isDisabled }) =>
    !$isActive &&
    !$isDisabled &&
    css`
      &:hover {
        color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
      }
    `}

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      &:hover {
        color: ${theme.secondary.blendToBackground(1300)};
        background-color: ${theme.secondary.blendToBackground(50)};
      }
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.blue.background};
      background-color: ${({ theme }) => theme.primary.blendToBackground(200)};

      &:hover {
        color: ${({ theme }) => theme.blue.background};
        background-color: ${({ theme }) => theme.primary.blendToBackground(200)};
      }

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          background-color: ${theme.primary.blendToBackground(1000, 200)};
          color: ${theme.primary.blendToBackground(1300)};

          &:hover {
            background-color: ${theme.primary.blendToBackground(1000, 200)};
            color: ${theme.primary.blendToBackground(1300)};
          }
        `}
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.secondary.blendToBackground(600)};
    `}
`;

const Lock = styled(Icon.Lock)`
  --icon-size: 14px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  display: inline-block;
`;

const StyledToggleHeight = styled(ToggleHeight)`
  ${SubmenuEntry} {
    display: flex;
    align-items: center;

    span {
      flex: auto;
    }
  }
`;

export const MenuItem: React.FC<MenuItemProps> = (props) => {
  const isSandbox = useSessionStore((state) => state.isSandbox);
  const matchPathString = props.activePath ?? props.path;

  const location = useLocation();
  const didMount = useDidMount();
  const isMatch = useMatch(matchPathString);
  const isMatchChild = useMatch(`${matchPathString}/*`);
  const activeWrapperRef = createRef<HTMLDivElement>();
  const activeDotRef = createRef<SVGSVGElement>();
  const [isOpen, setIsOpen] = useState<boolean>();
  const [prevPath, setPrevPath] = useState<string>('');
  const [triggerHover, setTriggerHover] = useState<boolean>(false);

  const activeFromArray = (base: string, paths: (string | undefined)[]) => {
    return (
      paths
        .map((path: string | undefined) => {
          return (
            path &&
            matchPath(
              {
                path,
              },
              base
            )
          );
        })
        .filter((p) => p).length >= 1
    );
  };

  useEffect(() => {
    if (!props.entries || !didMount) {
      return;
    }
    const index = props.entries.findIndex((e) => {
      return activeFromArray(location.pathname, [e.path, e.activePath, `${e.activePath}/*`]);
    });
    gsap.to(activeWrapperRef.current, {
      y: props.moveDot ? (props.entries.length - index) * 24 + 7 : 0,
      duration: 0.33,
      delay: props.moveDot ? 0 : 0.1,
    });
    if (activeDotRef.current?.querySelector('path')) {
      gsap.set(activeDotRef.current, {
        scaleY: props.moveDot ? 1 : -1,
      });
      dotAnimate(activeDotRef.current?.querySelector('path'), 0.4, props.moveDot ? 0 : 0.2);
    }
  }, [props.moveDot]);

  const dotAnimate = (path: SVGPathElement | null, duration: number = 0.45, delay: number = 0) => {
    gsap.to(path, {
      duration,
      delay,
      keyframes: [
        {
          morphSVG:
            'M8 5C8 6.65685 6.65685 8 5 8C3.34315 8 2 6.65685 2 5C2 3.34315 4.34315 0 5 0C5.65685 0 8 3.34315 8 5Z',
        },
        {
          morphSVG:
            'M8 5C8 6.65685 6.65685 8 5 8C3.34315 8 2 6.65685 2 5C2 3.34314 3.34315 4 5 4C6.65685 4 8 3.34314 8 5Z',
        },
        {
          morphSVG:
            'M8 5C8 6.65685 6.65685 8 5 8C3.34315 8 2 6.65685 2 5C2 3.34315 3.34315 2 5 2C6.65685 2 8 3.34315 8 5Z',
        },
      ],
    });
  };

  useEffect(() => {
    if (!props.entries) {
      return;
    }

    const openWithChild = isMatch !== null || (isMatchChild !== null && !props.exact);

    setIsOpen(openWithChild);

    if (openWithChild) {
      const index = props.entries.findIndex((e) => {
        return activeFromArray(location.pathname, [e.path, e.activePath, `${e.activePath}/*`]);
      });
      const prevIndex = prevPath
        ? props.entries.findIndex((e) => {
            return activeFromArray(prevPath, [e.path, e.activePath, `${e.activePath}/*`]);
          })
        : 0;
      setPrevPath(location.pathname);
      if (index === prevIndex) {
        return;
      }
      if (didMount) {
        if (activeDotRef.current?.querySelector('path')) {
          gsap.set(activeDotRef.current, {
            scaleY: prevIndex > index ? -1 : 1,
          });
          dotAnimate(activeDotRef.current?.querySelector('path'));
        }
      }
    }
  }, [location]);

  return (
    <MenuItemWrapper>
      <Entry
        to={{ pathname: props.path, search: location.pathname === props.path ? location.search : undefined }}
        $isActive={isMatch !== null || (isMatchChild !== null && !props.exact)}
        onClick={() => {
          if (!triggerHover) {
            setTriggerHover(true);
          }
        }}
        $isParent={!!props.entries}
      >
        <Label>
          {cloneElement(props.icon as ReactElement, {
            running: triggerHover,
            onDone: () => setTriggerHover(false),
          })}
          {props.label}
        </Label>
      </Entry>
      {props.entries && (
        <Arrow isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
        </Arrow>
      )}
      {props.entries && (
        <StyledToggleHeight isClose={!isOpen}>
          <SubmenuWrapper>
            {props.entries.map((entry: SubmenuEntryProps, index: number) => {
              const isDisabled = entry.isSandboxOnly && !isSandbox;
              const Element = (
                <SubmenuEntry
                  key={index}
                  to={entry.path}
                  $isActive={activeFromArray(location.pathname, [
                    entry.path,
                    entry.activePath,
                    `${entry.activePath}/*`,
                  ])}
                  $isDisabled={entry.isSandboxOnly && !isSandbox}
                  onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                    if (isDisabled) {
                      event.preventDefault();
                    }
                  }}
                >
                  <span>{entry.label}</span>
                  {isDisabled && <Lock />}
                </SubmenuEntry>
              );

              if (isDisabled) {
                return (
                  <Tooltip
                    key={index}
                    content={
                      <>
                        Only available in
                        <br />
                        sandbox mode
                      </>
                    }
                    zIndex={10}
                  >
                    {Element}
                  </Tooltip>
                );
              }

              return Element;
            })}
          </SubmenuWrapper>
        </StyledToggleHeight>
      )}
    </MenuItemWrapper>
  );
};
