import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Dropdown, Icon, Tooltip } from '@column/column-ui-kit';

import { Access, OptionsFullGranular, Permissions } from '../types';
import { getAccessLevel } from '~/app/pages/Platform/Roles/utils';
import { Breadcrumb, FormField, Modal, ModalSize, ModalVariant } from '~/components';
import { Divider, Inner } from '~/styles';

import { PermissionToggles } from './PermissionToggles';

const ClearOverridesButton = styled(Button)`
  padding: 0;
`;

const CustomOverridesModalControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const Wrapper = styled.div`
  width: calc(100vw - 48px);
  max-width: 800px;
`;

interface CustomOverridesModalProps {
  breadcrumbs: string[];
  open: boolean;
  onClose: () => void;
  onSave: (permissions: Permissions) => void;
  currentPermissions: Permissions;
  parentPermissions: Permissions;
  permissionsToLabelsMap: { [permission: string]: string };
}
export const CustomOverridesModal: React.FC<CustomOverridesModalProps> = ({
  breadcrumbs,
  currentPermissions,
  open,
  onClose,
  onSave,
  parentPermissions,
  permissionsToLabelsMap,
}) => {
  const permissionToLabelsMapWithoutBankAccount = useMemo(() => {
    const p = { ...permissionsToLabelsMap };
    delete p.bankAccounts;
    return p;
  }, [permissionsToLabelsMap]);

  const parentAccessLevel = useMemo(() => {
    const parentPermissionsInModal = Object.keys(permissionsToLabelsMap).reduce(
      (o, permission) => ({
        ...o,
        [permission]: parentPermissions[permission],
      }),
      {}
    );
    return getAccessLevel(parentPermissionsInModal);
  }, [parentPermissions]);
  const effectivePermissions = useMemo(() => {
    return Object.keys(permissionsToLabelsMap).reduce(
      (o, permission) => ({
        ...o,
        [permission]:
          currentPermissions[permission] !== 'none'
            ? currentPermissions[permission]
            : parentPermissions[permission] !== 'none'
              ? parentPermissions[permission]
              : 'read',
      }),
      {}
    );
  }, [currentPermissions, parentPermissions]);
  const form = useForm<Permissions>({
    values: effectivePermissions,
  });
  const { control, getValues, setValue, reset } = form;
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  const resetOverrides = useCallback(() => {
    Object.keys(permissionsToLabelsMap).forEach((permission) => {
      setValue(permission, parentPermissions[permission]);
    });
  }, [setValue, parentPermissions]);

  return (
    <Modal
      headline="Custom overrides"
      open={open}
      onClose={onClose}
      primaryButton={{
        text: 'Save Changes',
        onClick: () => onSave(getValues()),
      }}
      secondaryButton={{ text: 'Cancel', onClick: onClose }}
      size={ModalSize.FitContent}
      variant={ModalVariant.Compact}
    >
      <Wrapper>
        <CustomOverridesModalControls>
          <Breadcrumb entries={breadcrumbs.map((breadcrumb) => ({ label: breadcrumb }))} />
          <Tooltip
            isOpen={parentAccessLevel !== Access.NoAccess ? false : undefined}
            content={`Overrides cannot be reset since there is no access to ${breadcrumbs[breadcrumbs.length - 1]}.`}
            zIndex={100}
          >
            <ClearOverridesButton
              isDisabled={parentAccessLevel === Access.NoAccess}
              icon={<Icon.Reset />}
              iconRight
              size={'small'}
              variant="subtle-primary"
              onClick={() => resetOverrides()}
            >
              Clear overrides
            </ClearOverridesButton>
          </Tooltip>
        </CustomOverridesModalControls>
        <PermissionToggles control={control} permissionsToLabelsMap={permissionToLabelsMapWithoutBankAccount} />
        <Inner px={0}>
          <Divider fullWidth />
        </Inner>
        <FormProvider {...form}>
          <FormField id="bankAccounts" label="Bank Accounts on Entity" compact>
            {({ value, onChange }) => {
              return <Dropdown active={value} options={OptionsFullGranular} onChange={onChange} />;
            }}
          </FormField>
        </FormProvider>
      </Wrapper>
    </Modal>
  );
};
