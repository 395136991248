import React, { FC, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Chip, Fade, formatNumber, ToggleHeight } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { PageHeader, CopyInput, ImageLoading, SectionHeader } from '~/components';
import { LogoLoading } from '~/elements';
import { useBankAccount } from '~/hooks';
import { useTransfer } from '~/hooks/useTransfers';
import {
  CheckRepository,
  CheckTransfer,
  CheckTransferImages,
  CheckReturnsList,
  transferStatusTooltips,
} from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { checkReturnCodesMap } from '~/repositories/Transfer/CheckReturnCodes';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { EditSectionInfo, FormElement, FormLabel, Line, FormText, Inner, Grid } from '~/styles';
import { CheckReturn } from '~/typings/API';
import { formatString, generateFields, extractAccountAndCheckNumber } from '~/util';

import {
  AccountInformation,
  AmountSummary,
  AmountInfo,
  AmountText,
  Timeline,
  TimelineArea,
  TimelineEntryProps,
  TransferObjectWrapper,
  TransferSummary,
  TransferObject,
  loopObject,
  TransferParty,
  Label,
  PartyName,
  TransferSource,
  Arrow,
  TimelineEntry,
  ReturnTimelineEntryIcon,
  TimelineEntryText,
  TimelineEntryLabel,
  ReturnTimelineEntryDate,
} from './View';

interface Params {
  id: string;
}

const checkTypes: TimelineEntryProps[] = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Deposited',
    field: 'depositedAt',
  },
  {
    label: 'Pending deposit',
    field: 'PendingDepositAt',
  },
  {
    label: 'Settled',
    field: 'settledAt',
  },
  {
    label: 'Pending first return',
    field: 'pendingFirstReturnAt',
  },
  {
    label: 'First return',
    field: 'firstReturnAt',
  },
  {
    label: 'Reclear',
    field: 'reclearAt',
  },
  {
    label: 'Second return',
    field: 'secondReturnAt',
  },
  {
    label: 'Stopped',
    field: 'stoppedAt',
  },
  {
    label: 'Pending user initiated return',
    field: 'pendingUserInitiatedReturnAt',
  },
  {
    label: 'User initiated returned',
    field: 'userInitiatedReturnedAt',
  },
  {
    label: 'Returned',
    field: 'returnedAt',
  },
];

const checkReturnEvents: TimelineEntryProps[] = [
  {
    label: 'Created',
    field: 'createdAt',
  },
  {
    label: 'Pending review',
    field: 'pendingReviewAt',
  },
  {
    label: 'Scheduled',
    field: 'scheduledAt',
  },
  {
    label: 'Processed',
    field: 'processedAt',
  },
  {
    label: 'Cancelled',
    field: 'cancelledAt',
  },
];

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const Tags = styled.div`
  display: flex;
  gap: 12px;
`;

/*
  Only going to show the most recent return in this section for now
  Feel it could be confusing for dashboard users to see multiple returns
  with varying states. Most recent (2nd) return, if it exists, is the most
  relevant. We can adjust this if we get feedback that customers want to see
  details on both returns in the dashboard.
*/
const CheckReturnSection: React.FC<{ checkReturns: Partial<CheckReturnsList> }> = (props) => {
  const mostRecentReturn = props.checkReturns?.returns?.at(-1);
  const createdAt = new Date(mostRecentReturn?.createdAt?.toString() ?? '');

  return (
    <>
      <SectionHeader text="Return" border />
      <Inner pt={0}>
        <Timeline>
          {checkReturnEvents.map((event: TimelineEntryProps) => {
            const date = mostRecentReturn?.[event.field as keyof CheckReturn];
            if (!date) {
              return null;
            }
            const eventDate = new Date(date);

            return (
              <TimelineEntry key={event.field}>
                <ReturnTimelineEntryIcon />
                <TimelineEntryText>
                  <TimelineEntryLabel>{event.label}</TimelineEntryLabel>
                </TimelineEntryText>
                <ReturnTimelineEntryDate>
                  {eventDate.getDate()} {eventDate.toLocaleString('en-US', { month: 'long' })} {eventDate.getFullYear()}
                  , {eventDate.toLocaleString('en-US', { weekday: 'long' })} -{' '}
                  {eventDate.toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </ReturnTimelineEntryDate>
              </TimelineEntry>
            );
          })}
        </Timeline>
        <Line />
      </Inner>
      <Inner pt={0}>
        <Grid>
          <FormElement>
            <FormLabel>Created</FormLabel>
            <FormText>
              {createdAt.toLocaleString('en-US', { weekday: 'long' })},{' '}
              {createdAt.toLocaleString('en-US', { month: 'long' })} {createdAt.getDate()}, {createdAt.getFullYear()}
              <br />
              {createdAt.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
            </FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Status</FormLabel>
            <FormText>
              <Chip>{formatString(mostRecentReturn?.status ?? '-')}</Chip>
            </FormText>
          </FormElement>
          <FormElement>
            <FormLabel>Reason</FormLabel>
            <Chip>{mostRecentReturn?.returnReason}</Chip>
            <FormText>
              {checkReturnCodesMap.has(String(mostRecentReturn?.returnReason))
                ? checkReturnCodesMap.get(String(mostRecentReturn?.returnReason))
                : mostRecentReturn?.returnReason}
            </FormText>
          </FormElement>
        </Grid>
      </Inner>
    </>
  );
};

export const PageTransfersCheck: FC = () => {
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const { response: bankAccount, createRequest: fetchBankAccount } = useBankAccount({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });
    },
  });

  const {
    response: transfer,
    createRequest: fetchCheck,
    isLoading,
  } = useTransfer<CheckTransfer>('check')({
    initialParams: { id },
    onError: (error) => {
      addDangerNotification({
        content: error.message,
      });

      navigate(ROUTE.TRANSFERS);
    },
    onSuccess: (response) => {
      fetchAccountNumber(response.accountNumberId);

      fetchBankAccount({ id: response.bankAccountId });

      CheckRepository.getImages(id)
        .then(async (images) => {
          setTransferImages({
            frontImageJpeg: `data:image/jpeg;base64,${images.frontImageJpeg}`,
            backImageJpeg: `data:image/jpeg;base64,${images.backImageJpeg}`,
          });
        })
        .catch(() => {
          setTransferImages({
            frontImageJpeg: undefined,
            backImageJpeg: undefined,
          });
        });

      CheckRepository.listReturns(id)
        .then((returns) => {
          if (returns.returns && returns.returns.length > 0) {
            setCheckReturnsList(returns);
          }
        })
        .catch(() => {
          setCheckReturnsList({
            returns: undefined,
          });
        });
    },
  });
  const [transferImages, setTransferImages] = useState<CheckTransferImages>({});
  const [accountNumber, setAccountNumber] = useState<any>({});
  const [checkReturnsList, setCheckReturnsList] = useState<Partial<CheckReturnsList>>({});

  const handleReturn = () => {
    openModal('CheckReturn', {
      checkTransferId: id,
      callback: fetchCheck,
    });
  };

  const handleStop = () => {
    if (!transfer?.id) {
      return;
    }

    CheckRepository.stopPayment(transfer.id)
      .then(() => {
        fetchCheck();

        addSuccessNotification({
          content: 'Stop payment created',
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchAccountNumber = (accountNumberId?: string) => {
    if (!accountNumberId) {
      return;
    }

    BankAccountRepository.getAccountNumber({ id: accountNumberId })
      .then((response: any) => {
        setAccountNumber(response);
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });

        navigate(ROUTE.TRANSFERS);
      });
  };

  const { accountNumber: checkAccountNumber, checkNumber } = useMemo(
    () => extractAccountAndCheckNumber(transfer?.micrLine.onUs ?? '', transfer?.micrLine.auxiliaryOnUs ?? ''),
    [transfer?.micrLine.onUs, transfer?.micrLine.auxiliaryOnUs]
  );

  const updatedAt = transfer?.updatedAt as unknown as Date;

  return (
    <>
      <StyledFade show={isLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading}>
        <PageHeader text="Check">
          {(transfer?.status === 'issued' || transfer?.status === 'manual_review') && (
            <Button variant="danger" size="small" onClick={handleStop}>
              Stop Payment
            </Button>
          )}
          {transfer?.status === 'settled' && (
            <Button variant="secondary" onClick={handleReturn} size="small">
              Return
            </Button>
          )}
        </PageHeader>

        <AmountSummary>
          <AmountInfo>
            <AmountText>
              {formatNumber(
                transfer?.type?.toUpperCase() === 'DEBIT' ? transfer?.positivePayAmount : transfer?.depositedAmount
              )}
            </AmountText>
            <Tags>
              {transfer?.status && (
                <Chip
                  tooltip={
                    transferStatusTooltips.has(transfer.status)
                      ? {
                          content: transferStatusTooltips.get(transfer.status),
                          delay: 200,
                          triggerClick: false,
                        }
                      : undefined
                  }
                  type={
                    ['stopped'].includes(transfer.status)
                      ? 'danger'
                      : ['deposited'].includes(transfer.status)
                        ? 'success'
                        : 'default'
                  }
                >
                  {formatString(transfer.status)}
                </Chip>
              )}
              {transfer?.type && <Chip>{formatString(transfer.type)}</Chip>}
            </Tags>
          </AmountInfo>
          <FormElement>
            <FormLabel>ID</FormLabel>
            <CopyInput value={id} />
          </FormElement>
        </AmountSummary>

        <ToggleHeight
          isClose={
            typeof checkReturnsList?.returns === undefined ||
            (Array.isArray(checkReturnsList?.returns) && checkReturnsList.returns.length === 0)
          }
        >
          {checkReturnsList.returns && <CheckReturnSection checkReturns={checkReturnsList} />}
        </ToggleHeight>

        <SectionHeader text="Information" border />

        <Inner py={0}>
          <TransferSummary>
            <TransferSource>
              {transfer?.bankAccountId && (
                <>
                  <TransferParty>
                    <Label>Bank Account</Label>
                    <PartyName>
                      <a href={`${ROUTE.BANK_ACCOUNTS}/edit/${transfer.bankAccountId}`}>
                        {bankAccount?.displayName ? bankAccount?.displayName : bankAccount?.description}
                      </a>
                    </PartyName>
                  </TransferParty>

                  <FormElement newRow>
                    <FormLabel>Bank Account ID</FormLabel>
                    <CopyInput value={transfer.bankAccountId} />
                  </FormElement>
                </>
              )}
              {transfer?.accountNumberId && (
                <>
                  {accountNumber.routingNumber && accountNumber.accountNumber && (
                    <AccountInformation>
                      <FormElement>
                        <FormLabel>Routing Number</FormLabel>
                        <CopyInput value={accountNumber.routingNumber} />
                      </FormElement>
                      <FormElement>
                        <FormLabel>Account Number</FormLabel>
                        <CopyInput value={accountNumber.accountNumber} />
                      </FormElement>
                    </AccountInformation>
                  )}
                </>
              )}
              {transfer?.status === 'issued' ? <Arrow variant="right" /> : <Arrow variant="left" />}
            </TransferSource>

            <TransferParty>
              <Label>Payee Name</Label>
              <PartyName>{(transfer?.payeeName ?? transfer?.description) || '-'}</PartyName>
            </TransferParty>

            <FormElement fullWidth as={Line} />
            {!!transfer &&
              generateFields<CheckTransfer>(
                [
                  {
                    type: 'text',
                    label: 'Description',
                    value: 'description',
                    newRow: true,
                  },
                  {
                    type: 'text',
                    label: 'Idempotency Key',
                    value: 'idempotencyKey',
                  },
                ],
                transfer,
                undefined
              )}

            {transfer?.type?.toUpperCase() === 'DEBIT' && (
              <FormElement>
                <FormLabel>Positive pay amount</FormLabel>
                <FormText>{formatNumber(transfer.positivePayAmount)}</FormText>
              </FormElement>
            )}
          </TransferSummary>
        </Inner>

        <SectionHeader text="MICR line" border />
        <Inner pt={16}>
          <Grid>
            {!!transfer &&
              generateFields<CheckTransfer>(
                [
                  {
                    type: 'text',
                    label: 'Payor Bank Routing Number',
                    value: 'payorBankRoutingNumber',
                  },
                  {
                    type: 'text',
                    label: 'Auxiliary On Us',
                    value: 'auxiliaryOnUs',
                  },
                  {
                    type: 'text',
                    label: 'On Us',
                    value: 'onUs',
                  },
                ],
                transfer,
                undefined,
                'micrLine'
              )}

            <FormElement>
              <FormLabel>Account Number</FormLabel>
              <FormText>{checkAccountNumber || '-'}</FormText>
            </FormElement>

            <FormElement>
              <FormLabel>Check Number</FormLabel>
              <FormText>{checkNumber || '-'}</FormText>
            </FormElement>

            <FormElement>
              <FormLabel>External Processing Code</FormLabel>
              <FormText>{transfer?.micrLine.externalProcessingCode || '-'}</FormText>
            </FormElement>
          </Grid>
        </Inner>

        <SectionHeader text="Events" border={false}>
          {transfer?.updatedAt && (
            <EditSectionInfo>
              <small>Updated</small>
              {updatedAt.toLocaleString('en-US', { weekday: 'long' })},{' '}
              {updatedAt.toLocaleString('en-US', { month: 'long' })} {updatedAt.getDate()}, {updatedAt.getFullYear()} —{' '}
              {updatedAt.toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })}
            </EditSectionInfo>
          )}
        </SectionHeader>
        <Inner pt={16}>
          <Timeline>
            {checkTypes.map((entry) => (
              <TimelineArea key={entry.field} transfer={transfer} entry={entry} />
            ))}
          </Timeline>
        </Inner>

        <SectionHeader text="Images" border />
        <Inner pt={16}>
          <Grid>
            <FormElement>
              <FormLabel>Front</FormLabel>
              <ImageLoading
                base64String={transferImages.frontImageJpeg}
                errorMessage="Front image could not be loaded"
              />
            </FormElement>
            <FormElement>
              <FormLabel>Back</FormLabel>
              <ImageLoading base64String={transferImages.backImageJpeg} errorMessage="Back image could not be loaded" />
            </FormElement>
          </Grid>
        </Inner>

        <SectionHeader text="Transfer Details" border />
        <Inner pt={16}>
          <TransferObjectWrapper>
            <TransferObject>{transfer && loopObject(transfer)}</TransferObject>
          </TransferObjectWrapper>
        </Inner>
      </Fade>
    </>
  );
};
