import gsap from 'gsap';

export const extractAccountAndCheckNumber = (onUs: string, auxiliaryOnUs: string) => {
  const onUsTrimmed = onUs.trim();
  const auxiliaryOnUsTrimmed = auxiliaryOnUs.trim();

  const numberRegex = /(\d[-\d]*\d|\d)/g;

  let accountNumber = '';
  let checkNumber = '';

  if (auxiliaryOnUsTrimmed) {
    accountNumber = onUsTrimmed.replaceAll('/', '');
    checkNumber = auxiliaryOnUsTrimmed;
  } else {
    const hasSpace = onUsTrimmed.includes(' ');

    if (hasSpace) {
      const parts = onUsTrimmed.split(/\s+/);
      if (parts.length === 2) {
        const [first, second] = parts.map((part) => part.replace(/[^\d-]/g, ''));
        if (first.replace(/-/g, '').length < second.replace(/-/g, '').length) {
          checkNumber = first;
          accountNumber = second;
        } else {
          accountNumber = first;
          checkNumber = second;
        }
      } else {
        accountNumber = onUsTrimmed.replaceAll('/', '');
      }
    } else {
      const numbers = onUsTrimmed.match(numberRegex) || [];

      if (numbers.length === 2) {
        [accountNumber, checkNumber] = numbers;
      } else if (numbers.length === 1) {
        accountNumber = numbers[0];
      }
    }
  }

  return { accountNumber, checkNumber };
};

export const mergeAccountAndCheckNumber = (
  originalOnUs: string,
  originalAuxiliaryOnUs: string,
  editedAccountNumber: string,
  editedCheckNumber: string
): { onUs: string; auxiliaryOnUs: string } => {
  const onUsTrimmed = originalOnUs.trim();
  const auxiliaryOnUsTrimmed = originalAuxiliaryOnUs.trim();

  if (!onUsTrimmed && !auxiliaryOnUsTrimmed) {
    return { onUs: `${editedAccountNumber}/${editedCheckNumber}`, auxiliaryOnUs: '' };
  }

  let onUs = '';
  let auxiliaryOnUs = auxiliaryOnUsTrimmed;

  const startsWithSlash = onUsTrimmed.startsWith('/');
  const endsWithSlash = onUsTrimmed.endsWith('/');

  if (auxiliaryOnUsTrimmed) {
    auxiliaryOnUs = editedCheckNumber;
    onUs = startsWithSlash ? '/' : '';
    onUs += editedAccountNumber;
    onUs += endsWithSlash ? '/' : '';
  } else {
    const numberRegex = /(\d+[-\d]*)/g;
    const numbers = onUsTrimmed.match(numberRegex) || [];
    const hasSpace = onUsTrimmed.includes(' ');

    if (hasSpace) {
      const parts = onUsTrimmed.split(/\s+/);
      if (parts.length === 2) {
        const [first, second] = parts.map((part) => part.replace(/[^\d-]/g, ''));

        onUs =
          first.length < second.length
            ? `${editedCheckNumber} ${editedAccountNumber}`
            : `${editedAccountNumber} ${editedCheckNumber}`;
      } else {
        onUs = startsWithSlash ? '/' : '';
        onUs += editedAccountNumber;
        onUs += endsWithSlash ? '/' : '';
      }
    } else {
      if (numbers.length === 2 && onUsTrimmed === `${numbers[0]}/${numbers[1]}/`) {
        onUs = `${numbers[0].length > numbers[1].length ? editedAccountNumber : editedCheckNumber}/${
          numbers[0].length > numbers[1].length ? editedCheckNumber : editedAccountNumber
        }/`;
      } else {
        onUs = startsWithSlash ? '/' : '';

        if (numbers.length === 1) {
          onUs += editedAccountNumber;
        } else {
          onUs += editedAccountNumber;
          onUs += '/';
          onUs += editedCheckNumber;
        }

        onUs += endsWithSlash && !onUs.endsWith('/') ? '/' : '';
      }
    }
  }

  return { onUs, auxiliaryOnUs };
};

export const extractFirstNumericValue = (input: string): string => {
  const match = input.match(/\d+/);
  return match ? match[0] : '';
};

export const replaceWithOriginalFormat = (editedValue: string, originalFormat: string): string => {
  if (!originalFormat || originalFormat.length < 1 || originalFormat.trim() === '/') {
    return `${editedValue}/`;
  }

  return originalFormat.replace(/\d+/, editedValue);
};

export const preventSpaceInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === ' ') {
    event.preventDefault();
    gsap.to(event.target, {
      keyframes: [
        {
          x: -2,
        },
        {
          x: 2,
        },
        {
          x: -2,
        },
        {
          x: 2,
        },
        {
          x: 0,
        },
      ],
      duration: 0.4,
    });
  }
};
