const Entries = {
  money_movement: {
    label: 'Money Movement',
    examples: ['B2B Payments', 'Direct Deposit', 'Payroll'],
  },
  consumer_or_business_banking: {
    label: 'Bank Accounts',
    examples: ['DDAs', 'Checking Accounts', 'Savings Accounts'],
  },
  cards: {
    label: 'Cards',
    examples: ['Debit Card', 'Credit Card', 'Prepaid Card'],
  },
  credit_lending: {
    label: 'Credit & Lending',
    examples: ['Line of Credit', 'Credit Card', 'Term Loan'],
  },
  international: {
    label: 'International',
    examples: ['Cross-Border Transfers', 'Multi-Currency Accounts', 'Remittance Payments'],
  },
  crypto_decentralized_finance: {
    label: 'Crypto & Decentralized Finance',
    examples: ['Crypto Onramp / Offramp', 'Crypto Wallet', 'Crypto Exchange'],
  },
  other: {
    label: 'Other',
  },
};

export type financialProductType = keyof typeof Entries;

interface financialProductTypeOption {
  label: string;
  tooltip?: string;
  examples?: string[];
}

export const financialProducts: Record<financialProductType, financialProductTypeOption> = Entries;
