import { Center, Environment, MeshDistortMaterial, useAspect } from '@react-three/drei';
import { Canvas, useLoader } from '@react-three/fiber';
import gsap from 'gsap';
import React, { useRef, useEffect, FC, forwardRef } from 'react';
import { TextureLoader, Mesh, Material } from 'three';

interface FlagProps {
  countryCode?: string;
  size?: number;
}

interface FlagSceneProps {
  countryCode: string;
  position?: [number, number, number];
  scale?: [number, number, number];
}

const FlagScene: FC<FlagSceneProps> = ({ countryCode, ...props }) => {
  const ref = useRef<Mesh>(null);
  const texture = useLoader(TextureLoader, `/flags/${countryCode}.png`);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    gsap.to(ref.current.material as Material, {
      duration: 5,
      distort: 0.3,
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut',
    });

    gsap.to(ref.current.material as Material, {
      duration: 2.5,
      speed: '+=1',
      yoyo: true,
      repeat: -1,
      ease: 'sine.inOut',
    });
  }, []);

  const scale = useAspect(texture.source.data.width, texture.source.data.height, 0.6);

  return (
    <mesh ref={ref} {...props}>
      <planeGeometry args={[scale[0], scale[1], 64, 64]} />
      <MeshDistortMaterial map={texture} speed={1.5} distort={0.3} toneMapped={false} />
    </mesh>
  );
};

export const Flag = forwardRef<HTMLCanvasElement, FlagProps>(({ countryCode = 'us', size }, ref) => {
  return (
    <div style={{ width: `${size ?? 28}px`, aspectRatio: '16 / 9' }}>
      <Canvas camera={{ position: [0, 0, 1.5] }} dpr={3} ref={ref}>
        <Environment preset="warehouse" blur={0.5} />

        <Center>
          <FlagScene countryCode={countryCode} position={[0, 0, 0]} />
        </Center>
      </Canvas>
    </div>
  );
});
