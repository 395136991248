export const transferStatusTooltips = new Map<string, string>([
  ['canceled', 'The transaction was canceled, and no funds were moved.'],
  [
    'completed',
    'The transaction has been fully processed and the funds have successfully reached the intended destination.',
  ],
  ['deposited', 'Funds have been deposited into the account as scheduled.'],
  ['failed', 'The transaction could not be processed due to an error or rejection.'],
  [
    'first_return',
    'The first attempt to process the transaction was returned, possibly due to issues like NSF (Non-Sufficient Funds).',
  ],
  ['hold', 'Funds or the transaction are currently on hold, pending further verification or action.'],
  ['initiated', 'The transaction has been initiated and is pending completion of processing steps.'],
  ['issued', 'A check or other payment form has been issued but not yet cashed or deposited.'],
  ['manual_review', 'The transaction is undergoing manual review to verify its details or source before proceeding.'],
  ['manual_review_approved', 'The transaction has passed manual review and is approved for processing.'],
  ['pending_deposit', 'The deposit has been initiated but is not yet complete.'],
  [
    'pending_first_return',
    'A return has been initiated on the first attempt of processing the transaction, awaiting finalization.',
  ],
  ['pending_reclear', 'The transaction is undergoing an attempt to clear after an initial failure.'],
  ['pending_return', 'A return on the transaction has been initiated and is currently being processed.'],
  ['pending_second_return', 'The second attempt to return the transaction is underway.'],
  ['pending_stop', 'A stop payment request has been submitted and is pending action.'],
  ['pending_submission', 'The transaction is prepared and pending submission to the clearing system.'],
  ['pending_user_initiated_return', 'A return initiated by the user is currently being processed.'],
  ['recleared', 'The transaction has been cleared again following a previous clearance issue.'],
  ['rejected', 'The transaction was rejected by the receiving party or due to an issue with the transaction details.'],
  ['return_contested', 'The dishonored return of the transaction has been rejected by the receiving bank'],
  ['return_dishonored', 'the return was not accepted or honored by the originating bank.'],
  ['returned', 'The transaction was returned by the receiving party or due to a failure in processing.'],
  ['scheduled', 'The transaction is scheduled for a future date and has not yet been processed.'],
  ['second_return', 'The transaction has been returned for a second time, indicating persistent issues.'],
  ['settled', 'Funds movement is complete.'],
  ['stopped', 'The transaction has been stopped, typically upon the request of the sender.'],
  ['submitted', 'The transaction has been submitted to the processing network and is currently being processed.'],
  ['user_initiated_returned', 'A return initiated by the user has been processed and completed.'],
  ['user_initiated_return_submitted', 'A return initiated by the user has been submitted and is pending processing.'],
  [
    'user_initiated_return_dishonored',
    'A user-initiated return was not honored, typically due to procedural errors or disputes.',
  ],
]);
