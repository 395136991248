import React, {
  Children,
  FC,
  PropsWithChildren,
  isValidElement,
  cloneElement,
  useRef,
  useState,
  ReactElement,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

export interface PopoverFilterWrapperProps {
  label: ReactNode | string;
  className?: string;
  isActive?: boolean;
  onSubmit: (data: any) => void;
}

interface PopoverFilterStyleProps {
  $isFilterActive?: boolean;
}

const Wrapper = styled.div<PopoverFilterStyleProps>`
  --popover-top: 40px;
  --popover-left: 0;
  --popover-right: auto;

  position: relative;

  &:after {
    content: '';
    border-radius: 8px;
    z-index: 1;
    position: absolute;
    inset: 0;
    pointer-events: none;
    box-shadow: inset 0 0 0 1px
      ${({ $isFilterActive, theme }) => ($isFilterActive ? theme.primary.background : 'transparent')};
    transition: box-shadow 0.15s;
  }
`;

const FilterButton = styled(Button)<PopoverFilterStyleProps>`
  color: ${({ $isFilterActive, theme }) => $isFilterActive && theme.primary.background};
  --button-scale-pressed: 1;
  padding: 8px 10px 8px 12px;

  &::after {
    box-shadow:
      0 -1px 1px rgba(255, 255, 255, 0.75) inset,
      ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
        ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
        ${({ theme }) => theme.style.buttonSecondaryBorderColor},
      0 1px 1px rgba(0, 0, 0, 0.075),
      0 1px 4px 0 transparent inset;

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        background-color: transparent;
        box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(100)};

        &:hover {
          background-color: ${({}) => theme.secondary.blendToBackground(25)};
          box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(150)};
        }
      `}

    ${({ $isFilterActive }) =>
      $isFilterActive &&
      css`
        box-shadow:
          ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
            ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
            ${({ theme }) => theme.style.buttonSecondaryBorderColor},
          0 0 0 var(--button-outline, 0px)
            var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
          ${({ theme }) => theme.style.buttonSecondaryShadow};
      `}
  }

  &:hover {
    &::after {
      box-shadow:
        0 -1px 1px rgba(255, 255, 255, 0.75) inset,
        ${({ theme }) => theme.style.buttonSecondaryHoveredBorderProperties}
          ${({ theme }) => theme.style.buttonSecondaryHoveredBorderWidth}
          ${({ theme }) => theme.secondary.blendToBackground(1100, 150)},
        0 1px 1px rgba(0, 0, 0, 0),
        0 1px 4px 0 ${({ theme }) => theme.secondary.blendToBackground(120)} inset;

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          background-color: ${({}) => theme.secondary.blendToBackground(25)};
          box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(150)};
        `}
    }
  }
`;

export const PopoverFilterWrapper: FC<PropsWithChildren<PopoverFilterWrapperProps>> = ({
  label,
  className,
  children,
  isActive,
  onSubmit,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState<boolean>(false);

  const renderChildren = () => {
    return Children.map(children, (child) => {
      if (!child || !isValidElement(child)) {
        return;
      }

      return cloneElement(child as ReactElement<any>, {
        show,
        onClose: (event: MouseEvent) => {
          if (!(event.target instanceof Element)) {
            return;
          }
          if (buttonRef.current && (event.target === buttonRef.current || buttonRef.current.contains(event.target))) {
            return;
          }
          setShow(false);
        },
        onSubmit: (data: any) => {
          onSubmit(data);
          setShow(false);
        },
      });
    });
  };

  return (
    <Wrapper className={className} $isFilterActive={isActive}>
      <FilterButton
        ref={buttonRef}
        size="small"
        variant="secondary"
        icon={show ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
        iconRight
        onClick={() => setShow((s) => !s)}
        $isFilterActive={isActive}
      >
        {label}
      </FilterButton>
      {renderChildren()}
    </Wrapper>
  );
};
