import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface PendingIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const PendingIcon: FC<PendingIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={16}>
        <circle
          cx="8"
          cy="8"
          r="6"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.8042 5.00008V5.00008C3.55553 3.69875 5.18487 3.19942 6.5362 3.85608L6.5382 3.85675C7.62287 4.38408 8.26153 5.53408 8.13487 6.73408L7.86687 9.26608C7.7402 10.4654 8.3782 11.6161 9.46353 12.1434L9.46553 12.1441C10.8169 12.8008 12.4462 12.3014 13.1975 11.0001V11.0001"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.00042 13.1959V13.1959C3.69908 12.4445 3.19957 10.8154 3.85648 9.46383L3.85697 9.46201C4.38442 8.37691 5.53486 7.73888 6.73419 7.86551L9.26605 8.13348C10.4654 8.26012 11.6158 7.62208 12.1433 6.53698L12.1438 6.53516C12.8007 5.18363 12.3012 3.55445 10.9998 2.80313V2.80313"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconBase>
    </Wrapper>
  );
};
