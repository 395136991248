import React from 'react';

import { Button, Dropdown, Icon } from '@column/column-ui-kit';

import { ButtonLeft, FormElementSplit } from '../Information';
import { FormElementHint } from '../Location';
import { useAddress } from '~/hooks';
import { FormElement, FormLabel } from '~/styles';

interface CountriesProps {
  formLabel: string;
  value: string[];
  onValueChange: (value: string[]) => void;
}

export const Countries: React.FC<CountriesProps> = ({ value, onValueChange, formLabel }) => {
  const { countries } = useAddress({ types: ['country'] });

  return (
    <FormElement>
      <FormLabel>{formLabel}</FormLabel>
      <FormElementHint>(Please add all countries that apply) </FormElementHint>
      {value.map((code: string, index: number) => {
        const DropdownElement = (
          <Dropdown
            onChange={(val: string) => {
              const state = value;

              state[index] = val;

              onValueChange([...state]);
            }}
            active={code}
            options={countries
              .filter((s) => (s.code === code ? true : !value.includes(s.code)))
              .map((c) => ({
                label: c.name,
                value: c.code,
              }))}
            search
            fullWidth
            variant="muted"
          />
        );

        if (index === 0) {
          return <div key={index}>{DropdownElement}</div>;
        }

        return (
          <FormElementSplit key={index}>
            {DropdownElement}
            <Button
              variant="muted"
              type="button"
              icon={<Icon.Cross />}
              onlyIcon
              onClick={() => {
                const state = value;

                delete state[index];

                onValueChange([...state.filter((v) => typeof v !== 'undefined')]);
              }}
            />
          </FormElementSplit>
        );
      })}
      <ButtonLeft
        variant="subtle"
        type="button"
        icon={<Icon.Plus />}
        onClick={() => {
          onValueChange([...value, '']);
        }}
      >
        Add Another Country
      </ButtonLeft>
    </FormElement>
  );
};
