export const convertByteToString = (bytes: number) => {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB'];

  if (bytes === 0 || isNaN(bytes)) {
    return '0 Byte';
  }

  const convert = Math.floor(Math.log(bytes) / Math.log(K_UNIT));

  return `${parseFloat((bytes / Math.pow(K_UNIT, convert)).toFixed(2))} ${SIZES[convert]}`;
};
