type IsObjectType = (item: any) => boolean;

interface ObjectType {
  [key: string]: any;
}

type DeepMergeType = (target: ObjectType, ...sources: ObjectType[]) => ObjectType;

export const toCamel = (str: string) => {
  return str
    .replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    })
    .replace('_', '');
};

export const toSnake = (str: string) => {
  return str
    .replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)
    .replace(/[0-9]/g, (num: string) => `_${num}`);
};

const isObject: IsObjectType = (obj: any) => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const keysToCamelCase: any = (obj: any) => {
  if (isObject(obj)) {
    const n: any = {};

    Object.keys(obj).forEach((k: string) => {
      n[toCamel(k)] = keysToCamelCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i: string) => {
      return keysToCamelCase(i);
    });
  }

  return obj;
};

export const keysToSnakeCase: any = (obj: any) => {
  if (isObject(obj)) {
    const n: any = {};

    Object.keys(obj).forEach((k: string) => {
      n[toSnake(k)] = keysToSnakeCase(obj[k]);
    });

    return n;
  } else if (Array.isArray(obj)) {
    return obj.map((i: string) => {
      return keysToSnakeCase(i);
    });
  }

  return obj;
};

export const deepMerge: DeepMergeType = (target: ObjectType, ...sources: ObjectType[]): ObjectType => {
  if (!sources.length) {
    return target;
  }

  const result: ObjectType = target;

  if (isObject(result)) {
    const len: number = sources.length;

    for (let i = 0; i < len; i += 1) {
      const elm: any = sources[i];

      if (isObject(elm)) {
        for (const key in elm) {
          if (elm.hasOwnProperty(key)) {
            if (isObject(elm[key])) {
              if (!result[key] || !isObject(result[key])) {
                result[key] = {};
              }
              deepMerge(result[key], elm[key]);
            } else {
              if (Array.isArray(result[key]) && Array.isArray(elm[key])) {
                result[key] = Array.from(new Set(result[key].concat(elm[key])));
              } else {
                result[key] = elm[key];
              }
            }
          }
        }
      }
    }
  }

  return result;
};
