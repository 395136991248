import {
  BankAccountRepository,
  BankAccountListResponse,
  BankAccountFilterParams,
  AccountNumber,
  ListBankAccountActivitiesResponse,
  ListBankAccountActivitiesRequest,
} from '~/repositories/BankAccountRepository';
import { BankAccountWithDetails } from '~/typings/API';

import { createApiHook } from './utils/createApiHook';

export type ActivityFilterType = ListBankAccountActivitiesRequest & {
  page?: number;
};

export const useBankAccounts = createApiHook<BankAccountListResponse, BankAccountFilterParams>(
  BankAccountRepository.getAll,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
  }
);

export const useLazyBankAccounts = createApiHook<BankAccountListResponse, BankAccountFilterParams>(
  BankAccountRepository.getAll,
  {
    includeQueryParams: true,
  }
);

export const useBankAccount = createApiHook<BankAccountWithDetails, GetRequestType>(BankAccountRepository.get);

export const useAccountNumber = createApiHook<AccountNumber, GetRequestType>(BankAccountRepository.getAccountNumber);

export const useBankAccountActivity = (bankAccountId: string) =>
  createApiHook<ListBankAccountActivitiesResponse, ActivityFilterType>(
    (request) => BankAccountRepository.getActivity(bankAccountId, request),
    {
      triggerAutomatically: true,
      triggerOnSessionStoreSubscribe: true,
      includeQueryParams: true,
      addQueryParamsToUrl: true,
      excludeQueryParams: ['page'],
    }
  );
