import React from 'react';
import styled from 'styled-components';

import { IMAGE_URL } from '~/assets';
import { Paragraph, SmallHeadline } from '~/styles';

export interface ComingSoonProps {
  className?: string;
}

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  max-width: 320px;
  width: 100%;
`;

const Text = styled.div`
  display: grid;
  grid-gap: 4px;
  text-align: center;
`;

const Illustration = styled.img`
  display: block;
  width: 244px;
  height: 156px;
  margin: 0 auto 32px auto;
`;

export const ComingSoon: React.FC<ComingSoonProps> = (props) => {
  return (
    <Wrapper className={props.className}>
      <Illustration loading="lazy" src={IMAGE_URL.COLUMN} />
      <Text>
        <SmallHeadline>Under construction</SmallHeadline>
        <Paragraph>This feature is under construction. We work quickly, so check back soon.</Paragraph>
      </Text>
    </Wrapper>
  );
};
