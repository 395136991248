import { client } from '../client';
import {
  AchAchReturn,
  AchAchTransfer,
  AchCreateAchStopPaymentRequest,
  AchCreateAchTransferRequest,
  AchCreateAchStopPaymentResponse,
  AchTransferServiceCreateAchReturnPayload,
  AchListAchReturnsResponse,
  AchListAchTransfersResponse,
  AchTransferServiceListAchReturnsParams,
  AchTransferServiceListAchTransfersParams,
  AchAchTransferType,
} from '~/typings/API';
import { CreateAchReturnRequestReturnCode } from '~/typings/enum';
import { convertValues } from '~/util';

type AchReturn = AchAchReturn;
type AchTransfer = AchAchTransfer;
export type { AchReturn, AchTransfer };
export type CreateAchReturn = AchTransferServiceCreateAchReturnPayload;
export type CreateAchTransfer = AchCreateAchTransferRequest;
export type AchTransferType = AchAchTransferType;
export type AchReturnCodesType = CreateAchReturnRequestReturnCode;

export const AchReturnCodes = CreateAchReturnRequestReturnCode;

export class AchRepository {
  static async create(achTransfer: CreateAchTransfer, idempotency?: string) {
    return client
      .post<CreateAchTransfer, AchTransfer>('/transfers/ach', achTransfer, { idempotency })
      .then((response) => convertValues<AchTransfer>(response));
  }

  static async cancel(achTransferId: string) {
    return client
      .post<object, AchTransfer>(`/transfers/ach/${achTransferId}/cancel`, {})
      .then((response) => convertValues<AchTransfer>(response));
  }

  static async stopPayments(request: AchCreateAchStopPaymentRequest) {
    return client
      .post<AchCreateAchStopPaymentRequest, AchCreateAchStopPaymentResponse>('/transfers/ach/stop-payments', request)
      .then((response) => convertValues<AchCreateAchStopPaymentResponse>(response));
  }

  static async get(request: GetRequestType) {
    return client
      .get<unknown, AchTransfer>(`/transfers/ach/${request.id}`)
      .then((response) => convertValues<AchTransfer>(response));
  }

  static async getAll(query?: Partial<AchTransferServiceListAchTransfersParams>) {
    return client
      .get<AchTransferServiceListAchTransfersParams, AchListAchTransfersResponse>('/transfers/ach', { query })
      .then((response) => ({
        transfers: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<AchTransfer>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async getAllReturns(query?: Partial<AchTransferServiceListAchReturnsParams>) {
    return client
      .get<AchTransferServiceListAchReturnsParams, AchListAchReturnsResponse>('/transfers/ach/returns', { query })
      .then((response) => ({
        achReturns: Array.isArray(response?.achReturns)
          ? response.achReturns.map((e) => convertValues<AchReturn>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async getReturn(achTransferId: string) {
    return client
      .get<unknown, AchReturn>(`/transfers/ach/${achTransferId}/return`)
      .then((response) => convertValues<AchReturn>(response));
  }

  static async createReturn(achTransferId: string, request: CreateAchReturn) {
    return client
      .post<CreateAchReturn, AchReturn>(`/transfers/ach/${achTransferId}/return`, request)
      .then((response) => convertValues<AchReturn>(response));
  }
}
