import { COOKIE_DOMAIN } from '~/constants';

const MAX_AGE = 2147483647 * 1000;

export const setCookie = (props: { key: string; value: string; expiryDays?: number; path?: string }) => {
  const expiryDate = new Date(
    props.expiryDays !== undefined ? Date.now() + props.expiryDays * 24 * 60 * 60 * 1000 : MAX_AGE
  );
  const cookieParts: string[] = [];
  cookieParts.push(`${props.key}=${props.value}`);
  cookieParts.push(`expires=${expiryDate.toUTCString()}`);
  cookieParts.push(`path=${props.path ?? '/'}`);
  if (COOKIE_DOMAIN) {
    cookieParts.push(`domain=${COOKIE_DOMAIN}`);
  }
  document.cookie = cookieParts.join(';');
};

export const getCookie = (key: string) => {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trimLeft());
  for (const cookie of cookies) {
    if (cookie.startsWith(key)) {
      return cookie.split('=')[1];
    }
  }
  return null;
};

export const deleteCookie = (key: string) => {
  setCookie({ key, value: '', expiryDays: 0 });
};

interface Storage {
  getItem: (name: string) => string | null | Promise<string | null>;
  setItem: (name: string, value: string) => void | Promise<void>;
  removeItem: (name: string) => void | Promise<void>;
}

export const cookieStorage: Storage = {
  getItem: getCookie,
  setItem: (name: string, value: string) =>
    setCookie({
      key: name,
      value,
    }),
  removeItem: deleteCookie,
};
