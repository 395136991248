import { useCallback, useMemo } from 'react';

import { useValidateIBAN } from '../useCounterparties';
import counterpartyData from '~/data/wires/default/counterparty.json';
import { Counterparty } from '~/repositories';
import { titleCase } from '~/util';

export const useCounterpartyValidation = (countryCode: string) => {
  const { createRequest: validateIBAN } = useValidateIBAN();

  const mandatoryFields = useMemo(() => {
    return (
      counterpartyData.counterparty.mandatory[countryCode as keyof typeof counterpartyData.counterparty.mandatory] || []
    );
  }, [countryCode]);

  const highlyRecommendedFields = useMemo(() => {
    return (
      counterpartyData.counterparty.highlyRecommended[
        countryCode as keyof typeof counterpartyData.counterparty.highlyRecommended
      ] || []
    );
  }, [countryCode]);

  const fields = useMemo(() => {
    return [...mandatoryFields, ...highlyRecommendedFields];
  }, [mandatoryFields, highlyRecommendedFields]);

  const requirements = useMemo(
    () => ({
      postalCodeRegex:
        counterpartyData.counterparty.postalCode[countryCode as keyof typeof counterpartyData.counterparty.postalCode],
      accountNumberRegex:
        counterpartyData.counterparty.accountNumber[
          countryCode as keyof typeof counterpartyData.counterparty.accountNumber
        ],
      mandatoryFields,
      highlyRecommendedFields,
    }),
    [countryCode, mandatoryFields, highlyRecommendedFields]
  );

  const hasRequiredFields = useCallback(
    (formData: Counterparty) => {
      return (
        requirements.mandatoryFields.every((field) => !!formData[field as keyof Counterparty]) &&
        requirements.highlyRecommendedFields.every((field) => !!formData[field as keyof Counterparty])
      );
    },
    [requirements]
  );

  const isCounterpartyValid = useCallback(async (counterparty: Counterparty): Promise<true | string | string[]> => {
    const missingFields: string[] = [];

    const mandatory =
      counterpartyData.counterparty.mandatory[
        counterparty.localBankCountryCode as keyof typeof counterpartyData.counterparty.mandatory
      ] || [];
    const highlyRecommended =
      counterpartyData.counterparty.highlyRecommended[
        counterparty.localBankCountryCode as keyof typeof counterpartyData.counterparty.highlyRecommended
      ] || [];

    const checkField = (condition: boolean, fieldName: string) => {
      if (!condition) {
        missingFields.push(fieldName);
      }
    };

    mandatory.forEach((field) =>
      checkField(
        !!counterparty[field as keyof Counterparty],
        `Missing field → ${titleCase(field, { camelCase: true })}`
      )
    );
    highlyRecommended.forEach((field) =>
      checkField(
        !!counterparty[field as keyof Counterparty],
        `Missing field → ${titleCase(field, { camelCase: true })}`
      )
    );

    checkField('accountNumber' in counterparty, 'Missing field → Account Number / IBAN');
    checkField('routingNumber' in counterparty, 'Missing field → Routing Number / BIC');

    if (typeof counterparty.wire === 'object') {
      checkField(!!counterparty.wire?.beneficiaryAddress, 'Missing field → Address');

      if (
        counterparty.wire &&
        'beneficiaryAddress' in counterparty.wire &&
        typeof counterparty.wire.beneficiaryAddress === 'object'
      ) {
        const address = counterparty.wire.beneficiaryAddress;

        if (address) {
          checkField('countryCode' in address, 'Missing field → Address / Country Code');
          checkField('line1' in address, 'Missing field → Address / Line 1');
          checkField('city' in address, 'Missing field → Address / City');
          checkField('postalCode' in address, 'Missing field → Address / Postal Code');
        } else {
          checkField(false, 'Missing field → Address / Country Code');
          checkField(false, 'Missing field → Address / Line 1');
          checkField(false, 'Missing field → Address / City');
          checkField(false, 'Missing field → Address / Postal Code');
        }
      } else {
        checkField(false, 'Missing field → Address / Country Code');
        checkField(false, 'Missing field → Address / Line 1');
        checkField(false, 'Missing field → Address / City');
        checkField(false, 'Missing field → Address / Postal Code');
      }
    }

    if (counterparty.routingNumberType === 'bic' && counterparty.accountNumber) {
      try {
        const iban = await validateIBAN({ id: counterparty.accountNumber });

        if (iban && iban.bic !== counterparty.routingNumber) {
          missingFields.push('The counterparty BIC is not matching to the IBAN');
        }
      } catch (error) {
        missingFields.push('Unable to validate BIC with related IBAN');
      }
    }

    if (missingFields.length === 0) {
      return true;
    }

    if (missingFields.length === 1) {
      return missingFields[0];
    }

    return missingFields;
  }, []);

  const validateField = (fieldName: string, value: string): boolean | string => {
    switch (fieldName) {
      case 'postalCode':
        if (requirements.postalCodeRegex) {
          const regex = new RegExp(requirements.postalCodeRegex);
          return regex.test(value) || 'Invalid postal code format';
        }
        return true;
      case 'accountNumber':
        if (requirements.accountNumberRegex) {
          const regex = new RegExp(requirements.accountNumberRegex);
          return regex.test(value) || 'Invalid account number format';
        }
        return true;
      default:
        return true;
    }
  };

  const validatePostalCode = (value: string, countryCodeValue: string): boolean | string => {
    if (
      counterpartyData.counterparty.postalCode[
        countryCodeValue as keyof typeof counterpartyData.counterparty.postalCode
      ]
    ) {
      const regex = new RegExp(
        counterpartyData.counterparty.postalCode[
          countryCodeValue as keyof typeof counterpartyData.counterparty.postalCode
        ]
      );
      return regex.test(value) || 'Invalid postal code format';
    }
    return true;
  };

  const isFieldMandatory = (fieldName: string): boolean => {
    return requirements.mandatoryFields.includes(fieldName);
  };

  const isFieldHighlyRecommended = (fieldName: string): boolean => {
    return requirements.highlyRecommendedFields.includes(fieldName);
  };

  return {
    requirements,
    fields,
    mandatoryFields,
    highlyRecommendedFields,
    validateField,
    validatePostalCode,
    hasRequiredFields,
    isFieldMandatory,
    isFieldHighlyRecommended,
    isCounterpartyValid,
  };
};
