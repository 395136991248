import {
  EntityCreateBusinessEntityRequest,
  EntityCreatePersonEntityRequest,
  EntityEntity,
  EntityEntityWithDetails,
  EntityBusinessDetails,
  EntityList,
  EntityServiceGetEntitiesParams,
  EntityPersonDetails,
  EntityUpdateBusinessEntityBody,
  EntityUpdatePersonEntityBody,
  ApiAddress,
  EntityVerificationStatus,
} from '~/typings/API';
import { convertValues } from '~/util/convertValues';

import { client } from './client/Client';

type AddressType = ApiAddress;
type PersonDetails = EntityPersonDetails;
type EntityWithDetails = EntityEntityWithDetails;
export type EntityListParams = EntityServiceGetEntitiesParams;
export type CreatePersonEntity = EntityCreatePersonEntityRequest;
export type CreateBusinessEntity = EntityCreateBusinessEntityRequest;
export type UpdatePersonEntity = EntityUpdatePersonEntityBody;
export type UpdateBusinessEntity = EntityUpdateBusinessEntityBody;
export type VerificationStatus = EntityVerificationStatus;
export type { AddressType, PersonDetails, EntityWithDetails, EntityList, EntityBusinessDetails };

export class EntityRepository {
  static async createPerson(request: CreatePersonEntity) {
    return client
      .post<CreatePersonEntity, EntityWithDetails>('/entities/person', request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async updatePerson(id: string, request: UpdatePersonEntity) {
    return client
      .patch<UpdatePersonEntity, EntityWithDetails>(`/entities/person/${id}`, request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async createBusiness(request: CreateBusinessEntity) {
    return client
      .post<CreateBusinessEntity, EntityWithDetails>('/entities/business', request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async updateBusiness(id: string, request: UpdateBusinessEntity) {
    return client
      .patch<UpdateBusinessEntity, EntityWithDetails>(`/entities/business/${id}`, request)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async get(id: string) {
    return client
      .get<unknown, EntityWithDetails>(`/entities/${id}`)
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async getAll(query?: Partial<EntityListParams>) {
    return client.get<EntityListParams, EntityList>('/entities', { query }).then((response) => ({
      entities: Array.isArray(response?.entities) ? response.entities.map((e) => convertValues<EntityEntity>(e)) : [],
      hasMore: Boolean(response?.hasMore),
    }));
  }

  static async delete(id: string) {
    return client.delete<object, object>(`/entities/${id}`);
  }

  static async createRootPerson(platformId: string, request: CreatePersonEntity, mode: 'live' | 'sandbox') {
    return client
      .post<CreatePersonEntity, EntityWithDetails>(`/dashboard/platforms/${platformId}/root-entities/person`, request, {
        sandbox: mode === 'sandbox',
        platformId,
      })
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async createRootBusiness(platformId: string, request: CreateBusinessEntity, mode: 'live' | 'sandbox') {
    return client
      .post<
        CreateBusinessEntity,
        EntityWithDetails
      >(`/dashboard/platforms/${platformId}/root-entities/business`, request, { sandbox: mode === 'sandbox', platformId })
      .then((response) => convertValues<EntityWithDetails>(response));
  }

  static async getRoot(platformId: string, mode: 'live' | 'sandbox') {
    return client
      .get<unknown, EntityWithDetails>(`/dashboard/platforms/${platformId}/root-entities`, {
        sandbox: mode === 'sandbox',
        platformId,
      })
      .then((response) => convertValues<EntityWithDetails>(response));
  }
}
