import { PageDocumentView } from '~/app/pages/Documents/View';

import { PageDocuments } from './Documents';
import { REPORTING } from './Reporting';
import { PageStatements } from './Statements';

export const DOCUMENTS = {
  ...REPORTING,
  Documents: PageDocuments,
  DocumentView: PageDocumentView,
  Statements: PageStatements,
} as const;
