import React, { useState, useEffect, FormEvent } from 'react';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';

import { Icon, Radio, Input, Textarea, Button, Fade, Checkbox, ToggleHeight } from '@column/column-ui-kit';

import { UnderlineLink } from '~/elements';
import { Option, OptionLabel, FinancialProductsList } from '~/public/pages/Register';
import { PlatformRolePermissions, WebsiteRepository } from '~/repositories';
import { financialProducts, financialProductType } from '~/repositories/PlatformRepository';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import {
  PlatformInfoHeader,
  Headline,
  PlatformInfoDescription,
  PlatformInfoFormElement,
  PlatformInfoFormLabelInline,
  PlatformInfoFormLabelTooltip,
  SmallHeadline,
  Hint,
  Inner,
} from '~/styles';
import { validateEmail } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

const Grid = styled.div`
  display: grid;
  grid-gap: 48px;
`;

const FormSection = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const FormHeader = styled.div`
  display: grid;
  grid-gap: 4px;
`;

const StyledTextarea = styled(Textarea)`
  min-height: 120px;
`;

const OptionList = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const ComplianceList = styled.div`
  max-width: 60%;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const ComplianceListOption = styled(Option)<{ isActive?: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.secondary.background};

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary.background};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.primary.background};
    `}
`;

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const OtherContainer = styled(ToggleHeight)`
  --toggle-height-padding: 12px 0 0 0;
`;

export const PageContactForm: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission, loadPlatform } = useSessionStore();
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const [products, setProducts] = useState<financialProductType[]>([]);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companySize, setCompanySize] = useState<string>('');
  const [productsText, setProductsText] = useState<string>('');
  const [goLiveDate, setGoLiveDate] = useState<string>('');
  const [complianceCapabilities, setComplianceCapabilities] = useState<string>('');
  const [otherComment, setOtherComment] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [customerTypes, setCustomerTypes] = useState<string[]>([]);
  const [customerTypeOther, setCustomerTypesOther] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleReCaptcha } = reCaptchaCheck();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (!email || !validateEmail(email)) {
      addDangerNotification({
        content: 'Please enter a valid email address',
      });
      return;
    }

    setIsLoading(true);

    const data = {
      origin: 'Dashboard',
      firstName,
      lastName,
      emailAddress: email,
      companyName: companyName,
      companySize: companySize,
      selectedProducts: products.map((f) => financialProducts[f].label).join(', '),
      productsText: productsText,
      goLiveDate: goLiveDate,
      complianceCapabilities: complianceCapabilities,
      otherComment: otherComment,
      customerTypes: customerTypes.join(', '),
      customerTypeOther: customerTypeOther,
    };

    handleReCaptcha(
      (reCaptchaToken: string) =>
        WebsiteRepository.contact(data, reCaptchaToken)
          .then(() => {
            setSubmitted(true);

            if (currentPermission?.platformInfo === 'write') {
              currentPlatform?.updateMeta({
                companyName,
                companySize,
                financialProductsSelected: products,
                financialProductsText: productsText,
                goLiveDate,
                complianceCapabilities,
                otherComment,
                customerTypes,
                customerTypeOther,
              });
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          })
          .finally(() => setIsLoading(false)),
      (err) => {
        setIsLoading(false);

        addDangerNotification({
          content: err.message,
        });
      }
    );
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (s) => [s.currentPermission, s.currentUser?.defaultPlatformId],
        (s) => {
          const currentPermissionState = s[0] as PlatformRolePermissions | undefined;
          const defaultPlatformId = s[1] as string | undefined;

          if (!defaultPlatformId || !currentPermissionState || currentPermissionState.platformInfo === 'none') {
            return;
          }

          loadPlatform({ fetchMeta: true })
            .then((response) => {
              setProducts(response?.financialProductsSelected ?? []);
              setFirstName(currentUser?.firstName ?? '');
              setLastName(currentUser?.lastName ?? '');
              setEmail(currentUser?.email ?? '');
              setCompanyName(response?.companyName ?? '');
              setCompanySize(response?.companySize ?? '');
              setProductsText(response?.financialProductsText ?? '');
              setGoLiveDate(response?.goLiveDate ?? '');
              setComplianceCapabilities(response?.complianceCapabilities ?? '');
              setOtherComment(response?.otherComment ?? '');
              setCustomerTypes(response?.customerTypes ?? []);
              setCustomerTypesOther(response?.customerTypeOther ?? '');
            })
            .catch((error) => {
              console.error('ContactForm.loadPlatform', error);
            });
        },
        {
          fireImmediately: true,
          equalityFn: shallow,
        }
      ),
    [location.pathname]
  );

  return (
    <Inner px={0}>
      <Fade show={submitted}>
        <PlatformInfoHeader>
          <Headline>Submitted</Headline>
          <PlatformInfoDescription>
            <p>We look forward to getting connected with you!</p>
            <p>
              You can build anything with Column through our{' '}
              <UnderlineLink href="https://column.com/docs/guides/sandbox-and-testing" newTab withoutArrow>
                sandbox environment
              </UnderlineLink>{' '}
              and learn more about{' '}
              <UnderlineLink href="https://column.com/docs/guides#usecases" newTab withoutArrow>
                all the ways you can partner with us
              </UnderlineLink>
              .
            </p>
          </PlatformInfoDescription>
        </PlatformInfoHeader>
      </Fade>

      <Fade show={!submitted}>
        <PlatformInfoHeader smallMargin>
          <Headline>Contact us</Headline>
        </PlatformInfoHeader>

        <Grid as="form" onSubmit={handleSubmit}>
          <FormSection>
            <PlatformInfoFormElement>
              <PlatformInfoFormLabelInline>First Name</PlatformInfoFormLabelInline>
              <Input placeholder="First name" onChange={(value: string) => setFirstName(value)} value={firstName} />
            </PlatformInfoFormElement>

            <PlatformInfoFormElement>
              <PlatformInfoFormLabelInline>Last Name</PlatformInfoFormLabelInline>
              <Input placeholder="Last name" onChange={(value: string) => setLastName(value)} value={lastName} />
            </PlatformInfoFormElement>

            <PlatformInfoFormElement>
              <PlatformInfoFormLabelInline>Your Email</PlatformInfoFormLabelInline>
              <Input placeholder="Email address" onChange={(value: string) => setEmail(value)} value={email} />
            </PlatformInfoFormElement>

            <PlatformInfoFormElement>
              <PlatformInfoFormLabelInline>
                Company Name <small>(Optional)</small>
                <PlatformInfoFormLabelTooltip content="The name of the company you work for or are representing for this financial product.">
                  <Icon.CircleQuestionmark />
                </PlatformInfoFormLabelTooltip>
              </PlatformInfoFormLabelInline>
              <Input
                placeholder="Company name"
                onChange={(value: string) => setCompanyName(value)}
                value={companyName}
              />
            </PlatformInfoFormElement>

            <PlatformInfoFormElement>
              <PlatformInfoFormLabelInline>
                Number of Employees <small>(Optional)</small>
                <PlatformInfoFormLabelTooltip content="The number of people who work at the company.">
                  <Icon.CircleQuestionmark />
                </PlatformInfoFormLabelTooltip>
              </PlatformInfoFormLabelInline>
              <Input placeholder="50" onChange={(value: string) => setCompanySize(value)} value={companySize} />
            </PlatformInfoFormElement>
          </FormSection>

          <FormWrapper>
            <FormHeader>
              <SmallHeadline>Which products are you interested in?</SmallHeadline>
              <Hint>(Select all that apply)</Hint>
            </FormHeader>

            <FinancialProductsList
              value={products}
              onChange={(value) => setProducts(value)}
              other={productsText}
              onChangeOther={(value) => setProductsText(value)}
            />
          </FormWrapper>

          <FormWrapper>
            <FormHeader>
              <SmallHeadline>
                Who are your customers? <small>(optional)</small>
              </SmallHeadline>
              <Hint>(Select all that apply)</Hint>
            </FormHeader>

            <div>
              <OptionList>
                {['Consumers', 'Sole Proprietorships', 'SMBs', 'Large Enterprises', 'Other'].map((option: string) => (
                  <Option
                    key={option}
                    isActive={customerTypes.includes(option)}
                    isSimple
                    onClick={() =>
                      setCustomerTypes((state) =>
                        customerTypes.includes(option) ? state.filter((i) => i !== option) : [...state, option]
                      )
                    }
                  >
                    <Checkbox
                      isChecked={customerTypes.includes(option)}
                      onCheckedChange={() =>
                        setCustomerTypes((state) =>
                          customerTypes.includes(option) ? state.filter((i) => i !== option) : [...state, option]
                        )
                      }
                      label={option}
                    />
                  </Option>
                ))}
              </OptionList>

              <OtherContainer isClose={!customerTypes.includes('Other')}>
                <PlatformInfoFormElement onClick={(e) => e.stopPropagation()}>
                  <StyledTextarea
                    placeholder="Other customer types"
                    onChange={(value: string) => setCustomerTypesOther(value)}
                    value={customerTypeOther}
                  />
                </PlatformInfoFormElement>
              </OtherContainer>
            </div>
          </FormWrapper>

          <FormWrapper>
            <FormHeader>
              <SmallHeadline>
                When do you want to go live? <small>(optional)</small>
                <PlatformInfoFormLabelTooltip
                  content="Timeline for when you'd like to launch a financial product."
                  largeMargin
                >
                  <Icon.CircleQuestionmark />
                </PlatformInfoFormLabelTooltip>
              </SmallHeadline>
            </FormHeader>

            <OptionList>
              {['< 1 Month', '1 - 3 Months', '3 - 6 Months', '6 Months +', 'I’m not sure'].map((option: string) => (
                <Option key={option} isActive={option === goLiveDate} isSimple onClick={() => setGoLiveDate(option)}>
                  <Radio
                    isChecked={option === goLiveDate}
                    onCheckedChange={(value) => value && setGoLiveDate(option)}
                    label={option}
                  />
                </Option>
              ))}
            </OptionList>
          </FormWrapper>

          <FormWrapper>
            <FormHeader>
              <SmallHeadline>
                Do you have a compliance function or compliance&nbsp;capabilities?
                <PlatformInfoFormLabelTooltip
                  content="Compliance helps ensure adherence to applicable laws, regulations and internal policies and procedures."
                  largeMargin
                >
                  <Icon.CircleQuestionmark />
                </PlatformInfoFormLabelTooltip>
              </SmallHeadline>
            </FormHeader>

            <ComplianceList>
              <ComplianceListOption
                isSimple
                isActive={complianceCapabilities === 'Yes'}
                onClick={() => setComplianceCapabilities('Yes')}
              >
                <OptionLabel>Yes</OptionLabel>
              </ComplianceListOption>
              <ComplianceListOption
                isSimple
                isActive={complianceCapabilities === 'No'}
                onClick={() => setComplianceCapabilities('No')}
              >
                <OptionLabel>No</OptionLabel>
              </ComplianceListOption>
              <ComplianceListOption
                isSimple
                isActive={complianceCapabilities === "I'm not sure"}
                onClick={() => setComplianceCapabilities("I'm not sure")}
              >
                <OptionLabel>I'm not sure</OptionLabel>
              </ComplianceListOption>
            </ComplianceList>
          </FormWrapper>

          <FormWrapper>
            <FormHeader>
              <SmallHeadline>Please include any other questions or comments you&nbsp;may&nbsp;have.</SmallHeadline>
            </FormHeader>
            <StyledTextarea
              placeholder="Comments"
              onChange={(value: string) => setOtherComment(value)}
              value={otherComment}
            />
          </FormWrapper>

          <Action>
            <Button isLoading={isLoading}>Submit</Button>
          </Action>
        </Grid>
      </Fade>
    </Inner>
  );
};
