import { PageLoansCreate } from './Create';
import { DISBURSEMENTS } from './Disbursement';
import { PageLoansEdit } from './Edit';
import { PageLoans } from './Overview';
import { PAYMENTS } from './Payment';

export const LOANS = {
  Loans: PageLoans,
  LoansEdit: PageLoansEdit,
  LoansCreate: PageLoansCreate,
  ...DISBURSEMENTS,
  ...PAYMENTS,
} as const;
