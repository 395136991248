export const getPrettyFilename = ({
  fileType,
  fileDescription,
  ext,
}: {
  fileType: string;
  fileDescription?: string | string[];
  ext: string;
}): string => {
  const nameParts = [fileType];
  if (fileDescription) {
    if (Array.isArray(fileDescription)) {
      nameParts.push(...fileDescription);
    } else {
      nameParts.push(fileDescription);
    }
  }

  return `${nameParts.join(', ')}.${ext}`;
};

export const downloadFile = (url: string, filename: string) => {
  const anchorElement = document.createElement('a');

  anchorElement.style.display = 'none';

  anchorElement.href = url;
  anchorElement.download = filename;

  anchorElement.click();
  anchorElement.remove();
};

export const downloadBlob = (content: BlobPart, filename: string) => {
  const blobData = new Blob([content], { type: 'text/csv' });
  const csvURL = URL.createObjectURL(blobData);
  const anchorElement = document.createElement('a');

  anchorElement.style.display = 'none';

  document.body.appendChild(anchorElement);

  anchorElement.href = csvURL;
  anchorElement.download = filename;

  anchorElement.click();
  URL.revokeObjectURL(anchorElement.href);
  anchorElement.remove();
};

export const downloadFilePromise = async (
  url: string,
  filename: string
): Promise<{ success: boolean; error?: Error }> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.blob();
    const downloadUrl = window.URL.createObjectURL(data);

    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();

    window.URL.revokeObjectURL(downloadUrl);

    return { success: true };
  } catch (error) {
    return { success: false, error: error as Error };
  }
};
