import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventCheckProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventCheck = forwardRef<SVGSVGElement, EventCheckProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 13.0002C4.89271 13.0002 10 13 14 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M10 10.0002C10.9642 10.0002 12.6667 9.99999 14 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86307 3.11681C10.3518 2.6281 11.1445 2.62737 11.6332 3.11681V3.11681C12.1219 3.60552 12.1219 4.39822 11.6332 4.88693L5.97957 10.5369C5.84285 10.6729 5.65813 10.75 5.4654 10.75H4V9.2846C4 9.09188 4.07636 8.90715 4.21308 8.77043L9.86307 3.11681V3.11681Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.41455 4.56836L10.1818 6.33557"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50244 4.98027L6.69586 3.78686C7.04057 3.44214 7.59982 3.44214 7.94527 3.78686V3.78686C8.28998 4.13157 8.28998 4.69083 7.94527 5.03627L7.11548 5.86606"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
});
