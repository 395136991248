import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '@column/column-ui-kit';

import 'highlight.js/styles/github.css';

import { useModalStore, ModalType } from '~/stores/Modal';
import { Headline } from '~/styles';
import { keysToSnakeCase } from '~/util';

import { ModalBase } from './Base';

hljs.registerLanguage('json', json);

const Wrapper = styled(ModalBase)`
  --modal-width: 600px;
  padding: 24px;
`;

const Code = styled.div`
  margin: 24px 0 16px 0;
  max-height: 600px;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 1.6;

  pre {
    margin: 0;
    padding: 0;

    code.hljs {
      background: none;
      padding: 0;
      font-family: 'Roboto Mono', monospace;
    }
  }
`;

export const ModalEventDetail: React.FC = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const modalTypes: ModalType[] = ['EventDetail'];
  const [data, setData] = useState<string>('');

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          setData(modalData?.data ?? '');
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  useEffect(() => {
    hljs.highlightAll();
  }, [data]);

  return (
    <Wrapper modalTypes={modalTypes}>
      <Headline>Event details</Headline>
      <Code>
        <pre>
          <code className="language-json">{JSON.stringify(keysToSnakeCase(data), null, 2)}</code>
        </pre>
      </Code>
      <Button fullWidth variant="subtle" onClick={() => closeModal()}>
        Close
      </Button>
    </Wrapper>
  );
};
