import React, { MouseEvent, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import { NotificationList } from '../NotificationList';
import { AlertSize, AlertType, useAlertStore } from '~/stores/Alert';
import { ModalType } from '~/stores/Modal';
import { Headline, ModalIconCircle, ModalText, Paragraph } from '~/styles';

import { ModalBase } from './Base';

interface StyledWrapperProps {
  $size?: AlertSize;
}

const StyledWrapper = styled(ModalBase)<StyledWrapperProps>`
  --modal-width: ${(props) => (props.$size === 'small' ? '330px' : '550px')};
  padding: 24px;
`;

const Circle = styled(ModalIconCircle)<Pick<AlertType, 'type'>>`
  background-color: ${({ type, theme }) =>
    type === 'info' ? theme.primary.blendToBackground(1000, 100) : theme.danger.blendToBackground(1000, 100)};

  svg {
    --icon-color: ${({ type, theme }) => (type === 'info' ? theme.primary.background : theme.danger.background)};
  }
`;

export const Buttons = styled.div<{ grow?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0 -24px -24px -24px;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}

  ${({ grow }) =>
    grow &&
    css`
      * {
        flex-grow: 1;
      }
    `}
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 8px;
`;

export const ModalAlert: React.FC = () => {
  const { alert, closeAlert, alertData } = useAlertStore();
  const modalTypes: ModalType[] = ['Alert'];

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();

    setTimeout(() => {
      alert?.callback(alertData);

      if (!alertData?.submitStay) {
        closeAlert();
      }
    }, alertData?.closeTimeoutMs ?? 0);
  };

  const handleClose = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      closeAlert();
    },
    [closeAlert]
  );

  return (
    <StyledWrapper modalTypes={modalTypes} $size={alert?.size}>
      <Circle type={alert?.type}>
        {alert?.icon ?? (alert?.type === 'info' ? <Icon.CircleInfo /> : <Icon.Warning />)}
      </Circle>
      <ModalText>
        <StyledHeadline fullWidth size="small">
          {alert?.headline}
        </StyledHeadline>
        <div>
          <NotificationList display="popup" />
          {typeof alert?.text === 'string' ? <Paragraph>{alert?.text}</Paragraph> : alert?.text}
        </div>
      </ModalText>
      <Buttons grow>
        <StyledButton variant="secondary" onClick={handleClose}>
          Cancel
        </StyledButton>
        <StyledButton
          variant={alert?.type === 'info' ? 'primary' : 'danger'}
          onClick={handleClick}
          isLoading={alertData?.submitLoading}
        >
          {alert?.submitText ?? 'Delete'}
        </StyledButton>
      </Buttons>
    </StyledWrapper>
  );
};
