import { ApiError } from '~/typings/API';

type SuccessEvent<T> = (response: T) => void;
type ErrorEvent = (error: any | ApiError) => void;

export const clientWrapper = <T>(onSuccess: SuccessEvent<T> | undefined, onError: ErrorEvent, promise: Promise<T>) => {
  return promise.then(onSuccess).catch((error) => {
    if (error === 'Unauthorized') {
      return;
    }

    onError(error);
  });
};
