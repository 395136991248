import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventReportProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventReport = forwardRef<SVGSVGElement, EventReportProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 4C3.75 3.30964 4.30964 2.75 5 2.75H9.17157C9.50309 2.75 9.82104 2.8817 10.0555 3.11612L12.8839 5.94454C13.1183 6.17896 13.25 6.49691 13.25 6.82843V12C13.25 12.6904 12.6904 13.25 12 13.25H5C4.30964 13.25 3.75 12.6904 3.75 12V4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M6.5 9.75H10.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 3V6C9 6.55228 9.44772 7 10 7H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </SVG>
  );
});
