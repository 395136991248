export const validateEmail = (value: string) => {
  return /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/.test(
    value
  );
};

export const validateURL = (value?: string) => {
  return value && /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(value);
};

export const validateMinMax = (value: string, min: number, max: number) => {
  if (Number(value) >= min && Number(value) <= max) {
    return true;
  }
  return false;
};

export const validatePhoneIntl = (value: string): boolean => {
  return /^(\+[0-9]{1,3}-[0-9()+\-]{1,30})?$/.test(String(value));
};

export const validateRoutingNumber = (value: string) => {
  if (value.length !== 9) {
    return false;
  }

  const checksumTotal =
    7 * (parseInt(value.charAt(0), 10) + parseInt(value.charAt(3), 10) + parseInt(value.charAt(6), 10)) +
    3 * (parseInt(value.charAt(1), 10) + parseInt(value.charAt(4), 10) + parseInt(value.charAt(7), 10)) +
    9 * (parseInt(value.charAt(2), 10) + parseInt(value.charAt(5), 10) + parseInt(value.charAt(8), 10));

  return !(checksumTotal % 10 !== 0);
};

export const validateBIC = (value: string) => {
  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(value.toUpperCase());
};

export const isValidIBANFormat = (iban: string): boolean => {
  const cleanedIBAN = iban.replace(/\s/g, '').toUpperCase();

  const countryCode = cleanedIBAN.slice(0, 2);

  const nonIBANCountries = [
    'AU',
    'CA',
    'CN',
    'JP',
    'IN',
    'NZ',
    'US',
    'HK',
    'SG',
    'MX',
    'ZA',
    'BR',
    'MY',
    'KR',
    'AR',
    'CL',
    'ID',
    'TH',
  ];

  if (nonIBANCountries.includes(countryCode)) {
    return false;
  }

  const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]+$/;

  return ibanRegex.test(cleanedIBAN);
};

export const validateNumber = (value: string) => {
  return /^-?\d+$/.test(value);
};

export const validate9Digits = (value?: string) => {
  return value && value.toString().replaceAll('-', '').length === 9;
};

export const validate4Digits = (value?: string) => {
  return value && value.toString().replaceAll('-', '').length === 4;
};

export const isValidDate = (date: any) => {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
};

export const isValidBase64Image: (base64String?: string) => Promise<string> = async (base64String) => {
  return new Promise((resolve, reject) => {
    if (typeof base64String === 'undefined' || base64String.trim().length === 0) {
      reject(new Error('Invalid or empty Base64 string.'));
      return;
    }

    const image = new Image();
    image.src = base64String;

    image.onload = () => {
      if (image.height > 0 && image.width > 0) {
        resolve(base64String);
      } else {
        reject(new Error('Image has zero dimensions.'));
      }
    };

    image.onerror = () => {
      reject(new Error('Image could not be loaded.'));
    };
  });
};
