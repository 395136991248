import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventOutgoingProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventOutgoing = forwardRef<SVGSVGElement, EventOutgoingProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 11.5L11.5 4.5M11.5 4.5H5.9M11.5 4.5V10.1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SVG>
  );
});
