import React from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

interface MarkdownProps {
  markdown: Options['children'];
}

const StyledMarkdown = styled(ReactMarkdown)`
  max-width: 960px;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary.background};

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
    color: ${({ theme }) => theme.foreground};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 24px;
    padding: 0;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
    margin: 24px 0 6px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  strong {
    color: ${({ theme }) => theme.foreground};
  }

  a {
    color: ${({ theme }) => theme.primary.background};
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  ul,
  ol {
    padding: 0 0 0 1em;
    margin: 0;
    counter-reset: item;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: none;
  }

  ol > li {
    padding-left: 0;
  }

  ol li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    float: left;
    margin: 0 0.5em 0 -1em;
    font-weight: 600;
  }

  ul li:before {
    content: '';
  }

  li::marker {
    font-weight: 600;
  }

  ol > li > ol > li {
    font-weight: normal;
    padding-left: 1em;
  }

  ol > li > ol > li > ol {
    list-style-type: lower-alpha;
    padding-left: 1em;
  }

  ol > li > ol > li > ol > li:before {
    content: '';
  }

  li {
    line-height: 20px;
    position: relative;
  }

  li ul,
  li ul {
    margin-left: 24px;
  }

  p,
  ul,
  ol {
    font-size: 14px;
    line-height: 20px;
  }

  pre {
    padding: 0px 24px;
    white-space: pre-wrap;
  }

  code {
    font-family:
      Consolas,
      Monaco,
      Andale Mono,
      monospace;
    line-height: 1.5;
    font-size: 13px;
  }

  aside {
    display: block;
    float: right;
    width: 390px;
  }

  blockquote {
    margin: 1em 2em;
  }

  blockquote p {
    color: #666;
  }

  hr {
    width: 540px;
    text-align: left;
    margin: 0 auto 0 0;
    color: #999;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
    border-radius: 8px;
  }

  table thead {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};
  }

  table thead th {
    text-align: left;
    padding: 8px 16px;
    color: ${({ theme }) => theme.foreground};
    border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
    border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
    border-top-left-radius: 8px;

    &:last-child {
      border-right: 0;
      border-top-right-radius: 8px;
    }
  }

  table td {
    padding: 0.5em 1em;
    border-right: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
    border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};

    &:last-child {
      border-right: 0;
    }
  }

  table tbody tr:last-child td {
    border-bottom: 0;
  }
`;

export const Markdown: React.FC<MarkdownProps> = ({ markdown }) => {
  return <StyledMarkdown remarkPlugins={[remarkGfm]}>{markdown}</StyledMarkdown>;
};
