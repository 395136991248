import React, { isValidElement, ReactNode } from 'react';
import { FieldValues, Path } from 'react-hook-form';
import styled from 'styled-components';

import { FormField, FormFieldProps } from './FormField';
import { CounterpartyFieldProps, CounterpartyModalType, FormFieldCounterparty } from './FormFieldCounterparty';
import { Section, SectionProps } from './Section';
import { SectionCustom } from './Section/Custom';

export const TwoFieldsWrapperFirst = styled.div`
  display: flex;
  gap: 20px;

  & > *:last-child {
    flex-grow: 1;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 12px;
`;

const CustomField = styled.div``;

type isCounterparty = CounterpartyModalType | undefined;

type Field<T extends FieldValues, P extends Path<T> | undefined> = {
  id: P extends keyof T ? Path<T[P]> : Path<T>;
  updateOn?: P extends keyof T ? Path<T[P]> : Path<T>;
  hide?: boolean;
  isCounterparty?: isCounterparty;
  nextToPrevious?: boolean;
} & Omit<FormFieldProps<T>, 'id' | 'updateOn'> &
  Partial<CounterpartyFieldProps>;

type FieldContent = {
  hide?: boolean;
  content: ReactNode;
};

type SectionField<T extends FieldValues, P extends Path<T> | undefined> = Field<T, P> | FieldContent;

export type SectionWithFields<T extends FieldValues, P extends Path<T> | undefined> = (
  | {
      fields: SectionField<T, P>[];
    }
  | {
      content: ReactNode;
    }
) & {
  subSections?: SectionWithFields<T, P>[];
} & SectionProps;

type RenderFieldsProps<T extends FieldValues, P extends Path<T> | undefined = undefined> = {
  prefix?: P | undefined;
  sections: SectionWithFields<T, P>[];
};

export const getFullPath = <T extends FieldValues, P extends Path<T> | undefined = undefined>(
  prefix: P | undefined,
  path: P extends keyof T ? Path<T[P]> : Path<T>
) => {
  return prefix ? (`${String(prefix)}.${String(path)}` as Path<T>) : (path as Path<T>);
};

export const RenderFields = <T extends FieldValues, P extends Path<T> | undefined = undefined>({
  sections,
  prefix,
}: RenderFieldsProps<T, P>) => {
  return sections.map((section, index) => {
    const sectionProps: SectionProps = {
      headline: section.headline,
      isCollapsed: section.isCollapsed,
      isDisabled: section.isDisabled,
      description: section.description,
      variant: section.variant,
    };
    return (
      <Section key={index} {...sectionProps}>
        {'fields' in section &&
          section.fields.map((field: Field<T, P> | FieldContent, fieldIndex) => {
            if ('content' in field && isValidElement(field?.content)) {
              return !field?.hide && <CustomField key={fieldIndex}>{field.content}</CustomField>;
            }

            const { isCounterparty, children, id, onLoaded, updateOn, ...fieldProps } = field as Field<T, P>;

            const fieldPropsTyped: Pick<FormFieldProps<T>, 'id' | 'updateOn'> = {
              id: getFullPath(prefix, id),
              updateOn: (updateOn ? (prefix ? `${String(prefix)}.${String(id)}` : id) : undefined) as
                | Path<T>
                | undefined,
            };

            const fieldComponent =
              typeof isCounterparty === 'string' || typeof isCounterparty === 'object' ? (
                <FormFieldCounterparty<T>
                  {...fieldProps}
                  {...fieldPropsTyped}
                  type={isCounterparty}
                  onLoaded={onLoaded}
                  key={fieldIndex}
                >
                  {children}
                </FormFieldCounterparty>
              ) : (
                <FormField<T> {...fieldProps} {...fieldPropsTyped} key={fieldIndex}>
                  {children}
                </FormField>
              );

            return fieldComponent;
          })}

        {'content' in section && <SectionCustom>{section.content}</SectionCustom>}

        {section.subSections && <RenderFields sections={section.subSections} prefix={prefix} />}
      </Section>
    );
  });
};
