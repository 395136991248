import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { Dropdown, DropdownElement, Input } from '@column/column-ui-kit';

import { AddressField } from '~/components';
import { useAddress } from '~/hooks';
import { AddressType } from '~/repositories';
import { Divider, FormElement, FormLabel } from '~/styles';

interface AddressProps {
  formLabel?: string;
  id: string;
}

const GridLeft = styled.div`
  max-width: 100%;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 60% auto;
`;

const GridRight = styled.div`
  max-width: 100%;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: auto 60%;
`;

export const Address: React.FC<AddressProps> = ({ id, formLabel }) => {
  const stateRef = useRef<DropdownElement>(null);
  const { control, watch, getValues, setValue } = useFormContext();
  const [address, setAddress] = useState<AddressType>({});
  const { states, countries } = useAddress({ types: ['state', 'country'] });

  useEffect(() => {
    for (const [key, value] of Object.entries(address)) {
      const addressKey = key as keyof AddressType;

      if (value !== getValues(`${id}.${addressKey}`)) {
        setValue(`${id}.${addressKey}`, value);
      }
    }
  }, [address]);

  return (
    <>
      <FormElement>
        <FormLabel>{formLabel ?? 'Address'}</FormLabel>
        <AddressField
          onChange={(a) => {
            setAddress(a);
            if (!a.state) {
              stateRef.current?.open();
            }
          }}
        />
      </FormElement>
      <Divider>OR</Divider>
      <FormElement>
        <FormLabel>Address line 1</FormLabel>
        <Controller
          name={`${id}.line1`}
          control={control}
          defaultValue=""
          rules={{
            required: true,
          }}
          render={({ field, fieldState: { error, isTouched } }) => (
            <Input placeholder="Line 1" hasError={isTouched && !!error} {...field} />
          )}
        />
      </FormElement>
      <FormElement>
        <FormLabel>Line 2</FormLabel>
        <Controller
          name={`${id}.line2`}
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error, isTouched } }) => (
            <Input placeholder="Line 2" hasError={isTouched && !!error} {...field} />
          )}
        />
      </FormElement>
      <GridRight>
        <FormElement>
          <FormLabel>Postal code</FormLabel>
          <Controller
            name={`${id}.postalCode`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
              minLength: 5,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="Postal code" hasError={isTouched && !!error} {...field} />
            )}
          />
        </FormElement>
        <FormElement>
          <FormLabel>Country</FormLabel>
          <Controller
            name={`${id}.countryCode`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Dropdown
                onChange={onChange}
                active={value}
                hasError={!!error}
                options={countries.map((c) => ({
                  label: c.name,
                  value: c.code,
                }))}
                search
                fullWidth
                variant="muted"
              />
            )}
          />
        </FormElement>
      </GridRight>
      <GridLeft>
        <FormElement>
          <FormLabel>City</FormLabel>
          <Controller
            name={`${id}.city`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <Input placeholder="City" hasError={isTouched && !!error} {...field} />
            )}
          />
        </FormElement>
        <FormElement>
          <FormLabel>State</FormLabel>
          <Controller
            name={`${id}.state`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Dropdown
                ref={stateRef}
                onChange={onChange}
                active={value}
                hasError={!!error}
                options={
                  watch(`${id}.countryCode`)
                    ? states
                        .filter((s) => s.country_code === watch(`${id}.countryCode`))
                        .map((s) => ({
                          label: `${s.name} (${s.state_code})`,
                          value: s.state_code,
                        }))
                    : []
                }
                search
                fullWidth
                variant="muted"
              />
            )}
          />
        </FormElement>
      </GridLeft>
    </>
  );
};
