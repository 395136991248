import { useState, useEffect } from 'react';

type useAddressType = 'state' | 'country';

interface useAddressProps {
  types: useAddressType[];
  countryCodes?: string[];
}

export const useAddress = (props: useAddressProps) => {
  const [states, setStates] = useState<State[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    if (props.types.includes('state')) {
      import('~/data/states.json').then((json) => {
        setStates(json.default);
      });
    }

    if (props.types.includes('country')) {
      import('~/data/countries.json').then((json) => {
        setCountries(json.default);
      });
    }
  }, [props.types]);

  return {
    states: states.filter((s) => {
      if (props.countryCodes && !props.countryCodes.includes(s.country_code)) {
        return false;
      }
      return true;
    }),
    countries: countries.filter((c) => {
      if (props.countryCodes && !props.countryCodes.includes(c.code)) {
        return false;
      }
      return true;
    }),
  };
};
