import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

import { Tooltip } from '@column/column-ui-kit';

interface OptionGroupProps {
  orientation?: 'horizontal' | 'vertical';
  children?: React.ReactNode;
}

interface OptionProps {
  isActive?: boolean;
  isDisabled?: boolean;
  isMuted?: boolean;
}

export const OptionLabel = styled.label<OptionProps>`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.background};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: color 0.2s;
`;

export const OptionDescription = styled.div<OptionProps>`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  font-size: 12px;
  line-height: 1.5;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const Option = styled.div<OptionProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  line-height: 1;
  position: relative;
  padding: 16px 16px 16px 40px;
  color: ${({ theme }) => theme.secondary.background};
  opacity: ${({ isDisabled }) => (isDisabled ? '.8' : '1')};
  display: flex;
  gap: 4px;
  flex-direction: column;

  &:hover {
    ${OptionLabel} {
      color: ${({ theme }) => theme.foreground};
    }
  }

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.style.borderRadiusM};
    border-top-right-radius: ${({ theme }) => theme.style.borderRadiusM};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.style.borderRadiusM};
    border-bottom-right-radius: ${({ theme }) => theme.style.borderRadiusM};
  }

  background-color: ${({ isMuted, theme }) => (isMuted ? theme.secondary.blendToBackground(50) : 'transparent')};

  > *:first-child {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    ${OptionLabel} {
      color: ${theme.foreground};
    }
  `}
`;

export const OptionTooltip = styled(Tooltip)`
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  transition: color 0.2s;

  svg {
    --icon-size: 16px;
  }

  &:hover {
    color: ${({ theme }) => theme.secondary.background};
  }
`;

export const GroupWrapper = styled.div<OptionGroupProps>`
  background-color: ${({ theme }) => theme.background};
  border-radius: ${({ theme }) => theme.style.borderRadiusM};
  box-shadow:
    ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
      ${({ theme }) => theme.style.buttonSecondaryBorderWidth} ${({ theme }) => theme.style.buttonSecondaryBorderColor},
    0 1px 1px rgba(0, 0, 0, 0.1),
    0 1px 4px 0 transparent inset;
  display: grid;
  grid-template-columns: 1fr;

  ${Option} {
    border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

    &:last-child {
      border-bottom: none;
    }
  }

  ${({ theme, orientation }) =>
    orientation === 'horizontal' &&
    `
    grid-template-columns: 1fr 1fr;

    ${Option} {
      &:nth-last-child(2):not(:nth-child(even)),
      &:last-child {
        border-bottom: none;
      }

      &:nth-child(odd) {
        border-right: 1px solid ${theme.secondary.blendToBackground(150)};
      }
    }
  `}

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.style.dropdownBackgroundColor};
    `}
`;

export const OptionGroup: React.FC<PropsWithChildren<OptionGroupProps>> = ({ orientation, children }) => {
  return <GroupWrapper orientation={orientation}>{children}</GroupWrapper>;
};
