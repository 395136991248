import ACHAuthorizationTemplate from './ACHAuthorizationTemplate.pdf';
import ColumnProhibitedCategories from './Column-Prohibited-Categories.pdf';
import COLUMN from './column.png';
import CommercialDepositAccountAgreement_March2023 from './CommercialDepositAccountAgreement_March2023.pdf';
import csv from './csv.svg';
import gif from './gif.svg';
import html from './html.svg';
import jpeg from './jpeg.svg';
import jpg from './jpg.svg';
import json from './json.svg';
import pattern from './pattern.svg';
import pdf from './pdf.svg';
import png from './png.svg';
import SCREENSHOT from './screenshot.png';
import SCREENSHOT2x from './screenshot@2x.png';
import tiff from './tiff.svg';
import txt from './txt.svg';
import unknown from './unknown.svg';
import WELCOME from './welcome.png';
import WELCOME2X from './welcome@2x.png';
import zip from './zip.svg';

export const IMAGE_URL = {
  COLUMN,
  WELCOME,
  WELCOME2X,
  SCREENSHOT,
  SCREENSHOT2x,
} as const;

export const SVG_URL = {
  pattern,
} as const;

export const VIDEO_URL = {} as const;

export const PDF_URL = {
  ACHAuthorizationTemplate,
  CommercialDepositAccountAgreement_March2023,
  ColumnProhibitedCategories,
} as const;

export const FILE_ICONS_URL = {
  csv,
  gif,
  html,
  jpeg,
  jpg,
  json,
  pdf,
  png,
  tiff,
  txt,
  unknown,
  zip,
} as const;

export type FileIconsUrlType = keyof typeof FILE_ICONS_URL;
