import { RefObject, useEffect, useState } from 'react';

export const useScrollPosition = (container: RefObject<HTMLElement>) => {
  const [scrollPosition, setPosition] = useState<{ scrollX: number; scrollY: number }>({ scrollX: 0, scrollY: 0 });

  const updatePosition = () => {
    setPosition({ scrollX: container.current?.scrollLeft ?? 0, scrollY: container.current?.scrollTop ?? 0 });
  };

  useEffect(() => {
    const element = container.current;

    if (!element) {
      return;
    }

    element.addEventListener('scroll', updatePosition);

    updatePosition();

    return () => element.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};
