import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface EventBookTransferProps {
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
`;

export const EventBookTransfer = forwardRef<SVGSVGElement, EventBookTransferProps>((props, ref) => {
  const size = props.size ?? '16px';

  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10.25L7 10.25"
        stroke="var(--icon-color, currentColor)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11 8.25L13 10.2514L11 12.25"
        stroke="var(--icon-color, currentColor)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4 5.75L9 5.75"
        stroke="var(--icon-color, currentColor)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M5 3.75L3 5.75139L5 7.75"
        stroke="var(--icon-color, currentColor)"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </SVG>
  );
});
