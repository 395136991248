import React, { useRef } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, Icon } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { EmptyState, PageHeader, PaginationWrapper, PaginationWrapperRefProps, TableColumn } from '~/components';
import { RelativeTime } from '~/elements';
import { useNavigate } from '~/lib/navigation';
import { WebhookRepository } from '~/repositories';
import { useAlertStore } from '~/stores/Alert';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

const URL = styled.div`
  white-space: nowrap;
`;

const StyledDropdown = styled(Dropdown)`
  margin: -10px 0;
`;

export const PageWebhooks: React.FC = () => {
  const currentUser = useSessionStore((state) => state.currentUser);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const openAlert = useAlertStore((state) => state.openAlert);
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleDelete = (entry: any) => {
    openAlert({
      headline: 'Delete webhook',
      text: 'This action cannot be undone.',
      submitText: 'Delete',
      callback: () => {
        if (!currentUser) {
          return;
        }
        WebhookRepository.delete(entry.id, entry.type === 'Sandbox')
          .then(() => {
            addSuccessNotification({
              content: `${entry.url} deleted`,
            });
            if (paginationRef.current) {
              paginationRef.current.fetch();
            }
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
            });
          });
      },
    });
  };

  const handleFetch = async (params: any) => {
    return WebhookRepository.getAllList(params).then((response) => {
      const entries: any = [];
      if (response.webhookEndpoints.length) {
        response.webhookEndpoints.map((entry: any) => {
          entries.push({
            id: entry.id,
            url: entry.url,
            type: entry.type,
            status: entry.isDisabled ? 'Disabled' : 'Active',
            created: entry.createdAt,
            action: (
              <StyledDropdown
                isInside
                positionRight
                options={[
                  {
                    label: 'Edit',
                    onClick: () => navigate(`${ROUTE.PLATFORM_WEBHOOKS}/edit/${entry.type}/${entry.id}`),
                  },
                  {
                    label: 'Delete',
                    isDanger: true,
                    onClick: () => handleDelete(entry),
                  },
                ]}
                variant="dots"
              />
            ),
          });
        });
      }
      return { entries, hasMore: false };
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'URL',
      accessor: 'url',
      width: 'minmax(min-content, auto)',
      Cell: (current) => <URL>{current.value.replace('https://', '')}</URL>,
    },
    {
      Header: 'Type',
      width: 'min-content',
      Cell: (current) => (
        <Chip icon={current.value === 'Production' ? <Icon.CircleCheck /> : <Icon.Sandbox />}>{current.value}</Chip>
      ),
      accessor: 'type',
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 'min-content',
      Cell: (current) =>
        current.value === 'Disabled' ? <Chip type="danger">Disabled</Chip> : <Chip type="info">Active</Chip>,
    },
    {
      Header: 'Created',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
      accessor: 'created',
      sortType: 'datetime',
    },
    {
      Header: '',
      width: 'min-content',
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader text="Webhooks">
        <Button onClick={() => navigate(`${ROUTE.PLATFORM_WEBHOOKS}/edit`)} icon={<Icon.Plus />} size="small">
          Create Webhook
        </Button>
      </PageHeader>

      <PaginationWrapper
        tableId="webhooks"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        empty={
          <EmptyState headline="No webhooks" text="Add your first webhook to get started.">
            <Button onClick={() => navigate(`${ROUTE.PLATFORM_WEBHOOKS}/edit`)} size="small" icon={<Icon.Plus />}>
              Create Webhook
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.PLATFORM_WEBHOOKS}/edit/${row.original.type}/${row.original.id}`);
        }}
      />
    </>
  );
};
