import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

export interface PaginationProps {
  className?: string;
  current: number;
  disablePrev?: boolean;
  disableNext?: boolean;
  onPrev?: () => void;
  onNext?: () => void;
}

const Wrapper = styled.div`
  display: table;
`;

const Button = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 6px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  color: ${({ theme }) => theme.secondary.background};
  background-color: ${({ theme }) => theme.secondary.blendToBackground(100)};
  transition:
    background-color 0.1s,
    color 0.1s;

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};
    background-color: transparent;
  }

  svg {
    --icon-size: 16px;
    --icon-color: currentColor;
  }
`;

const Pages = styled.div`
  display: inline-flex;
  vertical-align: top;
  gap: 12px;
  margin: 0 12px;
`;

const Page = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  line-height: 28px;
  width: 28px;
  height: 28px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 5px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  transition:
    background-color 0.1s,
    color 0.1s;

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        color: ${({ theme }) => theme.secondary.background};
        background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary.foreground};
      background-color: ${({ theme }) => theme.primary.background};
    `}
`;

export const Pagination: FC<PaginationProps> = (props) => {
  const handlePrevClick = () => {
    if (props.onPrev) {
      props.onPrev();
    }
  };

  const handleNextClick = () => {
    if (props.onNext) {
      props.onNext();
    }
  };

  return (
    <Wrapper className={props.className}>
      <Button onClick={handlePrevClick} disabled={props.disablePrev}>
        <Icon.ChevronLeft />
      </Button>
      <Pages>
        {!props.disablePrev && <Page onClick={handlePrevClick}>{props.current - 1}</Page>}
        <Page isActive>{props.current}</Page>
        {!props.disableNext && <Page onClick={handleNextClick}>{props.current + 1}</Page>}
      </Pages>
      <Button onClick={handleNextClick} disabled={props.disableNext}>
        <Icon.ChevronRight />
      </Button>
    </Wrapper>
  );
};
