import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import UTIF from '@column/utif';

import { convertCleanBase64 } from '~/util';

interface Props {
  src?: string;
}

const BASE64_TIFF_DATA_PREFIX = 'data:image/tiff;base64';

const base64ToUint8Array = (base64: string) => {
  const binaryString = atob(base64);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
};

const StyledCanvas = styled.canvas`
  display: block;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export const ImageViewer: React.FC<Props> = ({ src }) => {
  const isTiff = useMemo(() => {
    if (!src) {
      return false;
    }
    return src.startsWith(BASE64_TIFF_DATA_PREFIX);
  }, [src]);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!isTiff || !src) {
      return;
    }

    const cleanImageData = convertCleanBase64(src);
    if (!cleanImageData) {
      return;
    }

    const renderTiff = async () => {
      const tiffUint8Array = base64ToUint8Array(cleanImageData);

      const ifds = UTIF.decode(tiffUint8Array);
      if (ifds.length === 0) {
        throw new Error('Failed to decode IFDs from image data');
      }
      UTIF.decodeImage(tiffUint8Array, ifds[0]);

      const firstImage = ifds[0];
      const rgba = UTIF.toRGBA8(firstImage);

      const canvas = canvasRef.current;
      if (!canvas) {
        throw new Error('No canvas found');
      }

      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('No 2d context found');
      }
      canvas.width = firstImage.width;
      canvas.height = firstImage.height;

      const imageData = new ImageData(new Uint8ClampedArray(rgba), firstImage.width, firstImage.height);
      context.putImageData(imageData, 0, 0);
    };

    renderTiff().catch((error) => console.error('Error rendering TIFF image:', error));
  }, [src]);

  return isTiff ? <StyledCanvas ref={canvasRef} /> : <StyledImage src={src} />;
};
