import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Icon, Input, Button, Textarea, Chip, SegmentedControl } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { SectionHeader, CopyInput, RestrictedBanner, PageHeader } from '~/components';
import { ApiKeyRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EditSection, EditToolbar, FormElement, FormLabel, FormText, Inner, FormGroup } from '~/styles';

interface Params {
  id: string;
  URLType: string;
}

export const PageApiKeysEdit: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id, URLType } = useParams<keyof Params>() as Params;
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [type, setType] = useState<string>('sandbox');
  const [apiKey, setApiKey] = useState<string>('');

  const handleSave = () => {
    if (!id || !currentUser) {
      return;
    }
    ApiKeyRepository.update(currentUser.defaultPlatformId, id, name, description, URLType === 'Sandbox')
      .then(() => {
        addSuccessNotification({
          content: 'API key updated',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }
    if (id) {
      handleSave();
      return;
    }
    ApiKeyRepository.create(currentUser.defaultPlatformId, name, description, type === 'sandbox')
      .then((response) => {
        navigate(`${ROUTE.PLATFORM_API_KEYS}/edit/${type === 'sandbox' ? 'Sandbox' : 'Production'}/${response.id}`);
        addSuccessNotification({
          content: 'API key created',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchData = () => {
    if (!currentUser) {
      return;
    }

    ApiKeyRepository.get(currentUser.defaultPlatformId, id, URLType === 'Sandbox')
      .then((response) => {
        setName(response.name);
        setDescription(response.description);
        setApiKey(response.apiKeyToken);
      })
      .catch((error: any) => {
        navigate(ROUTE.PLATFORM_API_KEYS);
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    if (id && currentUser) {
      fetchData();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (id && currentUser) {
            fetchData();
          }
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text={id ? 'Edit API Key' : 'Create API Key'} />

      {currentPermission?.apiKeys !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <SectionHeader text="General" />
      <Inner py={0}>
        <FormGroup data-disabled={currentPermission?.apiKeys !== 'write'}>
          <FormElement fullWidth>
            <FormLabel>Name</FormLabel>
            <Input value={name} onChange={setName} placeholder="Name" />
          </FormElement>

          <FormElement fullWidth>
            <FormLabel>Description</FormLabel>
            <Textarea value={description} onChange={setDescription} placeholder="Description" />
          </FormElement>

          <FormElement fullWidth>
            <FormLabel>Environment</FormLabel>
            {id ? (
              <FormText>
                <Chip icon={URLType === 'Production' ? <Icon.CircleCheck /> : <Icon.Sandbox />}>{URLType}</Chip>
              </FormText>
            ) : (
              <SegmentedControl
                options={[
                  {
                    label: (
                      <>
                        <Icon.CircleCheck />
                        Production
                      </>
                    ),
                    value: 'production',
                  },
                  {
                    label: (
                      <>
                        <Icon.Sandbox />
                        Sandbox
                      </>
                    ),
                    value: 'sandbox',
                  },
                ]}
                active={type}
                onOptionChange={setType}
                withIcon
                isDisabled={!currentPlatform?.isLiveEnabled}
              />
            )}
          </FormElement>
        </FormGroup>
      </Inner>

      <EditSection>
        <SectionHeader text="Keys" border />
        <Inner py={0}>
          <FormGroup>
            <FormElement fullWidth>
              <FormLabel>API Key</FormLabel>
              <CopyInput value={apiKey} placeholder="Key Will be Generated" icon={<Icon.CopyCode />} />
            </FormElement>
          </FormGroup>
        </Inner>
      </EditSection>
      <Inner py={0}>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary" size="small">
            {id ? 'Back' : 'Cancel'}
          </Button>
          <Button
            onClick={handleSubmit}
            isDisabled={currentPermission?.apiKeys !== 'write'}
            size="small"
            icon={!id && <Icon.Plus />}
          >
            {id ? 'Save' : 'Create API Key'}
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
