import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DropdownElement, Icon, Input, Button, Toggle, Dropdown, Chip } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { CopyInput, AddressField, RestrictedBanner, PageHeader, SectionHeader } from '~/components';
import { RoutingNumberField } from '~/components/RoutingNumberField';
import { useAddress } from '~/hooks';
import { CreateCounterparty, CounterpartyRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Divider, EditToolbar, FormElement, FormLabel, FormText, Inner, Grid } from '~/styles';
import { formatString } from '~/util';

interface Params {
  id: string;
}

export const PageCounterpartiesEdit: FC = () => {
  const { currentUser, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  const [counterparty, setCounterparty] = useState<DeepPartial<CreateCounterparty>>({});
  const { states, countries } = useAddress({ types: ['state', 'country'] });
  const stateRef = useRef<DropdownElement>(null);

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    if (id) {
      return;
    }

    CounterpartyRepository.create(counterparty as CreateCounterparty)
      .then(() => {
        navigate(ROUTE.COUNTERPARTIES);
        addSuccessNotification({
          content: 'Counterparty added',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  const fetchCounterparty = () => {
    CounterpartyRepository.get({ id })
      .then((response) => {
        setCounterparty(response);
      })
      .catch((error) => {
        navigate(ROUTE.COUNTERPARTIES);
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    if (id) {
      fetchCounterparty();
    }
  }, [id]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          if (id) {
            fetchCounterparty();
          }
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text={id ? 'View Counterparty' : 'New Counterparty'} />

      {currentPermission?.counterparties !== 'write' && !id && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <SectionHeader text="Information" />
      <Inner data-disabled={currentPermission?.counterparties !== 'write'} pt={16}>
        <Grid>
          <FormElement>
            <FormLabel>Description</FormLabel>
            {id ? (
              <FormText>{counterparty.description ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) => setCounterparty({ ...counterparty, description: value })}
                value={counterparty.description ?? ''}
                placeholder="Description"
              />
            )}
          </FormElement>
          {id && (
            <FormElement>
              <FormLabel>ID</FormLabel>
              <CopyInput value={id} />
            </FormElement>
          )}
          {id ? (
            <FormElement newRow>
              <FormLabel>Routing Number</FormLabel>
              <FormText>{counterparty.routingNumber}</FormText>
            </FormElement>
          ) : (
            <RoutingNumberField
              routingNumber={counterparty.routingNumber ?? ''}
              onRoutingNumberChange={(routingNumber: string) => setCounterparty({ ...counterparty, routingNumber })}
            />
          )}
          <FormElement>
            <FormLabel>Account Number</FormLabel>
            {id ? (
              <FormText>{counterparty.accountNumber}</FormText>
            ) : (
              <Input
                onChange={(value: string) => setCounterparty({ ...counterparty, accountNumber: value })}
                value={counterparty.accountNumber ?? ''}
                placeholder="Account number"
              />
            )}
          </FormElement>
          <FormElement newRow>
            <FormLabel>Routing Number Type {!id && <small>optional</small>}</FormLabel>
            {id ? (
              <FormText>{(counterparty?.routingNumberType ?? '-').toUpperCase()}</FormText>
            ) : (
              <Dropdown
                variant="muted"
                fullWidth
                active={counterparty?.routingNumberType ?? ''}
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    routingNumberType: value,
                  })
                }
                options={[
                  {
                    label: 'ABA',
                    value: 'aba',
                  },
                  {
                    label: 'BIC',
                    value: 'bic',
                  },
                ]}
              />
            )}
          </FormElement>
        </Grid>
      </Inner>

      <SectionHeader text="Wire" border />

      <Inner data-disabled={currentPermission?.counterparties !== 'write'} pt={16}>
        <Grid>
          <FormElement newRow>
            <FormLabel>Beneficiary Name</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryName ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryName: value } })
                }
                value={counterparty.wire?.beneficiaryName ?? ''}
                placeholder="Beneficiary name"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Wire Drawdown Allowed</FormLabel>
            <FormText>
              {id ? (
                <Chip
                  type={counterparty.wireDrawdownAllowed ? 'info' : 'default'}
                  icon={counterparty.wireDrawdownAllowed ? <Icon.CircleCheck /> : <Icon.CircleCross />}
                >
                  {counterparty.wireDrawdownAllowed ? 'True' : 'False'}
                </Chip>
              ) : (
                <Toggle
                  onCheckedChange={(value: boolean) => setCounterparty({ ...counterparty, wireDrawdownAllowed: value })}
                  isChecked={counterparty.wireDrawdownAllowed ?? false}
                />
              )}
            </FormText>
          </FormElement>
          <FormElement newRow>
            <FormLabel>Beneficiary Email</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryEmail ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryEmail: value } })
                }
                value={counterparty.wire?.beneficiaryEmail ?? ''}
                placeholder="Beneficiary email"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Beneficiary Phone</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryPhone ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryPhone: value } })
                }
                value={counterparty.wire?.beneficiaryPhone ?? ''}
                placeholder="Beneficiary phone"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Beneficiary Legal ID</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryLegalId ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: { ...counterparty.wire, beneficiaryLegalId: value },
                  })
                }
                value={counterparty.wire?.beneficiaryLegalId ?? ''}
                placeholder="Beneficiary legal ID"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Beneficiary Type</FormLabel>
            {id ? (
              <FormText>{formatString(counterparty.wire?.beneficiaryType ?? '-')}</FormText>
            ) : (
              <Dropdown
                variant="muted"
                fullWidth
                active={counterparty.wire?.beneficiaryType ?? ''}
                onChange={(value: string) =>
                  setCounterparty({ ...counterparty, wire: { ...counterparty.wire, beneficiaryType: value } })
                }
                options={[
                  {
                    label: 'Business',
                    value: 'business',
                  },
                  {
                    label: 'Nonprofit',
                    value: 'non_profit',
                  },
                  {
                    label: 'Individual',
                    value: 'individual',
                  },
                  {
                    label: 'Sole Proprietor',
                    value: 'sole_proprietor',
                  },
                ]}
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Local Bank Code</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.localBankCode ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({ ...counterparty, wire: { ...counterparty.wire, localBankCode: value } })
                }
                value={counterparty.wire?.localBankCode ?? ''}
                placeholder="Local bank code"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Local Account Number</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.localAccountNumber ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: { ...counterparty.wire, localAccountNumber: value },
                  })
                }
                value={counterparty.wire?.localAccountNumber ?? ''}
                placeholder="Local account number"
              />
            )}
          </FormElement>
        </Grid>
      </Inner>

      <SectionHeader text="Beneficiary Address" border />

      <Inner data-disabled={currentPermission?.counterparties !== 'write'} pt={16}>
        <Grid>
          {!id && (
            <>
              <FormElement fullWidth>
                <AddressField
                  onChange={(a) => {
                    setCounterparty({
                      ...counterparty,
                      wire: {
                        ...counterparty.wire,
                        beneficiaryAddress: a,
                      },
                    });
                    if (!a.state) {
                      stateRef.current?.open();
                    }
                  }}
                />
              </FormElement>
              <Divider fullWidth>or</Divider>
            </>
          )}
          <FormElement>
            <FormLabel>Line 1</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryAddress?.line1 ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: {
                      ...counterparty.wire,
                      beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, line1: value },
                    },
                  })
                }
                value={counterparty.wire?.beneficiaryAddress?.line1 ?? ''}
                placeholder="Line 1"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Line 2</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryAddress?.line2 ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: {
                      ...counterparty.wire,
                      beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, line2: value },
                    },
                  })
                }
                value={counterparty.wire?.beneficiaryAddress?.line2 ?? ''}
                placeholder="Line 2"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>City</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryAddress?.city ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: {
                      ...counterparty.wire,
                      beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, city: value },
                    },
                  })
                }
                value={counterparty.wire?.beneficiaryAddress?.city ?? ''}
                placeholder="City"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Country</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryAddress?.countryCode ?? '-'}</FormText>
            ) : (
              <Dropdown
                variant="muted"
                fullWidth
                active={counterparty.wire?.beneficiaryAddress?.countryCode ?? ''}
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: {
                      ...counterparty.wire,
                      beneficiaryAddress: { ...counterparty.wire?.beneficiaryAddress, countryCode: value },
                    },
                  })
                }
                options={countries.map((c) => ({
                  label: c.name,
                  value: c.code,
                }))}
                search
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>Postal Code</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryAddress?.postalCode ?? '-'}</FormText>
            ) : (
              <Input
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: {
                      ...counterparty.wire,
                      beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, postalCode: value },
                    },
                  })
                }
                value={counterparty.wire?.beneficiaryAddress?.postalCode ?? ''}
                placeholder="Postal code"
              />
            )}
          </FormElement>
          <FormElement>
            <FormLabel>State</FormLabel>
            {id ? (
              <FormText>{counterparty.wire?.beneficiaryAddress?.state ?? '-'}</FormText>
            ) : (
              <Dropdown
                ref={stateRef}
                variant="muted"
                fullWidth
                active={counterparty.wire?.beneficiaryAddress?.state ?? ''}
                onChange={(value: string) =>
                  setCounterparty({
                    ...counterparty,
                    wire: {
                      ...counterparty.wire,
                      beneficiaryAddress: { ...counterparty.wire.beneficiaryAddress, state: value },
                    },
                  })
                }
                options={
                  counterparty.wire?.beneficiaryAddress?.countryCode
                    ? states
                        .filter((s) => s.country_code === counterparty.wire?.beneficiaryAddress?.countryCode)
                        .map((s) => ({
                          label: `${s.name} (${s.state_code})`,
                          value: s.state_code,
                        }))
                    : []
                }
                search
              />
            )}
          </FormElement>
        </Grid>
      </Inner>

      <Inner>
        <EditToolbar>
          <Button onClick={() => navigate(-1)} variant="secondary" size="small">
            {id ? 'Back' : 'Cancel'}
          </Button>
          {!id && (
            <Button onClick={handleSubmit} isDisabled={currentPermission?.counterparties !== 'write'} size="small">
              Create Counterparty
            </Button>
          )}
        </EditToolbar>
      </Inner>
    </>
  );
};
