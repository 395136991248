import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { log } from './log';

type SuccessCallback = (reCaptchaToken: string) => void;
type ErrorCallback = (error: Error) => void;

export type HandleReCaptcha = (onSuccess: SuccessCallback, onError: ErrorCallback) => void;

export const reCaptchaCheck = (): { handleReCaptcha: HandleReCaptcha } => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptcha = useCallback(
    (onSuccess: SuccessCallback, onError: ErrorCallback) => {
      if (!executeRecaptcha) {
        return;
      }

      executeRecaptcha('submit')
        .then(onSuccess)
        .catch((error) => {
          onError(new Error('reCaptcha error'));

          log({ name: 'reCaptcha error', type: 'error', context: { error } });
        });
    },
    [executeRecaptcha]
  );

  return { handleReCaptcha };
};

export const useReCaptcha = (): (() => Promise<string>) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  return useCallback(() => {
    if (!executeRecaptcha) {
      throw new Error('reCaptcha not loaded');
    }
    try {
      return executeRecaptcha('submit');
    } catch (error) {
      log({ name: 'reCaptcha error', type: 'error', context: { error } });
      throw error;
    }
  }, [executeRecaptcha]);
};
