import { CreateCustomRoleRequestObject, CustomRolePermissionLevel } from '~/typings/API';

export enum Access {
  Default = 'default',
  NoAccess = 'no-access',
  ViewOnly = 'view-only',
  FullAccess = 'full-access',
  Custom = 'custom',
}

export type Permissions = { [permission: string]: CustomRolePermissionLevel };

export type TransfersAndAccountsFields = Pick<
  CreateCustomRoleRequestObject,
  'bankAccountLevelOverrides' | 'entityLevelOverrides' | 'platformLevelPermissions'
>;

const OptionCreate = { label: 'Create', value: 'write' };
const OptionCreateAndEdit = { label: 'Create & Edit', value: 'write' };
const OptionEdit = { label: 'Edit Access', value: 'write' };
const OptionEditOnly = { label: 'Edit Only', value: 'update' };
const OptionOn = { label: 'On', value: 'on' };
const OptionOff = { label: 'Off', value: 'off' };
const OptionNoAccess = { label: 'No Access', value: 'none' };
const OptionViewOnly = { label: 'View Only', value: 'read' };

export const OptionsNotCreatable = [OptionNoAccess, OptionViewOnly, OptionEdit];
export const OptionsNotEditable = [OptionNoAccess, OptionViewOnly, OptionCreate];
export const OptionsOnOff = [OptionOn, OptionOff];
export const OptionsFull = [OptionNoAccess, OptionViewOnly, OptionCreateAndEdit];
export const OptionsFullGranular = [OptionNoAccess, OptionViewOnly, OptionEditOnly, OptionCreateAndEdit];
