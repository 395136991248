import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Button, DropdownElement, Icon, ToggleHeight } from '@column/column-ui-kit';

import { AddressField, CopyInput, SectionHeader } from '~/components';
import { useAddress } from '~/hooks';
import {
  AddressType,
  CreateBusinessEntity,
  EntityRepository,
  PersonDetails,
  UpdateBusinessEntity,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Divider, PlatformInfoDescription, FormElement, FormLabel, Grid, Inner, Box } from '~/styles';
import { copy, generateFields } from '~/util';

interface BusinessFormProps {
  businessEntity?: DeepPartial<CreateBusinessEntity>;
  id?: string;
  sandbox?: boolean;
  disableForm?: boolean;
}

export const AddressSection = styled.div`
  margin: 32px 0;
  display: grid;
  grid-gap: 20px;
`;

export const FormSection = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const Up = styled(Icon.ChevronUpLarge)`
  cursor: pointer;
  margin-left: auto;

  --icon-size: 16px;
  margin: 3px 0 3px auto;
`;

const Down = styled(Icon.ChevronDownLarge)`
  cursor: pointer;
  margin-left: auto;

  --icon-size: 16px;
  margin: 3px 0 3px auto;
`;

const Action = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

const CTA = styled(Button)`
  margin-left: auto;
`;

const BeneficialOwnerDescription = styled(PlatformInfoDescription)`
  margin: 0;
`;

export const BusinessForm: React.FC<BusinessFormProps> = (props) => {
  const { currentPlatform, setRootEntity, setRootEntitySandbox } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const [business, setBusiness] = useState<DeepPartial<CreateBusinessEntity>>({});
  const [businessBeneficialOwner, setBusinessBeneficialOwner] = useState<DeepPartial<PersonDetails[]>>([{}]);
  const [businessBeneficialOwnerOpen, setBusinessBeneficialOwnerOpen] = useState<number[]>([0]);
  const { states, countries } = useAddress({ types: ['state', 'country'] });
  const businessStateRef = useRef<DropdownElement>(null);
  const beneficialOwnerStateRef = useRef<DropdownElement>(null);

  const handleBeneficialOwner = (object: DeepPartial<PersonDetails>, index: number) => {
    const beneficialOwner = copy(businessBeneficialOwner);
    beneficialOwner[index] = object;
    setBusinessBeneficialOwner(beneficialOwner as DeepPartial<PersonDetails[]>);
  };

  const addBeneficialOwner = () => {
    setBusinessBeneficialOwner([...businessBeneficialOwner, {}]);

    setBusinessBeneficialOwnerOpen([businessBeneficialOwner.length]);
  };

  const handleToggle = (index: number) => {
    const entries = copy(businessBeneficialOwnerOpen);
    if (entries.includes(index)) {
      const entry = entries.indexOf(index);
      if (entry !== -1) {
        entries.splice(entry, 1);
        setBusinessBeneficialOwnerOpen(entries);
      }
      return;
    }
    entries.push(index);
    setBusinessBeneficialOwnerOpen(entries);
  };

  const handleSave = () => {
    if (!currentPlatform?.id || !props.sandbox) {
      return;
    }

    if (props.id) {
      delete business.ein;
      delete (business as any).registrationId;

      business.beneficialOwners = businessBeneficialOwner.map((beneficialOwner: DeepPartial<PersonDetails>) => {
        delete beneficialOwner.ssn;
        delete beneficialOwner.passport;
        return beneficialOwner;
      });

      delete business.beneficialOwners;

      if (!business.countriesOfOperation) {
        delete business.countriesOfOperation;
      }

      EntityRepository.updateBusiness(props.id, business as UpdateBusinessEntity)
        .then((response) => {
          if (props.sandbox) {
            setRootEntitySandbox(response);
          } else {
            setRootEntity(response);
          }

          addSuccessNotification({
            content: `${response.businessDetails?.businessName} updated`,
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
          });
        });
      return;
    }

    business.beneficialOwners = businessBeneficialOwner.filter(
      (v: DeepPartial<PersonDetails>) => Object.keys(v).length !== 0
    );

    EntityRepository.createRootBusiness(
      currentPlatform.id,
      business as CreateBusinessEntity,
      props.sandbox ? 'sandbox' : 'live'
    )
      .then((response) => {
        if (props.sandbox) {
          setRootEntitySandbox(response);
        } else {
          setRootEntity(response);
        }

        addSuccessNotification({
          content: `${response.businessDetails?.businessName} created`,
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
        });
      });
  };

  useEffect(() => {
    if (!props.id || !props.businessEntity) {
      return;
    }
    setBusiness(props.businessEntity);
    setBusinessBeneficialOwner(props.businessEntity.beneficialOwners ?? []);
  }, [props.businessEntity]);

  return (
    <>
      <Inner pt={0}>
        <Grid>
          {props.id && (
            <FormElement>
              <FormLabel>ID</FormLabel>
              <CopyInput value={props.id} />
            </FormElement>
          )}

          {generateFields<CreateBusinessEntity>(
            [
              {
                label: 'Business Name',
                value: 'businessName',
                newRow: true,
                isDisabled: props.disableForm,
              },
              {
                label: 'Employer Identification Number',
                value: 'ein',
                newRow: true,
                hide: !!props.id,
                isDisabled: props.disableForm,
              },
              {
                label: 'Website',
                value: 'website',
                isDisabled: props.disableForm,
              },
            ],
            business,
            setBusiness
          )}
        </Grid>
      </Inner>

      <SectionHeader text="Address" border />
      <Inner pt={16}>
        {!props.id && (
          <>
            <AddressField
              onChange={(a) => {
                setBusiness({ ...business, address: a });
                if (!a.state) {
                  businessStateRef.current?.open();
                }
              }}
              justUS
              isDisabled={props.disableForm}
            />
            <Divider>or</Divider>
          </>
        )}

        <Grid>
          {generateFields<CreateBusinessEntity, AddressType>(
            [
              {
                label: 'Line 1',
                value: 'line1',

                isDisabled: props.disableForm,
              },
              {
                label: 'Line 2',
                value: 'line2',
                isDisabled: props.disableForm,
              },
              {
                label: 'City',
                value: 'city',
                isDisabled: props.disableForm,
              },
              {
                type: 'country',
                label: 'Country',
                value: 'countryCode',
                countries,
                isDisabled: props.disableForm,
              },
              {
                label: 'Postal Code',
                value: 'postalCode',
                isDisabled: props.disableForm,
              },
              {
                type: 'state',
                typeValue: business?.address?.countryCode,
                label: 'State',
                value: 'state',
                states,
                isDisabled: props.disableForm,
                fieldRef: businessStateRef,
              },
            ],
            business,
            setBusiness,
            'address'
          )}
        </Grid>
      </Inner>
      <SectionHeader text="Beneficial Owners" border />
      <Inner pt={0}>
        {businessBeneficialOwner.map((entry: DeepPartial<PersonDetails>, index: number) => (
          <Box variant="secondary" key={index}>
            <SectionHeader text="Beneficial Owner" counter={index + 1} onClick={() => handleToggle(index)}>
              {businessBeneficialOwnerOpen.includes(index) ? <Up /> : <Down />}
            </SectionHeader>

            <ToggleHeight isClose={!businessBeneficialOwnerOpen.includes(index)}>
              <Inner pt={0}>
                {index === 0 && (
                  <BeneficialOwnerDescription>
                    Please include the individuals who own or operate the business, including those who own 25 percent
                    or more of the business and a Controlling Person (an individual with significant responsibility
                    within the company, such as the CEO).
                  </BeneficialOwnerDescription>
                )}
              </Inner>

              <Inner pt={16}>
                <Grid>
                  {generateFields<PersonDetails>(
                    [
                      {
                        label: 'First Name',
                        value: 'firstName',
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'Last Name',
                        value: 'lastName',
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'Middle Name',
                        value: 'middleName',
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'Social Security Number',
                        value: 'ssn',
                        newRow: true,
                        hide: !!props.id,
                        isDisabled: props.disableForm,
                      },
                      {
                        type: 'date',
                        label: 'Date of Birth',
                        value: 'dateOfBirth',
                        disableBefore: new Date('1900-01-01T00:00:00'),
                        disableAfter: new Date(),
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'Email',
                        value: 'email',
                        isDisabled: props.disableForm,
                      },
                    ],
                    entry,
                    (data: any) => handleBeneficialOwner(data, index)
                  )}
                </Grid>
              </Inner>

              <SectionHeader text="Address" border />
              <Inner pt={0}>
                <Grid>
                  <FormElement fullWidth>
                    <AddressField
                      onChange={(a) => {
                        handleBeneficialOwner({ ...entry, address: a }, index);
                        if (!a.state) {
                          beneficialOwnerStateRef.current?.open();
                        }
                      }}
                      justUS
                      isDisabled={props.disableForm}
                    />
                  </FormElement>

                  <Divider fullWidth>or</Divider>

                  {generateFields<PersonDetails, AddressType>(
                    [
                      {
                        label: 'Line 1',
                        value: 'line1',
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'Line 2',
                        value: 'line2',
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'City',
                        value: 'city',
                        isDisabled: props.disableForm,
                      },
                      {
                        type: 'country',
                        label: 'Country',
                        value: 'countryCode',
                        countries,
                        isDisabled: props.disableForm,
                      },
                      {
                        label: 'Postal Code',
                        value: 'postalCode',
                        isDisabled: props.disableForm,
                      },
                      {
                        type: 'state',
                        typeValue: entry?.address?.countryCode,
                        label: 'State',
                        value: 'state',
                        states,
                        isDisabled: props.disableForm,
                      },
                    ],
                    entry,
                    (data: any) => handleBeneficialOwner(data, index),
                    'address'
                  )}
                </Grid>
              </Inner>
            </ToggleHeight>
          </Box>
        ))}
      </Inner>

      {props.sandbox && (
        <Inner>
          <Action>
            {!props.id && (
              <Button variant="subtle" onClick={addBeneficialOwner} icon={<Icon.Plus />} isDisabled={props.disableForm}>
                Add {businessBeneficialOwner.length + 1} Beneficial Owner
              </Button>
            )}
            <CTA onClick={handleSave} isDisabled={props.disableForm}>
              {props.id ? 'Update' : 'Create'}
            </CTA>
          </Action>
        </Inner>
      )}
    </>
  );
};
