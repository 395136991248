import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Icon, Dropdown, Chip, DropdownOption, formatNumber } from '@column/column-ui-kit';

import { ROUTE } from '~/app/routes';
import { EmptyState, PaginationWrapper, PopoverFilterEntry, TableColumn, PageHeader } from '~/components';
import { RelativeTime } from '~/elements';
import { LoanRepository } from '~/repositories';
import { formatString } from '~/util';

const Description = styled.div`
  display: grid;
  grid-gap: 2px;
`;

const Note = styled.div`
  line-height: 16px;
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const handleFetchPayments = async (params: any, navigate: NavigateFunction, addLoanLink?: boolean) => {
  return LoanRepository.getAllPayments(params).then((response) => {
    const entries: any = [];
    if (response.payments.length) {
      response.payments.map((entry: any) => {
        const options: DropdownOption[] = [];

        if (addLoanLink) {
          options.push({
            label: 'Loan',
            onClick: () => navigate(`${ROUTE.LOANS}/edit/${entry.loanId}`),
          });
        }

        entries.push({
          id: entry.id,
          description: [entry?.principalAmount ?? 0, entry.description],
          status: entry.status,
          created: entry.createdAt,
          action: (
            <Dropdown
              isInside
              positionRight
              options={[
                ...options,
                {
                  label: 'Edit',
                  onClick: () => navigate(`${ROUTE.LOANS}/payments/edit/${entry.id}`),
                },
              ]}
              variant="dots"
            />
          ),
        });
      });
    }
    return { entries, hasMore: Boolean(response?.hasMore) };
  });
};

export const paymentColumns: TableColumn[] = [
  {
    Header: 'Description',
    accessor: 'description',
    width: 'minmax(300px, auto)',
    Cell: (current) => (
      <Description>
        {formatNumber(current.value[0])}
        {current.value[1] && <Note>{current.value[1]}</Note>}
      </Description>
    ),
  },
  {
    Header: 'Status',
    width: 'minmax(min-content, 100px)',
    Cell: (current) => <Chip>{formatString(current.value)}</Chip>,
    accessor: 'status',
  },
  {
    Header: 'Created',
    accessor: 'created',
    sortType: 'datetime',
    width: 'min-content',
    Cell: (current) => <RelativeTime timestamp={current.value} />,
  },
  {
    Header: '',
    width: 'min-content',
    accessor: 'action',
  },
];

export const paymentFilter: PopoverFilterEntry[] = [
  {
    id: 'created',
    label: 'Created',
    type: 'date',
  },
];

export const PageLoansPaymentOverview: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader text="Payments" border={false}>
        <Button onClick={() => navigate(ROUTE.LOANS)} icon={<Icon.HandCoins />} size="small" variant="secondary">
          Loans
        </Button>
        <Button onClick={() => navigate(`${ROUTE.LOANS}/payments/create`)} icon={<Icon.Plus />} size="small">
          Create Payment
        </Button>
      </PageHeader>

      <PaginationWrapper
        tableId="payments"
        fetch={(o) => handleFetchPayments(o, navigate, true)}
        columns={paymentColumns}
        filter={paymentFilter}
        empty={
          <EmptyState headline="No payments found" text="Create your first payment to get started.">
            <Button onClick={() => navigate(`${ROUTE.LOANS}/payments/create`)} size="small" icon={<Icon.Plus />}>
              Create Payment
            </Button>
          </EmptyState>
        }
        rowClick={(row) => {
          navigate(`${ROUTE.LOANS}/payments/edit/${row.original.id}`);
        }}
      />
    </>
  );
};
