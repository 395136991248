export const ROUTE_PREFIX = '/app';

export const ROUTE = {
  ROOT: ROUTE_PREFIX,
  LEGAL: `${ROUTE_PREFIX}/legal`,
  LEGAL_COMMERCIAL_DEPOSIT_ACCOUNT_AGREEMENT: `${ROUTE_PREFIX}/legal/commercial-deposit-account-agreement`,
  LEGAL_SWEEP_AGREEMENT: `${ROUTE_PREFIX}/legal/column-sweep-program-deposit-placement-custodial-agreements`,
  SVB_RESOURCES: `${ROUTE_PREFIX}/svb-resources`,
  ENTITIES: `${ROUTE_PREFIX}/entities`,
  BANK_ACCOUNTS: `${ROUTE_PREFIX}/accounts`,
  TRANSFERS: `${ROUTE_PREFIX}/transfers`,
  RETURNS: `${ROUTE_PREFIX}/transfers/returns`,
  COUNTERPARTIES: `${ROUTE_PREFIX}/accounts/counterparties`,
  EVENTS: `${ROUTE_PREFIX}/events`,
  EVENTS_WEBHOOK: `${ROUTE_PREFIX}/events/webhook`,
  PROFILE: `${ROUTE_PREFIX}/profile`,
  PLATFORMS: `${ROUTE_PREFIX}/profile/platforms`,
  DOCUMENTS: `${ROUTE_PREFIX}/documents`,
  REPORTING: `${ROUTE_PREFIX}/documents/reporting`,
  STATEMENTS: `${ROUTE_PREFIX}/documents/statements`,
  LOANS: `${ROUTE_PREFIX}/loans`,
  CONTACT_FORM: `${ROUTE_PREFIX}/contact`,

  PLATFORM_ROOT: `${ROUTE_PREFIX}/platform`,
  PLATFORM: `${ROUTE_PREFIX}/platform/info`,
  PLATFORM_FEATURES: `${ROUTE_PREFIX}/platform/features`,
  PLATFORM_API_KEYS: `${ROUTE_PREFIX}/platform/api-keys`,
  PLATFORM_WEBHOOKS: `${ROUTE_PREFIX}/platform/webhooks`,
  PLATFORM_ROLES: `${ROUTE_PREFIX}/platform/roles`,
  PLATFORM_ROLES_CREATE: `${ROUTE_PREFIX}/platform/roles/create`,
  PLATFORM_SETTINGS: `${ROUTE_PREFIX}/platform/settings`,
  PLATFORM_TEAM: `${ROUTE_PREFIX}/platform/team`,
  PLATFORM_ROOT_ENTITY: `${ROUTE_PREFIX}/platform/root-entity-sandbox`,
} as const;
