import { useSessionStore } from '~/stores/Session';
// import { convertValuesToString, keysToCamelCase, keysToSnakeCase, log } from '~/util';
import { keysToCamelCase, keysToSnakeCase } from '~/util/convertKeys';
import { convertValuesToString } from '~/util/convertValues';
import { log } from '~/util/log';

import { APIRequest } from './Request';

interface Options {
  query?: Record<string, any>;
  platformId?: string;
  sandbox?: boolean;
  idempotency?: string;
  reCaptchaToken?: string;
  hideModeHeader?: boolean;
  allowNumbersInRequest?: boolean;
}

class Client {
  #isUnauthorized = false;

  async get<Request, Response>(path: string, options?: Options): Promise<Response> {
    return this.request<Request, Response>(path, 'GET', undefined, options);
  }

  async delete<Request, Response>(path: string, options?: Options): Promise<Response> {
    return this.request<Request, Response>(path, 'DELETE', undefined, options);
  }

  async patch<Request, Response>(path: string, requestBody: Request, options?: Options): Promise<Response> {
    return this.request<Request, Response>(path, 'PATCH', requestBody, options);
  }

  async post<Request, Response>(path: string, requestBody: Request, options?: Options): Promise<Response> {
    return this.request<Request, Response>(path, 'POST', requestBody, options);
  }

  async request<Request, Response>(
    path: string,
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
    requestBody?: Request,
    options?: Options
  ): Promise<Response> {
    const data = requestBody
      ? options?.allowNumbersInRequest
        ? JSON.stringify(keysToSnakeCase(requestBody))
        : (JSON.stringify(keysToSnakeCase(convertValuesToString(requestBody))) ?? {})
      : undefined;

    const req = new APIRequest<string, Record<string, any>>(path, method, data, undefined, {
      'Content-Type': 'application/json',
    });

    if (options?.query) {
      req.setQuery(options.query);
    }

    if (options?.idempotency) {
      req.addHeader('Idempotency-Key', options.idempotency);
    }

    if (options?.platformId || useSessionStore.getState().currentUser?.defaultPlatformId) {
      req.addHeader(
        'Platform-Id',
        options?.platformId ?? useSessionStore.getState().currentUser?.defaultPlatformId ?? ''
      );
    }

    if (!options?.hideModeHeader) {
      req.addHeader('Live-Mode', (options?.sandbox ?? useSessionStore.getState().isSandbox) ? 'No' : 'Yes');
    }

    if (options?.reCaptchaToken && options?.reCaptchaToken?.length > 0) {
      req.addHeader('g-recaptcha-response', options.reCaptchaToken);
    }

    return fetch(req.getRequest())
      .then((response) => {
        if (response.status === 401) {
          if (!this.#isUnauthorized) {
            this.#isUnauthorized = true;

            this.#logout();
          }
          return Promise.reject('Unauthorized');
        }

        return response;
      })
      .then(async (response) => {
        try {
          const json = await response.json();

          if (response.ok) {
            return Promise.resolve(json);
          }

          return Promise.reject(json);
        } catch (message) {
          return Promise.reject(
            JSON.stringify({
              message,
            })
          );
        }
      })
      .then((response) => keysToCamelCase(response))
      .catch((error) => {
        if (
          (typeof error === 'string' && error.trim().startsWith('TypeError: Failed to fetch')) ||
          error instanceof TypeError
        ) {
          log({ name: 'Fetch TypeError', type: 'warn', context: error });
          return;
        }

        if (error !== 'Unauthorized') {
          this.#isUnauthorized = false;
          return Promise.reject(error);
        }

        console.warn('Unauthorized');
      });
  }

  #logout() {
    if (!useSessionStore.getState().authRequired()) {
      useSessionStore.getState().clearCookies();
    }

    useSessionStore.getState().signOut();
  }
}

export const client = new Client();
