import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Dropdown, Input } from '@column/column-ui-kit';

import { Fields, Row } from '../index';
import { AddressField } from '~/components/AddressField';
import { FormField } from '~/components/FormField';
import { useAddress } from '~/hooks';
import { useCounterpartyValidation } from '~/hooks/validate/useCounterpartyValidate';
import { CreateCounterparty } from '~/repositories';
import { Divider, FormElement } from '~/styles';
import { validateEmail } from '~/util';

export const ContentOwner: FC = () => {
  const { setValue, watch } = useFormContext<CreateCounterparty>();

  const { states, countries } = useAddress({ types: ['state', 'country'] });

  const { fields, validatePostalCode } = useCounterpartyValidation(watch('localBankCountryCode') ?? 'US');

  return (
    <Fields>
      <Row>
        <FormField<CreateCounterparty> id="name" label="Full Name" rules={{ required: true }} oneColumn>
          {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
            <Input
              value={String(value ?? '')}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              placeholder="Name"
              hasError={isTouched && !!error}
            />
          )}
        </FormField>
      </Row>

      <FormField<CreateCounterparty>
        id="email"
        label="Email Address"
        rules={{ required: true, validate: (value) => validateEmail(String(value)) }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Email Address"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<CreateCounterparty> id="phone" label="Phone" rules={{ required: fields.includes('phone') }} oneColumn>
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Phone"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <Row>
        <Divider>Legal</Divider>
      </Row>

      <FormField<CreateCounterparty>
        id="legalType"
        label="Legal Type"
        rules={{ required: fields.includes('legalType') }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Dropdown
            fullWidth
            active={String(value ?? '')}
            onChange={onChange}
            placeholder="Select Legal Type"
            options={[
              {
                label: 'Business',
                value: 'business',
              },
              {
                label: 'Non Profit',
                value: 'non_profit',
              },
              {
                label: 'Individual',
                value: 'individual',
              },
              {
                label: 'Sole Proprietor',
                value: 'sole_proprietor',
              },
            ]}
          />
        )}
      </FormField>

      <FormField<CreateCounterparty>
        id="legalId"
        label="Legal ID"
        rules={{ required: fields.includes('legalId') }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Legal ID"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <Row>
        <Divider>Address</Divider>
      </Row>

      <FormElement fullWidth>
        <AddressField
          onChange={(address) => {
            setValue('address.line1', address.line1, { shouldValidate: true });
            setValue('address.line2', address.line2, { shouldValidate: true });
            setValue('address.city', address.city, { shouldValidate: true });
            setValue('address.postalCode', address.postalCode, { shouldValidate: true });
            setValue('address.countryCode', address.countryCode, { shouldValidate: true });
            setValue('address.state', address.state, { shouldValidate: true });
          }}
        />
      </FormElement>

      <FormField<CreateCounterparty> id="address.line1" label="Line 1" rules={{ required: true }} oneColumn>
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Line 1"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<CreateCounterparty> id="address.line2" label="Line 2" oneColumn>
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Line 2"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<CreateCounterparty> id="address.city" label="City" rules={{ required: true }} oneColumn>
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="City"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<CreateCounterparty> id="address.countryCode" label="Country Code" rules={{ required: true }} oneColumn>
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Dropdown
            fullWidth
            active={value}
            onChange={onChange}
            placeholder="Select Country"
            options={countries.map((c) => ({
              label: `${c.name} (${c.code})`,
              value: c.code,
            }))}
            search
          />
        )}
      </FormField>

      <FormField<CreateCounterparty>
        id="address.postalCode"
        label="Postal Code"
        rules={{
          required: true,
          validate: (value) =>
            validatePostalCode(String(value), watch('localBankCountryCode') ?? 'US') || 'Invalid account number format',
        }}
        oneColumn
      >
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Input
            value={String(value ?? '')}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            placeholder="Postal Code"
            hasError={isTouched && !!error}
          />
        )}
      </FormField>

      <FormField<CreateCounterparty> id="address.state" label="State" rules={{ required: true }} oneColumn>
        {({ value, onChange, onBlur, ref }, { isTouched, error }) => (
          <Dropdown
            fullWidth
            active={value}
            onChange={onChange}
            placeholder="Select State"
            options={
              watch('address.countryCode')
                ? states
                    .filter((s) => s.country_code === watch('address.countryCode'))
                    .map((s) => ({
                      label: `${s.name} (${s.state_code})`,
                      value: s.state_code,
                    }))
                : []
            }
            search
          />
        )}
      </FormField>
    </Fields>
  );
};
