import { ListInvitesResponse } from '~/repositories/InviteRepository';
import {
  AcceptInviteToPlatformRequest,
  AcceptInviteToPlatformResponse,
  ListInvitesRequest,
  ListPlatformRolesRequest,
  ListPlatformRolesResponse,
  ListFactorsResponse,
  UserRepository,
  DeleteFactorRequest,
  DeleteFactorResponse,
} from '~/repositories/UserRepository';

import { createApiHook } from './utils/createApiHook';

export const useAcceptPlatformInvite = createApiHook<AcceptInviteToPlatformResponse, AcceptInviteToPlatformRequest>(
  UserRepository.acceptInvite,
  {
    triggerAutomatically: false,
  }
);

export const useDeleteFactor = createApiHook<DeleteFactorResponse, DeleteFactorRequest>(UserRepository.deleteFactor);

export const useListFactors = createApiHook<ListFactorsResponse, object>(UserRepository.listFactors, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
});

export const useListPlatformInvites = createApiHook<ListInvitesResponse, ListInvitesRequest>(
  UserRepository.getInvites,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
  }
);

export const useListPlatformRoles = createApiHook<ListPlatformRolesResponse, ListPlatformRolesRequest>(
  UserRepository.platformRoles,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
  }
);
