import { useCallback } from 'react';
import { NavigateFunction, NavigateOptions, To, useNavigate as useNavigateReactRouter } from 'react-router-dom';

interface NavigateParams {
  allowExternalRedirects?: boolean;
}

export const useNavigate = (params?: NavigateParams): NavigateFunction => {
  const navigateReactRouter = useNavigateReactRouter();

  return useCallback(
    (toOrDelta: To | number, options?: NavigateOptions) => {
      if (typeof toOrDelta === 'number') {
        navigateReactRouter(toOrDelta);
        return;
      }

      if (params?.allowExternalRedirects) {
        navigateReactRouter(toOrDelta, options);
        return;
      }

      const path = typeof toOrDelta === 'string' ? toOrDelta : toOrDelta.pathname;
      if (path) {
        if (path.startsWith('/') || path.startsWith('#')) {
          navigateReactRouter(toOrDelta, options);
        } else {
          console.warn('External redirect blocked:', toOrDelta);
        }
      }
    },
    [navigateReactRouter]
  );
};
