import gsap from 'gsap';
import React, { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { isValidBase64Image } from '~/util';

export interface ImageLoadingProps {
  className?: string;
  headlessUI?: boolean;
  base64String?: string;
  errorMessage?: string;
  defaultAspectRatio?: number;
  onAspectRatioChange?: (aspectRatio: number) => void;
}

const Wrapper = styled.div<{ $defaultAspectRatio: number; $headlessUI?: boolean }>`
  width: 100%;
  max-width: 100%;
  aspect-ratio: ${({ $defaultAspectRatio }) => $defaultAspectRatio};
  box-sizing: border-box;
  position: relative;

  ${({ $headlessUI }) =>
    !$headlessUI &&
    css`
      border-radius: 8px;
      background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};

      &::before {
        content: '';
        display: block;
        inset: 0;
        position: absolute;
        border-radius: inherit;
        pointer-events: none;
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
        z-index: 1;
      }
    `}
`;

const Image = styled.img<{ $isShow?: boolean }>`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: center center;
  aspect-ratio: inherit;
  border-radius: inherit;
  transition: opacity 0.25s ease 0.1s;

  ${({ $isShow }) =>
    !$isShow &&
    css`
      opacity: 0;
      pointer-events: none;
      transition-delay: 0s;
    `}
`;

const Loading = styled(Icon.Loading)<{ $isLoading?: boolean }>`
  --icon-size: 18px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  position: absolute;
  left: 50%;
  top: 50%;
  margin: -9px 0 0 -9px;
  transition:
    transform 0.25s,
    opacity 0.2s;
  transition-delay: 0.1s;

  ${({ $isLoading }) =>
    !$isLoading &&
    css`
      opacity: 0;
      transform: translateY(-8px) scale(0.75);
      transition-delay: 0s;
    `}
`;

const Error = styled.div<{ $isShow?: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: inherit;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  transition: opacity 0.2s;
  transition-delay: 0.1s;

  ${({ $isShow }) =>
    !$isShow &&
    css`
      opacity: 0;
      transition-delay: 0s;
    `}
`;

export const ImageLoading: FC<ImageLoadingProps> = ({
  className,
  base64String,
  errorMessage,
  defaultAspectRatio,
  onAspectRatioChange,
  headlessUI,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);

    if (!base64String) {
      return;
    }

    isValidBase64Image(base64String)
      .then(() => {
        setImage(base64String);
      })
      .catch(() => {
        setImage(undefined);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [base64String]);

  const handleImageLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event>) => {
      const img = event.currentTarget;
      const aspectRatio = img.naturalWidth / img.naturalHeight;
      const wrapper = wrapperRef.current;

      if (wrapper) {
        const targetHeight = wrapper.offsetWidth / aspectRatio;

        gsap.to(wrapper, {
          height: targetHeight,
          duration: 0.3,
          onStart: () => {
            wrapper.style.aspectRatio = 'auto';
          },
          onComplete: () => {
            wrapper.style.aspectRatio = aspectRatio.toString();

            if (onAspectRatioChange) {
              onAspectRatioChange(aspectRatio);
            }

            wrapper.style.height = 'auto';
          },
        });
      }
    },
    [wrapperRef.current, onAspectRatioChange]
  );

  return (
    <Wrapper
      className={className}
      ref={wrapperRef}
      $defaultAspectRatio={defaultAspectRatio ?? 1 / 0.45}
      $headlessUI={headlessUI}
    >
      <Image src={image} $isShow={!isLoading && !!image} onLoad={handleImageLoad} />
      <Loading $isLoading={isLoading} />
      <Error $isShow={!isLoading && !image}>{errorMessage ?? 'Image could not be loaded'}</Error>
    </Wrapper>
  );
};
