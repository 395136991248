import React, { useRef, useEffect, useState, ReactNode, FC } from 'react';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import styled from 'styled-components';

import { NotificationGroupType, NotificationType, useNotificationStore } from '~/stores/Notification';
import { inViewport } from '~/util';

import { Notification, NotificationProps } from './Notification';

interface NotificationEntry extends NotificationProps {
  id: string;
  content: string | ReactNode;
}

type Spacing = 'bottom' | 'top' | 'none';

interface NotificationListStyleProps {
  display?: NotificationGroupType;
  spacing?: Spacing;
}

export interface NotificationListProps extends NotificationListStyleProps {
  className?: string;
  variant?: 'default' | 'light';
  size?: 'default' | 'large';
  disableScroll?: boolean;
}

const Wrapper = styled.div<NotificationListStyleProps>`
  scroll-margin-top: 0;
  z-index: 13;
  margin: 0 0 16px 0;
`;

export const StyledNotification = styled(Notification)<{ $spacing?: Spacing }>`
  margin: ${({ $spacing }) => ($spacing === 'top' ? '16px 0 0 0' : $spacing === 'bottom' ? '0 0 16px 0' : '0 0 8px 0')};
`;

export const NotificationList: FC<NotificationListProps> = ({
  display,
  className,
  variant,
  disableScroll,
  size,
  spacing,
}) => {
  const [entries, setEntries] = useState<NotificationType[]>([]);
  const removeNotification = useNotificationStore((state) => state.removeNotification);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      useNotificationStore.subscribe(
        (state) => {
          const getOpenNotification = [...state.getOpenNotification(display)];

          return {
            triggerNotification: state.triggerNotification,
            getOpenNotification,
          };
        },
        ({ triggerNotification, getOpenNotification }) => {
          const wrapper = wrapperRef.current;

          if (
            triggerNotification &&
            triggerNotification === display &&
            wrapper &&
            !disableScroll &&
            display !== 'global'
          ) {
            if (inViewport(wrapper)) {
              return;
            }
            elementScrollIntoView(wrapper, { behavior: 'smooth' });
          }

          setEntries([...getOpenNotification]);
        },
        {
          fireImmediately: true,
        }
      ),
    [display]
  );

  return (
    <Wrapper className={className} ref={wrapperRef} display={display}>
      {entries.map((entry: NotificationEntry) => (
        <StyledNotification
          key={entry.id}
          color={entry.color}
          variant={variant || entry.variant}
          size={size || entry.size}
          withClose={entry.withClose}
          shouldClose={entry.shouldClose}
          actionButton={entry.actionButton}
          smoothShow
          onClose={() => removeNotification(entry.id)}
          $spacing={spacing}
        >
          {entry.content}
        </StyledNotification>
      ))}
    </Wrapper>
  );
};
