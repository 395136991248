import { useCallback, useEffect, useRef, useState } from 'react';

import { BankAccount, BankAccountRepository } from '~/repositories/BankAccountRepository';
import { EntityRepository } from '~/repositories/EntityRepository';
import { EntityEntity } from '~/typings/API';

export interface EntitiesAndAccounts {
  bankAccounts: {
    data: BankAccount[];
    loadMore: (entityId: string) => void;
    paginationState: BankAccountsPaginationState;
  };
  entities: {
    data: EntityEntity[];
    loadMore: () => void;
    paginationState: PaginationState;
  };
}

interface PaginationState {
  startingAfter?: string;
  hasMore: boolean;
}

interface BankAccountsPaginationState {
  [bankAccountId: string]: PaginationState;
}

const mergeBankAccounts = (existingBankAccounts: BankAccount[], newBankAccounts: BankAccount[]) => {
  return newBankAccounts.reduce((acc, bankAccount) => {
    const existingBankAccount = existingBankAccounts.find((e) => e.id === bankAccount.id);
    if (existingBankAccount) {
      return acc;
    }
    return [...acc, bankAccount];
  }, existingBankAccounts);
};

const mergeEntities = (existingEntities: EntityEntity[], newEntities: EntityEntity[]) => {
  return newEntities.reduce((acc, entity) => {
    const existingBankAccount = existingEntities.find((e) => e.id === entity.id);
    if (existingBankAccount) {
      return acc;
    }
    return [...acc, entity];
  }, existingEntities);
};

export const useEntitiesAndAccounts = (inputs?: {
  bankAccountIDs?: string[];
  entityIds?: string[];
}): EntitiesAndAccounts => {
  const { bankAccountIDs = [], entityIds = [] } = inputs ?? {};
  const fetchedBankAccountIDs = useRef<Set<string>>(new Set());
  const fetchedEntityIDs = useRef<Set<string>>(new Set());

  const [entitiesPaginationState, setEntitiesPaginationState] = useState<PaginationState>({ hasMore: true });
  const [bankAccountsPaginationState, setBankAccountsPaginationState] = useState<BankAccountsPaginationState>({});

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [entities, setEntities] = useState<EntityEntity[]>([]);

  const loadMoreBankAccounts = useCallback(
    (entityId: string) => {
      const { hasMore, startingAfter } = bankAccountsPaginationState[entityId] ?? { hasMore: true };
      if (!hasMore) {
        return;
      }
      BankAccountRepository.getAll({ entityId, startingAfter }).then((resp) => {
        setBankAccountsPaginationState((curPaginationState) => ({
          ...curPaginationState,
          [entityId]: {
            startingAfter:
              resp.bankAccounts && resp.bankAccounts.length > 0
                ? resp.bankAccounts[resp.bankAccounts.length - 1].id
                : undefined,
            hasMore: resp.hasMore,
          },
        }));
        setBankAccounts((prevBankAccounts) => mergeBankAccounts(prevBankAccounts, resp.bankAccounts));
        resp.bankAccounts.forEach((bankAccount) => fetchedBankAccountIDs.current.add(bankAccount.id));
      });
    },
    [bankAccountsPaginationState]
  );

  const loadMoreEntities = useCallback(() => {
    const { hasMore, startingAfter } = entitiesPaginationState;
    if (!hasMore) {
      return;
    }
    EntityRepository.getAll({ startingAfter, isRoot: false }).then((resp) => {
      setEntitiesPaginationState({
        startingAfter:
          resp.entities && resp.entities.length > 0 ? resp.entities[resp.entities.length - 1].id : undefined,
        hasMore: resp.hasMore,
      });

      setEntities((prevEntities) => mergeEntities(prevEntities, resp.entities));
      resp.entities.forEach((entity) => fetchedEntityIDs.current.add(entity.id ?? ''));
    });
  }, [entitiesPaginationState]);

  const loadRootEntity = useCallback(() => {
    EntityRepository.getAll({ isRoot: true }).then((resp) => {
      const rootEntity = resp.entities.length > 0 ? resp.entities[0] : undefined;
      if (rootEntity) {
        setEntities((prevEntities) => mergeEntities(prevEntities, [rootEntity]));
        fetchedEntityIDs.current.add(rootEntity.id ?? '');
      }
    });
  }, [entitiesPaginationState]);

  // If the list of bank account IDs changes, fetch the new bank accounts
  useEffect(() => {
    Promise.all(
      bankAccountIDs
        .filter((id) => !fetchedBankAccountIDs.current.has(id))
        .map((id) => BankAccountRepository.get({ id }))
    ).then((newBankAccounts) => {
      setBankAccounts((prevBankAccounts) => mergeBankAccounts(prevBankAccounts, newBankAccounts));
      newBankAccounts.forEach((bankAccount) => fetchedBankAccountIDs.current.add(bankAccount.id));
    });
  }, [bankAccountIDs]);

  // If the list of entity IDs changes, fetch the new entities
  useEffect(() => {
    Promise.all(entityIds.filter((id) => !fetchedEntityIDs.current.has(id)).map((id) => EntityRepository.get(id))).then(
      (newEntities) => {
        setEntities((prevEntities) => mergeEntities(prevEntities, newEntities));
        newEntities.forEach((entity) => fetchedEntityIDs.current.add(entity.id));
      }
    );
  }, [entityIds]);

  // If the list of bank accounts changes, make sure we have corresponding entities
  useEffect(() => {
    bankAccounts.forEach((bankAccount) => {
      bankAccount.owners.forEach((entityId) => {
        if (!fetchedEntityIDs.current.has(entityId)) {
          EntityRepository.get(entityId).then((entity) => {
            setEntities((prevEntities) => mergeEntities(prevEntities, [entity]));
            fetchedEntityIDs.current.add(entity.id);
          });
        }
      });
    });
  }, [bankAccounts]);

  // If the list of entities changes, make sure we have corresponding bank accounts
  useEffect(() => {
    entities.forEach((entity) => {
      const entityId = entity.id;
      if (!entityId) {
        return;
      }
      if (!bankAccountsPaginationState[entityId]) {
        loadMoreBankAccounts(entityId);
      }
    });
  }, [bankAccountsPaginationState, entities, loadMoreBankAccounts]);

  // Load the first page of entities by default
  useEffect(() => {
    loadRootEntity();
    loadMoreEntities();
  }, []);

  return {
    bankAccounts: {
      data: bankAccounts,
      loadMore: loadMoreBankAccounts,
      paginationState: bankAccountsPaginationState,
    },
    entities: {
      data: entities,
      loadMore: loadMoreEntities,
      paginationState: entitiesPaginationState,
    },
  };
};
