/**
 * return_code
 * Return reason codes of the return
 *
 * You can read more about [ACH return reason codes here](https://www.column.com/docs/ach/returns#return-reason-codes).
 * @default "INVALID"
 */
export enum CreateAchReturnRequestReturnCode {
  INVALID = 'INVALID',
  R01 = 'R01',
  R02 = 'R02',
  R03 = 'R03',
  R04 = 'R04',
  R05 = 'R05',
  R06 = 'R06',
  R07 = 'R07',
  R08 = 'R08',
  R09 = 'R09',
  R10 = 'R10',
  R11 = 'R11',
  R12 = 'R12',
  R14 = 'R14',
  R15 = 'R15',
  R16 = 'R16',
  R17 = 'R17',
  R20 = 'R20',
  R21 = 'R21',
  R22 = 'R22',
  R23 = 'R23',
  R24 = 'R24',
  R29 = 'R29',
  R31 = 'R31',
  R33 = 'R33',
  R37 = 'R37',
  R38 = 'R38',
  R39 = 'R39',
  R51 = 'R51',
  R52 = 'R52',
  R53 = 'R53',
}

/**
 * entry_class_code
 * Standard Entry Class code of the transfer. Valid values: `CCD`, `CTX`, `CIE`, `PPD` (default), `TEL`, `WEB`. Please check [more details here](https://www.column.com/docs/ach/types#ach-standard-entry-class-codes).
 * @default "INVALID_CODE"
 */
export enum CreateAchTransferRequestEntryClassCode {
  INVALID_CODE = 'INVALID_CODE',
  CCD = 'CCD',
  CTX = 'CTX',
  CIE = 'CIE',
  PPD = 'PPD',
  TEL = 'TEL',
  WEB = 'WEB',
}

/** @default "VERIFICATION_STATUS_NONE" */
export enum EntityVerificationStatus {
  VERIFICATION_STATUS_NONE = 'VERIFICATION_STATUS_NONE',
  UNVERIFIED = 'UNVERIFIED',
  PENDING = 'PENDING',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  VERIFIED = 'VERIFIED',
  DENIED = 'DENIED',
}

export interface InternationalIdentification {
  number?: string;
  country_code?: string;
}

export interface AccountNumberAccountNumber {
  id: string;
  /** @format date-time */
  created_at: string;
  bank_account_id: string;
  description: string;
  routing_number: string;
  account_number: string;
}

export interface AccountNumberListAccountNumbersResponse {
  account_numbers: AccountNumberAccountNumber[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

/** Detai */
export interface AchAchReturn {
  /**
   * ach_transfer_id
   * ID of the ACH transfer associated with the return
   */
  ach_transfer_id: string;
  /**
   * created_at
   * Creation time of the return.
   * @format date-time
   */
  created_at: string;
  /**
   * updated_at
   * Last updation time of the return.
   * @format date-time
   */
  updated_at: string;
  /** Current status of the return. Valid values: `INITIATED`, `SENT`, `DISHONORED`, `CONTESTED`, `COMPLETED`, `REJECTED`. */
  status: AchAchReturnStatus;
  /**
   * is_incoming
   * `True` if this return is received from other RDFIs. `False` if this return is initiated by Column (upon your request).
   */
  is_incoming: boolean;
  /**
   * details
   * Details of the return processing history (e.g., initiated, dishonored, dishonor contested, manual review, etc.)
   */
  details: AchAchReturnEvent[];
}

/** Details of the return processing history (e.g., initiated, dishonored, dishonor contested, manual review, etc.) */
export interface AchAchReturnEvent {
  /**
   * created_at
   * Creation time of the return detail.
   * @format date-time
   */
  created_at: string;
  /**
   * updated_at
   * Last updation time of the return detail.
   * @format date-time
   */
  updated_at: string;
  /** Current status of the return. Valid values: `INITIATED`, `SENT`, `DISHONORED`, `CONTESTED`, `COMPLETED`, `REJECTED`. */
  status: AchAchReturnStatus;
  /**
   * return_code
   * Return reason code associated with the return detail. Empty for records that are generated from manual investigation.
   *
   * You can read more about [ACH return reason codes here](https://www.column.com/docs/ach/returns#return-reason-codes).
   */
  return_code: string;
  /**
   * description
   * Description, comment, etc. of the return detail.
   */
  description: string;
  /**
   * addenda
   * The addenda information field of the ACH addenda entry associated with the ACH return entry.
   */
  addenda?: string;
}

/**
 * status
 * Current status of the return. Valid values: `INITIATED`, `SENT`, `DISHONORED`, `CONTESTED`, `COMPLETED`, `REJECTED`.
 * @default "INVALID"
 */
export enum AchAchReturnStatus {
  INVALID = 'INVALID',
  INITIATED = 'INITIATED',
  SENT = 'SENT',
  DISHONORED = 'DISHONORED',
  CONTESTED = 'CONTESTED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export interface AchAchTransfer {
  /**
   * id
   * Unique ID of the transfer, starting with `acht_`
   */
  id: string;
  /**
   * created_at
   * Time at which the transfer was created. For incoming transfers, this is when Column received the request from the FRB.
   * @format date-time
   */
  created_at: string;
  /**
   * updated_at
   * Time at which the transfer was last updated.
   * @format date-time
   */
  updated_at: string;
  /**
   * submitted_at
   * Time at which the outgoing transfer was submitted to the FRB.
   */
  submitted_at?: TypesDateTimeNullable;
  /**
   * acknowledged_at
   * Time at which the outgoing CCD/CTX transfer was acknowledged by the RDFI.
   */
  acknowledged_at?: TypesDateTimeNullable;
  /**
   * settled_at
   * Time at which the transfer was settled. For outgoing debit transfers or incoming credit transfers, this is when funds become available to withdraw.
   */
  settled_at?: TypesDateTimeNullable;
  /**
   * completed_at
   * Time at which the transfer was completed, and no return (except `R06` and `R31`) is acceptable.
   */
  completed_at?: TypesDateTimeNullable;
  /**
   * returned_at
   * Time at which the transfer was initiately returned. This value is not updated if dishonored return or contested dishonor return is received.
   */
  returned_at?: TypesDateTimeNullable;
  /**
   * cancelled_at
   * Time at which the outgoing transfer was cancelled by your request via our ACH Return API.
   */
  cancelled_at?: TypesDateTimeNullable;
  /**
   * initiated_at
   * Time at which the outgoing transfer was initiated.
   */
  initiated_at?: TypesDateTimeNullable;
  /**
   * manual_review_at
   * Time at which the outgoing transfer was sent to manual review queue.
   */
  manual_review_at?: TypesDateTimeNullable;
  /**
   * return_dishonored_at
   * Time at which the return for this transfer was dishonored by the ODFI.
   */
  return_dishonored_at?: TypesDateTimeNullable;
  /**
   * return_contested_at
   * Time at which the dishonored return for this transfer was contested by the RDFI.
   */
  return_contested_at?: TypesDateTimeNullable;
  /**
   * Current status of the transfer. Possible enum values:
   * `INITIATED`: the outgoing transfer has been initiated but not submitted yet
   * `PENDING_SUBMISSION`: the outgoing transfer is to be submitted very soon
   * `SUBMITTED`: the outgoing transfer has been submitted to the FRB
   * `ACKNOWLEDGED`: the outgoing CCD/CTX transfer has been acknowledged by the RDFI
   * `SETTLED`: the transfer has been settled
   * `RETURNED`: the transfer has been returned. Please use the [ACH Return API](https://www.column.com/docs/api/#ach-return/get) to check return details.
   * `COMPLETED`: the transfer has been completed, and no return (except `R06` and `R31`) is acceptable
   * `CANCELED`: the outgoing transfer has been cancelled by your request via our ACH Return API
   * `SCHEDULED`: the incoming transfer has been scheduled to be settled on its Settlement Date
   * `PENDING_RETURN`: the incoming transfer will be returned shortly if no action is taken before the deadline
   * `RETURN_DISHONORED`: the return of the transfer has been dishonored by the ODFI. Please use the [ACH Return API](https://www.column.com/docs/api/#ach-return/get) to check return details.
   * `RETURN_CONTESTED`: the dishonored return of the transfer has been contested by the RDFI. Please use the [ACH Return API](https://www.column.com/docs/api/#ach-return/get) to check return details.
   * `MANUAL_REVIEW`: the transfer is under manual review
   */
  status: AchAchTransferStatus;
  /** ACH transfer type: `CREDIT` or `DEBIT` ([more details](https://www.column.com/docs/ach/types)). */
  type?: AchAchTransferType;
  /**
   * idempotency_key
   * Idempotency key you sent with your outgoing transfer request ([more details](https://www.column.com/docs/idempotency)).
   */
  idempotency_key?: string;
  /**
   * bank_account_id
   * ID of the Originator's bank account (for an outgoing transfer), or the Receiver's bank account (for an incoming transfer).
   */
  bank_account_id?: string;
  /**
   * account_number_id
   * ID of the Originator's account number (for an outgoing transfer), or the Receiver's account number (for an incoming transfer).
   */
  account_number_id?: string;
  /**
   * counterparty_id
   * ID of the counterparty involved in an outgoing transfer. For an incoming transfer, this field is not available.
   */
  counterparty_id?: string;
  /**
   * amount
   * Transfer amount in cents
   * @format int64
   */
  amount: number;
  /**
   * currency_code
   * Currency code of the transfer amount
   */
  currency_code: string;
  /**
   * description
   * Description of the transfer
   */
  description: string;
  /**
   * effective_on
   * Date (`00:00AM` in Pacific Time zone) on which the transfer will be effective. For incoming transfers, this is `00:00AM` on the Settlement Date of the transfer in PT timezone ([more details](https://www.column.com/docs/ach/notifications-and-states#rdfi-receiving-ach-payments)).
   * @format date-time
   */
  effective_on: string;
  /**
   * same_day
   * True if the transfer is a same-day ACH transfer
   */
  same_day: boolean;
  /**
   * company_discretionary_data
   * Company discretionary data of the transfer request. Please refer to the [ACH Transer API](https://www.column.com/docs/api/#ach-transfer/create) for more details.
   */
  company_discretionary_data: string;
  /**
   * company_entry_description
   * Company entry description of the transfer request. Please refer to the [ACH Transer API](https://www.column.com/docs/api/#ach-transfer/create) for more details.
   */
  company_entry_description: string;
  /**
   * company_id
   * ID of the originator, which begins with an one-digit Identification Code Designator (ICD), followed by its ID number.
   * `ICD = 1`: IRS Employer Identification Number
   * `ICD = 3`: Data Universal Numbering Systems (DUNS)
   * `ICD = 9`: Internal ID number assigned by the ODFI
   */
  company_id: string;
  /**
   * company_name
   * Name of the Originator. Please refer to the [ACH Transer API](https://www.column.com/docs/api/#ach-transfer/create) for more details.
   */
  company_name: string;
  /**
   * receiver_name
   * Name of the receiver, usually the name on the bank account of the Receiver.
   */
  receiver_name: string;
  /**
   * receiver_id
   * Accouting reference number by which the Receiver is known to the Originator. Please refer to the [ACH Transer API](https://www.column.com/docs/api/#ach-transfer/create) for more details.
   */
  receiver_id: string;
  /**
   * entry_class_code
   * Standard Entry Class code of the transfer ([more details](https://www.column.com/docs/ach/types#ach-standard-entry-class-codes)).
   */
  entry_class_code: string;
  /**
   * allow_overdraft
   * True for outgoing transfers allowed to overdraft the bank account
   */
  allow_overdraft: boolean;
  /**
   * is_incoming
   * True for incoming transfers originated by other banks (i.e., Column is the RDFI)
   */
  is_incoming: boolean;
  /**
   * nsf_deadline
   * Deadline to credit the bank account with additional funds. Otherwise, scheduled incoming ACH debit requests will be returned. Available only with `ach.incoming_transfer.nsf` events.
   * @format date-time
   */
  nsf_deadline?: object | null;
  /**
   * trace_number
   * Raw trace number (length: `15` characters) of the ACH entries that are sent to the FRB for an outgoing transfer, or received from the FRB for an incoming transfer. For an outgoing transfer, this field is populated only after it is submitted (i.e., after `ach.outgoing_transfer.submitted` event).
   */
  trace_number?: string;
  /**
   * odfi_routing_number
   * Routing number of the Originating Depository Financial Institution for the ACH transfer.
   */
  odfi_routing_number: string;
  /**
   * return_details
   * Details of the return processing history (e.g., initiated, dishonored, dishonor contested, manual review, etc.). Available only with `ach.*.returned`, `ach.*.return_dishonored` or `ach.*.return_contested` events.
   */
  return_details?: AchAchReturnEvent[];
  is_on_us: boolean;
  iat: AchIATAddenda;
  /**
   * payment_related_info
   * payment related info from addenda 5
   */
  payment_related_info: string;
  reversal_pair_transfer_id?: string;
  notification_of_changes?: AchNotificationOfChange;
  ultimate_originator_counterparty_id?: string;
  ultimate_beneficiary_counterparty_id?: string;
  transaction_type_code: string;
  intermediary_financial_institutions: any[] | null;
}

/**
 * status
 * Current status of the transfer. Possible enum values:
 * `INITIATED`: the outgoing transfer has been initiated but not submitted yet
 * `PENDING_SUBMISSION`: the outgoing transfer is to be submitted very soon
 * `SUBMITTED`: the outgoing transfer has been submitted to the FRB
 * `ACKNOWLEDGED`: the outgoing CCD/CTX transfer has been acknowledged by the RDFI
 * `SETTLED`: the transfer has been settled
 * `RETURNED`: the transfer has been returned. Please use the [ACH Return API](https://www.column.com/docs/api/#ach-return/get) to check return details.
 * `COMPLETED`: the transfer has been completed, and no return (except `R06` and `R31`) is acceptable
 * `CANCELED`: the outgoing transfer has been cancelled by your request via our ACH Return API
 * `SCHEDULED`: the incoming transfer has been scheduled to be settled on its Settlement Date
 * `PENDING_RETURN`: the incoming transfer will be returned shortly if no action is taken before the deadline
 * `RETURN_DISHONORED`: the return of the transfer has been dishonored by the ODFI. Please use the [ACH Return API](https://www.column.com/docs/api/#ach-return/get) to check return details.
 * `RETURN_CONTESTED`: the dishonored return of the transfer has been contested by the RDFI. Please use the [ACH Return API](https://www.column.com/docs/api/#ach-return/get) to check return details.
 * `MANUAL_REVIEW`: the transfer is under manual review
 * @default "INVALID_STATUS"
 */
export enum AchAchTransferStatus {
  INVALID_STATUS = 'INVALID_STATUS',
  INITIATED = 'INITIATED',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  SUBMITTED = 'SUBMITTED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  SETTLED = 'SETTLED',
  RETURNED = 'RETURNED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  SCHEDULED = 'SCHEDULED',
  PENDING_RETURN = 'PENDING_RETURN',
  RETURN_DISHONORED = 'RETURN_DISHONORED',
  RETURN_CONTESTED = 'RETURN_CONTESTED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
}

/**
 * type
 * ACH transfer type: `CREDIT` or `DEBIT` ([more details](https://www.column.com/docs/ach/types)).
 * @default "INVALID_TYPE"
 */
export enum AchAchTransferType {
  INVALID_TYPE = 'INVALID_TYPE',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface AchAddenda18 {
  /**
   * name
   * foreign correspondent bank name
   */
  name?: string;
  /**
   * identification_number_qualifier
   * foreign correspondent bank identification number qualifier
   */
  identification_number_qualifier?: string;
  /**
   * identification_number
   * foreign correspondent bank identification number
   */
  identification_number?: string;
  /**
   * branch_country_code
   * foreign correspondent bank branch country code
   */
  branch_country_code?: string;
}

export interface AchCreateAchStopPaymentRequest {
  reason?: string;
  routing_number?: string;
  /**
   * amount
   * Amount (in cents) of the funds that will be transfered between originator and counterparty accounts.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  amount: string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. *e.g.* `USD`
   */
  currency_code: string;
}

export interface AchCreateAchStopPaymentResponse {
  ach_stop_payment_id: string;
}

export interface AchCreateAchTransferRequest {
  /** Description of the transfer visible only in your platform. Maximum length: `255` characters. */
  description?: string;
  /**
   * amount
   * Amount (in cents) of the funds that will be transferred between originator and counterparty accounts.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  amount: number | string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. Currently only `USD` is supported.
   */
  currency_code: string;
  /**
   * account_number_id
   * ID of the account number that is sending the transfer. If this is specified, the `bank_account_id` does not need to be included.
   *
   * `account_number_id` or `bank_account_id` is required
   */
  account_number_id?: string;
  /**
   * bank_account_id
   * ID of the bank number that is sending the transfer. If no `account_number_id` is specified, the default account number on `bank_account_id` is used.
   *
   * `account_number_id` or `bank_account_id` is required
   */
  bank_account_id?: string;
  /**
   * counterparty_id
   * ID of the counterparty that will receive the transfer
   */
  counterparty_id: string;
  /**
   * idempotency_key
   * DEPRECATED: see https://www.column.com/docs/idempotency
   */
  idempotency_key?: string;
  /** ACH transfer type: `CREDIT` or `DEBIT` ([more details](https://www.column.com/docs/ach/types#ach-types)). */
  type: AchCreateAchTransferRequestType;
  /**
   * effective_date
   * Date (format: `YYYY-MM-DD`) on which the transfer will be effective
   */
  effective_date?: string | null;
  /**
   * same_day
   * Specify if the transfer is a same-day ACH transfer. If it is set as `true`, it takes precedence over `effective_date` and overrides it ([more details](https://www.column.com/docs/ach/timing)).
   */
  same_day?: boolean;
  /**
   * company_discretionary_data
   * This optional field allows you to include codes (one or more), of significance only to you, to enable specialized handling of the transfer. There is no standardized interpretation for the value of the field. Maximum length: `20` characters.
   *
   * `CIE`: This field may contain the Biller’s name.
   * `CTX`: The Originator’s bank account number may be placed in this field.
   */
  company_discretionary_data?: string | null;
  /**
   * company_entry_description
   * You can use this optional field to provide the Receiver with a description of the purpose of the transfer. For example, “Gas bill”, “Reg. Salary”, “ins. prem.”, “Soc. Sec.”, “DTC”, “Trade Pay”, “PURCHASE”, etc.
   *
   * Default value: "PAYMENT". Maximum length: `10` characters.
   */
  company_entry_description?: string | null;
  /**
   * company_name
   * This optional field identifies the source of the transfer and is used for descriptive purposes for the Receiver. Except as otherwise noted below, this field must contain the name by which the Originator is known to and readily recognized by the Receiver of the transfer to reduce risk of return by the Receiver.
   *
   * `CCD`: For a Health Care EFT Transaction, this field must contain the name of the Health Plan, or where an organization is self-insured, the name of the organization’s third-party administrator that is recognized by the Health Care Provider and to which the Health Care Provider submits its claims.
   * `CIE`: This field contains the bill payment service provider’s name.
   * `WEB`: For a P2P transfer, this field contains the P2P service provider’s name.
   *
   * Default value: "COLUMN*XXX" where `XXX` is the root entity name of your platform, or "COLUMN NA" if no root entity exists. Maximum length: `16` characters.
   */
  company_name?: string | null;
  /**
   * receiver_name
   * The name of the receiver, usually the name on the bank account of the Receiver.
   *
   * Except for the following Standard Entry Class codes, this field is optional but highly recommended to reduce return risks,  with up to `22` characters.
   * `CIE`: This field is required. Maximum length: `15` characters.
   * `TEL`: This field is required. Maximum length: `22` characters.
   * `WEB`: This field is required. Maximum length: `22` characters.
   *
   * Default value: beneficiary name of the counterparty.
   */
  receiver_name?: string | null;
  /**
   * receiver_id
   * This field contains the accounting reference number by which the Receiver is known to the Originator. It is included for further identification and for descriptive purposes.
   *
   * For example, it may be the number shown on an invoice, statement, billhead, notice, or other communication as the reference for the Receiver to update account receivable records.
   *
   * Except for the following Standard Entry Class codes, this field is optional with up to `15` characters.
   * `CIE`: This field is required (maximum length: `22` characters).
   * `WEB`: This field is required for credit transfers (maximum length: `15` characters). For a P2P credit transfer, this field contains the originator name to show on the periodic statement.
   */
  receiver_id?: string | null;
  /** Standard Entry Class code of the transfer. Valid values: `CCD`, `CTX`, `CIE`, `PPD` (default), `TEL`, `WEB`. Please check [more details here](https://www.column.com/docs/ach/types#ach-standard-entry-class-codes). */
  entry_class_code: CreateAchTransferRequestEntryClassCode;
  /**
   * allow_overdraft
   * Allow the account to go negative for the transfer. The bank account needs to have `is_overdraftable` enabled with an overdraft reserve account linked to it.
   */
  allow_overdraft?: boolean;
  /**
   * payment_related_info
   * For CCD, CIE, PPD Entries, and debit WEB Entries, an asterisk (`*`) must be used as the delimiter between the data elements, and the backslash (`\`) or tilde (`~`) must be used as the terminator at the end of a data segment.
   *
   * `CCD`, `PPD`: This field contains payment related ANSI ASC X12 data segments or Nacha-endorsed banking conventions (i.e., Tax Payment, Third-Party Tax Payments, Child Support, or Electronic Dealer Drafting). For CCD Entries that are Health Care EFT Transactions, this field must contain the ASC X12 835 TRN (Reassociation Trace Number) data segment, which conveys the Reassociation Trace Number used by the Health Care Provider to match the payment to remittance data. Example: `TRN*1*12345*1512345678*999999999\`.
   *
   * `CIE`: This field contains payment related ANSI ASC X12 data segments to further identify the payment or Transmit additional remittance information. Example: `N1*BT*JohnDoe\N3*12MainStreet\N4*21070\`.
   *
   * `CTX`: This field contains information formatted in accordance with the syntax of ANSI ASC X12.5 and X12.6, an ASC X12 transaction set containing a BPR or BPS data segment, or payment related UN/EDIFACT syntax.
   *
   * `TEL`: This field is not allowed.
   *
   * Maximum length: `80` characters.
   */
  payment_related_info?: string | null;
}

/**
 * type
 * ACH transfer type: `CREDIT` or `DEBIT` ([more details](https://www.column.com/docs/ach/types#ach-types)).
 * @default "INVALID_TYPE"
 */
export enum AchCreateAchTransferRequestType {
  INVALID_TYPE = 'INVALID_TYPE',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface AchIATAddenda {
  /**
   * foreign_payment_amount
   * addenda 10: foreign Payment Amound amount in cents
   * @format int64
   */
  foreign_payment_amount?: number;
  /**
   * foreign_trace_number
   * addenda 10: foreign trace number
   */
  foreign_trace_number?: string;
  /**
   * receiving_company_or_individual_name
   * addenda 10: name of the party receiving transfer
   */
  receiving_company_or_individual_name?: string;
  /**
   * originator_name
   * addenda 11: name of the originator
   */
  originator_name?: string;
  /**
   * originator_street_address
   * addenda 11: street address of the originator
   */
  originator_street_address?: string;
  /**
   * originator_city_state_province
   * addenda 12: city state province of the originator
   */
  originator_city_state_province?: string;
  /**
   * originator_country_postal_code
   * addenda 12: country and postal code of the originator
   */
  originator_country_postal_code?: string;
  /**
   * odfi_name
   * addenda 13: odfi name
   */
  odfi_name?: string;
  /**
   * odfi_identification_number_qualifier
   * addenda 13: odfi identification number qualifier
   */
  odfi_identification_number_qualifier?: string;
  /**
   * odfi_identification
   * addenda 13: odfi identification
   */
  odfi_identification?: string;
  /**
   * odfi_branch_country_code
   * addenda 13: odfi branch country code
   */
  odfi_branch_country_code?: string;
  /**
   * rdfi_name
   * addenda 14: rdfi name
   */
  rdfi_name?: string;
  /**
   * rdfi_identification_number_qualifier
   * addenda 14: rdfi identification number qualifier
   */
  rdfi_identification_number_qualifier?: string;
  /**
   * rdfi_identification
   * addenda 14: rdfi identification
   */
  rdfi_identification?: string;
  /**
   * rdfi_branch_country_code
   * addenda 14: rdfi branch country code
   */
  rdfi_branch_country_code?: string;
  /**
   * receiver_identification_number
   * addenda 15: receiver identification number
   */
  receiver_identification_number?: string;
  /**
   * receiver_street_address
   * addenda 15: receiver street address
   */
  receiver_street_address?: string;
  /**
   * receiver_city_state_province
   * addenda 16: receiver city state province
   */
  receiver_city_state_province?: string;
  /**
   * receiver_country_postal_code
   * addenda 16: country and postal code of the receiver
   */
  receiver_country_postal_code?: string;
  /**
   * foreign_correspondent_bank_info
   * addenda 18: foreign_correspondent_bank_info
   */
  foreign_correspondent_bank_info?: AchAddenda18[];
  /**
   * foreign_correspondent_bank_info
   * addenda 17: payment_related_info
   */
  payment_related_info?: string[];
  /**
   * transaction_type_code
   * addenda 10: transaction type code
   */
  transaction_type_code?: string;
}

export interface AchNotificationOfChange {
  /** routing_number */
  routing_number?: string;
  /** account_number */
  account_number?: string;
  /** receiver_id */
  account_type?: string;
}

export interface AchIntermediaryFinancialInstitution {
  /** name */
  name?: string;
  /** identifier */
  identifier?: string;
  /** identifier_type */
  identifier_type?: 'aba' | 'bic' | 'iban' | 'other';
  /** country_code */
  country_code?: string;
}

export interface AchListAchReturnsResponse {
  ach_returns: AchAchReturn[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface AchListAchTransfersResponse {
  /**
   * transfers
   * List of ACH transfers under your developer account.
   */
  transfers: AchAchTransfer[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface AchReceiveACHTransferRequest {
  /**
   * destination_account_number_id
   * identifier for the account to credit or debit
   */
  destination_account_number_id: string;
  /**
   * amount
   * Amount (in cents) to credit or debit the account
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  amount: string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. *e.g.* `USD`
   */
  currency_code: string;
}

export type AchReceiveACHTransferResponse = object;

export interface AchSettleACHTransferRequest {
  /**
   * ach_transfer_id
   * ID of the outgoing transfer to force a settlement
   */
  ach_transfer_id?: string;
}

export type AchSettleACHTransferResponse = object;

export interface ApiAddress {
  line_1?: string;
  line_2?: string | null;
  city?: string;
  state?: string;
  postal_code?: string;
  country_code?: string;
}

/**
 * code
 * Specific type of error
 * @default "unknown"
 */
export enum ApiErrCode {
  Unknown = 'unknown',
  InvalidOperation = 'invalid_operation',
  Validation = 'validation',
  ResourceNotFound = 'resource_not_found',
  PermissionDenied = 'permission_denied',
  InternalServerError = 'internal_server_error',
  RequestTimeout = 'request_timeout',
  DnsLookupFailed = 'dns_lookup_failed',
  InvalidRequestField = 'invalid_request_field',
  InvalidFieldValue = 'invalid_field_value',
  InvalidResourceIdFormat = 'invalid_resource_id_format',
  ApiNotFound = 'api_not_found',
  MethodNotAllowed = 'method_not_allowed',
  LimitExceeded = 'limit_exceeded',
  FieldValueLength = 'field_value_length',
  MediaTypeNotSupported = 'media_type_not_supported',
  TransferInvalidAchType = 'transfer_invalid_ach_type',
  TransferInvalidAmount = 'transfer_invalid_amount',
  TransferInvalidCurrency = 'transfer_invalid_currency',
  TransferInvalidMemo = 'transfer_invalid_memo',
  TransferSameAccounts = 'transfer_same_accounts',
  TransferInvalidSourceAccount = 'transfer_invalid_source_account',
  TransferInvalidDestinationAccount = 'transfer_invalid_destination_account',
  TransferNonSufficientFund = 'transfer_non_sufficient_fund',
  TransferNoBeneficiaryInformation = 'transfer_no_beneficiary_information',
  TransferAccountDebitNotAllowed = 'transfer_account_debit_not_allowed',
  TransferFrozenAccountNumber = 'transfer_frozen_account_number',
  TransferCounterpartyBlocked = 'transfer_counterparty_blocked',
  TransferInvalidEntryClassCode = 'transfer_invalid_entry_class_code',
  TransferInvalidBookTransfer = 'transfer_invalid_book_transfer',
  TransferInvalidTransferId = 'transfer_invalid_transfer_id',
  TransferInvalidTransferStatus = 'transfer_invalid_transfer_status',
  TransferInvalidReturnCode = 'transfer_invalid_return_code',
  TransferInvalidReturnRequest = 'transfer_invalid_return_request',
  TransferReturnDeadlinePassed = 'transfer_return_deadline_passed',
  InvalidSsn = 'invalid_ssn',
  InvalidAddress = 'invalid_address',
  InvalidBirthday = 'invalid_birthday',
  InvalidCredentials = 'invalid_credentials',
  InvalidEmailVerification = 'invalid_email_verification',
  InvalidMfa = 'invalid_mfa',
  InvalidSession = 'invalid_session',
  BankAccountNotFound = 'bank_account_not_found',
  AccountNumberNotFound = 'account_number_not_found',
}

/**
 * type
 * Category of error. View all types in the [errors section](https://www.column.com/docs/errors).
 * @default "NONE"
 */
export enum ApiErrType {
  NONE = 'NONE',
  AuthenticationError = 'authentication_error',
  DashboardError = 'dashboard_error',
  RequestValidationError = 'request_validation_error',
  ServerError = 'server_error',
  TransferError = 'transfer_error',
  EntityError = 'entity_error',
  BankAccountError = 'bank_account_error',
  NotFound = 'not_found',
  Limit = 'limit',
  LoanError = 'loan_error',
}

/**
 * Error response
 * Response format when a request failed.
 */
export interface ApiError {
  /** Category of error. View all types in the [errors section](https://www.column.com/docs/errors). */
  type?: ApiErrType;
  /** Specific type of error */
  code?: ApiErrCode;
  /**
   * message
   * Human readable error message
   */
  message?: string;
  /**
   * documentation_url
   * Best attempt at the relevant documentation. WIP.
   */
  documentation_url?: string;
  /**
   * details
   * Any relevant details on the error. Optional.
   */
  details?: object | null;
}

export interface ApiMoney {
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. *e.g.* `USD`
   */
  currency_code?: string;
  /**
   * cents
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  cents?: string;
}

export interface ApiTimeRange {
  /**
   * *(optional)* Return results where the specified time field is greater than this value
   * @format date-time
   */
  gt?: string;
  /**
   * *(optional)* Return results where the specified time field is greater than or equal to this value
   * @format date-time
   */
  gte?: string;
  /**
   * *(optional)* Return results where the specified time field is less than this value
   * @format date-time
   */
  lt?: string;
  /**
   * *(optional)* Return results where the specified time field is less than or equal to this value
   * @format date-time
   */
  lte?: string;
}

export interface BankAccountWithDetails {
  id: string;
  /** @example "Travel checking" */
  description: string | null;
  owners: string[];
  default_account_number_id: string;
  default_account_number: string;
  routing_number: string;
  balances: {
    /** @format int64 */
    available_amount?: number;
    /** @format int64 */
    holding_amount?: number;
    /** @format int64 */
    pending_amount?: number;
    /** @format int64 */
    locked_amount?: number;
  };
  currency_code: string;
  /** @format date-time */
  created_at: string;
  type: BankAccountType;
  /** Whether the account can be overdrafted. overdraft_reserve_account_id is also specified if enabled */
  is_overdraftable: boolean;
  /** The overdraft reserve account that this account linked to if is_overdraftable is true */
  overdraft_reserve_account_id: string;
  /** The name of the account that will be displayed to the user */
  display_name?: string;
}

export interface BankAccountHistory {
  /** Bank account ID */
  id: string;
  history: BankAccountSummary[];
}

export interface BankAccountSummary {
  /** @format date-time */
  effective_on: string;
  time_zone: string;
  currency: string;
  /** @format int64 */
  transaction_count: number;
  /** @format int64 */
  available_balance_credit: number;
  /** @format int64 */
  available_balance_debit: number;
  /** @format int64 */
  available_balance_close: number;
  /** @format int64 */
  holding_balance_credit: number;
  /** @format int64 */
  holding_balance_debit: number;
  /** @format int64 */
  holding_balance_close: number;
  /** @format int64 */
  locked_balance_credit: number;
  /** @format int64 */
  locked_balance_debit: number;
  /** @format int64 */
  locked_balance_close: number;
  /** @format int64 */
  pending_balance_credit: number;
  /** @format int64 */
  pending_balance_debit: number;
  /** @format int64 */
  pending_balance_close: number;
}

/** @default "UNKNOWN" */
export enum BankAccountType {
  UNKNOWN = 'UNKNOWN',
  OVERDRAFT_RESERVE = 'OVERDRAFT_RESERVE',
  CHECKING = 'CHECKING',
  PROGRAM_RESERVE = 'PROGRAM_RESERVE',
}

export interface BankAccountCreateRequest {
  /**
   * description
   * A name for the bank account (minimum of three characters)
   * @example "Travel checking"
   */
  description: string;
  /**
   * entity_id
   * The entity to create the account under
   */
  entity_id: string;
  /**
   * is_overdraftable
   * Whether the account can be overdrafted, must include a overdraft_reserve_account_id
   */
  is_overdraftable?: boolean;
  /**
   * overdraft_reserve_account_id
   * the overdraft reserve account that this account linked to if is_overdraftable is true
   */
  overdraft_reserve_account_id?: string;
  /**
   * custom_account_number
   * A custom account number to use for this account
   */
  custom_account_number?: string;
  /**
   * display_name
   * The name of the account that will be displayed to the user
   */
  display_name?: string;
}

export type BankAccountDeleteResponse = object;

export interface BankAccountListResponse {
  bank_accounts: BankAccountWithDetails[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface ReportingListSettlementReportResponse {
  reports?: ReportingSettlementReport[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more?: boolean;
}

export interface ReportingSettlementReport {
  id?: string;
  created_at?: TypesDateTime;
  updated_at?: TypesDateTime;
  type?: string;
  status?: string;
  time_zone?: string;
  from_date?: string;
  to_date?: string;
  initiated_at?: TypesDateTimeNullable;
  completed_at?: TypesDateTimeNullable;
  /** @format int32 */
  row_count?: number | null;
  columns?: any[] | null;
  csv_document_id?: string;
  json_document_id?: string;
  parquet_document_id?: string;
  bai2_document_id?: string;
  pdf_document_id?: string;
  statement_subject?: string;
  statement_subject_description?: string;
}

export interface BookTransfer {
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  idempotency_key: string;
  sender_bank_account_id: string;
  sender_account_number_id: string;
  receiver_bank_account_id: string;
  receiver_account_number_id: string;
  /** @format int64 */
  amount: number;
  currency_code: string;
  description: string;
  status: BookTransferStatus;
  allow_overdraft: boolean;
}

/** @default "NONE" */
export enum BookTransferStatus {
  NONE = 'NONE',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  HOLD = 'HOLD',
  CANCELED = 'CANCELED',
}

export interface BookCreateBookTransferRequest {
  /**
   * description
   * A description of the transfer visible in people's accounts.
   */
  description?: string;
  /**
   * amount
   * Amount (in cents) of the funds that will be transferred between sender and receiver accounts.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  amount: string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. *e.g.* `USD`
   */
  currency_code: string;
  /**
   * sender_bank_account_id
   * ID of the bank account that is sending the transfer. If no `sender_account_number_id` is specified,
   * the default account number on `sender_bank_account_id` is used.
   *
   * `sender_bank_account_id` or `sender_account_number_id` is required
   */
  sender_bank_account_id?: string;
  /**
   * sender_account_number_id
   * ID of the account number that is sending the transfer.
   * If this is specified, the `sender_bank_account_id` does not need to be included.
   *
   * `sender_bank_account_id` or `sender_account_number_id` is required
   */
  sender_account_number_id?: string;
  /**
   * receiver_bank_account_id
   * ID of the bank account that will receive the transfer. If no `receiver_account_number_id` is specified,
   * the default account number on `receiver_bank_account_id` is used.
   *
   * `receiver_bank_account_id` or `receiver_account_number_id` is required
   */
  receiver_bank_account_id?: string;
  /**
   * receiver_account_number_id
   * ID of the account number that will receive the transfer.
   * If this is specified, the `receiver_bank_account_id` does not need to be included.
   *
   * `receiver_bank_account_id` or `receiver_account_number_id` is required
   */
  receiver_account_number_id?: string;
  /**
   * idempotency_key
   * DEPRECATED: see https://www.column.com/docs/idempotency
   */
  idempotency_key?: string;
  /**
   * allow_overdraft
   * Allows the account to go negative for an outgoing transfer. The bank account
   * needs to have 'is_overdraftable' enabled with an overdraft reserve account linked to it
   */
  allow_overdraft?: boolean;
  /**
   * hold
   * Creates a book transfer in a "hold" state. The transfer will not be completed until the `clear` API is called.
   * Transfers in a "hold" state may be updated or canceled.
   */
  hold?: boolean;
  details?: DetailsTransferMonitoringDetails;
}

export interface BookTransferList {
  /**
   * transfers
   * List of book transfers under your developer account.
   */
  transfers: BookTransfer[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface CheckReturn {
  /**
   * id
   * Unique ID of the check return, starting with `chrt_`
   */
  id: string;
  /**
   * id
   * Unique ID of the transfer, starting with `chkt_`
   */
  check_transfer_id: string;
  /** Check return reason. */
  return_reason: CheckReturnReason;
  /** Status of the check return. */
  status: CheckReturnStatus;
  /** Time at which the transfer was created. For incoming transfers, this is when Column received the request from the FRB. */
  created_at: TypesDateTime;
  updated_at: TypesDateTime;
  pending_review_at: TypesDateTime;
  pending_addendum_a_at: TypesDateTime;
  scheduledAt: TypesDateTime;
  processedAt: TypesDateTime;
  cancelledAt: TypesDateTime;
}

export interface CheckTransfer {
  /**
   * id
   * Unique ID of the transfer, starting with `chkt_`
   */
  id: string;
  /**
   * idempotency_key
   * Idempotency key you sent with your outgoing transfer request ([more details](https://www.column.com/docs/idempotency)).
   */
  idempotency_key: string | null;
  /** Check transfer type: `CREDIT` or `DEBIT` ([more details](https://www.column.com/docs/check/types)). */
  type: CheckCheckTransferType;
  /**
   * bank_account_id
   * ID of the Originator's bank account (for an issued transfer), or the Receiver's bank account (for a deposited transfer).
   */
  bank_account_id: string;
  /**
   * account_number_id
   * ID of the Originator's account number (for an issued transfer), or the Receiver's account number (for a deposited transfer).
   */
  account_number_id: string;
  /**
   * currency_code
   * Currency code of the transfer amount
   */
  currency_code: string | null;
  /**
   * description
   * Description of the transfer
   */
  description: string | null;
  /** external_routing_number */
  external_routing_number: string | null;
  micr_line: CheckMICRLine;
  /** Check transfer status. */
  status: CheckCheckTransferStatus;
  /**
   * amount
   * Amount (in cents) of the funds that will be debited from the account.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  positive_pay_amount: string | null;
  /**
   * amount
   * Amount (in cents) of the funds that is deposited.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  deposited_amount: string | null;
  /**
   * check_number
   * Check number of the check
   * @format int64
   */
  check_number: number | null;
  /** Name of the payee */
  payee_name: string;
  /** The payee name extracted from the deposited check (for Column issued checks only). This can be compared to the payee name on the issued check to detect fraudulently altered checks. */
  extracted_payee_name?: string;
  /**
   * front_image
   * Front of the check image in TIFF format. Base64 Encoded. This field is only available in GET endpoint and only after the Column issued check is deposited.
   */
  front_image: string | null;
  /**
   * back_image
   * Back of the check image in TIFF format. Base64 Encoded. This field is only available in GET endpoint and only after the Column issued check is deposited.
   */
  back_image: string | null;
  delivered_by_column: boolean;
  message: string | null;
  memo: string | null;
  payee_address: ApiAddress;
  delivery_status:
    | 'created'
    | 'mailed'
    | 'in_transit'
    | 'in_local_area'
    | 'processed_for_delivery'
    | 'delivered'
    | 'rejected'
    | 'rerouted'
    | 'returned_to_sender'
    | 'international_exit';
  is_preview_pdf_available: boolean;
  attachment_document_id: string | null;
  /**
   * created_at
   * Time at which the transfer was created. For incoming transfers, this is when Column received the request from the FRB.
   * @format date-time
   */
  created_at: string;
  /**
   * updated_at
   * Time at which the transfer was last updated.
   * @format date-time
   */
  updated_at: string;
  /**
   * issued_at
   * Time at which the transfer was issued.
   */
  issued_at: TypesDateTimeNullable;
  /**
   * manual_review_at
   * Time at which the transfer was manual reviewed.
   */
  manual_review_at: TypesDateTimeNullable;
  /**
   * rejected_at
   * Time at which the transfer was rejected.
   */
  rejected_at: TypesDateTimeNullable;
  /**
   * pending_deposit_at
   * Time at which the transfer was pending deposit.
   */
  pending_deposit_at: TypesDateTimeNullable;
  /**
   * deposited_at
   * Time at which the transfer was deposited.
   */
  deposited_at: TypesDateTimeNullable;
  pending_stop_at: TypesDateTimeNullable;
  /**
   * stopped_at
   * Time at which the transfer was stopped.
   */
  stopped_at: TypesDateTimeNullable;
  /**
   * pending_first_return_at
   * Time at which the transfer was pending first return.
   */
  pending_first_return_at: TypesDateTimeNullable;
  /**
   * first_return_at
   * Time at which the transfer was first returned.
   */
  first_return_at: TypesDateTimeNullable;
  /**
   * reclear_at
   * Time at which the transfer was recleared.
   */
  reclear_at: TypesDateTimeNullable;
  /**
   * second_return_at
   * Time at which the transfer was returned the second time.
   */
  second_return_at: TypesDateTimeNullable;
  /**
   * settled_at
   * Time at which the transfer was settled.
   */
  settled_at: TypesDateTimeNullable;
  /**
   * pending_user_initiated_return_at
   * Time at which a return is initiated by the user.
   */
  pending_user_initiated_return_at: TypesDateTimeNullable;
  /**
   * user_initiated_returned_at
   * Time at which a user initiated return is completed.
   */
  user_initiated_returned_at: TypesDateTimeNullable;
}

export interface CheckTransferList {
  /**
   * transfers
   * List of check transfers under your developer account.
   */
  transfers: CheckTransfer[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface CheckTransferImages {
  /**
   * front_image_jpeg
   * Front of the check image in JPEG format. Base64 Encoded.
   */
  front_image_jpeg?: string;
  /**
   * back_image_jpeg
   * Back of the check image in JPEG format. Base64 Encoded.
   */
  back_image_jpeg?: string;
}

export interface CheckDepositRequest {
  bank_account_id?: string;
  account_number_id?: string;
  currency_code?: string;
  description?: string;
  /** @format int64 */
  deposited_amount?: number;
  micr_line?: CheckMICRLine;
  image_front?: string;
  image_back?: string;
}

export interface CheckIssueRequest {
  bank_account_id?: string;
  account_number_id?: string;
  entity_id?: string;
  positive_pay_amount?: string | number;
  currency_code?: string;
  description?: string;
  payee_name?: string;
  customized_check_number?: number;
  mail_check_request?: {
    message: string;
    memo?: string;
    payee_address?: ApiAddress;
    attachment_document_id?: string;
    payor_name?: string;
    courtesy_of?: string;
    payor_address?: ApiAddress;
    signatory?: string;
  };
}

export interface CheckReturnsList {
  /**
   * returns
   * List of check returns under your developer account.
   */
  returns?: CheckReturn[];
  has_more?: boolean;
}

export interface CheckReturnRequest {
  return_reason: string;
}

export interface CheckImages {
  /**
   * front_image_jpeg
   * Front of the check image in JPEG format. Base64 Encoded.
   */
  front_image_jpeg?: string;
  /**
   * back_image_jpeg
   * Back of the check image in JPEG format. Base64 Encoded.
   */
  back_image_jpeg?: string;
}

export interface CheckFrontImageRequest {
  /** @format binary */
  file?: File;
  bypass_doc_cropping?: boolean;
}

export interface CheckFrontImage {
  /** @format int64 */
  deposit_amount?: number;
  /** @format float64 */
  deposit_amount_confidence?: number;
  micr_line?: CheckMICRLine;
  /** @format float64 */
  micr_line_confidence?: number;
  receiver_name?: string;
  /** @format float64 */
  receiver_name_confidence?: number;
  receiver_address?: string;
  /** @format float64 */
  receiver_address_confidence?: number;
  image_front?: string;
}

export interface CheckBackImageRequest {
  /** @format binary */
  file?: File;
}

export interface CheckBackImage {
  image_back?: string;
}

export interface CheckImageConvertToJPGRequest {
  file_name?: string;
  /** base64 encoded string */
  image?: string;
}

export interface CheckImageConvertToJPGResponse {
  /** base64 encoded string */
  image?: string;
}

/**
 * type
 * Check transfer type: `CREDIT` or `DEBIT` ([more details](https://www.column.com/docs/check/types)).
 */
export enum CheckCheckTransferType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

/**
 * return_reason
 * Check return reason.
 */
export enum CheckReturnReason {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  Value3 = '3',
  Value4 = '4',
  Value5 = '5',
  Value6 = '6',
  Value7 = '7',
  Value8 = '8',
  Value9 = '9',
  Value0 = '0',
}

/**
 * status
 * Status of the check return.
 */
export enum CheckReturnStatus {
  Scheduled = 'scheduled',
  Processed = 'processed',
}

/** micr_line */
export interface CheckMICRLine {
  /**
   * amount_field
   * Amount field on the MICR line
   */
  amount_field?: string;
  /**
   * auxiliary_on_us
   * Auxiliary on-us field
   */
  auxiliary_on_us?: string;
  /**
   * external_processing_code
   * External processing code
   */
  external_processing_code?: string;
  /**
   * payor_bank_routing_number
   * Payor bank routing number on the MICR line
   */
  payor_bank_routing_number?: string;
  /**
   * on_us
   * On-us field on the MICR line
   */
  on_us?: string;
}

/**
 * type
 * Check transfer status.
 */
export enum CheckCheckTransferStatus {
  Issued = 'issued',
  ManualReview = 'manual_review',
  Rejected = 'rejected',
  PendingDeposit = 'pending_deposit',
  Deposited = 'deposited',
  Stopped = 'stopped',
  PendingFirstReturn = 'pending_first_return',
  PendingSecondReturn = 'pending_second_return',
  FirstReturn = 'first_return',
  PendingReclear = 'pending_reclear',
  SecondReturn = 'second_return',
  Settled = 'settled',
  PendingUserInitiatedReturn = 'pending_user_initiated_return',
  UserInitiatedReturned = 'user_initiated_returned',
}

export interface CreateCheckRequest {
  /** Description of the transfer visible only in your platform. Maximum length: `255` characters. */
  description?: string;
  /**
   * amount
   * Amount (in cents) of the funds that will be debited from the account.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  positive_pay_amount: string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. Currently only `USD` is supported.
   */
  currency_code: string;
  /**
   * account_number_id
   * ID of the account number that is sending the transfer. If this is specified, the `bank_account_id` does not need to be included.
   *
   * `account_number_id` or `bank_account_id` is required
   */
  account_number_id?: string;
  /**
   * bank_account_id
   * ID of the bank number that is sending the transfer. If no `account_number_id` is specified, the default account number on `bank_account_id` is used.
   *
   * `account_number_id` or `bank_account_id` is required
   */
  bank_account_id?: string;
}

export interface DetailsTransferMonitoringDetails {
  sender_name?: string;
  merchant_name?: string;
  merchant_category_code?: string;
  authorization_method?: string;
  website?: string;
  address?: ApiAddress;
}

export interface EntityBusinessDetails {
  ein?: string;
  business_name?: string;
  website?: string;
  address?: ApiAddress;
  beneficial_owners?: EntityPersonDetails[];
  industry?: string;
  legal_type?:
    | 'corporation'
    | 'llc'
    | 'limited-partnership'
    | 'general-partnership'
    | 'sole-proprietorship'
    | 'trust'
    | 'pic-piv'
    | 'spv'
    | 'other';
  registration_id?: InternationalIdentification;
}

export interface EntityCreateBusinessEntityRequest {
  /**
   * ein
   * Employer Identification Number (Tax ID)
   * @example "123456789"
   */
  ein: string;
  /**
   * business_name
   * Legal Business Name
   * @example "Yellen Cocktails LLC"
   */
  business_name: string;
  /**
   * website
   * Website of the business. Optional, but highly encouraged as it will increase likelihood of an automated verification.
   * @example "https://www.example.com"
   */
  website?: string;
  address: ApiAddress;
  /**
   * beneficial_owners
   * A list of all the persons who have ultimate control over funds in the business, whether through ownership or other means.
   *
   * This is anyone who owns 25 percent or more of the business and those who control the funds. If no one owns more than 25% of the business, just the individual who has ultimate control of the funds must be included.
   */
  beneficial_owners?: EntityCreatePersonEntityRequest[];
  /**
   * is_root
   * Whether this entity is going to be a root entity of the platform
   */
  is_root?: boolean;
  /**
   * industry
   * Industry of the business
   * @example "Software"
   */
  industry?: string;
  /**
   * legal_type
   * Legal type of the business
   * @example "LLC"
   */
  legal_type?: string;
  /**
   * state_of_incorporation
   * State of incorporation
   * @example "NY"
   */
  state_of_incorporation?: string;
  /**
   * year_of_incorporation
   * Year of incorporation (YYYY format)
   * @example 2015
   */
  year_of_incorporation?: string;
  /**
   * account_usage
   * Intended account usage for the business
   * @example ["payroll"]
   */
  account_usage?: string[];
  /**
   * description
   * Description of the business
   * @example "A software development company"
   */
  description?: string;
  /**
   * payment_volumes
   * Payment volume category
   * @example "medium"
   */
  payment_volumes?: string;
  /** @example ["US","CA"] */
  countries_of_operation?: string[];
}

export interface EntityCreatePersonEntityRequest {
  /**
   * first_name
   * First name of the legal person
   * @example "Oliver"
   */
  first_name?: string;
  /**
   * last_name
   * Last name of the legal person
   * @example "Hockey"
   */
  last_name?: string;
  /**
   * middle_name
   * Middle name of the legal person
   * @example "Middleware"
   */
  middle_name?: string;
  /**
   * ssn
   * Social Security Number
   * @example "123-45-6789"
   */
  ssn?: string;
  /**
   * date_of_birth
   * Date of birth (YYYY-MM-DD)
   * @example "1990-01-01T00:00:00.000Z"
   */
  date_of_birth?: string;
  /**
   * email
   * (optional) Email of the individual. Optional, but highly encouraged as it will increase likelihood of an automated verification.
   * @example "john.doe@example.com"
   */
  email?: string;
  address?: ApiAddress;
  /**
   * is_root
   * Whether this entity is going to be a root entity of the platform
   * @example false
   */
  is_root?: boolean;
  /**
   * passport
   * Passport information of the individual
   */
  passport?: {
    /**
     * number
     * Passport number
     * @example "123456789"
     */
    number?: string;
    /**
     * country_code
     * Passport country code
     * @example "US"
     */
    country_code?: string;
  };
  is_control_person?: boolean;
  is_beneficial_owner?: boolean;
  ownership_percentage?: number;
  job_title?: string;
}

export interface EntityEntity {
  id?: string;
  name?: string;
  type?: EntityEntityType;
  verification_status?: EntityVerificationStatus;
  is_root?: boolean;
  /**
   * review_reasons
   * Reasons why manual review is required for this entity. Possible enum values:
   * `address_mismatch`: address verification failed
   * `dob_mismatch`: date of birth verification failed
   * `ssn_mismatch`: SSN verification failed
   * `name_mismatch`: name verification failed
   * `fraud_risk`: potential fraud risk detected
   * `fein_mismatch`: FEIN verification failed
   * `other_review_reason`: a catch-all placeholder. We will break it down to more detailed reason soon.
   */
  review_reasons?: string[];
  /**
   * documents
   * Documents that have been submitted for the manual review process of KYC/KYB for this entity.
   */
  documents?: EntityEntityDocument[];
}

/** Documents that have been submitted for the manual review process of KYC/KYB for this entity. */
export interface EntityEntityDocument {
  /**
   * created_at
   * Time at which the document was submitted.
   * @format date-time
   */
  created_at?: string;
  /**
   * entity_id
   * ID of the entity for which the document was submitted.
   */
  entity_id?: string;
  /**
   * document_id
   * ID of the document that was submitted.
   */
  document_id?: string;
  /**
   * description
   * Description of why the document was submitted for review
   */
  description?: string;
}

/** @default "TYPE_NONE" */
export enum EntityEntityType {
  TYPE_NONE = 'TYPE_NONE',
  PERSON = 'PERSON',
  BUSINESS = 'BUSINESS',
}

export interface EntityEntityWithDetails {
  id: string;
  type: EntityEntityType;
  verification_status: EntityVerificationStatus;
  person_details?: EntityPersonDetails;
  business_details?: EntityBusinessDetails;
  is_root: boolean;
  /**
   * review_reasons
   * Reasons why manual review is required for this entity. This field is only set when the entity is in `manual_review` status. Possible enum values:
   * `address_mismatch`: address verification failed
   * `dob_mismatch`: date of birth verification failed
   * `ssn_mismatch`: SSN verification failed
   * `name_mismatch`: name verification failed
   * `fraud_risk`: potential fraud risk detected
   * `fein_mismatch`: FEIN verification failed
   * `other_review_reason`: a catch-all placeholder. We will break it down to more detailed reason soon.
   */
  review_reasons: string[];
  documents: EntityEntityDocument[];
  /**
   * verification_tags
   * List of verification tags that provide more details on how `verification_status` and `review_reasons` are decided.
   */
  verification_tags?: string[];
}

export interface EntityList {
  entities?: EntityEntity[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more?: boolean;
}

export interface EntityPersonDetails {
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  ssn?: string;
  date_of_birth?: string;
  email?: string;
  address?: ApiAddress;
  passport?: InternationalIdentification;
  drivers_license?: string | null;
  national_id?: string | null;
}

export interface EntityUpdateBusinessEntityBody {
  /**
   * business_name
   * Legal Business Name
   * @example "Yellen Cocktails LLC"
   */
  business_name?: string;
  /**
   * website
   * (optional) Website of the business. Optional, but highly encouraged as it will increase likelihood of an automated verification.
   * @example "column.com"
   */
  website?: string;
  address?: ApiAddress;
  /**
   * beneficial_owners
   * A list of all the persons who have ultimate control over funds in the business, whether through ownership or other means.
   *
   * This is anyone who owns owns 25 percent or more of the business and those who control the funds. If no one owns more than 25% of the business, just the individual who has ultimate control of the funds must be included.
   */
  beneficial_owners?: EntityCreatePersonEntityRequest[];
}

export interface EntityUpdatePersonEntityBody {
  /**
   * first_name
   * First name of the legal person
   * @example "Oliver"
   */
  first_name?: string;
  /**
   * last_name
   * Last name of the legal person
   * @example "Hockey"
   */
  last_name?: string;
  /**
   * middle_name
   * Middle name of the legal person
   */
  middle_name?: string;
  /**
   * date_of_birth
   * Date of birth (YYYY-MM-DD)
   * @example "1985-08-04T00:00:00.000Z"
   */
  date_of_birth?: string;
  /**
   * email
   * (optional) Email of the individual. Optional, but highly encouraged as it will increase likelihood of an automated verifcation.
   * @example "apis@column.com"
   */
  email?: string;
  address?: ApiAddress;
}

export interface EventEvent {
  id?: string;
  /** @format date-time */
  created_at?: string;
  /**
   * type
   * Event type. See all [events here](https://www.column.com/docs/events-and-webhooks#all-event-types)
   */
  type?: string;
  /**
   * data
   * Object containing data associated with the event. See data object for [each event here](https://www.column.com/docs/events-and-webhooks#all-event-types)
   *
   * *e.g.* for any ACH transfer related event (i.e., `ach.*`), its data will be an ACH transfer object.
   */
  data?: object;
}

export interface EventList {
  events: EventEvent[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface ExternalCounterparty {
  id?: string;
  account_number?: string;
  routing_number?: string;
  description?: string;
  /**
   * routing_number_type
   * The type of the routing number. Can be 'aba' or 'bic'. Default value is 'aba' if this field is not set.
   * @default "aba"
   */
  routing_number_type?: 'aba' | 'bic';
  wire?: ExternalWireObject;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  /** Indicate if the counterparty is actually a Column bank account */
  is_column_account?: boolean;
  /** Indicate if the counterparty is allowed to send drawdown requests to Column via Fedwire. If it is false, all inbound drawdown requests from this counterparty will be rejected. */
  wire_drawdown_allowed?: boolean;
  account_type?: string;
  name?: string;
  address?: ApiAddress;
  phone?: string;
  email?: string;
  legal_id?: string;
  legal_type?: string;
  local_bank_code?: string;
  local_bank_name?: string;
  local_account_number?: string;
  local_bank_country_code?: string;
}

export interface ExternalCreateCounterpartyRequest {
  /**
   * routing_number
   * The ABA routing number of the external account you will be sending or receiving transfers to/from
   */
  routing_number: string;
  /**
   * routing_number_type
   * The type of the routing number. Can be 'aba' or 'bic'. Default value is 'aba' if this field is not set.
   * @default "aba"
   */
  routing_number_type?: 'aba' | 'bic';
  /**
   * account_number
   * The account number of the external account you will be sending or receiving transfers to/from
   */
  account_number: string;
  /**
   * account_type
   * The type of the external account. Can be 'checking' or 'savings'. Default value is 'checking' if this field is not set.
   * @default "checking"
   */
  account_type?: 'checking' | 'savings';
  description?: string;
  /**
   * wire_drawdown_allowed
   * Indicate if the counterparty is allowed to send drawdown requests to Column via Fedwire. If it is false, all inbound drawdown requests from this counterparty will be rejected.
   */
  wire_drawdown_allowed?: boolean;
  wire?: ExternalWireObject;
  name?: string;
  address?: ApiAddress;
  phone?: string;
  email?: string;
  legal_id?: string;
  legal_type?: string;
  local_bank_code?: string;
  local_bank_name?: string;
  local_bank_country_code?: string;
  local_account_number?: string;
}

export interface ExternalFinancialInstitution {
  /** Institution's routing/ABA number. Nine digits including a check digit. */
  routing_number?: string;
  routing_number_type?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  /** Institution's short name */
  short_name?: string;
  /** Institution's offical full name */
  full_name?: string;
  /** Indicate if an institution accepts wire transfers */
  wire_eligible?: boolean;
  /** Indicate if an institution accepts wire transfers for settlement only */
  wire_settlement_only?: boolean;
  /** Indicate if an institution accepts ACH transfers */
  ach_eligible?: boolean;
  /** Institution's street address. Only available if an institution accepts ACH transfers. */
  street_address?: string;
  /** Institution's address city name */
  city?: string;
  /** Institution's address state code (e.g., CA) */
  state?: string;
  /** Institution's address zip code. */
  zip_code?: string;
  /** Institution's address country code (e.g., US). */
  country_code?: string;
  /** Institution's phone number. Only available if an institution accepts ACH transfers. */
  phone_number?: string;
}

export interface CounterpartiesList {
  counterparties: ExternalCounterparty[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface ExternalWireObject {
  beneficiary_name?: string;
  beneficiary_address?: ApiAddress;
  beneficiary_phone?: string;
  beneficiary_email?: string;
  beneficiary_legal_id?: string;
  beneficiary_type?: 'business' | 'non_profit' | 'individual' | 'sole_proprietor';
  local_bank_code?: string;
  local_account_number?: string;
}

export interface WebhookCreateWebhookEndpointRequest {
  /**
   * enabled_events
   * Enables you to subscribe to certain events. See all [events here](https://www.column.com/docs/events-and-webhooks#all-event-types)
   *
   * `enabled_events` can contain specific event types, or can include wildcard string (e.g., ach.* for all ACH events)
   */
  enabled_events: string[];
  /**
   * url
   * The `https` url that webhooks are going to be delivered to.
   *
   * *e.g.* https://yoursite.com/columnevents
   */
  url: string;
  /**
   * description
   * *(optional)* A description for this webhook
   */
  description?: string;
}

export interface WebhookListEndpointWebhookDeliveriesResponse {
  webhook_deliveries?: WebhookWebhookDelivery[];
}

export interface WebhookListEventWebhookDeliveriesResponse {
  webhook_deliveries?: WebhookWebhookDelivery[];
}

export interface WebhookListWebhookEndpointsResponse {
  webhook_endpoints?: WebhookWebhookEndpoint[];
}

export interface WebhookVerifyWebhookEndpointResponse {
  request_body?: string;
  response_body?: string;
  /** @format int32 */
  response_code?: number;
  success?: boolean;
}

export interface WebhookWebhookDelivery {
  event?: EventEvent;
  /** @format date-time */
  scheduled_at?: string;
  status?: WebhookWebhookDeliveryStatus;
}

/** @default "UNKNOWN" */
export enum WebhookWebhookDeliveryStatus {
  UNKNOWN = 'UNKNOWN',
  SCHEDULED = 'SCHEDULED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  IGNORED = 'IGNORED',
}

export interface WebhookWebhookEndpoint {
  id?: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  updated_at?: string;
  description?: string;
  enabled_events?: string[];
  secret?: string;
  is_disabled?: boolean;
  url?: string;
}

export interface WireCreateWireTransferRequest {
  /**
   * description
   * A description of the transfer visible in people's accounts.
   */
  description?: string;
  /**
   * amount
   * Amount (in cents) of the funds that will be transferred between originator and counterparty accounts.
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  amount: string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. *e.g.* `USD`
   */
  currency_code: string;
  /**
   * account_number_id
   * ID of the account number that is sending the transfer.
   * If this is specified, the `bank_account_id` does not need to be included.
   *
   * `account_number_id` or `bank_account_id` is required
   */
  account_number_id?: string;
  /**
   * bank_account_id
   * ID of the bank number that is sending the transfer. If no `account_number_id` is specified,
   * the default account number on `bank_account_id` is used.
   *
   * `account_number_id` or `bank_account_id` is required
   */
  bank_account_id?: string;
  /**
   * counterparty_id
   * ID of the `counterparty` that will receive the transfer
   *
   * **Note:** A wire object must be attached to the `counterparty`. If it is not, this request will fail.
   */
  counterparty_id?: string;
  /**
   * idempotency_key
   * DEPRECATED: see https://www.column.com/docs/idempotency
   */
  idempotency_key?: string;
  /**
   * allow_overdraft
   * Allow the account to go negative for an outgoing transfer. The bank account
   * needs to have 'is_overdraftable' enabled with an overdraft reserve account linked to it
   */
  allow_overdraft?: boolean;
}

export interface WireTransferList {
  /**
   * transfers
   * List of wire transfers under your developer account.
   */
  transfers: WireTransfer[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface WireReceiveWireTransferRequest {
  /**
   * destination_account_number_id
   * identifier for the account that will receive the wire
   */
  destination_account_number_id: string;
  /**
   * amount
   * Amount (in cents) to wire into the account
   *
   * e.g. $1.75 would be represented by `175`
   * @format int64
   */
  amount: string;
  /**
   * currency_code
   * The three-letter currency code defined in ISO 4217. *e.g.* `USD`
   */
  currency_code: string;
}

export type WireReceiveWireTransferResponse = object;

export interface WireSettleWireTransferRequest {
  /**
   * wire_transfer_id
   * ID of the outgoing transfer to force a settlement
   */
  wire_transfer_id?: string;
}

export type WireSettleWireTransferResponse = object;

export interface ChecksSettleCheckTransferRequest {
  /**
   * check_transfer_id
   * ID of the outgoing transfer to force a settlement
   */
  check_transfer_id?: string;
}

export type ChecksSettleCheckTransferResponse = object;

export interface InternationalWireTransfer {
  account_number_id?: string;
  allow_overdraft?: boolean;
  /** @format int64 */
  amount?: number;
  bank_account_id?: string;
  beneficiary_account_number?: string;
  beneficiary_address?: ApiAddress;
  beneficiary_fi?: string;
  beneficiary_name?: string;
  charge_bearer?: ChargeBearerCode;
  /** charges */
  charges?: any[] | null;
  completed_at?: TypesDateTimeNullable;
  counterparty_id?: string;
  /** @format date-time */
  created_at?: string;
  currency_code?: string;
  description?: string | null;
  end_to_end_id?: string | null;
  fx_quote_id?: string;
  fx_rate?: string | null;
  id?: string;
  idempotency_key?: string | null;
  /** @format date-time */
  initiated_at?: string;
  /** @format int64 */
  instructed_amount?: number;
  instructed_currency_code?: string;
  instruction_id?: string;
  instruction_to_beneficiary_fi?: string;
  is_incoming?: boolean;
  manual_review_at?: TypesDateTimeNullable;
  originator_account_number?: string;
  originator_address?: ApiAddress;
  originator_fi?: string;
  originator_name?: string;
  pending_submission_at?: TypesDateTimeNullable;
  remittance_info?: InternationalWireRemittanceInfo;
  raw_message?: string | null;
  return_reason?: string | null;
  /** @format int64 */
  returned_amount?: number | null;
  returned_at?: TypesDateTimeNullable;
  returned_currency_code?: string | null;
  /** @format int64 */
  settled_amount?: number | null;
  settled_currency_code?: string;
  /** @format date */
  settlement_date?: string;
  status?: InternationalWireStatus;
  submitted_at?: TypesDateTimeNullable;
  uetr?: string;
  ultimate_beneficiary_address?: ApiAddress;
  ultimate_beneficiary_name?: string;
  ultimate_originator_address?: ApiAddress;
  ultimate_originator_name?: string;
  /** @format date-time */
  updated_at?: string;
  cancellation_reason?: string | null;
  cancellation_status?: string | null;
}

export interface InternationalWireTransferList {
  /**
   * transfers
   * List of ACH transfers under your developer account.
   */
  transfers: InternationalWireTransfer[];
  /** A flag for use in pagination. Tells whether or not there are more elements available to list after this page. If `false`, this page is the end of the list and there's no more records after it. */
  has_more: boolean;
}

export interface CreateInternationalWireTransferRequest {
  bank_account_id?: string;
  account_number_id?: string;
  counterparty_id?: string;
  /** @format int64 */
  amount?: number;
  currency_code?: string;
  charge_bearer?: 'CRED' | 'DEBT' | 'SHAR' | 'SLEV';
  allow_overdraft?: boolean;
  description?: string;
  fx_quote_id?: string;
  remittance_info?: {
    general_info?: string;
    beneficiary_reference?: string;
  };
  message_to_beneficiary_bank?: string;
  purpose_code?: string;
  intermediary_bank?: string;
  is_iso_20022_enabled?: boolean;
  correspondent_bank?: string;
}

export interface InternationalWireBillingConfig {
  /** USD cents. */
  fixed_fee?: number;
  /** Number of basis points (bps) for FX margin. 1 bps = 0.01%. */
  fx_rate_margin_bps?: number;
  /** Account number ID for international wire revenues of a platform. */
  revenue_account_number_id?: string;
}

export enum InternationalWireReturnReason {
  IncorrectBeneficiaryAccount = 'incorrect_beneficiary_account',
  BeneficiaryAccountBlocked = 'beneficiary_account_blocked',
  IncorrectAmount = 'incorrect_amount',
  BeneficiaryMismatch = 'beneficiary_mismatch',
  RefusedByBeneficiary = 'refused_by_beneficiary',
  CancelationRequested = 'cancelation_requested',
  Fraud = 'fraud',
  ComplianceRejected = 'compliance_rejected',
}

export interface ReturnInternationalWireTransferRequest {
  return_reason?: InternationalWireReturnReason;
  message_to_originator_bank?: string;
}

export enum InternationalWireCancelReason {
  IncorrectAmount = 'incorrect_amount',
  IncorrectCurrency = 'incorrect_currency',
  RequestedByOriginator = 'requested_by_originator',
  Duplicate = 'duplicate',
  Fraud = 'fraud',
  TechFailure = 'tech_failure',
  PaymentNotJustified = 'payment_not_justified',
}

export interface CancelInternationalWireTransferRequest {
  cancellation_reason?: InternationalWireCancelReason;
  message_to_originator_bank?: string;
}

export enum InternationalWireTrackingStatus {
  Rejected = 'rejected',
  Pending = 'pending',
  Completed = 'completed',
}

export enum InternationalWireCancellationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface InternationalWireTransferTracking {
  id: string;
  uetr: string;
  transfer_status: InternationalWireTrackingStatus;
  transfer_status_reason: string;
  cancellation_status: InternationalWireCancellationStatus;
  cancellation_reason: string;
  completed_at: TypesDateTimeNullable;
  updated_at: TypesDateTimeNullable;
  events: {
    network_reference?: string;
    updated_by?: string;
    updated_at?: TypesDateTime;
    type?:
      | 'fi_transfer_cover_initiated'
      | 'fi_transfer_cover_updated'
      | 'transfer_cover_initiated'
      | 'transfer_cover_updated'
      | 'transfer_cancellation_requested'
      | 'transfer_cancellation_responded'
      | 'transfer_initiated'
      | 'transfer_updated'
      | 'transfer_cancellation_tracking_updated'
      | 'fi_transfer_cancellation_requested'
      | 'fi_transfer_cancellation_responded'
      | 'fi_transfer_initiated'
      | 'fi_transfer_updated'
      | 'fi_transfer_cancellation_tracking_updated'
      | 'fi_transfer_cover_return_initiated'
      | 'fi_transfer_cover_return_updated'
      | 'transfer_cover_return_initiated'
      | 'transfer_cover_return_updated'
      | 'transfer_return_initiated'
      | 'transfer_return_updated'
      | 'fi_transfer_return_initiated'
      | 'fi_transfer_return_updated';
    transfer_status?: InternationalWireTrackingStatus;
    transfer_status_reason?: string | null;
    cancellation_status?: InternationalWireCancellationStatus;
    cancellation_reason?: string | null;
    cancellation_status_reason?: string | null;
    /** @format int64 */
    instructed_amount?: number;
    instructed_currency_code?: string;
    /** @format int64 */
    settled_amount?: number;
    settled_currency_code?: string;
    fx_rate?: string;
    charge_bearer?: number;
    charges?: {
      /** @format int64 */
      amount?: number;
      currency_code?: string;
      agent?: string;
    }[];
    previous_instructing_fis?: string[];
    intermediary_fis?: string[];
  }[];
}

export interface FxRateSheet {
  data: {
    updated_at?: TypesDateTime;
    sell_currency_code?: string;
    buy_currency_code?: string;
    rate?: string;
  }[];
}

export type FxQuoteRequest = object;

export interface FxQuote {
  id: string;
  created_at: TypesDateTime;
  updated_at: TypesDateTime;
  rate: string;
  rate_date: string;
  expired_at: string;
  sell_currency_code: string;
  /** @format int64 */
  sell_amount: number;
  buy_currency_code: string;
  /** @format int64 */
  buy_amount: number;
  status: string;
}

/** @default "SHAR" */
export enum ChargeBearerCode {
  DEBT = 'DEBT',
  CRED = 'CRED',
  SHAR = 'SHAR',
}

export interface InternationalWireCharge {
  /** @format int64 */
  amount?: number;
  currency_code?: string;
  agent?: string;
}

export interface InternationalWireRemittanceInfo {
  general_info?: string;
  beneficiary_reference?: string;
}

/** @default "initiated" */
export enum InternationalWireStatus {
  Initiated = 'initiated',
  ManualReview = 'manual_review',
  PendingSubmission = 'pending_submission',
  Submitted = 'submitted',
  Completed = 'completed',
  PendingReturn = 'pending_return',
  Returned = 'returned',
}

export interface WireTransfer {
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  /** @format date-time */
  initiated_at: string;
  pending_submission_at?: TypesDateTimeNullable;
  submitted_at?: TypesDateTimeNullable;
  completed_at?: TypesDateTimeNullable;
  rejected_at?: TypesDateTimeNullable;
  manual_review_at?: TypesDateTimeNullable;
  idempotency_key?: string;
  bank_account_id: string;
  account_number_id: string;
  counterparty_id: string;
  amount: string | number;
  currency_code: string;
  description?: string;
  status: WireWireTransferStatus;
  allow_overdraft: boolean;
  is_on_us: boolean;
  is_incoming: boolean;
  /** {1120} */
  omad?: string;
  /** {1510} */
  type_code: string;
  subtype_code: string;
  /** {1520} */
  imad?: string;
  /** {3100} */
  sender_di_routing_number: string;
  sender_di_name: string;
  /** {3320} */
  sender_reference: string;
  /** {3400} */
  receiver_di_routing_number: string;
  receiver_di_name: string;
  /** {3500} */
  previous_message_reference: string;
  /** {4200} */
  beneficiary_name: string;
  raw_beneficiary_address: string;
  beneficiary_account_number: string;
  /** {5000} */
  originator_name: string;
  raw_originator_address: string;
  originator_account_number: string;
  sender_address: ApiAddress;
  business_function_code: string;
  originator_to_beneficiary_information_line_1: string;
  originator_to_beneficiary_information_line_2: string;
  originator_to_beneficiary_information_line_3: string;
  originator_to_beneficiary_information_line_4: string;
  fi_to_fi_information_line_1: string;
  fi_to_fi_information_line_2: string;
  fi_to_fi_information_line_3: string;
  fi_to_fi_information_line_4: string;
  fi_to_fi_information_line_5: string;
  fi_to_fi_information_line_6: string;
  reversal_pair_transfer_id?: string;
  wire_drawdown_request_id?: string;
}

/** @default "NONE" */
export enum WireWireTransferStatus {
  NONE = 'NONE',
  INITIATED = 'INITIATED',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
}

export interface WireDrawdownRequest {
  id: string;
  status: 'received' | 'approved' | 'denied' | 'completed' | 'rejected';
  received_at: TypesDateTimeNullable;
  approved_at: TypesDateTimeNullable;
  completed_at: TypesDateTimeNullable;
  rejected_at: TypesDateTimeNullable;
  bank_account_id: string;
  account_number_id: string;
  originator_counterparty_id: string;
  beneficiary_counterparty_id: string;
  beneficiary_account_number: string;
  beneficiary_name: string;
  originator_account_number: string;
  originator_name: string;
  originator_to_beneficiary_information: string[];
  receiver_di_name: string;
  receiver_di_routing_number: string;
  sender_di_name: string;
  sender_di_routing_number: string;
  /** @format int64 */
  amount: number;
  currency_code: string;
  imad: string;
  message_identifier: string;
  wire_transfer_id: string;
  is_incoming: boolean;
}

export interface Loan {
  balances: {
    /** @example "0" */
    principal_charged_off?: string;
    /** @example "0" */
    principal_holding?: string;
    /** @example "0" */
    principal_outstanding?: string;
    /** @example "0" */
    principal_paid?: string;
  };
  charged_off_at: TypesDateTimeNullable;
  created_at: TypesDateTimeNullable;
  /** @example "USD" */
  currency: string;
  delinquent_at: TypesDateTimeNullable;
  /** @example "new_account" */
  description: string;
  disputed_at: TypesDateTimeNullable;
  /** @example "loan_26RVIHzwLaCxVXmMLcUJyhFHT3b" */
  id: string;
  is_revolving: boolean;
  /**
   * @format date
   * @example "2021-01-01T00:00:00.000Z"
   */
  maturity_date: string;
  /** @example "1000000" */
  max_principal_balance: string;
  paid_off_at: TypesDateTimeNullable;
  canceled_at: TypesDateTimeNullable;
  /** @example "enti_26RC4ARuBwDmxDlRX1ZX252YPfe" */
  primary_signer_entity_id: string;
  /** @example "current" */
  status: string;
}

export interface CreateLoanRequest {
  currency?: string;
  description?: string;
  entity_id?: string;
  is_revolving?: boolean;
  /** @format date */
  maturity_date?: string;
  max_principal_balance?: string;
}

export interface UpdateLoanRequest {
  description?: string;
  entity_id?: string;
  is_revolving?: boolean;
  /** @format date */
  maturity_date?: string;
  max_principal_balance?: string;
}

export interface LoanDisbursement {
  account_number_id?: string;
  /** @format int64 */
  amount?: number;
  bank_account_id?: string;
  /** @format date-time */
  created_at?: string;
  currency?: string;
  description?: string;
  effective_at?: string;
  id?: string;
  loan_id?: string;
  status?: string;
}

export interface CreateLoanDisbursementRequest {
  amount?: string;
  bank_account_id?: string;
  currency?: string;
  description?: string;
  hold?: boolean;
  loan_id?: string;
}

export interface UpdateLoanDisbursementRequest {
  amount?: string;
  currency?: string;
}

export interface ClearLoanDisbursementRequest {
  amount?: string;
  currency?: string;
}

export interface AssignPlatformRoleRequest {
  user_id: string;
  custom_role_id?: string;
  precanned_role_name?: PrecannedRoleName;
}

export interface LoanPayment {
  account_number_id: string;
  bank_account_id: string;
  currency: string;
  description: string;
  id: string;
  loan_id: string;
  principal_amount: string;
  status: string;
  /** @format date-time */
  created_at?: string;
  /** @format date-time */
  effective_at?: string;
}

export interface CreateLoanPaymentRequest {
  principal_amount?: string;
  currency?: string;
  description?: string;
  account_number_id?: string;
  loan_id?: string;
}

export interface UnifiedTransfers {
  transfers: UnifiedTransfer[];
  has_more: boolean;
  /** @format int64 */
  total_results_count: number;
}

export type UnifiedTransfer = any & {
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  completed_at: TypesDateTimeNullable;
  status: string;
  type: string;
  /** @format int64 */
  amount: number;
  currency_code: string;
  is_incoming: boolean;
  idempotency_key: string;
  description: string;
  sender_internal_account?: {
    bank_account_id: string;
    account_number_id: string;
  };
  receiver_internal_account?: {
    bank_account_id: string;
    account_number_id: string;
  };
  external_source?: {
    counterparty_id?: string;
    bank_name?: string;
    sender_name?: string;
  };
  external_destination?: {
    counterparty_id: string;
  };
};

export interface CustomRoleBankAccountLevelOverrides {
  ach_credits?: CustomRolePermissionLevel;
  ach_debits?: CustomRolePermissionLevel;
  ach_returns?: CustomRolePermissionLevel;
  bank_account_id?: string;
  book_transfers?: CustomRolePermissionLevel;
  counterparties?: CustomRolePermissionLevel;
  deposit_checks?: CustomRolePermissionLevel;
  entities?: CustomRolePermissionLevel;
  issue_checks?: CustomRolePermissionLevel;
  loans?: CustomRolePermissionLevel;
  realtime_transfers?: CustomRolePermissionLevel;
  stop_payments?: CustomRolePermissionLevel;
  this_bank_account?: CustomRoleGranularPermissionLevel;
  wire_transfers?: CustomRolePermissionLevel;
}

export interface CustomRoleEntityLevelOverrides {
  ach_credits?: CustomRolePermissionLevel;
  ach_debits?: CustomRolePermissionLevel;
  ach_returns?: CustomRolePermissionLevel;
  bank_accounts?: CustomRoleGranularPermissionLevel;
  book_transfers?: CustomRolePermissionLevel;
  deposit_checks?: CustomRolePermissionLevel;
  entity_id?: string;
  issue_checks?: CustomRolePermissionLevel;
  loans?: CustomRolePermissionLevel;
  realtime_transfers?: CustomRolePermissionLevel;
  stop_payments?: CustomRolePermissionLevel;
  this_entity?: CustomRolePermissionLevel;
  wire_transfers?: CustomRolePermissionLevel;
}

export interface CustomRolePlatformLevelPermissions {
  ach_credits?: CustomRolePermissionLevel;
  ach_debits?: CustomRolePermissionLevel;
  ach_returns?: CustomRolePermissionLevel;
  api_keys?: CustomRolePermissionLevel;
  bank_accounts?: CustomRoleGranularPermissionLevel;
  book_transfers?: CustomRolePermissionLevel;
  counterparties?: CustomRolePermissionLevel;
  deposit_checks?: CustomRolePermissionLevel;
  entities?: CustomRolePermissionLevel;
  issue_checks?: CustomRolePermissionLevel;
  loans?: CustomRolePermissionLevel;
  platform_info?: CustomRolePermissionLevel;
  realtime_transfers?: CustomRolePermissionLevel;
  reporting?: CustomRolePermissionLevel;
  stop_payments?: CustomRolePermissionLevel;
  webhooks?: CustomRolePermissionLevel;
  wire_transfers?: CustomRolePermissionLevel;
}

export interface CustomRoleResponseObject {
  bank_account_level_overrides?: CustomRoleBankAccountLevelOverrides[];
  description?: string;
  entity_level_overrides?: CustomRoleEntityLevelOverrides[];
  id: string;
  name: string;
  platform_id: string;
  /** @format int32 */
  user_count?: number;
  editable?: boolean;
  platform_level_permissions: CustomRolePlatformLevelPermissions;
}

export interface CreateCustomRoleRequestObject {
  bank_account_level_overrides: CustomRoleBankAccountLevelOverrides[];
  description?: string;
  entity_level_overrides: CustomRoleEntityLevelOverrides[];
  name: string;
  platform_id: string;
  platform_level_permissions: CustomRolePlatformLevelPermissions;
}

export interface UpdateCustomRoleRequestObject {
  bank_account_level_overrides?: CustomRoleBankAccountLevelOverrides[];
  description?: string;
  entity_level_overrides?: CustomRoleEntityLevelOverrides[];
  name?: string;
  platform_id?: string;
  platform_level_permissions?: CustomRolePlatformLevelPermissions;
}

export interface PlatformRoleResponseObject {
  bank_account_level_overrides: CustomRoleBankAccountLevelOverrides[];
  created_at: string;
  dashboard_user_id: string;
  description: string;
  entity_level_overrides: CustomRoleEntityLevelOverrides[];
  id: string;
  name: PrecannedRoleName;
  platform_id: string;
  platform_level_permissions: CustomRolePlatformLevelPermissions;
}

export interface CustomRolesList {
  roles?: CustomRoleResponseObject[];
}

export interface PlatformRolesList {
  platform_roles: PlatformRoleResponseObject[];
}

export enum CustomRolePermissionLevel {
  Default = 'default',
  None = 'none',
  Read = 'read',
  Write = 'write',
}

export enum CustomRoleGranularPermissionLevel {
  Default = 'default',
  None = 'none',
  Read = 'read',
  Update = 'update',
  CreateDelete = 'create-delete',
}

export enum PrecannedRoleName {
  Owner = 'owner',
  Admin = 'admin',
  Developer = 'developer',
  MoneyMover = 'money_mover',
  Readonly = 'readonly',
  CheckDepositor = 'check_depositor',
}

/**
 * @format date-time
 * @example "2023-08-01T17:00:00.000Z"
 */
export type TypesDateTime = string;

/**
 * @format date-time
 * @example "2023-08-01T17:00:00.000Z"
 */
export type TypesDateTimeNullable = string | null;

export type Non200Responses = any;

/** Response format when a request fails. */
export type ErrorResponse = any;
