import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import React, { useRef, useEffect, FC } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface ReadIconProps {
  className?: string;
}

gsap.registerPlugin(MorphSVGPlugin);

const Wrapper = styled.div`
  --read-icon-head-rotate: 0deg;
  --read-icon-hand-left-x: 0px;
  --read-icon-hand-right-x: 0px;
`;

const Head = styled.path`
  transform-origin: 50% -100%;
  transform: rotate(var(--read-icon-head-rotate)) translateZ(0);
`;

const LeftHand = styled.path`
  transform: translateX(var(--read-icon-hand-left-x)) translateZ(0);
`;

const RightHand = styled.path`
  transform: translateX(var(--read-icon-hand-right-x)) translateZ(0);
`;

export const ReadIcon: FC<ReadIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);
  const pathRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    const wrapper = wrapeprRef.current;
    const path = pathRef.current;

    if (!wrapper && !path) {
      return;
    }

    const wrapperTween: gsap.core.Tween = gsap.to(wrapper, {
      keyframes: [
        {
          '--read-icon-head-rotate': '2deg',
          '--read-icon-hand-left-x': '1px',
          duration: 0.3,
        },
        {
          '--read-icon-head-rotate': '-2deg',
          '--read-icon-hand-left-x': '0px',
          '--read-icon-hand-right-x': '-1px',
          duration: 0.6,
        },
        {
          '--read-icon-head-rotate': '0deg',
          '--read-icon-hand-right-x': '0px',
          duration: 0.3,
        },
      ],
      repeat: -1,
      repeatDelay: 1,
    });

    const pathTween: gsap.core.Tween = gsap.to(path, {
      keyframes: [
        {
          morphSVG:
            'M4.75002 10.4167V8.96174C4.7492 8.81965 4.77754 8.67891 4.83327 8.5482C4.889 8.4175 4.97095 8.29961 5.07404 8.20183C5.17714 8.10404 5.29919 8.02844 5.43266 7.97969C5.56612 7.93095 5.70817 7.91009 5.85002 7.91841C7.66068 7.97599 8.42934 8.47826 10 9.38091M10 9.38091C11.5707 8.47826 13.3394 7.97599 15.15 7.91841C15.2919 7.91009 15.4339 7.93095 15.5674 7.97969C15.7008 8.02844 15.8229 8.10404 15.926 8.20183C16.0291 8.29961 16.111 8.4175 16.1668 8.5482C16.2225 8.67891 16.2508 8.81965 16.25 8.96174V10.4167M10 9.38091V17.5001M4.75002 13.7501V15.1792C4.75134 15.3946 4.83554 15.6011 4.98513 15.756C5.13472 15.9108 5.33821 16.0021 5.55335 16.0109C7.47166 15.9943 8.35702 16.5098 10 17.5001M10 17.5001C11.643 16.5098 13.5284 15.9943 15.4467 16.0109C15.6618 16.0021 15.8653 15.9108 16.0149 15.756C16.1645 15.6011 16.2487 15.3946 16.25 15.1792V13.7501',
          duration: 0.3,
        },
        {
          morphSVG:
            'M3.75002 10.4167V8.96174C3.7492 8.81965 3.77754 8.67891 3.83327 8.5482C3.889 8.4175 3.97095 8.29961 4.07404 8.20183C4.17714 8.10404 4.29919 8.02844 4.43266 7.97969C4.56612 7.93095 4.70817 7.91009 4.85002 7.91841C6.66068 7.97599 8.42934 8.47826 10 9.38091M10 9.38091C11.5707 8.47826 12.3394 7.97599 14.15 7.91841C14.2919 7.91009 14.4339 7.93095 14.5674 7.97969C14.7008 8.02844 14.8229 8.10404 14.926 8.20183C15.0291 8.29961 15.111 8.4175 15.1668 8.5482C15.2225 8.67891 15.2508 8.81965 15.25 8.96174V10.4167M10 9.38091V17.5001M3.75002 13.7501V15.1792C3.75134 15.3946 3.83554 15.6011 3.98513 15.756C4.13472 15.9108 4.33821 16.0021 4.55335 16.0109C6.47166 15.9943 8.35702 16.5098 10 17.5001M10 17.5001C11.643 16.5098 12.5284 15.9943 14.4467 16.0109C14.6618 16.0021 14.8653 15.9108 15.0149 15.756C15.1645 15.6011 15.2487 15.3946 15.25 15.1792V13.7501',
          duration: 0.6,
        },
        {
          morphSVG:
            'M3.75002 10.4166V8.96162C3.7492 8.81953 3.77754 8.67879 3.83327 8.54808C3.889 8.41738 3.97095 8.29949 4.07404 8.20171C4.17714 8.10392 4.29919 8.02832 4.43266 7.97957C4.56612 7.93082 4.70817 7.90997 4.85002 7.91829C6.66068 7.97587 8.42934 8.47813 10 9.38079M10 9.38079C11.5707 8.47813 13.3394 7.97587 15.15 7.91829C15.2919 7.90997 15.4339 7.93082 15.5674 7.97957C15.7008 8.02832 15.8229 8.10392 15.926 8.20171C16.0291 8.29949 16.111 8.41738 16.1668 8.54808C16.2225 8.67879 16.2508 8.81953 16.25 8.96162V10.4166M10 9.38079V17.5M3.75002 13.75V15.1791C3.75134 15.3944 3.83554 15.601 3.98513 15.7558C4.13472 15.9107 4.33821 16.002 4.55335 16.0108C6.47166 15.9942 8.35702 16.5096 10 17.5M10 17.5C11.643 16.5096 13.5284 15.9942 15.4467 16.0108C15.6618 16.002 15.8653 15.9107 16.0149 15.7558C16.1645 15.601 16.2487 15.3944 16.25 15.1791V13.75',
          duration: 0.3,
        },
      ],
      repeat: -1,
      repeatDelay: 1,
    });

    return () => {
      wrapperTween?.kill();
      pathTween?.kill();
    };
  }, []);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase>
        <g stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round">
          <path
            ref={pathRef}
            d="M3.75002 10.4166V8.96162C3.7492 8.81953 3.77754 8.67879 3.83327 8.54808C3.889 8.41738 3.97095 8.29949 4.07404 8.20171C4.17714 8.10392 4.29919 8.02832 4.43266 7.97957C4.56612 7.93082 4.70817 7.90997 4.85002 7.91829C6.66068 7.97587 8.42934 8.47813 10 9.38079M10 9.38079C11.5707 8.47813 13.3394 7.97587 15.15 7.91829C15.2919 7.90997 15.4339 7.93082 15.5674 7.97957C15.7008 8.02832 15.8229 8.10392 15.926 8.20171C16.0291 8.29949 16.111 8.41738 16.1668 8.54808C16.2225 8.67879 16.2508 8.81953 16.25 8.96162V10.4166M10 9.38079V17.5M3.75002 13.75V15.1791C3.75134 15.3944 3.83554 15.601 3.98513 15.7558C4.13472 15.9107 4.33821 16.002 4.55335 16.0108C6.47166 15.9942 8.35702 16.5096 10 17.5M10 17.5C11.643 16.5096 13.5284 15.9942 15.4467 16.0108C15.6618 16.002 15.8653 15.9107 16.0149 15.7558C16.1645 15.601 16.2487 15.3944 16.25 15.1791V13.75"
            strokeOpacity={0.5}
          />
          <Head d="M9.99967 6.24996C11.2653 6.24996 12.2913 5.22395 12.2913 3.95829C12.2913 2.69264 11.2653 1.66663 9.99967 1.66663C8.73402 1.66663 7.70801 2.69264 7.70801 3.95829C7.70801 5.22395 8.73402 6.24996 9.99967 6.24996Z" />
          <LeftHand d="M3.33333 10.4166H4.16667C4.60869 10.4166 5.03262 10.5922 5.34518 10.9048C5.65774 11.2173 5.83333 11.6413 5.83333 12.0833C5.83333 12.5253 5.65774 12.9492 5.34518 13.2618C5.03262 13.5744 4.60869 13.75 4.16667 13.75H3.33333C3.11232 13.75 2.90036 13.6622 2.74408 13.5059C2.5878 13.3496 2.5 13.1376 2.5 12.9166V11.25C2.5 11.0289 2.5878 10.817 2.74408 10.6607C2.90036 10.5044 3.11232 10.4166 3.33333 10.4166Z" />
          <RightHand d="M16.667 13.75H15.8337C15.3916 13.75 14.9677 13.5744 14.6551 13.2618C14.3426 12.9492 14.167 12.5253 14.167 12.0833C14.167 11.6413 14.3426 11.2173 14.6551 10.9048C14.9677 10.5922 15.3916 10.4166 15.8337 10.4166H16.667C16.888 10.4166 17.1 10.5044 17.2562 10.6607C17.4125 10.817 17.5003 11.0289 17.5003 11.25V12.9166C17.5003 13.1376 17.4125 13.3496 17.2562 13.5059C17.1 13.6622 16.888 13.75 16.667 13.75Z" />
        </g>
      </IconBase>
    </Wrapper>
  );
};
