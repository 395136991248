import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

interface ScrollStoreState {
  scrollId?: string;
  pageFrameScroll?: number;
}

interface ScrollStoreAction {
  setScrollId: (scrollId?: string) => void;
  setPageFrameScroll: (pageFrameScroll: number) => void;
}

export const useScrollStore = create<ScrollStoreState & ScrollStoreAction>()(
  subscribeWithSelector((set) => ({
    setScrollId: (scrollId) => {
      set(() => ({
        scrollId,
      }));
    },

    setPageFrameScroll: (pageFrameScroll) => {
      set(() => ({
        pageFrameScroll,
      }));
    },
  }))
);
