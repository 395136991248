import { gsap } from 'gsap';
import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useMemo,
  ReactNode,
  useImperativeHandle,
  PropsWithChildren,
} from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

import { Chip, Dropdown, Fade, Icon } from '@column/column-ui-kit';

import { Table, Pagination, PopoverFilterEntry } from '~/components';
import { LogoLoading } from '~/elements';
import { clientWrapper } from '~/repositories/client';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

export interface PaginationWrapperProps {
  tableId: string;
  className?: string;
  fetch: (object: any) => Promise<any>;
  fetchId?: string;
  fetchSearch?: (object: any, search: string) => Promise<any>;
  searchTooltip?: ReactNode;
  columns: any;
  filter?: PopoverFilterEntry[];
  action?: ReactNode;
  empty?: ReactNode;
  rowClick?: (row: any) => void;
}

export interface PaginationWrapperRefProps {
  fetch: () => void;
}

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  will-change: height;
  transform: translateZ(0);
  position: relative;
  z-index: 2;
`;

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const Entries = styled(Chip)`
  --chip-pale: ${({ theme }) => theme.secondary.blendToBackground(150)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 -4px;
  }
`;

const Bottom = styled.div`
  padding: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
`;

export const PaginationWrapper = forwardRef<PaginationWrapperRefProps, PropsWithChildren<PaginationWrapperProps>>(
  (props, ref) => {
    const { currentUser, updateSettings, settings } = useSessionStore();
    const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const fadeRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [showPagination, setShowPagination] = useState<boolean>(false);
    const [usedPagination, setUsedPagination] = useState<boolean>(false);
    const [list, setList] = useState<any>([]);
    const [customFilter, setCustomFilter] = useState<PopoverFilterEntry[]>(props?.filter ?? []);
    const [usedFilter, setUsedFilter] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [entriesOpen, setEntriesOpen] = useState<boolean>(false);

    const handleSearchSubmit = (value: string) => {
      if (props.fetchSearch) {
        setSearchTerm(value);
      }
    };

    const settingsLimit = useMemo(() => {
      if (!settings.tableSettings || !settings.tableSettings[props.tableId]) {
        return 10;
      }
      return settings.tableSettings[props.tableId].limit;
    }, [settings.tableSettings, props.tableId, loading, searchLoading]);

    const filterObject = useMemo(
      () =>
        Object.assign(
          {},
          ...customFilter
            .map((filter: PopoverFilterEntry) => {
              if (!filter.show) {
                return;
              }
              if (filter.type === 'date' && filter.active) {
                return {
                  [`${filter.id}.${filter?.typeActive ?? 'gt'}`]: new Date(filter.active).toISOString(),
                };
              }
              return {
                [filter.id]: filter.active,
              };
            })
            .filter(Boolean)
        ),
      [customFilter]
    );

    const useInlineLoading = useMemo(
      () =>
        searchTerm.length > 0 ||
        usedFilter ||
        usedPagination ||
        (filterObject && Object.keys(filterObject).length !== 0),
      [filterObject, usedPagination, searchTerm]
    );

    const handlePrev = () => {
      setUsedPagination(true);
      fetchData(undefined, list[0][props?.fetchId ?? 'id'], settingsLimit, () => {
        setCurrentPage(currentPage - 1);
      });
    };

    const handleNext = () => {
      setUsedPagination(true);
      fetchData(list[list.length - 1][props?.fetchId ?? 'id'], undefined, settingsLimit, () => {
        setCurrentPage(currentPage + 1);
      });
    };

    const fetchData = (startingAfter?: string, endingBefore?: string, limit?: number, callback?: () => void) => {
      if (!currentUser) {
        return;
      }

      setLoading(true);

      const handleResponse = (response: any) => {
        if (callback) {
          callback();
        }

        if (!startingAfter && !endingBefore) {
          setCurrentPage(1);
        }

        if (!startingAfter && endingBefore) {
          setHasMore(true);
        } else {
          setHasMore(response.hasMore);
        }

        if (!startingAfter && !endingBefore) {
          setShowPagination(response.hasMore);
        }

        setList(response.entries);

        setLoading(false);
        setUsedPagination(false);
      };

      setTimeout(() => {
        if (searchTerm.length > 0 && props.fetchSearch) {
          setSearchLoading(true);
          props
            .fetchSearch({ startingAfter, endingBefore, limit: limit ?? settingsLimit, ...filterObject }, searchTerm)
            .then(handleResponse)
            .finally(() => {
              setSearchLoading(false);
            });

          return;
        }

        clientWrapper(
          handleResponse,
          (e) => {
            if (e?.type === 'request_validation_error') {
              addDangerNotification({
                content: e.message,
              });

              setLoading(false);

              console.warn('PaginationWrapper.RequestValidationError', e);

              return;
            }
            console.error('PaginationWrapper', e);
          },
          props.fetch({ startingAfter, endingBefore, limit: limit ?? settingsLimit, ...filterObject })
        );
      }, 100);
    };

    useImperativeHandle(
      ref,
      () => ({
        fetch: fetchData,
      }),
      []
    );

    useEffect(
      () =>
        useSessionStore.subscribe(
          (state) => [state.isSandbox, state.isLoading, state.currentUser?.defaultPlatformId],
          () => {
            fetchData();
          },
          {
            fireImmediately: true,
            equalityFn: shallow,
          }
        ),
      [settingsLimit]
    );

    useEffect(() => {
      if (searchTerm.length > 0) {
        setUsedFilter(true);
      }
      fetchData();
    }, [searchTerm]);

    useEffect(() => {
      fetchData();
      if (filterObject && Object.keys(filterObject).length !== 0) {
        setUsedFilter(true);
      }
    }, [filterObject]);

    const handleOnExit = () => {
      gsap.set(wrapperRef.current, {
        height: fadeRef.current?.offsetHeight,
      });
    };

    const handleOnEnter = () => {
      gsap.to(wrapperRef.current, {
        height: fadeRef.current?.offsetHeight,
        duration: 0.4,
        clearProps: 'height',
      });
    };

    return (
      <Container>
        <Fade show={loading && !useInlineLoading} base={StyledLoading} />
        <Wrapper ref={wrapperRef}>
          <Fade show={!loading || useInlineLoading} ref={fadeRef} onExit={handleOnExit} onEnter={handleOnEnter}>
            {props.empty && list.length < 1 && !useInlineLoading ? (
              props.empty
            ) : (
              <>
                {props.children}
                <Table
                  className={props.className}
                  action={props.action}
                  onRowClick={props.rowClick}
                  columns={props.columns}
                  filter={customFilter}
                  onFilterChange={setCustomFilter}
                  data={list}
                  onSearchSubmit={props.fetchSearch ? handleSearchSubmit : undefined}
                  searchShowReset={searchTerm.length > 0}
                  searchTooltip={props.searchTooltip}
                  searchLoading={searchLoading}
                  isLoading={loading && useInlineLoading}
                />
              </>
            )}
          </Fade>
        </Wrapper>
        <Bottom>
          <Fade show={(!loading || useInlineLoading) && list.length >= 1} timeoutEnter={400}>
            <Dropdown
              options={[5, 10, 15, 20, 25, 50].map((entries: number) => ({
                label: entries.toString(),
                value: entries,
              }))}
              size="small"
              variant="muted"
              active={settingsLimit}
              customLabel={
                <Entries counter={settingsLimit}>{entriesOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}</Entries>
              }
              onOpenChange={setEntriesOpen}
              onChange={(value: number) => {
                updateSettings({
                  tableSettings: {
                    [props.tableId]: {
                      limit: value,
                    },
                  },
                }).then(() => fetchData(undefined, undefined, value));
              }}
            />
          </Fade>
          <Fade show={(!loading || useInlineLoading) && showPagination} timeoutEnter={400}>
            <Pagination
              disablePrev={currentPage === 1}
              disableNext={!hasMore}
              current={currentPage}
              onPrev={handlePrev}
              onNext={handleNext}
            />
          </Fade>
        </Bottom>
      </Container>
    );
  }
);
