import React, { forwardRef } from 'react';
import styled from 'styled-components';

interface LogoProps {
  variant?: 'full' | 'word';
  size?: string;
}

const SVG = styled.svg`
  overflow: visible;
  fill: var(--logo-color, ${({ theme }) => theme.foreground});
`;

export const Logo = forwardRef<SVGSVGElement, LogoProps>((props, ref) => {
  const size = props.size ?? '24px';

  if (props.variant === 'full') {
    return (
      <SVG
        {...props}
        ref={ref}
        height={size}
        width={`${parseInt(size, 10) * 6.041}px`}
        viewBox="0 0 145 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path d="M9 9V15H12C13.5913 15 15.1174 15.6321 16.2426 16.7574C17.3679 17.8826 18 19.4087 18 21V24H6C4.4087 24 2.88258 23.3679 1.75736 22.2426C0.632141 21.1174 0 19.5913 0 18V6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0L18 0C19.5913 0 21.1174 0.632141 22.2426 1.75736C23.3679 2.88258 24 4.4087 24 6V9H9Z" />
        </g>
        <path d="M144.941 23.5906H140.96V13.9059C141.002 13.3849 140.933 12.8609 140.76 12.368C140.586 11.8751 140.31 11.4243 139.95 11.0449C139.591 10.6655 139.156 10.3659 138.673 10.1656C138.19 9.9653 137.671 9.8688 137.148 9.88235C134.706 9.88235 132.913 11.6329 132.913 14.7106V23.6188H128.96V6.67765H132.659V8.69647C133.345 7.90673 134.196 7.27738 135.153 6.8529C136.109 6.42843 137.147 6.2192 138.193 6.24C139.094 6.21602 139.99 6.3775 140.827 6.71441C141.663 7.05131 142.421 7.55646 143.054 8.1985C143.686 8.84054 144.18 9.60578 144.505 10.4467C144.83 11.2877 144.978 12.1864 144.941 13.0871V23.5906Z" />
        <path d="M124.922 23.5906H120.941V13.7082C120.971 13.2326 120.904 12.7558 120.743 12.3073C120.582 11.8587 120.33 11.448 120.004 11.1004C119.678 10.7529 119.284 10.4759 118.847 10.2865C118.409 10.0971 117.938 9.99941 117.461 9.99941C116.985 9.99941 116.513 10.0971 116.076 10.2865C115.638 10.4759 115.244 10.7529 114.918 11.1004C114.592 11.448 114.341 11.8587 114.18 12.3073C114.018 12.7558 113.951 13.2326 113.981 13.7082V23.6612H110.042V13.7788C110.086 13.2983 110.029 12.8141 109.875 12.3569C109.721 11.8997 109.473 11.4796 109.147 11.1235C108.822 10.7673 108.426 10.4828 107.985 10.2882C107.543 10.0936 107.066 9.99306 106.584 9.99306C106.101 9.99306 105.624 10.0936 105.182 10.2882C104.741 10.4828 104.345 10.7673 104.02 11.1235C103.694 11.4796 103.447 11.8997 103.292 12.3569C103.138 12.8141 103.081 13.2983 103.125 13.7788V23.7318H99.1718V6.79059H102.744V8.73882C103.292 7.96829 104.015 7.33961 104.855 6.90501C105.695 6.47041 106.626 6.24243 107.572 6.24C108.579 6.23261 109.57 6.48944 110.447 6.98488C111.324 7.48031 112.056 8.19703 112.569 9.06353C113.216 8.17514 114.066 7.45499 115.049 6.96364C116.032 6.47229 117.118 6.22412 118.216 6.24C119.07 6.21179 119.921 6.35306 120.72 6.65565C121.519 6.95825 122.25 7.41617 122.871 8.00295C123.492 8.58974 123.99 9.29375 124.338 10.0743C124.685 10.8549 124.874 11.6965 124.894 12.5506L124.922 23.5906Z" />
        <path d="M94.9929 6.64942V23.5906H91.2941V21.5294C90.5976 22.3375 89.7287 22.9789 88.7514 23.4065C87.774 23.8341 86.7132 24.0369 85.6471 24C84.7428 24.0222 83.8436 23.8582 83.0054 23.5179C82.1672 23.1777 81.4081 22.6687 80.7751 22.0225C80.1421 21.3762 79.6489 20.6067 79.3261 19.7617C79.0033 18.9166 78.8579 18.0142 78.8988 17.1106V6.64942H82.8518V16.3341C82.8074 16.8581 82.8748 17.3855 83.0496 17.8814C83.2243 18.3773 83.5024 18.8305 83.8655 19.2108C84.2285 19.5912 84.6682 19.8902 85.1554 20.0879C85.6427 20.2856 86.1663 20.3775 86.6918 20.3577C89.12 20.3577 90.9271 18.6353 90.9271 15.5577V6.64942H94.9929Z" />
        <path d="M74.8753 0V23.5906H70.9223V4.64471C70.9223 3.36 70.4 2.62588 69.44 2.62588H68.7059V0H74.8753Z" />
        <path d="M58.6682 6.24C60.4203 6.25394 62.129 6.78585 63.5794 7.76879C65.0298 8.75172 66.157 10.1417 66.8191 11.7639C67.4812 13.386 67.6487 15.1678 67.3004 16.8849C66.9521 18.602 66.1036 20.1777 64.8618 21.4137C63.62 22.6497 62.0402 23.4906 60.3215 23.8308C58.6028 24.1709 56.8218 23.995 55.2028 23.3252C53.5838 22.6554 52.1992 21.5216 51.2231 20.0666C50.2471 18.6116 49.7233 16.9003 49.7177 15.1482C49.7176 13.9748 49.9495 12.813 50.3998 11.7294C50.8501 10.6458 51.5101 9.6619 52.3418 8.83414C53.1735 8.00638 54.1605 7.35109 55.2462 6.90591C56.3319 6.46072 57.4948 6.23442 58.6682 6.24V6.24ZM58.6682 9.85412C57.9868 9.85913 57.3134 10.0015 56.6882 10.2728C56.0631 10.544 55.499 10.9385 55.0299 11.4328C54.5607 11.927 54.196 12.5107 53.9576 13.1491C53.7192 13.7875 53.612 14.4675 53.6424 15.1482C53.6138 15.8221 53.7219 16.4948 53.9602 17.1258C54.1986 17.7568 54.5621 18.3331 55.0291 18.8198C55.4961 19.3065 56.0567 19.6937 56.6773 19.958C57.2978 20.2223 57.9655 20.3583 58.64 20.3576C59.3176 20.3579 59.9884 20.2223 60.6127 19.9588C61.237 19.6953 61.8021 19.3093 62.2746 18.8236C62.7472 18.3379 63.1175 17.7624 63.3637 17.1311C63.61 16.4999 63.7271 15.8256 63.7082 15.1482C63.7327 14.4714 63.6217 13.7965 63.3818 13.1632C63.1418 12.5299 62.7776 11.951 62.3107 11.4604C61.8438 10.9698 61.2836 10.5775 60.6629 10.3065C60.0422 10.0355 59.3737 9.89132 58.6965 9.88235L58.6682 9.85412Z" />
        <path d="M40.9506 6.24C42.2777 6.2301 43.5904 6.51498 44.794 7.07406C45.9976 7.63314 47.0621 8.45248 47.9106 9.47294L44.96 11.9153C44.5029 11.2874 43.9042 10.7761 43.2124 10.4229C42.5207 10.0697 41.7555 9.88447 40.9788 9.88235C40.2999 9.88751 39.6289 10.029 39.0057 10.2985C38.3824 10.568 37.8197 10.96 37.351 11.4512C36.8822 11.9424 36.5168 12.5227 36.2767 13.1578C36.0365 13.7929 35.9264 14.4698 35.9529 15.1482C35.9244 15.8221 36.0325 16.4948 36.2708 17.1258C36.5091 17.7568 36.8727 18.3331 37.3397 18.8198C37.8066 19.3065 38.3673 19.6937 38.9879 19.958C39.6084 20.2223 40.2761 20.3582 40.9506 20.3576C41.795 20.3717 42.6287 20.1672 43.3708 19.764C44.1129 19.3608 44.7382 18.7726 45.1859 18.0565L48.2918 20.2871C47.4493 21.4428 46.3445 22.382 45.0683 23.0275C43.792 23.673 42.3808 24.0063 40.9506 24C38.5955 24 36.3368 23.0644 34.6715 21.3991C33.0062 19.7338 32.0706 17.4751 32.0706 15.12C32.0706 12.7649 33.0062 10.5062 34.6715 8.84089C36.3368 7.17557 38.5955 6.24 40.9506 6.24V6.24Z" />
      </SVG>
    );
  }
  if (props.variant === 'word') {
    return (
      <SVG {...props} ref={ref} height={size} viewBox="0 0 76 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M75.2336 15.7242H72.58V9.26891C72.6078 8.92164 72.5623 8.57239 72.4464 8.24386C72.3305 7.91533 72.1467 7.61484 71.9071 7.36193C71.6675 7.10901 71.3774 6.90932 71.0556 6.77583C70.7337 6.64233 70.3876 6.57801 70.0393 6.58704C68.4113 6.58704 67.2163 7.75389 67.2163 9.8053V15.7431H64.5814V4.45095H67.0469V5.79659C67.5043 5.27019 68.0717 4.8507 68.7092 4.56776C69.3466 4.28484 70.0383 4.14537 70.7356 4.15924C71.3363 4.14326 71.9337 4.25089 72.4911 4.47545C73.0484 4.70001 73.5537 5.03673 73.9754 5.46468C74.3972 5.89263 74.7265 6.40269 74.943 6.96321C75.1594 7.52374 75.2583 8.12277 75.2336 8.72313V15.7242Z" />
        <path d="M61.89 15.7243H59.2363V9.13718C59.2566 8.82014 59.2116 8.50233 59.1041 8.20336C58.9967 7.90439 58.8292 7.63062 58.6118 7.39896C58.3944 7.16729 58.1319 6.98264 57.8403 6.85642C57.5488 6.73019 57.2344 6.66507 56.9167 6.66507C56.5992 6.66507 56.2848 6.73019 55.9932 6.85642C55.7017 6.98264 55.4392 7.16729 55.2218 7.39896C55.0044 7.63062 54.8368 7.90439 54.7295 8.20336C54.622 8.50233 54.577 8.82014 54.5972 9.13718V15.7713H51.9717V9.18423C52.0007 8.86396 51.9627 8.54117 51.8599 8.23644C51.7573 7.93171 51.5922 7.65171 51.3753 7.4143C51.1584 7.1769 50.8944 6.98728 50.6002 6.85756C50.306 6.72783 49.9879 6.66083 49.6664 6.66083C49.3447 6.66083 49.0267 6.72783 48.7324 6.85756C48.4383 6.98728 48.1742 7.1769 47.9573 7.4143C47.7404 7.65171 47.5753 7.93171 47.4727 8.23644C47.3699 8.54117 47.3319 8.86396 47.3609 9.18423V15.8184H44.7261V4.52624H47.1068V5.82483C47.472 5.31123 47.9546 4.89218 48.5143 4.6025C49.074 4.31282 49.6948 4.16086 50.3251 4.15924C50.9964 4.15431 51.6573 4.32551 52.2417 4.65574C52.8263 4.98597 53.314 5.4637 53.6561 6.04126C54.087 5.4491 54.6539 4.96908 55.3088 4.64158C55.9639 4.31408 56.688 4.14866 57.4202 4.15924C57.9894 4.14043 58.5565 4.2346 59.0891 4.43629C59.6216 4.63799 60.1089 4.94322 60.5228 5.33434C60.9366 5.72545 61.2689 6.19471 61.5003 6.71499C61.7318 7.23528 61.8578 7.79627 61.8711 8.36555L61.89 15.7243Z" />
        <path d="M41.9407 4.43216V15.7242H39.4752V14.3504C39.011 14.889 38.4319 15.3166 37.7804 15.6015C37.1289 15.8866 36.4219 16.0218 35.7112 15.9971C35.1084 16.012 34.5091 15.9026 33.9504 15.6759C33.3917 15.4491 32.8857 15.1098 32.4638 14.6791C32.0419 14.2483 31.7132 13.7353 31.498 13.1721C31.2828 12.6088 31.1859 12.0074 31.2132 11.405V4.43216H33.848V10.8875C33.8185 11.2367 33.8634 11.5882 33.9799 11.9188C34.0963 12.2494 34.2817 12.5514 34.5237 12.805C34.7657 13.0585 35.0587 13.2578 35.3836 13.3895C35.7083 13.5213 36.0573 13.5826 36.4076 13.5693C38.0261 13.5693 39.2306 12.4213 39.2306 10.3699V4.43216H41.9407Z" />
        <path d="M28.5313 0V15.7242H25.8965V3.09592C25.8965 2.2396 25.5483 1.75028 24.9084 1.75028H24.4191V0H28.5313Z" />
        <path d="M17.7285 4.15925C18.8964 4.16853 20.0353 4.52309 21.0021 5.17826C21.9688 5.83343 22.7202 6.75995 23.1616 7.84119C23.6029 8.92242 23.7145 10.1101 23.4823 11.2546C23.2502 12.3991 22.6846 13.4495 21.8569 14.2732C21.0291 15.0971 19.9762 15.6576 18.8306 15.8843C17.685 16.1111 16.4979 15.9938 15.4187 15.5473C14.3396 15.1009 13.4167 14.3452 12.766 13.3753C12.1155 12.4055 11.7663 11.2648 11.7626 10.097C11.7626 9.31487 11.9171 8.54044 12.2172 7.81819C12.5174 7.09594 12.9574 6.44011 13.5117 5.88837C14.066 5.33663 14.724 4.89984 15.4476 4.60311C16.1713 4.30637 16.9465 4.15553 17.7285 4.15925ZM17.7285 6.56823C17.2744 6.57158 16.8255 6.66648 16.4088 6.84727C15.9921 7.02808 15.6162 7.29104 15.3035 7.62047C14.9907 7.94989 14.7476 8.339 14.5887 8.76452C14.4298 9.19004 14.3583 9.64326 14.3786 10.097C14.3595 10.5462 14.4316 10.9946 14.5905 11.4152C14.7493 11.8358 14.9917 12.2198 15.3029 12.5443C15.6142 12.8688 15.9879 13.1268 16.4015 13.3029C16.8152 13.4791 17.2602 13.5697 17.7097 13.5693C18.1614 13.5695 18.6086 13.4791 19.0247 13.3035C19.4408 13.1278 19.8175 12.8705 20.1324 12.5468C20.4474 12.2231 20.6942 11.8395 20.8583 11.4188C21.0225 10.9979 21.1006 10.5485 21.0879 10.097C21.1043 9.6459 21.0304 9.19605 20.8704 8.77392C20.7104 8.3518 20.4677 7.9659 20.1564 7.63891C19.8452 7.31192 19.4719 7.05042 19.0581 6.86978C18.6444 6.68916 18.1988 6.59302 17.7474 6.58705L17.7285 6.56823Z" />
        <path d="M5.91894 4.15922C6.80351 4.15263 7.6785 4.3425 8.48077 4.71516C9.28304 5.08782 9.99252 5.63394 10.5581 6.31413L8.5914 7.94208C8.28671 7.52354 7.88763 7.18274 7.42658 6.9473C6.96552 6.71187 6.45545 6.58844 5.93776 6.58703C5.4852 6.59046 5.03794 6.6848 4.62255 6.86442C4.20715 7.04406 3.83209 7.30532 3.51962 7.63272C3.20714 7.96012 2.96364 8.34696 2.80357 8.77027C2.64349 9.19359 2.57011 9.64476 2.58777 10.097C2.56872 10.5461 2.6408 10.9945 2.79965 11.4152C2.95851 11.8357 3.20086 12.2198 3.51211 12.5443C3.82335 12.8687 4.19705 13.1268 4.61069 13.3029C5.02433 13.4791 5.46935 13.5697 5.91894 13.5693C6.4818 13.5786 7.03751 13.4424 7.53215 13.1736C8.02679 12.9049 8.44354 12.5128 8.74197 12.0355L10.8121 13.5223C10.2506 14.2926 9.5142 14.9186 8.66357 15.3489C7.81289 15.7792 6.87223 16.0013 5.91894 15.9971C4.34914 15.9971 2.84363 15.3735 1.73362 14.2635C0.623601 13.1535 0 11.648 0 10.0782C0 8.50836 0.623601 7.00286 1.73362 5.89283C2.84363 4.78282 4.34914 4.15922 5.91894 4.15922Z" />
      </SVG>
    );
  }
  return (
    <SVG
      {...props}
      ref={ref}
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.5 9.5V14.5H12C13.3261 14.5 14.5979 15.0268 15.5355 15.9645C16.4732 16.9021 17 18.1739 17 19.5V22H7C5.67392 22 4.40215 21.4732 3.46447 20.5355C2.52678 19.5979 2 18.3261 2 17V7C2 5.67392 2.52678 4.40215 3.46447 3.46447C4.40215 2.52678 5.67392 2 7 2L17 2C18.3261 2 19.5979 2.52678 20.5355 3.46447C21.4732 4.40215 22 5.67392 22 7V9.5H9.5Z" />
    </SVG>
  );
});
