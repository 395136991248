import React, { FC } from 'react';

import { Layout } from '~/app/Layout';

import { AuthProvider } from './AuthProvider';
import { LDClientIdentifier } from './LDClientIdentifier';
import Router from './Router';

export const App: FC = () => {
  return (
    <Layout>
      <AuthProvider>
        <LDClientIdentifier>
          <Router />
        </LDClientIdentifier>
      </AuthProvider>
    </Layout>
  );
};
