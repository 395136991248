import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Fade, SegmentedControl, SegmentedControlVariant } from '@column/column-ui-kit';

import { AdditionalPermissions } from '../partials/AdditionalPermissions';
import { RoleInfo } from '../partials/RoleInfo';
import { TransfersAndAccounts } from '../partials/TransfersAndAccounts';
import { useEntitiesAndAccounts } from '../useEntitiesAndAccounts';
import { ROUTE } from '~/app/routes';
import { PageHeader } from '~/components';
import { LogoLoading } from '~/elements';
import { useGetCustomRole, useUpdateCustomRole } from '~/hooks/useRoles';
import { useNavigate } from '~/lib/navigation';
import { useNotificationStore } from '~/stores/Notification';
import { UpdateCustomRoleRequestObject } from '~/typings/API';

enum Section {
  SectionRoleInfo = 'Role Info',
  SectionTransfersAndAccounts = 'Transfers & Accounts',
  SectionAdditionalPermissions = 'Additional Permissions',
}

const StyledLoading = styled(LogoLoading)`
  top: 80px;
`;

const trimData = (data: UpdateCustomRoleRequestObject): UpdateCustomRoleRequestObject => {
  const output: UpdateCustomRoleRequestObject = {};

  output.name = data.name;
  output.description = data.description ? data.description : '';

  if (data.platformLevelPermissions) {
    output.platformLevelPermissions = Object.fromEntries(
      Object.entries(data.platformLevelPermissions).filter(([, v]) => v !== 'default')
    );
  }
  if (data.entityLevelOverrides) {
    output.entityLevelOverrides = data.entityLevelOverrides
      .map((entity) => {
        return Object.fromEntries(Object.entries(entity).filter(([k, v]) => k === 'entityId' || v !== 'default'));
      })
      .filter((entity) => Object.keys(entity).length > 1);
  }
  if (data.bankAccountLevelOverrides) {
    output.bankAccountLevelOverrides = data.bankAccountLevelOverrides
      .map((bankAccount) => {
        return Object.fromEntries(
          Object.entries(bankAccount).filter(([k, v]) => k === 'bankAccountId' || v !== 'default')
        );
      })
      .filter((entity) => Object.keys(entity).length > 1);
  }

  return output;
};

export const PageEditRole: React.FC = () => {
  const { roleId } = useParams();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();

  const {
    createRequest: getRole,
    isLoading: isRoleLoading,
    response: role,
  } = useGetCustomRole({
    initialParams: roleId ? { roleId } : undefined,
    shouldNotLoad: !roleId,
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });
  useEffect(() => {
    if (roleId) {
      getRole({ roleId });
    }
  }, [roleId]);

  const updateRole = useUpdateCustomRole({
    onSuccess: () => {
      addSuccessNotification({ content: 'Role updated successfully' });
    },
    onError: (error) => {
      addDangerNotification({ content: error.message });
    },
  });
  const entitiesAndAccounts = useEntitiesAndAccounts();

  const [visibleSection, setVisibleSection] = useState<Section>(Section.SectionRoleInfo);
  const sections = useMemo(() => {
    return Object.values(Section).map((section) => ({ label: section, value: section }));
  }, []);
  const handleCancel = useCallback(() => {
    navigate(ROUTE.PLATFORM_ROLES);
  }, []);

  const form = useForm<UpdateCustomRoleRequestObject>({
    values: role,
  });
  useEffect(() => {
    form.reset();
  }, [role]);

  const handleSubmit = form.handleSubmit((data: UpdateCustomRoleRequestObject) => {
    if (!roleId) return;
    updateRole.createRequest({ roleId, ...trimData(data) });
  });

  return (
    <>
      <Fade show={isRoleLoading || role === undefined} base={StyledLoading}>
        <LogoLoading />
      </Fade>
      <Fade show={!isRoleLoading && role !== undefined}>
        <PageHeader
          text={role?.name ?? ''}
          tabs={
            <SegmentedControl
              active={visibleSection}
              options={sections}
              onOptionChange={setVisibleSection}
              variant={SegmentedControlVariant.Secondary}
            />
          }
        >
          <Button onClick={handleCancel} size="small" variant="secondary">
            Cancel
          </Button>
          <Button isLoading={!updateRole.isInitialLoad && updateRole.isLoading} onClick={handleSubmit} size="small">
            Save Role
          </Button>
        </PageHeader>
        <FormProvider {...form}>
          {visibleSection === Section.SectionRoleInfo && <RoleInfo />}
          {visibleSection === Section.SectionTransfersAndAccounts && (
            <TransfersAndAccounts entitiesAndAccounts={entitiesAndAccounts} />
          )}
          {visibleSection === Section.SectionAdditionalPermissions && <AdditionalPermissions />}
        </FormProvider>
      </Fade>
    </>
  );
};
