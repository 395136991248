import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { formatMonthDay } from '~/util/dateFilterHelpers';

interface DatePreviewProps {
  selectedDates: string[];
  hoverDate?: string;
  isActive?: boolean;
  format?: 'default' | 'month-name';
}

const DatePreviewText = styled.span<{ $isActive?: boolean }>`
  color: ${({ theme, $isActive }) => ($isActive ? theme.primary.background : theme.secondary.blendToBackground(800))};
  display: inline-flex;
  align-items: center;

  svg {
    display: block;
    margin: 0 4px;

    --icon-size: 14px;
    --icon-color: ${({ theme, $isActive }) =>
      $isActive ? theme.primary.blendToBackground(800) : theme.secondary.blendToBackground(800)};
  }
`;

export const DatePreview: FC<DatePreviewProps> = ({ selectedDates, hoverDate, isActive, format = 'default' }) => {
  if (selectedDates.length === 0) {
    return null;
  }

  const [fromDate, toDate] = useMemo(() => {
    if (selectedDates.length === 1) {
      if (hoverDate) {
        return [selectedDates[0], hoverDate].toSorted();
      }
      return [selectedDates[0], hoverDate ?? '?'];
    }

    return selectedDates.toSorted();
  }, [hoverDate, selectedDates]);

  return (
    <DatePreviewText $isActive={isActive}>
      {format === 'month-name' ? formatMonthDay(new Date(fromDate)) : fromDate}
      <Icon.ArrowRight />
      {format === 'month-name' ? formatMonthDay(new Date(toDate)) : toDate}
    </DatePreviewText>
  );
};
