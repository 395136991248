import React from 'react';

interface PassportProps {
  className?: string;
}

export const Passport: React.FC<PassportProps> = ({ className }) => {
  return (
    <svg
      width="46"
      height="53"
      viewBox="0 0 46 53"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M35 9.5H11C9.89543 9.5 9 10.3954 9 11.5V41.5C9 42.6046 9.89543 43.5 11 43.5H35C36.1046 43.5 37 42.6046 37 41.5V11.5C37 10.3954 36.1046 9.5 35 9.5ZM11 7.5C8.79086 7.5 7 9.29086 7 11.5V41.5C7 43.7091 8.79086 45.5 11 45.5H35C37.2091 45.5 39 43.7091 39 41.5V11.5C39 9.29086 37.2091 7.5 35 7.5H11Z" />
      <path d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H7V2.5H4C2.89543 2.5 2 3.39543 2 4.5V7.5H0V4.5Z" />
      <path d="M0 48.5C0 50.7091 1.79086 52.5 4 52.5H7V50.5H4C2.89543 50.5 2 49.6046 2 48.5V45.5H0V48.5Z" />
      <path d="M42 0.5C44.2091 0.5 46 2.29086 46 4.5V7.5L44 7.5V4.5C44 3.39543 43.1046 2.5 42 2.5L39 2.5V0.5L42 0.5Z" />
      <path d="M42 52.5C44.2091 52.5 46 50.7091 46 48.5V45.5H44V48.5C44 49.6046 43.1046 50.5 42 50.5H39V52.5H42Z" />
      <path d="M20 32.5H14V38.5H20V32.5ZM14 30.5C12.8954 30.5 12 31.3954 12 32.5V38.5C12 39.6046 12.8954 40.5 14 40.5H20C21.1046 40.5 22 39.6046 22 38.5V32.5C22 31.3954 21.1046 30.5 20 30.5H14Z" />
      <path d="M9 26.5C9 26.1299 8.7989 25.8067 8.5 25.6338C8.2011 25.8067 8 26.1299 8 26.5C8 26.8701 8.2011 27.1933 8.5 27.3662C8.7989 27.1933 9 26.8701 9 26.5ZM8 25.5C7.44772 25.5 7 25.9477 7 26.5C7 27.0523 7.44772 27.5 8 27.5H9C9.55228 27.5 10 27.0523 10 26.5C10 25.9477 9.55228 25.5 9 25.5H8Z" />
      <path d="M13 25.5C12.4477 25.5 12 25.9477 12 26.5C12 27.0523 12.4477 27.5 13 27.5H15C15.5523 27.5 16 27.0523 16 26.5C16 25.9477 15.5523 25.5 15 25.5H13Z" />
      <path d="M19 25.5C18.4477 25.5 18 25.9477 18 26.5C18 27.0523 18.4477 27.5 19 27.5H21C21.5523 27.5 22 27.0523 22 26.5C22 25.9477 21.5523 25.5 21 25.5H19Z" />
      <path d="M25 25.5C24.4477 25.5 24 25.9477 24 26.5C24 27.0523 24.4477 27.5 25 27.5H27C27.5523 27.5 28 27.0523 28 26.5C28 25.9477 27.5523 25.5 27 25.5H25Z" />
      <path d="M25 30.5C24.4477 30.5 24 30.9477 24 31.5C24 32.0523 24.4477 32.5 25 32.5H33C33.5523 32.5 34 32.0523 34 31.5C34 30.9477 33.5523 30.5 33 30.5H25Z" />
      <path d="M25 34.5C24.4477 34.5 24 34.9477 24 35.5C24 36.0523 24.4477 36.5 25 36.5H33C33.5523 36.5 34 36.0523 34 35.5C34 34.9477 33.5523 34.5 33 34.5H25Z" />
      <path d="M25 38.5C24.4477 38.5 24 38.9477 24 39.5C24 40.0523 24.4477 40.5 25 40.5H33C33.5523 40.5 34 40.0523 34 39.5C34 38.9477 33.5523 38.5 33 38.5H25Z" />
      <path d="M31 25.5C30.4477 25.5 30 25.9477 30 26.5C30 27.0523 30.4477 27.5 31 27.5H33C33.5523 27.5 34 27.0523 34 26.5C34 25.9477 33.5523 25.5 33 25.5H31Z" />
      <path d="M38 26.5C38 26.1299 37.7989 25.8067 37.5 25.6338C37.2011 25.8067 37 26.1299 37 26.5C37 26.8701 37.2011 27.1933 37.5 27.3662C37.7989 27.1933 38 26.8701 38 26.5ZM37 25.5C36.4477 25.5 36 25.9477 36 26.5C36 27.0523 36.4477 27.5 37 27.5H38C38.5523 27.5 39 27.0523 39 26.5C39 25.9477 38.5523 25.5 38 25.5H37Z" />
    </svg>
  );
};
