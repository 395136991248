import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

import { Button, Fade, Icon } from '@column/column-ui-kit';

import { GetStarted, SandboxBanner } from '~/app/Layout';
import { ROUTE } from '~/app/routes';
import {
  EmptyState,
  Modal,
  ModalSize,
  PageHeader,
  PaginationWrapper,
  PaginationWrapperRefProps,
  SectionHeader,
  TableColumn,
} from '~/components';
import { LogoLoading, RelativeTime } from '~/elements';
import { useQueryParams } from '~/hooks';
import {
  ApiKeyRepository,
  EntityRepository,
  EventRepository,
  getEventACHType,
  getEventIcon,
  getEventRoute,
  getEventStatus,
  getEventSummary,
  getRawEventDirection,
  getRawEventType,
  ParsedEvent,
  parseEvent,
  PlatformRolePermissions,
  UserRepository,
  WebhookRepository,
} from '~/repositories';
import { BankAccountRepository } from '~/repositories/BankAccountRepository';
import { clientWrapper } from '~/repositories/client/wrapper';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Inner, Paragraph } from '~/styles';

import { Action, EventDetail, EventIcon, EventMeta, EventType, JSONButton } from './Events';

interface LocationState {
  action?: string;
}

type EventsTableRow = ParsedEvent & { action: React.ReactElement };

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

export const PageHome: React.FC = () => {
  const { isSandbox, currentUser, currentPermission } = useSessionStore();
  const location = useLocation();
  const openModal = useModalStore((s) => s.openModal);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const openHelpSidebar = useHelpSidebarStore((s) => s.openHelpSidebar);
  const [entities, setEntities] = useState<boolean>(false);
  const [bankAccounts, setBankAccounts] = useState<boolean>(false);
  const [apiKeys, setApiKeys] = useState<boolean>(false);
  const [webhooks, setWebhooks] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const state = location.state as LocationState;

  const fetchData = (defaultPlatformId: string, currentPermissionState: PlatformRolePermissions) => {
    const promises = [];

    promises.push(
      EntityRepository.getAll().then((response) => {
        setEntities(response.entities.length > 0);
      })
    );

    promises.push(
      BankAccountRepository.getAll().then((response) => {
        setBankAccounts(response.bankAccounts.length > 0);
      })
    );

    if (currentPermissionState?.apiKeys === 'read' || currentPermissionState?.apiKeys === 'write') {
      promises.push(
        ApiKeyRepository.getAll(defaultPlatformId).then((response) => {
          setApiKeys(response.apiKeys.length > 0);
        })
      );
    } else {
      setApiKeys(false);
    }

    if (currentPermissionState?.webhooks === 'read' || currentPermissionState?.webhooks === 'write') {
      promises.push(
        WebhookRepository.getAll().then((response) => {
          setWebhooks(response.webhookEndpoints.length > 0);
        })
      );
    } else {
      setWebhooks(false);
    }

    clientWrapper(undefined, (e) => console.error('HomeFetchData', e), Promise.all(promises)).finally(() =>
      setLoading(false)
    );
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (s) => [s.isLoading, s.isSandbox, s.currentUser?.defaultPlatformId, s.currentPermission],
        (s) => {
          const isLoading = s[0] as boolean | undefined;
          const defaultPlatformId = s[2] as string | undefined;
          const currentPermissionState = s[3] as PlatformRolePermissions | undefined;

          if (isLoading || !defaultPlatformId || !currentPermissionState) {
            return;
          }

          fetchData(defaultPlatformId, currentPermissionState);
        },
        {
          fireImmediately: true,
          equalityFn: shallow,
        }
      ),
    []
  );

  useEffect(() => {
    if (state && state.action === 'platformInfoPermissionError') {
      addDangerNotification({
        content: "You got redirected, because you don't have permissions to view this page.",
        actionButton: {
          label: 'Roles',
          onClick: () => openHelpSidebar('Roles'),
        },
      });
    }
  }, [location]);

  const handleFetch = async (params: any) => {
    return EventRepository.getAll(params).then((response) => {
      const entries = response.events.map(
        (event): EventsTableRow => ({
          ...parseEvent(event),
          action: (
            <Action>
              <JSONButton
                onlyIcon
                size="tiny"
                variant="muted"
                onClick={(e) => {
                  e.stopPropagation();
                  openModal('EventDetail', {
                    data: event.data,
                  });
                }}
              >
                JSON
              </JSONButton>
            </Action>
          ),
        })
      );
      return { entries, hasMore: false };
    });
  };

  const columns: TableColumn[] = [
    {
      Header: 'Type',
      accessor: 'data',
      width: 'minmax(300px, auto)',
      Cell: (current) => (
        <EventMeta>
          <EventType>
            {getRawEventDirection(current.value.eventType)} {getRawEventType(current.value.eventType)}{' '}
            {getEventACHType(current.value)}
          </EventType>

          <EventDetail>
            <EventIcon>{getEventIcon(current.value.eventType)}</EventIcon>

            <span>{getEventSummary(current.value)}</span>
          </EventDetail>
        </EventMeta>
      ),
    },
    {
      Header: 'Status',
      accessor: 'eventType',
      width: 'minmax(min-content, 100px)',
      Cell: (current) => getEventStatus(current.value),
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      sortType: 'datetime',
      width: 'min-content',
      Cell: (current) => <RelativeTime timestamp={current.value} />,
    },
    {
      Header: '',
      accessor: 'action',
      width: 'min-content',
    },
  ];

  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  return (
    <>
      <AcceptLegalAgreementModal />
      <StyledFade show={loading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!loading}>
        <PageHeader text="Home" borderOnScroll />

        <Inner pt={0}>
          {isSandbox && <SandboxBanner />}

          <GetStarted
            showVerifyEmail={!currentUser?.isEmailVerified}
            showGenerateApiKeys={
              !apiKeys && currentPermission?.apiKeys !== 'none' && currentPermission?.apiKeys !== 'default'
            }
            showSetupMfa={!(currentUser?.isMfaVerified ?? false)}
            showAddBankAccount={
              !bankAccounts &&
              entities &&
              currentPermission?.bankAccounts !== 'none' &&
              currentPermission?.bankAccounts !== 'default'
            }
            showAddEntity={!entities && currentPermission?.entities === 'read'}
            showAddWebhook={
              !webhooks &&
              apiKeys &&
              currentPermission?.webhooks !== 'none' &&
              currentPermission?.webhooks !== 'default'
            }
            showGettingStartedGuide
          />
        </Inner>

        <SectionHeader text="Recent Events" border />

        <PaginationWrapper
          tableId="events"
          ref={paginationRef}
          fetch={handleFetch}
          columns={columns}
          rowClick={({ original }: { original: EventsTableRow }) =>
            navigate(
              getRawEventType(original.eventType) === 'Identity'
                ? `${getEventRoute(original.eventType)}/${(original.data.subType ?? 'undefined').toLowerCase()}/${original.data.id}`
                : getRawEventType(original.eventType) === undefined
                  ? ''
                  : `${getEventRoute(original.eventType)}/${original.data.id}`
            )
          }
          empty={
            <EmptyState headline="No events found" text="Create your first person or a business entity.">
              <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/person`)} size="small" icon={<Icon.User />}>
                Create Entity
              </Button>
            </EmptyState>
          }
        />
      </Fade>
    </>
  );
};

const ACCEPT_CODE = 'c1e43af8-f71f-4c34-bb2f-f98561b5ec71';

const AcceptLegalAgreementModal = () => {
  const { queryParams, removeQueryParam } = useQueryParams<{ acceptAgreementsCode?: string }>();

  const closeModal = useCallback(() => {
    removeQueryParam('acceptAgreementsCode');
  }, []);

  useEffect(() => {
    if (queryParams?.acceptAgreementsCode === ACCEPT_CODE) {
      UserRepository.acceptLegalAgreement();
    }
  }, [queryParams]);

  return (
    <Modal
      icon={<Icon.AnimationDocumentEdit />}
      primaryButton={{
        text: 'Continue to dashboard',
        onClick: closeModal,
      }}
      headline="Terms Accepted"
      open={queryParams?.acceptAgreementsCode === ACCEPT_CODE}
      onClose={closeModal}
      size={ModalSize.Small}
    >
      <Inner pb={0} px={0} pt={12}>
        <Paragraph>No further action needed.</Paragraph>
      </Inner>
    </Modal>
  );
};
