import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from '@column/column-ui-kit';

import { FinancialInstitution } from '~/repositories';

import { RoutingNumberInput } from './RoutingNumberInput';

interface RoutingNumberFieldProps {
  routingNumber: string;
  onRoutingNumberChange: (routingNumber: string) => void;
  onInstitutionChange?: (institution: FinancialInstitution | null) => void;
}

const SuccessRoutingNumber = styled.div<{ $isVisible: boolean }>`
  max-height: 0;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.success.background};
  display: block;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 0;
  line-height: 20px;
  pointer-events: none;
  margin-top: -4px;
  transition:
    max-height 0.2s,
    opacity 0.2s,
    margin-top 0.2s;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      margin-top: 4px;
      max-height: 20px;
      opacity: 1;
      pointer-events: auto;

      svg {
        animation: scale 0.4s ease 0.2s;
        display: inline-block;
        vertical-align: top;
        margin: 2px 4px 0 0;
        path {
          &:last-child {
            transition-delay: 0.15s;
          }
        }
      }
    `}

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      opacity: 0;
      max-height: 0;

      svg {
        path {
          &:first-child {
            stroke-dashoffset: 48px;
          }
          &:last-child {
            stroke-dashoffset: 9px;
          }
        }
      }
    `}

  @keyframes scale {
    50% {
      transform: scale(1.15);
    }
  }

  svg {
    display: inline-block;
    vertical-align: top;
    --icon-size: 16px;

    path {
      &:first-child {
        stroke-dasharray: 48px;
        transition: stroke-dashoffset 0.15s;
      }

      &:last-child {
        stroke-dasharray: 9px;
        transition: stroke-dashoffset 0.25s;
      }
    }
  }

  span {
    display: inline-block;
    vertical-align: top;
    line-height: 16px;
  }
`;

const Check = styled(Icon.CircleCheck)`
  --icon-size: 16px;
  --icon-color: currentColor;
`;

export const RoutingNumberField: FC<RoutingNumberFieldProps> = ({
  routingNumber,
  onRoutingNumberChange,
  onInstitutionChange,
}) => {
  const [institution, setInstitution] = useState<FinancialInstitution | null>(null);

  useEffect(() => {
    if (onInstitutionChange) {
      onInstitutionChange(institution);
    }
  }, [institution, onInstitutionChange]);

  return (
    <>
      <RoutingNumberInput value={routingNumber} onChange={onRoutingNumberChange} onInstitutionChange={setInstitution} />
      <SuccessRoutingNumber $isVisible={institution !== null}>
        <Check />
        {institution?.fullName ?? institution?.shortName}
      </SuccessRoutingNumber>
    </>
  );
};
