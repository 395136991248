export const returnCodesTooltipMap = new Map<string, string>([
  ['R01', 'The available and/or cash reserve balance is not sufficient to cover the dollar value of the debit Entry.'],
  ['R02', 'A previously active account has been closed by action of the customer or the RDFI.'],
  [
    'R03',
    'The account number structure is valid and it passes the check digit validation, but the account number does not correspond to the individual identified in the Entry, or the account number designated is not an existing account.',
  ],
  ['R04', 'The account number structure is not valid.'],
  [
    'R05',
    'CCD or CTX debit Entry was transmitted to a Consumer Account of the Receiver and was not authorized by the Receiver.',
  ],
  [
    'R06',
    'The ODFI has requested that the RDFI return an Erroneous Entry, or a credit Entry originated without the authorization of the Originator. The RDFI is not obligated to return.',
  ],
  [
    'R07',
    "The RDFI's customer (the Receiver) revoked the authorization previously provided to the Originator for this debit Entry.",
  ],
  ['R08', 'The Receiver has placed a stop payment order on this debit Entry.'],
  [
    'R09',
    'A sufficient ledger balance exists to satisfy the dollar value of the transaction, but the available balance is below the dollar value of the debit Entry.',
  ],
  [
    'R10',
    'The RDFI has been notified by the Receiver that the Receiver does not know the identity of the Originator; has no relationship with the Originator; or has not authorized the Originator to debit his account.',
  ],
  [
    'R11',
    'The RDFI has been notified by the Receiver that the Originator and Receiver have a relationship and an authorization to debit exists, but there is an error or defect in the payment such that the entry does not conform to the terms of the authorization.',
  ],
  ['R12', 'A financial institution received an Entry to an account that was sold to another financial institution.'],
  [
    'R14',
    'The representative payee is either deceased or unable to continue in that capacity. The beneficiary is not deceased.',
  ],
  ['R15', 'The beneficiary is deceased, or the account holder is deceased.'],
  [
    'R16',
    'Access to the account is restricted due to specific action taken by the RDFI or by legal action; or OFAC has instructed the RDFI or Gateway to return the Entry.',
  ],
  [
    'R17',
    'Field(s) cannot be processed by RDFI; the Entry contains an invalid DFI Account Number and is believed by the RDFI to have been initiated under questionable circumstances.',
  ],
  ['R20', 'ACH Entry to a non-Transaction Account.'],
  ['R21', 'The Identification Number used in the Company Identification Field is not valid.'],
  [
    'R22',
    'The Receiver has indicated to the RDFI that the number with which the Originator was identified is not correct.',
  ],
  ['R23', 'Any credit Entry that is refused by the Receiver may be returned by the RDFI.'],
  [
    'R24',
    'The RDFI has received what appears to be a duplicate Entry; i.e., the trace number, date, dollar amount and/or other data matches another transaction.',
  ],
  [
    'R29',
    'The RDFI has been notified by the Receiver (Non-Consumer) that a specific Entry has not been authorized by the Receiver.',
  ],
  ['R31', 'The RDFI may return a CCD or CTX Entry that the ODFI agrees to accept.'],
  ['R33', "This Return Reason Code may only be used to return XCK Entries and is at the RDFI's sole discretion."],
  ['R37', 'The source document to which an ARC, BOC, or POP Entry relates has been presented for payment.'],
  [
    'R38',
    'The RDFI determines a stop payment order has been placed on the source document to which the ARC or BOC Entry relates.',
  ],
  [
    'R39',
    "The RDFI determines that: the source document used for an ARC, BOC, or POP Entry to its Receiver's account is improper, or an ARC, BOC, or POP Entry and the source document to which the Entry relates have both been presented for payment and posted to the Receiver's account.",
  ],
  ['R51', 'An RCK Entry is considered to be ineligible or improper.'],
  ['R52', 'A stop payment order has been placed on the item to which the RCK Entry relates.'],
  ['R53', 'In addition to an RCK Entry, the item to which the RCK Entry relates has also been presented for payment.'],
  ['R61', 'The RDFI has determined the Entry to be ineligible or improper and returned the Entry.'],
  ['R62', 'The return Entry contains one or more fields that are incorrect.'],
  ['R67', 'The RDFI has received a duplicate return.'],
  ['R68', 'The RDFI has already returned this Entry.'],
  ['R69', 'Field(s) in the Return Entry are incorrect.'],
  ['R70', 'Permissible Return Entry not accepted/return not requested by the ODFI.'],
]);
