import React, { useRef, FC } from 'react';
import styled from 'styled-components';

import { IconBase } from '@column/column-ui-kit';

interface DocsIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const DocsIcon: FC<DocsIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={20}>
        <mask
          id="mask0_2060_45087"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="4"
          width="6"
          height="12"
        >
          <path d="M7 4H1V16H7V4Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2060_45087)">
          <path
            d="M7.99914 14L3.24914 10.83C2.65914 10.43 2.65914 9.56 3.24914 9.17L7.99914 6"
            stroke="var(--icon-color, currentColor)"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask1_2060_45087"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="13"
          y="4"
          width="6"
          height="12"
        >
          <path d="M19 4H13V16H19V4Z" fill="white" />
        </mask>
        <g mask="url(#mask1_2060_45087)">
          <path
            d="M12 14L16.75 10.83C17.34 10.43 17.34 9.56 16.75 9.17L12 6"
            stroke="var(--icon-color, currentColor)"
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
        </g>
        <mask
          id="mask2_2060_45087"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="3"
          width="8"
          height="14"
        >
          <path d="M14 3H6V17H14V3Z" fill="white" />
        </mask>
        <g mask="url(#mask2_2060_45087)">
          <path d="M7 19L13 1" stroke="var(--icon-color, currentColor)" strokeWidth="1.5" strokeMiterlimit="10" />
        </g>
      </IconBase>
    </Wrapper>
  );
};
