import { client } from '../client';
import {
  BookTransfer,
  BookCreateBookTransferRequest,
  BookTransferList,
  BookTransferServiceClearBookTransferPayload,
  BookTransferServiceListBookTransfersParams,
  BookTransferServiceUpdateBookTransferPayload,
} from '~/typings/API';
import { convertValues } from '~/util';

export type CreateBookTransfer = BookCreateBookTransferRequest;
export type { BookTransfer };

export class BookRepository {
  static async create(bookTransfer: CreateBookTransfer, idempotency?: string) {
    return client
      .post<CreateBookTransfer, BookTransfer>('/transfers/book', bookTransfer, { idempotency })
      .then((response) => convertValues<BookTransfer>(response));
  }

  static async cancel(bookTransferId: string) {
    return client
      .post<object, BookTransfer>(`/transfers/book/${bookTransferId}/cancel`, {})
      .then((response) => convertValues<BookTransfer>(response));
  }

  static async clear(bookTransferId: string, request: BookTransferServiceClearBookTransferPayload) {
    return client
      .post<
        BookTransferServiceClearBookTransferPayload,
        BookTransfer
      >(`/transfers/book/${bookTransferId}/clear`, request)
      .then((response) => convertValues<BookTransfer>(response));
  }

  static async update(bookTransferId: string, request: BookTransferServiceUpdateBookTransferPayload) {
    return client
      .patch<BookTransferServiceUpdateBookTransferPayload, BookTransfer>(`/transfers/book/${bookTransferId}`, request)
      .then((response) => convertValues<BookTransfer>(response));
  }

  static async get(request: GetRequestType) {
    return client
      .get<unknown, BookTransfer>(`/transfers/book/${request.id}`)
      .then((response) => convertValues<BookTransfer>(response));
  }

  static async getAll(query?: Partial<BookTransferServiceListBookTransfersParams>) {
    return client
      .get<BookTransferServiceListBookTransfersParams, BookTransferList>('/transfers/book', { query })
      .then((response) => ({
        transfers: Array.isArray(response?.transfers)
          ? response.transfers.map((e) => convertValues<BookTransfer>(e))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }
}
