import React from 'react';

import { Markdown, PageHeader } from '~/components';
import { Inner } from '~/styles';

import markdown from './Documents/CommercialDepositAccountAgreement.md';

export const PageCommercialDepositAccountAgreement: React.FC = () => {
  return (
    <>
      <PageHeader text="Commercial Deposit Account Agreement" />
      <Inner pt={0}>
        <Markdown markdown={markdown} />
      </Inner>
    </>
  );
};
