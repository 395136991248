import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import { EmptyState } from '~/components';
import { ReportingTable } from '~/components/ReportingTable';

import { BankAccountRouteProps } from './Route';

export const PageBankAccountsDocuments: FC = () => {
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();

  return (
    <ReportingTable
      tableId="statements"
      bankAccountId={bankAccount.id}
      filter={{ category: 'statement' }}
      visibleFiles={['pdf', 'csv']}
      hiddenFiles={['json', 'parquet', 'bai2']}
      empty={<EmptyState headline="No documents found" text="Wait for your first statements to get generated." />}
    />
  );
};
