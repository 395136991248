import { client } from '../client';
import { EventType } from '~/if/event/EventType';
import {
  EventEvent,
  EventList,
  EventServiceListEventsParams,
  EventServiceListWebhookEventsParams,
} from '~/typings/API';
import { convertValues } from '~/util';

type Event = EventEvent;
export const EventTypes = EventType;
export type { Event, EventType };

export class EventRepository {
  static async get(id: string) {
    return client.get<unknown, Event>(`/events/${id}`).then((response) => convertValues<Event>(response));
  }

  static async getAll(query?: EventServiceListEventsParams) {
    return client.get<EventServiceListEventsParams, EventList>('/events', { query }).then((response) => ({
      events: Array.isArray(response?.events) ? response.events.map((e: Event) => convertValues<Event>(e)) : [],
      hasMore: Boolean(response?.hasMore),
    }));
  }

  static async getAllWebhooks(query?: EventServiceListWebhookEventsParams) {
    return client
      .get<EventServiceListWebhookEventsParams, EventList>('/events/webhook', { query })
      .then((response) => ({
        events: Array.isArray(response?.events) ? response.events.map((e: Event) => convertValues<Event>(e)) : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }
}
