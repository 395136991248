import React from 'react';

import { IMAGE_URL } from '~/assets';
import { UnderlineLink } from '~/elements';
import { HelpSidebarType } from '~/stores/HelpSidebar';

import { BottomLinks, LinkList, HelpSidebarBase, Line } from './Base';
import { HelpSidebarText, Image } from './Onboarding';

export const HelpSidebarSupport: React.FC = () => {
  const helpSidebar: HelpSidebarType[] = ['Support'];

  return (
    <HelpSidebarBase helpSidebars={helpSidebar} headline="Help">
      <Image src={IMAGE_URL.SCREENSHOT} srcSet={`${IMAGE_URL.SCREENSHOT2x} 2x`} />
      <HelpSidebarText>
        Running into some issues? Email us at{' '}
        <UnderlineLink href="mailto:support@column.com" withoutArrow>
          support@column.com
        </UnderlineLink>{' '}
        or check out our helpful docs below.
      </HelpSidebarText>
      <Line />
      <LinkList
        title="Helpful links"
        entries={[
          {
            label: 'Getting started guide',
            url: 'https://column.com/docs/guides/getting-started-with-the-column-api',
          },
          {
            label: 'Data models',
            url: 'https://column.com/docs/data-models',
          },
          {
            label: 'Changelog',
            url: 'https://column.com/changelog',
          },
          {
            label: 'Use case guides',
            url: 'https://column.com/docs/guides',
          },
        ]}
      />
      <BottomLinks />
    </HelpSidebarBase>
  );
};
