import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon, Button, Dropdown } from '@column/column-ui-kit';

import { NotificationList } from '~/components';
import { CheckRepository, CheckReturnReason } from '~/repositories';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { Headline, FormElement, FormLabel, ModalIconCircle, ModalText } from '~/styles';

import { Buttons } from './Alert';
import { ModalBase } from './Base';
import { Form } from './Counterparty';

const ReturnWrapper = styled(ModalBase)`
  --modal-width: 244px;
  padding: 24px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 16px;
`;

const StyledDropdown = styled(Dropdown)`
  max-width: 260px;
`;

export const ModalCheckReturn: React.FC = () => {
  const { closeModal, getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const modalTypes: ModalType[] = ['CheckReturn'];
  const [returnCode, setReturnCode] = useState<CheckReturnReason | undefined>(undefined);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!returnCode) {
      return;
    }

    CheckRepository.createReturn(getModalData()?.checkTransferId, { return_reason: returnCode })
      .then(() => {
        closeModal();
        addSuccessNotification({
          content: 'Check return created',
        });
        if (getModalData()?.callback) {
          getModalData()?.callback();
        }
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();

    closeModal('Return');
  };

  return (
    <ReturnWrapper modalTypes={modalTypes}>
      <ModalIconCircle>
        <Icon.AnimationMoney />
      </ModalIconCircle>
      <ModalText>
        <Headline fullWidth size="small">
          Create check return
        </Headline>
      </ModalText>
      <NotificationList display="popup" />
      <Form onSubmit={handleSubmit}>
        <Fields>
          <FormElement>
            <FormLabel>Return code</FormLabel>
            <StyledDropdown
              onChange={setReturnCode}
              active={returnCode}
              options={[
                {
                  label: 'N: Altered/fictitious item - suspected counterfeit',
                  value: 'N',
                },
                {
                  label: 'Q: Not authorized',
                  value: 'Q',
                },
              ]}
              fullWidth
              variant="muted"
            />
          </FormElement>
        </Fields>
        <Buttons grow>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button>Create</Button>
        </Buttons>
      </Form>
    </ReturnWrapper>
  );
};
