import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Button, Chip, Dropdown, Fade, Icon } from '@column/column-ui-kit';

import { PageHeader, SectionHeader, Table, TableColumn } from '~/components';
import { Loading, LogoLoading, RelativeTime } from '~/elements';
import {
  DashboardUserRedacted,
  FactorTypeResponse,
  InviteRepository,
  PlatformRoleName,
  precannedRoles,
  precannedRolesDropdown,
  UserRepository,
} from '~/repositories';
import { Factor } from '~/repositories/UserRepository';
import { useAlertStore } from '~/stores/Alert';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';
import { useModalStore } from '~/stores/Modal';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Inner, Line, Truncate } from '~/styles';

interface Params {
  id?: string;
}

interface InviteList {
  id?: string;
  email?: string;
  created?: Date | undefined;
  role?: string;
  action?: ReactNode;
}

interface UserListInfo {
  id: string;
  name: string;
  isOwner: boolean;
}

interface UserListRole {
  id: string;
  email: string;
  name: string;
}

interface UserList {
  id?: string;
  info?: UserListInfo;
  email?: string;
  factors?: Factor[];
  role?: UserListRole;
  action?: ReactNode;
}

const StyledFade = styled(Fade)`
  margin-top: 10%;
`;

const StyledLoading = styled(Loading)`
  top: 40px;
  transform: translateX(-50%);
`;

const Relative = styled.div`
  position: relative;
`;

const RoleHelpButton = styled(Button)`
  padding: 0;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
`;

const UserInfo = styled.div`
  display: inline-flex;
  gap: 4px;
  vertical-align: top;
  margin-left: 8px;
`;

const StyledTruncate = styled(Truncate)`
  display: flex;
  align-items: center;
`;

const StyledTable = styled(Table)`
  tr {
    align-items: center;
  }
`;

export const PageTeam: React.FC = () => {
  const { currentUser, currentPlatform, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();

  const openModal = useModalStore((state) => state.openModal);
  const openAlert = useAlertStore((state) => state.openAlert);
  const [userList, setUserList] = useState<UserList[]>([]);
  const [inviteList, setInviteList] = useState<InviteList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const openHelpSidebar = useHelpSidebarStore((state) => state.openHelpSidebar);

  const fetchData = useCallback(() => {
    if (!currentPlatform || !currentUser) return;

    Promise.all([
      UserRepository.getAll(currentPlatform.id).then((response) => {
        const entries: UserList[] = [];
        if (response.dashboardUsers.length) {
          response.dashboardUsers.map((entry) => {
            const smsFactor = entry.factors.find(
              (factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.SMS
            );
            const totpFactor = entry.factors.find(
              (factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.TOTP
            );

            entries.push({
              id: entry.id,
              info: {
                id: entry.id,
                name: `${entry.firstName} ${entry.lastName}`,
                isOwner: entry.platformRole.name === 'owner',
              },
              email: entry.email,
              factors: entry.factors,
              role: {
                id: entry.id,
                email: entry.email,
                name: entry.platformRole.name,
              },
              action: (
                <Dropdown
                  isInside
                  positionRight
                  options={[
                    {
                      label: 'Reset Authenticator 2FA',
                      isDisabled: !smsFactor || !totpFactor,
                      tooltip: !totpFactor
                        ? { content: 'User does not have Authenticator 2FA configured', placement: 'left' }
                        : !smsFactor
                          ? { content: 'Authenticator 2FA may not be reset without SMS 2FA enabled', placement: 'left' }
                          : undefined,
                      onClick: totpFactor ? () => handleUserDisableTotpMfa(entry, totpFactor) : undefined,
                    },
                    {
                      label: 'Remove',
                      isDanger: true,
                      isDisabled: currentUser.id === entry.id || entry.platformRole.name === 'owner',
                      onClick: () => handleUserRemoveConfirm(entry, entry.platformRole.platformId),
                    },
                  ]}
                  variant="dots"
                />
              ),
            });
          });
        }
        setUserList(entries);
      }),
      InviteRepository.getAll(currentPlatform.id).then((response) => {
        const entries: InviteList[] = [];
        if (response.invites.length) {
          response.invites.map((entry) => {
            entries.push({
              id: entry.platformId,
              email: entry.email,
              role: entry.platformRole,
              created: entry.createdAt,
              action: (
                <Dropdown
                  isInside
                  positionRight
                  options={[
                    {
                      label: 'Resend',
                      onClick: () => resendInvite(entry.email),
                    },
                    {
                      label: 'Delete',
                      onClick: () => deleteInvite(entry.platformId, entry.email),
                      isDanger: true,
                    },
                  ]}
                  variant="dots"
                />
              ),
            });
          });
        }
        setInviteList(entries);
      }),
    ])
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => console.error('InviteRepository.getAll', e));
  }, [currentPlatform, currentUser, setIsLoading]);

  const deleteInvite = useCallback(
    (platformId: string, email: string) => {
      InviteRepository.delete(platformId, email)
        .then(() => {
          fetchData();
          addSuccessNotification({
            content: 'Invite deleted',
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [fetchData]
  );

  const handleRoleChange = useCallback(
    (userId: string, email: string, role: PlatformRoleName) => {
      if (!currentPlatform) return;

      setUserList((list) => {
        const current = list.findIndex((u) => u.id === userId);

        if (current !== -1) {
          list[current].role = {
            id: userId,
            name: role,
            email,
          };
          return [...list];
        }

        return list;
      });

      UserRepository.updatePlatformRole(currentPlatform.id, {
        userId,
        precannedRoleName: role,
      })
        .then(() => {
          fetchData();

          addSuccessNotification({
            content: `The role from "${email}" is updated to "${precannedRoles[role]}" successfully.`,
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [currentPlatform]
  );

  const handleRemoveUser = useCallback((user: DashboardUserRedacted, platformId: string) => {
    UserRepository.removeUserFromPlatform({
      dashboardUserId: user.id,
      platformId,
    })
      .then(() => {
        fetchData();

        addSuccessNotification({
          content: `"${user.firstName} ${user.lastName}" successfully removed from this platform.`,
        });
      })
      .catch((error: any) => {
        addDangerNotification({
          content: error.message,
        });
      });
  }, []);

  const handleUserRemoveConfirm = useCallback(
    (user: DashboardUserRedacted, platformId: string) => {
      openAlert({
        headline: `Remove ${user.firstName}?`,
        text: `Are you sure you want to remove ${user.firstName} ${user.lastName} from this platform?.`,
        submitText: 'Remove',
        callback: () => handleRemoveUser(user, platformId),
      });
    },
    [handleRemoveUser]
  );

  const handleUserDisableTotpMfa = useCallback(
    (user: DashboardUserRedacted, totpFactor: Factor) => {
      openAlert({
        headline: `Disable Authenticator App for ${user.firstName}`,
        text: `This action cannot be undone. If authenticator app two-factor authentication is disabled, it can
          only be re-enabled by ${user.firstName}.`,
        submitText: 'Disable',
        size: 'small',
        callback: () => {
          UserRepository.deleteFactor({ factorId: totpFactor?.factorId })
            .then(() => {
              addSuccessNotification({
                content: 'Authenticator app disabled',
              });
              setUserList((list) =>
                list.map((u) =>
                  u.id === user.id
                    ? { ...u, factors: (u.factors ?? []).filter((f) => f.factorId !== totpFactor.factorId) }
                    : u
                )
              );
            })
            .catch((error) => {
              addDangerNotification({
                content: error.message,
              });
            });
        },
      });
    },
    [currentUser]
  );

  const resendInvite = useCallback(
    (email: string) => {
      if (!currentPlatform) return;

      InviteRepository.resend(email, currentPlatform.id)
        .then(() => {
          addSuccessNotification({
            content: 'Invite resent',
          });
        })
        .catch((error: any) => {
          addDangerNotification({
            content: error.message,
          });
        });
    },
    [currentPlatform]
  );

  const handleOpenInviteModal = useCallback(() => {
    if (!currentPlatform) return;
    openModal('Invite', { platformId: currentPlatform.id, callback: () => setTimeout(fetchData, 50) });
  }, [currentPlatform, fetchData]);

  const userColumns: TableColumn[] = useMemo(
    () =>
      currentPlatform
        ? [
            {
              Header: 'Name',
              accessor: 'info',
              width: 'minmax(300px, auto)',
              Cell: (current: { value: UserListInfo }) => (
                <StyledTruncate>
                  {current.value.name}
                  {(current.value.id === currentUser?.id || current.value.isOwner) && (
                    <UserInfo>
                      {current.value.id === currentUser?.id && <Chip type="info">You</Chip>}
                      {current.value.isOwner && <Chip>Owner</Chip>}
                    </UserInfo>
                  )}
                </StyledTruncate>
              ),
            },
            {
              Header: 'Email',
              accessor: 'email',
              width: 'min-content',
              Cell: (current: { value: string }) => <Truncate>{current.value}</Truncate>,
            },
            {
              Header: '2FA',
              accessor: 'factors',
              width: 'min-content',
              Cell: (current: { value: Factor[] }) => {
                const factors = current.value ?? [];
                if (factors.length === 0) {
                  return <Chip>Not enabled</Chip>;
                }
                if (factors.some((factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.TOTP)) {
                  return <Chip type={'success'}>Authenticator</Chip>;
                }
                if (factors.some((factor) => factor.factorVerified && factor.factorType === FactorTypeResponse.SMS)) {
                  return <Chip type={'success'}>SMS</Chip>;
                }
                return null;
              },
            },
            {
              Header: () => (
                <RoleHelpButton
                  icon={<Icon.CircleQuestionmark />}
                  onClick={() => openHelpSidebar('Roles')}
                  variant="subtle"
                  size="small"
                >
                  Role
                </RoleHelpButton>
              ),
              accessor: 'role',
              width: 'minmax(200px, min-content)',
              Cell: (current: { value: UserListRole }) => (
                <Dropdown
                  size="small"
                  active={current.value.name === 'owner' ? 'admin' : current.value.name}
                  onChange={(value: PlatformRoleName) => {
                    handleRoleChange(current.value.id, current.value.email, value);
                  }}
                  fullWidth
                  isDisabled={
                    (currentPermission?.platformInfo !== 'write' &&
                      currentPlatform.id === currentUser?.defaultPlatformId) ||
                    currentUser?.id === current.value.id ||
                    (current.value.name === 'owner' && currentPlatform.id === currentUser?.defaultPlatformId)
                  }
                  options={
                    currentUser?.id === current.value.id
                      ? precannedRolesDropdown
                      : precannedRolesDropdown.filter((g) => g.value !== 'owner')
                  }
                />
              ),
            },
            {
              Header: '',
              width: 'min-content',
              accessor: 'action',
            },
          ]
        : [],
    [currentPlatform, currentUser, currentPermission]
  );

  const inviteColumns: TableColumn[] = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        width: 'minmax(300px, auto)',
        Cell: (current: { value: string }) => <Truncate>{current.value}</Truncate>,
      },
      {
        Header: () => (
          <RoleHelpButton
            icon={<Icon.CircleQuestionmark />}
            onClick={() => openHelpSidebar('Roles')}
            variant="subtle"
            size="small"
          >
            Role
          </RoleHelpButton>
        ),
        accessor: 'role',
        width: 'minmax(116px, min-content)',
        Cell: (current: { value: PlatformRoleName }) => <>{precannedRoles[current.value]}</>,
      },
      {
        Header: 'Created',
        accessor: 'created',
        width: 'min-content',
        Cell: (current: { value: string }) => <RelativeTime timestamp={current.value} />,
      },
      {
        Header: '',
        width: 'min-content',
        accessor: 'action',
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
    setIsLoading(true);
  }, [currentPlatform]);

  return (
    <>
      <PageHeader text="Team" />
      <StyledFade show={isLoading}>
        <LogoLoading />
      </StyledFade>
      <Fade show={!isLoading}>
        <Relative>
          <Fade show={isLoading} base={StyledLoading} />
          <Fade show={!isLoading}>
            <SectionHeader text="Users" />
            <Inner pt={0} px={0}>
              <StyledTable columns={userColumns} data={userList} />
            </Inner>
            <Line />
            <SectionHeader text="Pending Invites">
              <Button onClick={handleOpenInviteModal} icon={<Icon.Mail />} size="small">
                Invite User
              </Button>
            </SectionHeader>

            <StyledTable columns={inviteColumns} data={inviteList} />
          </Fade>
        </Relative>
      </Fade>
    </>
  );
};
