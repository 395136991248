import { ReportingListParams, ReportingListResponse, ReportingRepository } from '~/repositories';
import { CustomizedBankAccountStatementParams } from '~/typings/API';

import { createApiHook } from './utils/createApiHook';

export type ReportsFilterType = ReportingListParams & {
  page?: number;
};

export const useCustomizedBankAccountStatement = createApiHook<string, CustomizedBankAccountStatementParams>(
  ReportingRepository.getCustomizedBankAccountStatement,
  {
    triggerAutomatically: false,
  }
);

export const useReporting = createApiHook<ReportingListResponse, ReportsFilterType>(ReportingRepository.getAll, {
  triggerAutomatically: true,
  triggerOnSessionStoreSubscribe: true,
  includeQueryParams: true,
  addQueryParamsToUrl: true,
  excludeQueryParams: ['page'],
});
