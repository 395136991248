import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import React, { ChangeEvent, DragEvent, FC, MouseEvent, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon } from '@column/column-ui-kit';

import {
  calculateDistance,
  calculateRotate,
  clampNumber,
  createParticles,
  getCirclePath,
  moveCircle,
  resetCircle,
} from '../FileUpload/utils';
import { ImageViewer } from '~/components/ImageCapture/ImageViewer';
import { NotificationList, StyledNotification } from '~/components/NotificationList';
import { ScannerIcon } from '~/elements';
import { CheckBackImage, CheckFrontImage, CheckRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { convertByteToString } from '~/util';

gsap.registerPlugin(MorphSVGPlugin);

export type ImageCaptureStatus = 'loading' | 'success' | 'default';
export type ImageCaptureSide = 'front' | 'back';

export interface ImageCaptureProps {
  className?: string;
  side: ImageCaptureSide;
  largePreview?: boolean;
  value?: string;
  onImageUpload?: () => void;
  onImageUploadDone?: () => void;
  onImageCaptured?: (data: CheckFrontImage | CheckBackImage) => void;
  onFileRemove?: () => void;
}

interface ImageUploadStyleProps {
  $isDragOver: boolean;
  $isShowCircle: boolean;
  $isShowRemove: boolean;
  $state: ImageCaptureStatus;
}

interface ImageUploadCircleStyleProps {
  $rotate: string;
}

const Wrapper = styled.div`
  display: grid;
  padding: 12px 0 0 0;
`;

const StyledNotificationList = styled(NotificationList)`
  ${StyledNotification} {
    margin: 0;
  }
`;

const Field = styled.div`
  position: relative;
`;

const UploadInner = styled.div<Partial<ImageUploadStyleProps & { $largePreview: boolean }>>`
  --upload-circle-opacity: ${({ $isShowCircle }) => ($isShowCircle ? '1' : '0')};
  --upload-circle-opacity-delay: ${({ $isShowCircle }) => ($isShowCircle ? '.1s' : '0s')};
  --upload-circle-pointer-events: ${({ $isShowCircle }) => !$isShowCircle && 'none'};
  --upload-dot-color: ${({ theme }) => theme.primary.background};
  --upload-file-name-opacity: 0;
  --upload-file-name-x: 4px;
  --upload-file-icon-opacity: 0;
  --upload-remove-button-opacity: ${({ $isShowRemove }) => ($isShowRemove ? '1' : '0')};
  --upload-remove-button-pointer-events: ${({ $isShowRemove }) => ($isShowRemove ? 'auto' : 'none')};

  ${({ $largePreview, $isShowRemove }) =>
    $largePreview
      ? `
        display: flex;
        flex-direction: column;
        padding: 0 44px;
        overflow: hidden;
      `
      : `
        display: table;
        table-layout: fixed;
        padding: 12px ${$isShowRemove ? '48px' : '2px'} 12px 40px;
  `}

  width: 100%;
  height: 120px;
  color: ${({ theme }) => theme.secondary.background};
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${({ $isDragOver, $state, theme }) =>
    $state === 'success'
      ? theme.background
      : $isDragOver
        ? theme.primary.blendToBackground(100)
        : theme.id !== 'dark'
          ? theme.secondary.blendToBackground(75)
          : theme.secondary.blendToBackground(25)};
  transition: background-color 0.15s;
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
  }

  &:before {
    border: 1px dashed
      ${({ $isDragOver, theme }) =>
        $isDragOver ? theme.primary.blendToBackground(850) : theme.secondary.blendToBackground(300)};
    transition: border-color 0.15s;
  }

  &:after {
    border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
    opacity: ${({ $state }) => ($state === 'success' ? '1' : '0')};
    transition: opacity 0.15s;
    z-index: 1;
  }

  &:hover {
    &:before {
      border-color: ${({ $isDragOver, $state, theme }) =>
        !$isDragOver && $state !== 'success' && theme.secondary.blendToBackground(700)};
    }
  }

  input {
    opacity: 0;
    position: absolute;
    inset: 0;
    cursor: pointer;
    pointer-events: ${({ $state }) => $state !== 'default' && 'none'};
  }
`;

const RemoveButton = styled(Button)`
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 4;
  transform: opacity 0.15s;
  opacity: var(--upload-remove-button-opacity);
  pointer-events: var(--upload-remove-button-pointer-events);
`;

const CircleWrapper = styled.div`
  --circle-svg-scale: 1;

  opacity: var(--upload-circle-opacity);
  pointer-events: var(--upload-circle-pointer-events);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -26px 0 0 -26px;
  z-index: 2;
  transition: opacity 0.15s ease var(--upload-circle-opacity-delay);
  color: ${({ theme }) => theme.primary.background};
`;

const Circle = styled.div`
  transform: translateY(var(--upload-circle-svg-y));
  position: relative;
  pointer-events: none;
`;

const CircleSVG = styled.svg.attrs<ImageUploadCircleStyleProps>(({ $rotate }) => ({
  style: {
    transform: `scale(var(--circle-svg-scale)) rotate(${$rotate}) translateZ(0)`,
  },
}))<ImageUploadCircleStyleProps>`
  display: block;
  width: 52px;
  height: 52px;
  position: relative;
  z-index: 2;
  fill: currentColor;
`;

const FileName = styled.div<{ $largePreview: boolean }>`
  ${({ $largePreview }) =>
    $largePreview
      ? `
        left: auto;
        top: auto;
        position: relative;
        padding: 8px 0 8px 0;

      `
      : `
        left: 121px;
        top: 50%;
        position: absolute;
        margin-top: -10px;
        transform: translateX(var(--upload-file-name-x)) translateZ(0);
        width: calc(100% - 152px);
        white-space: nowrap;
  `}

  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: table-cell;
  pointer-events: none;

  font-size: 12px;
  font-weight: 500;
  opacity: var(--upload-file-name-opacity);

  small {
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const FileIcon = styled.div<{ $isShow?: boolean }>`
  position: absolute;
  left: 12px;
  top: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary.background};
  z-index: 2;
  pointer-events: none;
  opacity: var(--upload-file-icon-opacity);
`;

const iconStyles = css`
  color: ${({ theme }) => theme.primary.foreground};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  transition: opacity 0.15s;
`;

const IconArrow = styled(Icon.ArrowDown)<{ $isShow: boolean }>`
  ${iconStyles}
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transition-delay: ${({ $isShow }) => ($isShow ? '.1s' : '0s')};
`;

const IconLoading = styled(Icon.Loading)<{ $isShow: boolean }>`
  ${iconStyles}
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transition-delay: ${({ $isShow }) => ($isShow ? '.1s' : '0s')};
`;

const IconCheck = styled(Icon.Checkmark)<{ $isShow: boolean }>`
  ${iconStyles}
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transition-delay: ${({ $isShow }) => ($isShow ? '.1s' : '0s')};
`;

const UploadText = styled.div<{ $isHidden?: boolean }>`
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  pointer-events: none;
  opacity: ${({ $isHidden }) => $isHidden && '0'};
  transform: translate(0, ${({ $isHidden }) => ($isHidden ? '0%' : '-50%')}) translateZ(0);
  transition:
    transform 0.15s,
    opacity 0.15s ease ${({ $isHidden }) => ($isHidden ? '0s' : '.1s')};

  strong {
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    font-weight: 600;
  }
`;

const UploadIcon = styled(ScannerIcon)`
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};

  svg {
    width: 52px;
    height: 37px;
    display: block;
    margin: 0 auto 12px auto;
  }
`;

const ImagePreview = styled.div<{ $isLoading: boolean; $isShow: boolean; $largePreview: boolean }>`
  --image-capture-loading-opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0')};
  --image-capture-loading-scale: ${({ $isLoading }) => ($isLoading ? '1' : '.75')};

  ${({ $largePreview, $isLoading, $isShow }) =>
    $largePreview
      ? `
        position: relative;
        width: auto;
        height: auto;
        top: 0;
        left: 0;
        margin: 12px 0 0 0;
      `
      : `
        position: absolute;    
        width: 69px;
        height: 32px;
        top: 6px;
        left: 44px;
        --image-capture-loading-y: ${$isLoading ? '0px' : '4px'};
        transform: translateX(${$isShow ? '0' : '-4px'});
  `}

  pointer-events: ${({ $isShow }) => ($isShow ? 'auto' : 'none')};
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};

  transition:
    opacity 0.2s ${({ $isShow }) => ($isShow ? '0.5s' : '0s')},
    transform 0.25s ${({ $isShow }) => ($isShow ? '0.5s' : '0s')};
  pointer-events: none;
`;

const ImagePreviewLoading = styled(Icon.Loading)`
  --icon-size: 18px;

  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -9px 0 0 -9px;
  opacity: var(--image-capture-loading-opacity);
  transform: translateY(var(--image-capture-loading-y)) scale(var(--image-capture-loading-scale)) translateZ(0);
  transition:
    transform 0.3s,
    opacity 0.2s;
`;

const ImagePreviewViewer = styled.div<{ $isImageShow: boolean }>`
  opacity: ${({ $isImageShow }) => ($isImageShow ? '1' : '0')};
  transform: scale(${({ $isImageShow }) => ($isImageShow ? '1' : '.8')}) translateZ(0);
  transition:
    opacity 0.2s,
    transform 0.25s;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  object-fit: cover;
  object-position: center center;
  border-radius: 3px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

export const ImageCapture: FC<ImageCaptureProps> = ({
  className,
  side,
  largePreview = false,
  onImageUpload,
  onImageUploadDone,
  onImageCaptured,
  onFileRemove,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const circleWrapperRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const circleSVGRef = useRef<SVGSVGElement>(null);
  const pathRef = useRef<SVGPathElement>(null);

  const circleTweenRef = useRef<gsap.core.Tween>();
  const [circleRotation, setCircleRotation] = useState<string>('0deg');

  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const { currentUser } = useSessionStore();

  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isDragOverCircle, setIsDragOverCircle] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const [isImageShow, setImageShow] = useState<boolean>(false);
  const [isPreviewShow, setIsPreviewShow] = useState<boolean>(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(true);

  const [state, setState] = useState<ImageCaptureStatus>('default');

  const [file, setFile] = useState<File | undefined>(undefined);
  const [_, setFileObject] = useState<CheckFrontImage | CheckBackImage | undefined>(undefined);

  const setDistance = (distance: number, options?: gsap.TweenVars) => {
    const duration = options?.duration ?? 0.15;

    gsap.to(pathRef.current, {
      morphSVG: getCirclePath(distance),
      duration,
      ...options,
    });
  };

  const handleDragEnterCircle = () => {
    setDistance(12);

    setIsDragOverCircle(true);
  };

  const handleDragLeaveCircle = () => {
    setIsDragOverCircle(false);
  };

  const handleDragEnter = useCallback((event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();

    setIsDragOver(true);

    if (circleTweenRef.current) {
      circleTweenRef.current.play();
      return;
    }

    circleTweenRef.current = gsap.to(circleRef.current, {
      repeat: -1,
      keyframes: [
        {
          '--upload-circle-svg-y': '4px',
          duration: 0.4,
        },
        {
          '--upload-circle-svg-y': '-4px',
          duration: 0.8,
        },
        {
          '--upload-circle-svg-y': '0px',
          duration: 0.4,
        },
      ],
    });
  }, []);

  const handleDragLeave = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();

    setDistance(12, {
      duration: 0.25,
    });

    setIsDragOver(false);
  };

  const handleDragOver = useCallback(
    (event: DragEvent<HTMLInputElement>) => {
      const element = event.target as HTMLElement;

      if (!circleRef.current) {
        return;
      }

      setCircleRotation(
        `${calculateRotate(element, {
          mouseX: event.pageX,
          mouseY: event.pageY,
        })}deg`
      );

      setDistance(
        clampNumber(
          calculateDistance(circleRef.current, element, {
            mouseX: event.pageX,
            mouseY: event.pageY,
          })
        ) * 12
      );

      setIsDragOver(true);
    },
    [circleRef]
  );

  const handleDragOverPrevent = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleRemoveButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const uploadElement = uploadRef.current;

    setState('default');
    setIsDragOver(false);
    setIsDone(false);
    setIsPreviewShow(false);
    setImageShow(false);
    setIsPreviewLoading(true);
    setFile(undefined);
    setFileObject((s) => {
      if (s && onFileRemove) {
        onFileRemove();
      }
      return undefined;
    });

    const circleWrapperElement = circleWrapperRef.current;

    if (circleWrapperElement) {
      resetCircle(circleWrapperElement);
    }

    gsap.to(uploadElement, {
      '--upload-file-name-opacity': '0',
      '--upload-file-name-x': '4px',
      '--upload-file-icon-opacity': '0',
      height: '120px',
      duration: 0.25,
    });

    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  const handleImageCapture = useCallback(
    (uploadFile: File, onSuccess: () => void, onError: () => void) => {
      if (!currentUser?.defaultPlatformId) {
        return;
      }

      setIsDone(false);
      setFile(uploadFile);
      setState('loading');

      if (onImageUpload) {
        onImageUpload();
      }

      const methodName = side === 'front' ? 'captureFrontImage' : 'captureBackImage';
      CheckRepository[methodName]({ file: uploadFile })
        .then((response) => {
          onSuccess();

          setIsDone(true);
          setFileObject(response);

          setIsPreviewShow(true);

          if (onImageUploadDone) {
            onImageUploadDone();
          }

          if (onImageCaptured) {
            onImageCaptured(response);
          }

          setTimeout(() => setImageShow(true), 200);
          setIsPreviewLoading(false);
        })
        .catch((error) => {
          onError();

          if (onImageUploadDone) {
            onImageUploadDone();
          }

          addDangerNotification({
            content: error.message,
            display: `capture-image-${side}`,
          });
        });
    },
    [side, onImageCaptured, onImageUpload, onImageUploadDone, currentUser?.defaultPlatformId]
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const uploadElement = uploadRef.current;
      const circleWrapperElement = circleWrapperRef.current;

      if (!uploadElement || !circleWrapperElement || state !== 'default') {
        return;
      }

      const eventFile =
        event.dataTransfer.files && event.dataTransfer.files?.length > 0 ? event.dataTransfer.files[0] : undefined;

      if (!uploadRef.current || !circleRef.current || !eventFile) {
        return;
      }

      if (circleTweenRef.current) {
        circleTweenRef.current.pause();

        gsap.to(circleRef.current, {
          '--upload-circle-svg-y': '0px',
        });
      }

      setState('loading');

      createParticles(
        uploadElement,
        circleRef.current,
        {
          mouseX: event.pageX,
          mouseY: event.pageY,
        },
        () => {
          setDistance(18, {
            duration: 0.15,
            onComplete: () => {
              setDistance(12, {
                duration: 0.2,
              });

              handleImageCapture(
                eventFile,
                () => {
                  moveCircle(circleWrapperElement, uploadElement, largePreview, () => {
                    gsap.to(uploadElement, {
                      '--upload-file-name-opacity': '1',
                      '--upload-file-name-x': '0px',
                      duration: 0.25,
                    });
                  });
                  setState('success');
                },
                () => {
                  setTimeout(() => {
                    setState('default');
                    setIsDragOver(false);
                    setIsDone(false);
                    setIsPreviewShow(false);
                    setImageShow(false);
                    setIsPreviewLoading(true);
                    setFile(undefined);
                    setFileObject((s) => {
                      if (s && onFileRemove) {
                        onFileRemove();
                      }
                      return undefined;
                    });

                    if (circleWrapperElement) {
                      resetCircle(circleWrapperElement);
                    }

                    gsap.to(uploadElement, {
                      '--upload-file-name-opacity': '0',
                      '--upload-file-name-x': '4px',
                      '--upload-file-icon-opacity': '0',
                      height: '120px',
                      duration: 0.25,
                    });
                  }, 0);
                }
              );
            },
          });
        }
      );
    },
    [state, side]
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const uploadElement = uploadRef.current;
    const circleElement = circleWrapperRef.current;

    if (!uploadElement || !circleElement || state !== 'default') {
      return;
    }

    const eventFile = event.target.files && event.target.files?.length > 0 ? event.target.files[0] : undefined;

    if (!eventFile) {
      return;
    }

    setState('loading');

    handleImageCapture(
      eventFile,
      () => {
        moveCircle(circleElement, uploadElement, largePreview, () => {
          gsap.to(uploadElement, {
            '--upload-file-name-opacity': '1',
            '--upload-file-name-x': '0px',
            '--upload-file-icon-opacity': '1',
            duration: 0.25,
          });

          gsap.to(uploadElement, {
            height: largePreview ? 'auto' : '44px',
            duration: 0.3,
          });

          setState('success');
        });
      },
      () => {
        setTimeout(() => {
          setState('default');
          setIsDragOver(false);
          setIsDone(false);
          setIsPreviewShow(false);
          setImageShow(false);
          setIsPreviewLoading(true);
          setFile(undefined);
          setFileObject((s) => {
            if (s && onFileRemove) {
              onFileRemove();
            }
            return undefined;
          });

          if (circleElement) {
            resetCircle(circleElement);
          }

          gsap.to(uploadElement, {
            '--upload-file-name-opacity': '0',
            '--upload-file-name-x': '4px',
            '--upload-file-icon-opacity': '0',
            height: '120px',
            duration: 0.25,
          });
        }, 0);

        if (inputRef.current?.value) {
          inputRef.current.value = '';
        }
      }
    );
  };

  return (
    <Wrapper className={className}>
      <StyledNotificationList display={`capture-image-${side}`} />
      <Field>
        <UploadInner
          ref={uploadRef}
          $isShowCircle={isDragOver || isDragOverCircle || state === 'loading'}
          $isShowRemove={isDone}
          $isDragOver={isDragOver || isDragOverCircle}
          $state={state}
          onDrop={handleDrop}
          onDragOver={handleDragOverPrevent}
          $largePreview={largePreview || false}
        >
          <ImagePreview $isShow={isPreviewShow} $isLoading={isPreviewLoading} $largePreview={largePreview || false}>
            <ImagePreviewLoading />
            <ImagePreviewViewer $isImageShow={isImageShow}>
              <ImageViewer src={value} />
            </ImagePreviewViewer>
          </ImagePreview>
          <input
            ref={inputRef}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragLeave}
            type="file"
            name="file"
            onChange={handleFileChange}
          />
          <CircleWrapper
            ref={circleWrapperRef}
            onDragEnter={handleDragEnterCircle}
            onDragOver={handleDragEnterCircle}
            onDragLeave={handleDragLeaveCircle}
            onDragEnd={handleDragLeaveCircle}
          >
            <Circle ref={circleRef}>
              <CircleSVG ref={circleSVGRef} $rotate={circleRotation} viewBox="0 0 92 92">
                <path
                  ref={pathRef}
                  d="M46,80 C55.3966448,80 63.9029705,76.1880913 70.0569683,70.0262831 C76.2007441,63.8747097 80,55.3810367 80,46 C80,36.6003571 76.1856584,28.0916013 70.0203842,21.9371418 C63.8692805,15.7968278 55.3780386, 12 46, 12 C36.596754, 12 28.0850784,15.8172663 21.9300655,21.9867066 C15.7939108,28.1372443 12,36.6255645 12,46 C12,55.4035343 15.8175004,63.9154436 21.9872741,70.0705007 C28.1377665,76.2063225 36.6258528,80 46,80 Z"
                />
              </CircleSVG>

              <IconArrow $isShow={state === 'default'} />
              <IconLoading $isShow={state === 'loading'} />
              <IconCheck $isShow={state === 'success'} />
            </Circle>
          </CircleWrapper>
          <UploadText $isHidden={isDragOver || isDragOverCircle || (state && state !== 'default')}>
            <UploadIcon side={side} />
            Drag file here, or <strong>click to upload</strong>
          </UploadText>
          <FileIcon>
            <IconLoading $isShow={state === 'loading'} />
            <IconCheck $isShow={state === 'success'} />
          </FileIcon>
          <FileName $largePreview={largePreview || false}>
            {file?.name} <small>({convertByteToString(file?.size ?? 0)})</small>
          </FileName>
          <RemoveButton
            icon={<Icon.Cross />}
            variant="subtle"
            size="small"
            type="button"
            onlyIcon
            onClick={handleRemoveButtonClick}
          />
        </UploadInner>
      </Field>
    </Wrapper>
  );
};
