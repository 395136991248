import React, { FC, ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonProps, Dropdown, DropdownOption, Icon } from '@column/column-ui-kit';

type ButtonType = ButtonProps & { label: string };
type IconType = 'chevron' | 'dots';

interface ButtonGroupDropdownProps {
  buttons: (ButtonType | ReactNode)[];
  options: DropdownOption[];
  type?: IconType;
}

const IconMore = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  box-shadow: inset 1px 0 0 ${({ theme }) => theme.style.buttonPrimaryBorderColor};

  &:hover {
    background: var(--button-background, ${({ theme }) => theme.style.buttonPrimaryHoveredBackgroundColor});
    box-shadow:
      ${({ theme }) => theme.style.buttonPrimaryHoveredBorderProperties}
        ${({ theme }) => theme.style.buttonPrimaryHoveredBorderWidth}
        ${({ theme }) => theme.style.buttonPrimaryHoveredBorderColor},
      inset 0 -1px 0 rgba(0, 0, 0, 0.3);
  }

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.primary.foreground};
  }
`;

const ButtonGroupDropdownWrapper = styled.div<{ $type: IconType }>`
  position: relative;
  display: flex;
  height: 32px;

  ${({ $type }) =>
    $type === 'dots' &&
    css`
      gap: 8px;
    `}
`;

const StyledButton = styled(Button)<{ $isLast?: boolean; $type: IconType }>`
  ${({ $type }) =>
    $type === 'chevron' &&
    css<{ $isLast?: boolean }>`
      ${({ $isLast }) => (!$isLast ? 'border-radius: 8px 0 0 8px;' : 'padding-right: 43px;')}
    `}
`;

const StyledDropdown = styled(Dropdown)<{ $type: IconType }>`
  ${({ $type }) =>
    $type === 'chevron' &&
    css`
      --dropdown-custom-padding: 0;
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

const IconDots = styled(Icon.Dots)<{ $isOpen?: boolean }>`
  transition: transform 0.2s;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(0)')};
`;

export const ButtonGroupDropdown: FC<ButtonGroupDropdownProps> = ({ buttons, options, type = 'chevron' }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <ButtonGroupDropdownWrapper $type={type}>
      {buttons.map((button, index) => {
        if (!!button && typeof button === 'object' && 'label' in button) {
          const { label, ...buttonProps } = button;

          return (
            <StyledButton key={index} size="small" $isLast={index === buttons.length - 1} $type={type} {...buttonProps}>
              {label}
            </StyledButton>
          );
        }

        return button;
      })}

      {options.length > 0 && (
        <StyledDropdown
          positionRight
          onOpenChange={setIsOpen}
          $type={type}
          customLabel={
            type === 'chevron' ? (
              <IconMore>{isOpen ? <Icon.ChevronUpLarge /> : <Icon.ChevronDownLarge />}</IconMore>
            ) : (
              <Button size="small" variant="secondary" onlyIcon icon={<IconDots $isOpen={isOpen} />} />
            )
          }
          options={options}
          maxHeight="216px"
        />
      )}
    </ButtonGroupDropdownWrapper>
  );
};
