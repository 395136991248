import { ReactNode } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export type HelpSidebarType = 'Onboarding' | 'Support' | 'Custom' | 'Roles';

export interface HelpSidebarCustomType {
  headline: string;
  content: ReactNode;
}

interface HelpSidebarStoreState {
  openHelpSidebars: HelpSidebarType[];
  contentHelpSidebarCustom?: HelpSidebarCustomType;
}

interface HelpSidebarStoreComputed {
  getHelpSidebarIsOpen: () => boolean;
  getHelpSidebar: () => HelpSidebarType | undefined;
}

interface HelpSidebarStoreAction {
  openHelpSidebar: (helpSidebarType: HelpSidebarType) => void;
  openHelpSidebarCustom: (contentHelpSidebarCustom: HelpSidebarCustomType) => void;
  replaceHelpSidebar: (helpSidebarType: HelpSidebarType) => void;
  closeHelpSidebar: (helpSidebarType?: HelpSidebarType) => void;
}

export const useHelpSidebarStore = create<HelpSidebarStoreState & HelpSidebarStoreComputed & HelpSidebarStoreAction>()(
  subscribeWithSelector((set, get) => ({
    openHelpSidebars: [],

    getHelpSidebarIsOpen: () => get()?.openHelpSidebars.length > 0,
    getHelpSidebar: () => (get()?.openHelpSidebars.length > 0 ? get()?.openHelpSidebars[0] : undefined),

    openHelpSidebar: (helpSidebarType) => {
      set((state) => ({
        openHelpSidebars: [...state.openHelpSidebars, helpSidebarType],
      }));
    },

    openHelpSidebarCustom: (contentHelpSidebarCustom: HelpSidebarCustomType) => {
      set(() => ({
        openHelpSidebars: ['Custom'],
        contentHelpSidebarCustom,
      }));
    },

    replaceHelpSidebar: (helpSidebarType) => {
      set(() => ({
        openHelpSidebars: [helpSidebarType],
      }));
    },

    closeHelpSidebar: (helpSidebarType) => {
      if (helpSidebarType) {
        if (helpSidebarType === 'Custom') {
          setTimeout(() => {
            set(() => ({
              contentHelpSidebarCustom: undefined,
            }));
          }, 200);
        }

        set((state) => ({
          openHelpSidebars: state.openHelpSidebars.filter((m: HelpSidebarType) => m !== helpSidebarType),
        }));

        return;
      }

      set(() => ({
        openHelpSidebars: [],
      }));
    },
  }))
);
