import { DropdownOption } from '@column/column-ui-kit';

import { PlatformRoleName } from '~/repositories/UserRepository';
import {
  CreateCustomRoleRequestObject,
  CustomRoleResponseObject,
  CustomRolesList,
  UpdateCustomRoleRequestObject,
} from '~/typings/API';

import { client } from './client';

export const precannedRoles: Record<PlatformRoleName, string> = {
  owner: 'Owner',
  admin: 'Admin',
  money_mover: 'Money Mover',
  developer: 'Developer',
  readonly: 'Viewer',
  check_depositor: 'Check Depositor',
};
export const precannedRolesDropdown: DropdownOption[] = Object.keys(precannedRoles).map((value) => ({
  label: precannedRoles[value as PlatformRoleName],
  value,
}));

const parseCustomRole = ({ name, editable, ...rest }: CustomRoleResponseObject): CustomRoleResponseObject => {
  let parsedName = name;
  if (!editable && precannedRoles[name as PlatformRoleName]) {
    parsedName = precannedRoles[name as PlatformRoleName];
  }

  return {
    ...rest,
    name: parsedName,
    editable,
  };
};

export class RoleRepository {
  static async create(request: CreateCustomRoleRequestObject) {
    return client.post<unknown, CustomRoleResponseObject>('/dashboard/roles', request);
  }

  static async get({ roleId }: { roleId: string }) {
    return client.get<unknown, CustomRoleResponseObject>(`/dashboard/roles/${roleId}`).then(parseCustomRole);
  }

  static async list() {
    return client.get<unknown, CustomRolesList>('/dashboard/roles').then(({ roles }) => ({
      roles: roles ? roles.map(parseCustomRole) : undefined,
    }));
  }

  static async update({ roleId, ...request }: { roleId: string } & UpdateCustomRoleRequestObject) {
    return client.patch<unknown, CustomRoleResponseObject>(`/dashboard/roles/${roleId}`, request);
  }
}
