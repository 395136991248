import React, { FC, PropsWithChildren, Suspense, useLayoutEffect, useMemo, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ROUTE as APP_ROUTE } from '~/app/routes';
import { LogoLoading } from '~/elements';
import { ROUTE } from '~/public/routes';
import { useSessionStore } from '~/stores/Session';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const loadingRef = useRef<HTMLDivElement>(null);
  const { isLoading, signIn, signOut, authRequired, isSignedIn } = useSessionStore();
  const location = useLocation();

  const redirectURL = useMemo(
    () =>
      (location.pathname !== ROUTE.ROOT && location.pathname !== APP_ROUTE.ROOT) || location.search
        ? `redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`
        : '',
    [location]
  );

  useLayoutEffect(() => {
    if (isSignedIn() && !authRequired()) {
      signIn().catch(signOut);
    } else {
      signOut();
    }
  }, []);

  return (
    <Suspense fallback={<LogoLoading ref={loadingRef} />}>
      {isLoading ? (
        <LogoLoading ref={loadingRef} />
      ) : isSignedIn() ? (
        children
      ) : (
        <Navigate to={{ pathname: `${ROUTE.LOGIN}`, search: redirectURL }} />
      )}
    </Suspense>
  );
};
