import {
  CounterpartyRepository,
  FinancialInstitution,
  GetInstitutionRequest,
  ListExternalFinancialInstitutionParams,
  ListExternalFinancialInstitutionResponse,
} from '~/repositories';

import { createApiHook } from './utils/createApiHook';

export const useInstitution = createApiHook<FinancialInstitution, GetInstitutionRequest>(
  CounterpartyRepository.getInstitution
);

export const useInstitutions = (triggerAutomatically: boolean) =>
  createApiHook<ListExternalFinancialInstitutionResponse, ListExternalFinancialInstitutionParams>(
    CounterpartyRepository.getAllInstitutions,
    {
      triggerAutomatically,
      triggerOnSessionStoreSubscribe: true,
      includeQueryParams: true,
    }
  );
