import { APIRequest } from '~/repositories/client/Request';
import { useSessionStore } from '~/stores/Session';
import {
  ReportingSettlementReport,
  ReportingListSettlementReportResponse,
  ReportingServiceListSettlementReportsParams,
  ReportingServiceScheduleSettlementReportPayload,
  CustomizedBankAccountStatementParams,
} from '~/typings/API';
import { convertCamelCaseStringToSnakeCase, convertValues } from '~/util';

import { client } from './client';

export type { ReportingSettlementReport };
export type ReportingListResponse = ReportingListSettlementReportResponse;
export type ReportingListParams = ReportingServiceListSettlementReportsParams;
export type Report = ReportingSettlementReport;
export type ReportType = 'bankAccountTransaction' | 'bankAccountSummary' | 'bankAccountMonthlyStatement';
export type ReportStatus = 'initiated' | 'scheduled' | 'completed' | 'failed';

export type DocumentExtensionType = 'pdf' | 'parquet' | 'json' | 'bai2' | 'csv';
export type ReportFileEntry = {
  label: string;
  fieldId: string;
};

export const DocumentTypeMap = new Map<DocumentExtensionType, ReportFileEntry>([
  ['pdf', { label: 'PDF', fieldId: 'pdfDocumentId' }],
  ['csv', { label: 'CSV', fieldId: 'csvDocumentId' }],
  ['bai2', { label: 'BAI2', fieldId: 'bai2DocumentId' }],
  ['parquet', { label: 'Parquet', fieldId: 'parquetDocumentId' }],
  ['json', { label: 'JSON', fieldId: 'jsonDocumentId' }],
]);

const documentCast = ({ toDate, fromDate }: Report) => ({
  toDate,
  fromDate,
});

export class ReportingRepository {
  static async schedule(request: ReportingServiceScheduleSettlementReportPayload) {
    return client
      .post<ReportingServiceScheduleSettlementReportPayload, Report>('/reporting', request)
      .then((response) => convertValues<Report>(response));
  }

  static async getAll(query?: ReportingListParams) {
    return client
      .get<ReportingListParams, ReportingListResponse>('/reporting', {
        query: query?.type ? { ...query, type: convertCamelCaseStringToSnakeCase(query.type) } : query,
      })
      .then((response) => ({
        reports: Array.isArray(response?.reports)
          ? response.reports.map((e: Report) => convertValues<Report>(e, undefined, documentCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async get(id: string) {
    return client
      .get<any, Report>(`/reporting/${id}`)
      .then((response) => convertValues<Report>(response, undefined, documentCast(response)));
  }

  static async getCustomizedBankAccountStatement(query: CustomizedBankAccountStatementParams) {
    const request = new APIRequest<undefined, CustomizedBankAccountStatementParams>(
      '/reporting/customized-bank-account-statement',
      'GET',
      undefined,
      query,
      {
        'Content-Type': 'application/json',
        'Live-Mode': useSessionStore.getState().isSandbox ? 'No' : 'Yes',
        'Platform-Id': useSessionStore.getState().currentUser?.defaultPlatformId ?? '',
      }
    );

    const response = await fetch(request.getRequest());
    const rawResponse = await response.text();

    let errMessage = null;
    try {
      const json = JSON.parse(rawResponse);
      errMessage = json.message;
    } catch {
      /* Happy path - response is CSV, not JSON */
    }

    if (errMessage) {
      throw new Error(errMessage);
    }

    return rawResponse;
  }
}
