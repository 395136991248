import isEqual from 'lodash.isequal';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { useQueryParams } from '../useQueryParams';
import { useSessionStore } from '~/stores/Session';
import { ApiError } from '~/typings/API';

interface FetchProps<Response, Request> {
  onPlatformChange: (data: Response) => void;
  onSuccess: (data: Response) => void;
  onError: (error: ApiError) => void;
  onInitialLoad: (data: Response) => void;
  initialQueryParams: Request;
  initialParams: Request;
  initialLoad: boolean;
  persistParams: Request;
  shouldNotLoad: boolean;
}

export type RepositoryMethod<Response, Request> = (params: Request) => Promise<Response>;

interface HookOptions<Request> {
  triggerAutomatically: boolean;
  triggerOnSessionStoreSubscribe: boolean;
  includeQueryParams: boolean;
  addQueryParamsToUrl: boolean;
  excludeQueryParams: (keyof Request)[];
  requiresAuth: boolean;
}

type TriggerSource = 'session' | 'automatic' | 'query' | 'manual';

export const createApiHook =
  <Response, Request>(method: RepositoryMethod<Response, Request>, options: Partial<HookOptions<Request>> = {}) =>
  ({
    onSuccess,
    onError,
    onInitialLoad,
    onPlatformChange,
    initialQueryParams,
    initialParams,
    initialLoad = false,
    persistParams,
    shouldNotLoad,
  }: Partial<FetchProps<Response, Request>> = {}) => {
    const {
      triggerAutomatically,
      triggerOnSessionStoreSubscribe,
      includeQueryParams,
      addQueryParamsToUrl,
      excludeQueryParams,
      requiresAuth = true,
    } = options;
    const [stateChangeTrigger, setStateChangeTrigger] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [response, setResponse] = useState<Response | undefined>(undefined);
    const queryParamsRef = useRef<Request | undefined>(initialQueryParams);
    const [initialLoadLocal, setInitialLoadLocal] = useState<boolean>(true);

    const triggerSourceRef = useRef<TriggerSource>('manual');

    const {
      queryParams: urlQueryParams,
      setQueryParams: setUrlQueryParams,
      setQueryParam: setUrlQueryParam,
    } = useQueryParams<Request | undefined>();

    const [queryParams, setLocalQueryParams] = useState<Request | undefined>(initialQueryParams);

    const setQueryParams = useCallback(
      (newParams: Request | undefined, overwrite?: boolean) => {
        if (overwrite) {
          setLocalQueryParams(() => newParams);

          return;
        }

        if (!isEqual(queryParams, newParams)) {
          setLocalQueryParams((prev) => (!prev ? newParams : { ...prev, ...newParams }));
        }
      },
      [setLocalQueryParams, queryParams]
    );

    const isInitialLoad = useMemo(() => isLoading && initialLoadLocal, [isLoading, initialLoadLocal]);

    const createRequest = useCallback(
      async (params?: Request): Promise<Response | void> => {
        if (shouldNotLoad || (requiresAuth && !useSessionStore.getState().currentUser?.id)) {
          return;
        }

        setIsLoading(true);

        try {
          const parameters = {
            ...(includeQueryParams || addQueryParamsToUrl
              ? { ...params, ...initialQueryParams, ...queryParams }
              : initialParams
                ? { ...initialParams, ...params }
                : params),
            ...(persistParams && Object.values(persistParams) ? persistParams : {}),
          } as Request;

          Object.keys(parameters as Record<string, unknown>).forEach((key) => {
            if (parameters[key as keyof Request] === undefined) {
              delete parameters[key as keyof Request];
            }
          });

          if (excludeQueryParams && excludeQueryParams?.length > 0) {
            excludeQueryParams.forEach((key) => {
              delete parameters[key as keyof Request];
            });
          }

          const result = await method(parameters);
          setResponse(result);

          if (onSuccess) {
            onSuccess(result);
          }

          if (initialLoadLocal && onInitialLoad) {
            onInitialLoad(result);
          }

          if (initialLoadLocal) {
            setInitialLoadLocal(false);
          }

          return result;
        } catch (error) {
          if (error === 'Unauthorized') {
            return;
          }

          if (onError) {
            onError(error as ApiError);
          }

          throw error;
        } finally {
          setIsLoading(false);
        }
      },
      [
        initialLoadLocal,
        onSuccess,
        onError,
        onInitialLoad,
        initialParams,
        persistParams,
        includeQueryParams,
        addQueryParamsToUrl,
        initialQueryParams,
        method,
        shouldNotLoad,
        queryParams,
        requiresAuth,
      ]
    );

    useEffect(() => {
      if (stateChangeTrigger > 0) {
        createRequest().then((data) => {
          if (data && triggerSourceRef.current === 'session' && onPlatformChange) {
            onPlatformChange(data);
          }
        });
      }
    }, [stateChangeTrigger]);

    useEffect(() => {
      if (addQueryParamsToUrl) {
        setUrlQueryParams(() => queryParams);
      }
    }, [queryParams, addQueryParamsToUrl]);

    useEffect(() => {
      const unsubscribe = useSessionStore.subscribe(
        (state) => [state.isLoading, state.isSignedIn(), state.currentUser?.defaultPlatformId, state.isSandbox],
        (state) => {
          const isLoadingState = state[0] as boolean | undefined;
          const isSignedIn = state[1] as boolean | undefined;
          const defaultPlatformId = state[2] as string | undefined;

          if (isLoadingState || !isSignedIn || !triggerOnSessionStoreSubscribe) {
            return;
          }

          if (defaultPlatformId) {
            triggerSourceRef.current = 'session';

            setStateChangeTrigger((prevState) => prevState + 1);
          }
        },
        {
          fireImmediately: triggerAutomatically || initialLoad,
          equalityFn: shallow,
        }
      );

      return () => unsubscribe();
    }, [triggerOnSessionStoreSubscribe, triggerAutomatically, initialLoad]);

    useEffect(() => {
      if (
        (triggerAutomatically || initialLoad) &&
        !triggerOnSessionStoreSubscribe &&
        (!requiresAuth || useSessionStore.getState().currentUser?.id)
      ) {
        triggerSourceRef.current = 'automatic';
        setStateChangeTrigger((prev) => prev + 1);
      }
    }, [triggerAutomatically, triggerOnSessionStoreSubscribe, requiresAuth, initialLoad]);

    useEffect(() => {
      if (includeQueryParams && queryParamsRef.current !== queryParams) {
        triggerSourceRef.current = 'query';

        setStateChangeTrigger((prev) => prev + 1);

        queryParamsRef.current = queryParams;
      }
    }, [queryParams, includeQueryParams, queryParamsRef.current]);

    const getFilteredQueryParams = useCallback(
      (excludeParams: string[]): Request => {
        return Object.fromEntries(
          Object.entries(queryParams || {}).filter(([key]) => !excludeParams.includes(key))
        ) as Request;
      },
      [queryParams]
    );

    return {
      createRequest,
      response,
      isLoading,
      isInitialLoad,
      queryParams,
      setQueryParams,
      setUrlQueryParams,
      setUrlQueryParam,
      getFilteredQueryParams,
      urlQueryParams,
    };
  };
